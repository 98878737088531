import {
    Company,
    GetCompanyResponseModel,
} from "src/app/services/response-models/companies.response.model";
import { Pagination } from "src/app/services/response-models/pagination.response.model";
import { Currency } from "src/app/services/response-models/currency.model";

export class SetCompanies {
    public static readonly type = "[Companies] Set companies";

    constructor(public payload: GetCompanyResponseModel) {}
}

export class SaveCompaniesPagination {
    public static readonly type = "[Companies] Save companies pagination";

    constructor(public payload: Pagination) {}
}

export class ClearCompanies {
    public static readonly type = "[Companies] Clear companies";

    constructor() {}
}

export class SetCompanyVendors {
    public static readonly type = "[Companies] Set company vendors";

    constructor(public payload: Company[]) {}
}

export class SetCompanyIntegrationSettings {
    public static readonly type = "[Companies] Set company integration settings";

    constructor(public payload: Company[]) {}
}

export class SetCompanyCurrency {
    public static readonly type = "[Companies] Set company currency";

    constructor(public payload: Currency) {}
}
