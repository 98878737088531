/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMe200ResponseLogos
 */
export interface GetMe200ResponseLogos {
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseLogos
     */
    small: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseLogos
     */
    large: string;
}

/**
 * Check if a given object implements the GetMe200ResponseLogos interface.
 */
export function instanceOfGetMe200ResponseLogos(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "small" in value;
    isInstance = isInstance && "large" in value;

    return isInstance;
}

export function GetMe200ResponseLogosFromJSON(json: any): GetMe200ResponseLogos {
    return GetMe200ResponseLogosFromJSONTyped(json, false);
}

export function GetMe200ResponseLogosFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMe200ResponseLogos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'small': json['small'],
        'large': json['large'],
    };
}

export function GetMe200ResponseLogosToJSON(value?: GetMe200ResponseLogos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'small': value.small,
        'large': value.large,
    };
}

