/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
 */
export interface UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner {
    /**
     * 
     * @type {string}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
     */
    formId: string;
    /**
     * 
     * @type {string}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
     */
    entryType: string;
    /**
     * 
     * @type {Date}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
     */
    formApproved?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
     */
    timeUnit: string;
    /**
     * 
     * @type {number}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
     */
    totalHours: number;
    /**
     * 
     * @type {number}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner
     */
    rangeHours: number;
}

/**
 * Check if a given object implements the UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner interface.
 */
export function instanceOfUsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "formId" in value;
    isInstance = isInstance && "entryType" in value;
    isInstance = isInstance && "timeUnit" in value;
    isInstance = isInstance && "totalHours" in value;
    isInstance = isInstance && "rangeHours" in value;

    return isInstance;
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInnerFromJSON(json: any): UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner {
    return UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInnerFromJSONTyped(json, false);
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'formId': json['form_id'],
        'entryType': json['entry_type'],
        'formApproved': !exists(json, 'form_approved') ? undefined : (json['form_approved'] === null ? null : new Date(json['form_approved'])),
        'timeUnit': json['time_unit'],
        'totalHours': json['total_hours'],
        'rangeHours': json['range_hours'],
    };
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInnerToJSON(value?: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString()),
        'form_id': value.formId,
        'entry_type': value.entryType,
        'form_approved': value.formApproved === undefined ? undefined : (value.formApproved === null ? null : value.formApproved.toISOString()),
        'time_unit': value.timeUnit,
        'total_hours': value.totalHours,
        'range_hours': value.rangeHours,
    };
}

