/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormTemplateModelWithJsonFormsData } from './FormTemplateModelWithJsonFormsData';
import {
    FormTemplateModelWithJsonFormsDataFromJSON,
    FormTemplateModelWithJsonFormsDataFromJSONTyped,
    FormTemplateModelWithJsonFormsDataToJSON,
} from './FormTemplateModelWithJsonFormsData';

/**
 * 
 * @export
 * @interface FormTemplateView200Response
 */
export interface FormTemplateView200Response {
    /**
     * 
     * @type {boolean}
     * @memberof FormTemplateView200Response
     */
    success: boolean;
    /**
     * 
     * @type {FormTemplateModelWithJsonFormsData}
     * @memberof FormTemplateView200Response
     */
    data: FormTemplateModelWithJsonFormsData;
}

/**
 * Check if a given object implements the FormTemplateView200Response interface.
 */
export function instanceOfFormTemplateView200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function FormTemplateView200ResponseFromJSON(json: any): FormTemplateView200Response {
    return FormTemplateView200ResponseFromJSONTyped(json, false);
}

export function FormTemplateView200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormTemplateView200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': FormTemplateModelWithJsonFormsDataFromJSON(json['data']),
    };
}

export function FormTemplateView200ResponseToJSON(value?: FormTemplateView200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': FormTemplateModelWithJsonFormsDataToJSON(value.data),
    };
}

