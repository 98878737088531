export class SetProjectView {
    static type = "[Projects] Set view";

    constructor(public payload: string) {}
}

export class SetProjectSortBy {
    static type = "[Projects] Set sort";

    constructor(public payload: string) {}
}

export class SetProjectGroupBy {
    static type = "[Projects] Set group by";

    constructor(public payload: string) {}
}
