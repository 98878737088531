/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Email } from './Email';
import {
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
} from './Email';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface OfferAction
 */
export interface OfferAction {
    /**
     * 
     * @type {string}
     * @memberof OfferAction
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof OfferAction
     */
    readonly userId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof OfferAction
     */
    user?: User;
    /**
     * 
     * @type {Email}
     * @memberof OfferAction
     */
    email?: Email;
    /**
     * 
     * @type {string}
     * @memberof OfferAction
     */
    readonly contactId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferAction
     */
    offerId: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferAction
     */
    action: OfferActionActionEnum;
    /**
     * 
     * @type {Date}
     * @memberof OfferAction
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OfferAction
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof OfferAction
     */
    readonly deleted?: Date | null;
}


/**
 * @export
 */
export const OfferActionActionEnum = {
    Created: 'created',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Sent: 'sent',
    Expired: 'expired',
    PublicUrlAccessed: 'public_url_accessed',
    EmailDelivered: 'email_delivered',
    EmailOpened: 'email_opened',
    EmailSendingFailed: 'email_sending_failed'
} as const;
export type OfferActionActionEnum = typeof OfferActionActionEnum[keyof typeof OfferActionActionEnum];


/**
 * Check if a given object implements the OfferAction interface.
 */
export function instanceOfOfferAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "offerId" in value;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function OfferActionFromJSON(json: any): OfferAction {
    return OfferActionFromJSONTyped(json, false);
}

export function OfferActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'email': !exists(json, 'email') ? undefined : EmailFromJSON(json['email']),
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'offerId': json['offer_id'],
        'action': json['action'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function OfferActionToJSON(value?: OfferAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'email': EmailToJSON(value.email),
        'offer_id': value.offerId,
        'action': value.action,
    };
}

