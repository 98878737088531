/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressLookup
 */
export interface AddressLookup {
    /**
     * 
     * @type {string}
     * @memberof AddressLookup
     */
    streetName: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressLookup
     */
    streetNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressLookup
     */
    subLocality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressLookup
     */
    city: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressLookup
     */
    zipCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressLookup
     */
    country: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressLookup
     */
    countryCode: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressLookup
     */
    latitude: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddressLookup
     */
    longitude: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddressLookup
     */
    apactaCityId?: string | null;
}

/**
 * Check if a given object implements the AddressLookup interface.
 */
export function instanceOfAddressLookup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "streetName" in value;
    isInstance = isInstance && "streetNumber" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "latitude" in value;
    isInstance = isInstance && "longitude" in value;

    return isInstance;
}

export function AddressLookupFromJSON(json: any): AddressLookup {
    return AddressLookupFromJSONTyped(json, false);
}

export function AddressLookupFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressLookup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'streetName': json['street_name'],
        'streetNumber': json['street_number'],
        'subLocality': !exists(json, 'sub_locality') ? undefined : json['sub_locality'],
        'city': json['city'],
        'zipCode': json['zip_code'],
        'country': json['country'],
        'countryCode': json['country_code'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'apactaCityId': !exists(json, 'apacta_city_id') ? undefined : json['apacta_city_id'],
    };
}

export function AddressLookupToJSON(value?: AddressLookup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'street_name': value.streetName,
        'street_number': value.streetNumber,
        'sub_locality': value.subLocality,
        'city': value.city,
        'zip_code': value.zipCode,
        'country': value.country,
        'country_code': value.countryCode,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'apacta_city_id': value.apactaCityId,
    };
}

