/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KpiCardHoursSummedIndividualEntry } from './KpiCardHoursSummedIndividualEntry';
import {
    KpiCardHoursSummedIndividualEntryFromJSON,
    KpiCardHoursSummedIndividualEntryFromJSONTyped,
    KpiCardHoursSummedIndividualEntryToJSON,
} from './KpiCardHoursSummedIndividualEntry';
import type { UsersPriceGroup } from './UsersPriceGroup';
import {
    UsersPriceGroupFromJSON,
    UsersPriceGroupFromJSONTyped,
    UsersPriceGroupToJSON,
} from './UsersPriceGroup';

/**
 * 
 * @export
 * @interface ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner
 */
export interface ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner {
    /**
     * User price group ID
     * @type {string}
     * @memberof ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner
     */
    readonly id: string;
    /**
     * 
     * @type {UsersPriceGroup}
     * @memberof ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner
     */
    readonly userPriceGroup?: UsersPriceGroup;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner
     */
    readonly totalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner
     */
    readonly estimatedHours?: number;
    /**
     * 
     * @type {Array<KpiCardHoursSummedIndividualEntry>}
     * @memberof ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner
     */
    readonly entries?: Array<KpiCardHoursSummedIndividualEntry>;
}

/**
 * Check if a given object implements the ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner interface.
 */
export function instanceOfProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInnerFromJSON(json: any): ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner {
    return ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInnerFromJSONTyped(json, false);
}

export function ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userPriceGroup': !exists(json, 'userPriceGroup') ? undefined : UsersPriceGroupFromJSON(json['userPriceGroup']),
        'totalHours': !exists(json, 'total_hours') ? undefined : json['total_hours'],
        'estimatedHours': !exists(json, 'estimated_hours') ? undefined : json['estimated_hours'],
        'entries': !exists(json, 'entries') ? undefined : ((json['entries'] as Array<any>).map(KpiCardHoursSummedIndividualEntryFromJSON)),
    };
}

export function ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInnerToJSON(value?: ProjectGetKpiCardHoursSummedByUserPriceGroupSubPage200ResponseDataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

