/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationSettingUsersInner } from './IntegrationSettingUsersInner';
import {
    IntegrationSettingUsersInnerFromJSON,
    IntegrationSettingUsersInnerFromJSONTyped,
    IntegrationSettingUsersInnerToJSON,
} from './IntegrationSettingUsersInner';

/**
 * 
 * @export
 * @interface IntegrationSetting
 */
export interface IntegrationSetting {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSetting
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSetting
     */
    integrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSetting
     */
    readonly createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSetting
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSetting
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSetting
     */
    authUriJson?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSetting
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSetting
     */
    defaultValue?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationSetting
     */
    isUserSetting?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationSetting
     */
    isCompanySetting?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationSetting
     */
    isCustom?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IntegrationSetting
     */
    placement?: number;
    /**
     * 
     * @type {Array<IntegrationSettingUsersInner>}
     * @memberof IntegrationSetting
     */
    users?: Array<IntegrationSettingUsersInner>;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationSetting
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IntegrationSetting
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof IntegrationSetting
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the IntegrationSetting interface.
 */
export function instanceOfIntegrationSetting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function IntegrationSettingFromJSON(json: any): IntegrationSetting {
    return IntegrationSettingFromJSONTyped(json, false);
}

export function IntegrationSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'authUriJson': !exists(json, 'auth_uri_json') ? undefined : json['auth_uri_json'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'defaultValue': !exists(json, 'default_value') ? undefined : json['default_value'],
        'isUserSetting': !exists(json, 'is_user_setting') ? undefined : json['is_user_setting'],
        'isCompanySetting': !exists(json, 'is_company_setting') ? undefined : json['is_company_setting'],
        'isCustom': !exists(json, 'is_custom') ? undefined : json['is_custom'],
        'placement': !exists(json, 'placement') ? undefined : json['placement'],
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(IntegrationSettingUsersInnerFromJSON)),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function IntegrationSettingToJSON(value?: IntegrationSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'integration_id': value.integrationId,
        'name': value.name,
        'description': value.description,
        'auth_uri_json': value.authUriJson,
        'identifier': value.identifier,
        'default_value': value.defaultValue,
        'is_user_setting': value.isUserSetting,
        'is_company_setting': value.isCompanySetting,
        'is_custom': value.isCustom,
        'placement': value.placement,
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(IntegrationSettingUsersInnerToJSON)),
    };
}

