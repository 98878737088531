/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KpiCardHoursSummedIndividualEntry
 */
export interface KpiCardHoursSummedIndividualEntry {
    /**
     * 
     * @type {string}
     * @memberof KpiCardHoursSummedIndividualEntry
     */
    readonly label: string;
    /**
     * 
     * @type {number}
     * @memberof KpiCardHoursSummedIndividualEntry
     */
    readonly count: number;
    /**
     * 
     * @type {string}
     * @memberof KpiCardHoursSummedIndividualEntry
     */
    readonly type: KpiCardHoursSummedIndividualEntryTypeEnum;
}


/**
 * @export
 */
export const KpiCardHoursSummedIndividualEntryTypeEnum = {
    TimeEntry: 'time_entry',
    Driving: 'driving'
} as const;
export type KpiCardHoursSummedIndividualEntryTypeEnum = typeof KpiCardHoursSummedIndividualEntryTypeEnum[keyof typeof KpiCardHoursSummedIndividualEntryTypeEnum];


/**
 * Check if a given object implements the KpiCardHoursSummedIndividualEntry interface.
 */
export function instanceOfKpiCardHoursSummedIndividualEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function KpiCardHoursSummedIndividualEntryFromJSON(json: any): KpiCardHoursSummedIndividualEntry {
    return KpiCardHoursSummedIndividualEntryFromJSONTyped(json, false);
}

export function KpiCardHoursSummedIndividualEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiCardHoursSummedIndividualEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'count': json['count'],
        'type': json['type'],
    };
}

export function KpiCardHoursSummedIndividualEntryToJSON(value?: KpiCardHoursSummedIndividualEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

