/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MobileGetCards200ResponseDataInnerAllOfTask } from './MobileGetCards200ResponseDataInnerAllOfTask';
import {
    MobileGetCards200ResponseDataInnerAllOfTaskFromJSON,
    MobileGetCards200ResponseDataInnerAllOfTaskFromJSONTyped,
    MobileGetCards200ResponseDataInnerAllOfTaskToJSON,
} from './MobileGetCards200ResponseDataInnerAllOfTask';

/**
 * 
 * @export
 * @interface MobileGetCards200ResponseDataInner
 */
export interface MobileGetCards200ResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInner
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInner
     */
    readonly createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInner
     */
    readonly modifiedById?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInner
     */
    readonly companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInner
     */
    userId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInner
     */
    taskId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInner
     */
    type?: MobileGetCards200ResponseDataInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInner
     */
    status?: MobileGetCards200ResponseDataInnerStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MobileGetCards200ResponseDataInner
     */
    productsAdded?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MobileGetCards200ResponseDataInner
     */
    formTemplates?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MobileGetCards200ResponseDataInner
     */
    listIndex: number | null;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInner
     */
    date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInner
     */
    startTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MobileGetCards200ResponseDataInner
     */
    estimate?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInner
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInner
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInner
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {MobileGetCards200ResponseDataInnerAllOfTask}
     * @memberof MobileGetCards200ResponseDataInner
     */
    task: MobileGetCards200ResponseDataInnerAllOfTask;
}


/**
 * @export
 */
export const MobileGetCards200ResponseDataInnerTypeEnum = {
    Task: 'task'
} as const;
export type MobileGetCards200ResponseDataInnerTypeEnum = typeof MobileGetCards200ResponseDataInnerTypeEnum[keyof typeof MobileGetCards200ResponseDataInnerTypeEnum];

/**
 * @export
 */
export const MobileGetCards200ResponseDataInnerStatusEnum = {
    ToDo: 'to_do',
    Doing: 'doing',
    Done: 'done'
} as const;
export type MobileGetCards200ResponseDataInnerStatusEnum = typeof MobileGetCards200ResponseDataInnerStatusEnum[keyof typeof MobileGetCards200ResponseDataInnerStatusEnum];


/**
 * Check if a given object implements the MobileGetCards200ResponseDataInner interface.
 */
export function instanceOfMobileGetCards200ResponseDataInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "taskId" in value;
    isInstance = isInstance && "listIndex" in value;
    isInstance = isInstance && "task" in value;

    return isInstance;
}

export function MobileGetCards200ResponseDataInnerFromJSON(json: any): MobileGetCards200ResponseDataInner {
    return MobileGetCards200ResponseDataInnerFromJSONTyped(json, false);
}

export function MobileGetCards200ResponseDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileGetCards200ResponseDataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'userId': json['user_id'],
        'taskId': json['task_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'productsAdded': !exists(json, 'products_added') ? undefined : json['products_added'],
        'formTemplates': !exists(json, 'form_templates') ? undefined : json['form_templates'],
        'listIndex': json['list_index'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'startTime': !exists(json, 'start_time') ? undefined : (json['start_time'] === null ? null : new Date(json['start_time'])),
        'estimate': !exists(json, 'estimate') ? undefined : json['estimate'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'task': MobileGetCards200ResponseDataInnerAllOfTaskFromJSON(json['task']),
    };
}

export function MobileGetCards200ResponseDataInnerToJSON(value?: MobileGetCards200ResponseDataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'task_id': value.taskId,
        'type': value.type,
        'status': value.status,
        'products_added': value.productsAdded,
        'form_templates': value.formTemplates,
        'list_index': value.listIndex,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'start_time': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
        'estimate': value.estimate,
        'task': MobileGetCards200ResponseDataInnerAllOfTaskToJSON(value.task),
    };
}

