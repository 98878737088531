/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LabelEntity1
 */
export interface LabelEntity1 {
    /**
     * 
     * @type {string}
     * @memberof LabelEntity1
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity1
     */
    readonly companyId: string;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity1
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity1
     */
    entity?: LabelEntity1EntityEnum;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity1
     */
    bgColor?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity1
     */
    textColor?: string;
    /**
     * 
     * @type {Date}
     * @memberof LabelEntity1
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LabelEntity1
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof LabelEntity1
     */
    readonly deleted?: Date | null;
}


/**
 * @export
 */
export const LabelEntity1EntityEnum = {
    Employee: 'employee',
    Task: 'task'
} as const;
export type LabelEntity1EntityEnum = typeof LabelEntity1EntityEnum[keyof typeof LabelEntity1EntityEnum];


/**
 * Check if a given object implements the LabelEntity1 interface.
 */
export function instanceOfLabelEntity1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "text" in value;

    return isInstance;
}

export function LabelEntity1FromJSON(json: any): LabelEntity1 {
    return LabelEntity1FromJSONTyped(json, false);
}

export function LabelEntity1FromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelEntity1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['company_id'],
        'text': json['text'],
        'entity': !exists(json, 'entity') ? undefined : json['entity'],
        'bgColor': !exists(json, 'bg_color') ? undefined : json['bg_color'],
        'textColor': !exists(json, 'text_color') ? undefined : json['text_color'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function LabelEntity1ToJSON(value?: LabelEntity1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'entity': value.entity,
        'bg_color': value.bgColor,
        'text_color': value.textColor,
    };
}

