/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ICreateProjectRequest
 */
export interface ICreateProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    contactId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    contactPersonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    cityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    projectStatusId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    offerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ICreateProjectRequest
     */
    invoiceDiscountPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    erpProjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProjectRequest
     */
    erpTaskId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ICreateProjectRequest
     */
    isFixedPrice?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ICreateProjectRequest
     */
    workingHoursTotalCostPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ICreateProjectRequest
     */
    productsTotalCostPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ICreateProjectRequest
     */
    totalSalesPrice?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ICreateProjectRequest
     */
    customFields?: { [key: string]: string; };
}

/**
 * Check if a given object implements the ICreateProjectRequest interface.
 */
export function instanceOfICreateProjectRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ICreateProjectRequestFromJSON(json: any): ICreateProjectRequest {
    return ICreateProjectRequestFromJSONTyped(json, false);
}

export function ICreateProjectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICreateProjectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'contactPersonId': !exists(json, 'contact_person_id') ? undefined : json['contact_person_id'],
        'cityId': !exists(json, 'city_id') ? undefined : json['city_id'],
        'projectStatusId': !exists(json, 'project_status_id') ? undefined : json['project_status_id'],
        'offerId': !exists(json, 'offer_id') ? undefined : json['offer_id'],
        'name': json['name'],
        'cityName': !exists(json, 'city_name') ? undefined : json['city_name'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'streetName': !exists(json, 'street_name') ? undefined : json['street_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'invoiceDiscountPercent': !exists(json, 'invoice_discount_percent') ? undefined : json['invoice_discount_percent'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'erpProjectId': !exists(json, 'erp_project_id') ? undefined : json['erp_project_id'],
        'erpTaskId': !exists(json, 'erp_task_id') ? undefined : json['erp_task_id'],
        'isFixedPrice': !exists(json, 'is_fixed_price') ? undefined : json['is_fixed_price'],
        'workingHoursTotalCostPrice': !exists(json, 'working_hours_total_cost_price') ? undefined : json['working_hours_total_cost_price'],
        'productsTotalCostPrice': !exists(json, 'products_total_cost_price') ? undefined : json['products_total_cost_price'],
        'totalSalesPrice': !exists(json, 'total_sales_price') ? undefined : json['total_sales_price'],
        'customFields': !exists(json, 'custom_fields') ? undefined : json['custom_fields'],
    };
}

export function ICreateProjectRequestToJSON(value?: ICreateProjectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'contact_id': value.contactId,
        'contact_person_id': value.contactPersonId,
        'city_id': value.cityId,
        'project_status_id': value.projectStatusId,
        'offer_id': value.offerId,
        'name': value.name,
        'city_name': value.cityName,
        'zip_code': value.zipCode,
        'street_name': value.streetName,
        'description': value.description,
        'invoice_discount_percent': value.invoiceDiscountPercent,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'erp_project_id': value.erpProjectId,
        'erp_task_id': value.erpTaskId,
        'is_fixed_price': value.isFixedPrice,
        'working_hours_total_cost_price': value.workingHoursTotalCostPrice,
        'products_total_cost_price': value.productsTotalCostPrice,
        'total_sales_price': value.totalSalesPrice,
        'custom_fields': value.customFields,
    };
}

