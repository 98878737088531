/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersGetWeekly200ResponseDateRangeInner
 */
export interface UsersGetWeekly200ResponseDateRangeInner {
    /**
     * 
     * @type {string}
     * @memberof UsersGetWeekly200ResponseDateRangeInner
     */
    week?: string;
    /**
     * 
     * @type {Date}
     * @memberof UsersGetWeekly200ResponseDateRangeInner
     */
    fromDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UsersGetWeekly200ResponseDateRangeInner
     */
    toDate?: Date;
}

/**
 * Check if a given object implements the UsersGetWeekly200ResponseDateRangeInner interface.
 */
export function instanceOfUsersGetWeekly200ResponseDateRangeInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UsersGetWeekly200ResponseDateRangeInnerFromJSON(json: any): UsersGetWeekly200ResponseDateRangeInner {
    return UsersGetWeekly200ResponseDateRangeInnerFromJSONTyped(json, false);
}

export function UsersGetWeekly200ResponseDateRangeInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetWeekly200ResponseDateRangeInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'week': !exists(json, 'week') ? undefined : json['week'],
        'fromDate': !exists(json, 'from_date') ? undefined : (new Date(json['from_date'])),
        'toDate': !exists(json, 'to_date') ? undefined : (new Date(json['to_date'])),
    };
}

export function UsersGetWeekly200ResponseDateRangeInnerToJSON(value?: UsersGetWeekly200ResponseDateRangeInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'week': value.week,
        'from_date': value.fromDate === undefined ? undefined : (value.fromDate.toISOString()),
        'to_date': value.toDate === undefined ? undefined : (value.toDate.toISOString()),
    };
}

