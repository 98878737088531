/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginWithEmailRequest
 */
export interface LoginWithEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginWithEmailRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginWithEmailRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginWithEmailRequest
     */
    rememberMe?: boolean;
}

/**
 * Check if a given object implements the LoginWithEmailRequest interface.
 */
export function instanceOfLoginWithEmailRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LoginWithEmailRequestFromJSON(json: any): LoginWithEmailRequest {
    return LoginWithEmailRequestFromJSONTyped(json, false);
}

export function LoginWithEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginWithEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'rememberMe': !exists(json, 'rememberMe') ? undefined : json['rememberMe'],
    };
}

export function LoginWithEmailRequestToJSON(value?: LoginWithEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'rememberMe': value.rememberMe,
    };
}

