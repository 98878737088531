/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobilePlanningEditCardRequest
 */
export interface MobilePlanningEditCardRequest {
    /**
     * 
     * @type {Date}
     * @memberof MobilePlanningEditCardRequest
     */
    date: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MobilePlanningEditCardRequest
     */
    startTime: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MobilePlanningEditCardRequest
     */
    estimate: number | null;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningEditCardRequest
     */
    taskName: string;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningEditCardRequest
     */
    taskDescription: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MobilePlanningEditCardRequest
     */
    labelIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningEditCardRequest
     */
    status: string;
}

/**
 * Check if a given object implements the MobilePlanningEditCardRequest interface.
 */
export function instanceOfMobilePlanningEditCardRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "estimate" in value;
    isInstance = isInstance && "taskName" in value;
    isInstance = isInstance && "taskDescription" in value;
    isInstance = isInstance && "labelIds" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function MobilePlanningEditCardRequestFromJSON(json: any): MobilePlanningEditCardRequest {
    return MobilePlanningEditCardRequestFromJSONTyped(json, false);
}

export function MobilePlanningEditCardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobilePlanningEditCardRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (json['date'] === null ? null : new Date(json['date'])),
        'startTime': (json['start_time'] === null ? null : new Date(json['start_time'])),
        'estimate': json['estimate'],
        'taskName': json['task_name'],
        'taskDescription': json['task_description'],
        'labelIds': json['label_ids'],
        'status': json['status'],
    };
}

export function MobilePlanningEditCardRequestToJSON(value?: MobilePlanningEditCardRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date === null ? null : value.date.toISOString()),
        'start_time': (value.startTime === null ? null : value.startTime.toISOString()),
        'estimate': value.estimate,
        'task_name': value.taskName,
        'task_description': value.taskDescription,
        'label_ids': value.labelIds,
        'status': value.status,
    };
}

