/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationSettingUsersInnerJoinData } from './IntegrationSettingUsersInnerJoinData';
import {
    IntegrationSettingUsersInnerJoinDataFromJSON,
    IntegrationSettingUsersInnerJoinDataFromJSONTyped,
    IntegrationSettingUsersInnerJoinDataToJSON,
} from './IntegrationSettingUsersInnerJoinData';

/**
 * 
 * @export
 * @interface IntegrationSettingUsersInner
 */
export interface IntegrationSettingUsersInner {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettingUsersInner
     */
    id?: string;
    /**
     * 
     * @type {IntegrationSettingUsersInnerJoinData}
     * @memberof IntegrationSettingUsersInner
     */
    joinData?: IntegrationSettingUsersInnerJoinData;
}

/**
 * Check if a given object implements the IntegrationSettingUsersInner interface.
 */
export function instanceOfIntegrationSettingUsersInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntegrationSettingUsersInnerFromJSON(json: any): IntegrationSettingUsersInner {
    return IntegrationSettingUsersInnerFromJSONTyped(json, false);
}

export function IntegrationSettingUsersInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationSettingUsersInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'joinData': !exists(json, '_joinData') ? undefined : IntegrationSettingUsersInnerJoinDataFromJSON(json['_joinData']),
    };
}

export function IntegrationSettingUsersInnerToJSON(value?: IntegrationSettingUsersInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        '_joinData': IntegrationSettingUsersInnerJoinDataToJSON(value.joinData),
    };
}

