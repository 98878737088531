import { PaginationDetails, Product } from "@apacta/sdk";

export class LoadVendorProducts {
    public static readonly type = "[Products] Load vendor products ";

    constructor(public payload: Product[]) {}
}

export class LoadVendorProductsPagination {
    public static readonly type = "[Products] Load vendor products pagination";

    constructor(public payload: PaginationDetails) {}
}

export class ClearVendorProducts {
    public static readonly type = "[Products] Clear vendor products";
}
