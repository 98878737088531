import { Component } from "@angular/core";
import { Store } from "@ngxs/store";
import { Navigate } from "src/store/router/router.actions";
import { AuthService } from "src/app/services/auth.service";

@Component({
    selector: "error-page",
    templateUrl: "./error-page.component.html",
    styleUrls: ["./error-page.component.scss"],
})
export class ErrorPageComponent {
    constructor(
        private store: Store,
        private authService: AuthService
    ) {}

    async navigateToHome(): Promise<void> {
        const isAuthenticated = await this.authService.isAuthenticated();
        if (isAuthenticated) {
            this.store.dispatch(new Navigate(["companies", "overview"]));
        } else {
            this.store.dispatch(new Navigate(["login"]));
        }
    }
}
