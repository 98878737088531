import { Pagination } from "src/app/services/response-models/projects.response.model";
import { Contact } from "src/app/services/response-models/contacts.response.model";

export class LoadContacts {
    public static readonly type = "[Contacts] Load contacts";

    constructor(public payload: Contact[]) {}
}

export class LoadPagination {
    public static readonly type = "[Contacts] Load pagination";

    constructor(public payload: Pagination) {}
}

export class ClearContacts {
    public static readonly type = "[Contacts] Clear contacts";

    constructor() {}
}
