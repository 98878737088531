/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseEntity } from './BaseEntity';
import {
    BaseEntityFromJSON,
    BaseEntityFromJSONTyped,
    BaseEntityToJSON,
} from './BaseEntity';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface FormReadDTO
 */
export interface FormReadDTO {
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    modifiedById?: string;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    userId?: string;
    /**
     * 
     * @type {User}
     * @memberof FormReadDTO
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    projectId: string;
    /**
     * 
     * @type {Project}
     * @memberof FormReadDTO
     */
    project?: Project;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    formTemplateId: string;
    /**
     * 
     * @type {BaseEntity}
     * @memberof FormReadDTO
     */
    formTemplate?: BaseEntity;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    massFormId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    approvedById?: string;
    /**
     * 
     * @type {User}
     * @memberof FormReadDTO
     */
    approvedBy?: User;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    activityId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    clockingRecordId?: string;
    /**
     * 
     * @type {Date}
     * @memberof FormReadDTO
     */
    formDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FormReadDTO
     */
    isInvoiced?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FormReadDTO
     */
    isDraft: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormReadDTO
     */
    isShared: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormReadDTO
     */
    sharedWithContact: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormReadDTO
     */
    sharedWithVendor: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FormReadDTO
     */
    approved?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FormReadDTO
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof FormReadDTO
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof FormReadDTO
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    linkToPdf?: string;
    /**
     * 
     * @type {string}
     * @memberof FormReadDTO
     */
    linkToHtml?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormReadDTO
     */
    isEdited?: boolean;
}

/**
 * Check if a given object implements the FormReadDTO interface.
 */
export function instanceOfFormReadDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "formTemplateId" in value;
    isInstance = isInstance && "isDraft" in value;
    isInstance = isInstance && "isShared" in value;
    isInstance = isInstance && "sharedWithContact" in value;
    isInstance = isInstance && "sharedWithVendor" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function FormReadDTOFromJSON(json: any): FormReadDTO {
    return FormReadDTOFromJSONTyped(json, false);
}

export function FormReadDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormReadDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'companyId': json['company_id'],
        'projectId': json['project_id'],
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'formTemplateId': json['form_template_id'],
        'formTemplate': !exists(json, 'form_template') ? undefined : BaseEntityFromJSON(json['form_template']),
        'massFormId': !exists(json, 'mass_form_id') ? undefined : json['mass_form_id'],
        'approvedById': !exists(json, 'approved_by_id') ? undefined : json['approved_by_id'],
        'approvedBy': !exists(json, 'approved_by') ? undefined : UserFromJSON(json['approved_by']),
        'activityId': !exists(json, 'activity_id') ? undefined : json['activity_id'],
        'clockingRecordId': !exists(json, 'clocking_record_id') ? undefined : json['clocking_record_id'],
        'formDate': !exists(json, 'form_date') ? undefined : (new Date(json['form_date'])),
        'isInvoiced': !exists(json, 'is_invoiced') ? undefined : (new Date(json['is_invoiced'])),
        'isDraft': json['is_draft'],
        'isShared': json['is_shared'],
        'sharedWithContact': json['shared_with_contact'],
        'sharedWithVendor': json['shared_with_vendor'],
        'approved': !exists(json, 'approved') ? undefined : (new Date(json['approved'])),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'linkToPdf': !exists(json, 'link_to_pdf') ? undefined : json['link_to_pdf'],
        'linkToHtml': !exists(json, 'link_to_html') ? undefined : json['link_to_html'],
        'isEdited': !exists(json, 'is_edited') ? undefined : json['is_edited'],
    };
}

export function FormReadDTOToJSON(value?: FormReadDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by_id': value.createdById,
        'modified_by_id': value.modifiedById,
        'user_id': value.userId,
        'user': UserToJSON(value.user),
        'company_id': value.companyId,
        'project_id': value.projectId,
        'project': ProjectToJSON(value.project),
        'form_template_id': value.formTemplateId,
        'form_template': BaseEntityToJSON(value.formTemplate),
        'mass_form_id': value.massFormId,
        'approved_by_id': value.approvedById,
        'approved_by': UserToJSON(value.approvedBy),
        'activity_id': value.activityId,
        'clocking_record_id': value.clockingRecordId,
        'form_date': value.formDate === undefined ? undefined : (value.formDate.toISOString().substr(0,10)),
        'is_invoiced': value.isInvoiced === undefined ? undefined : (value.isInvoiced.toISOString()),
        'is_draft': value.isDraft,
        'is_shared': value.isShared,
        'shared_with_contact': value.sharedWithContact,
        'shared_with_vendor': value.sharedWithVendor,
        'approved': value.approved === undefined ? undefined : (value.approved.toISOString()),
        'link_to_pdf': value.linkToPdf,
        'link_to_html': value.linkToHtml,
        'is_edited': value.isEdited,
    };
}

