/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseFile } from './BaseFile';
import {
    BaseFileFromJSON,
    BaseFileFromJSONTyped,
    BaseFileToJSON,
} from './BaseFile';
import type { Project1 } from './Project1';
import {
    Project1FromJSON,
    Project1FromJSONTyped,
    Project1ToJSON,
} from './Project1';
import type { User1 } from './User1';
import {
    User1FromJSON,
    User1FromJSONTyped,
    User1ToJSON,
} from './User1';
import type { WallPostWallCommentsInner } from './WallPostWallCommentsInner';
import {
    WallPostWallCommentsInnerFromJSON,
    WallPostWallCommentsInnerFromJSONTyped,
    WallPostWallCommentsInnerToJSON,
} from './WallPostWallCommentsInner';

/**
 * 
 * @export
 * @interface WallPost
 */
export interface WallPost {
    /**
     * 
     * @type {string}
     * @memberof WallPost
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof WallPost
     */
    userId?: string;
    /**
     * 
     * @type {User1}
     * @memberof WallPost
     */
    user?: User1;
    /**
     * 
     * @type {string}
     * @memberof WallPost
     */
    projectId?: string;
    /**
     * 
     * @type {Project1}
     * @memberof WallPost
     */
    project?: Project1;
    /**
     * 
     * @type {string}
     * @memberof WallPost
     */
    message?: string;
    /**
     * 
     * @type {Array<BaseFile>}
     * @memberof WallPost
     */
    files?: Array<BaseFile>;
    /**
     * 
     * @type {Array<WallPostWallCommentsInner>}
     * @memberof WallPost
     */
    wallComments?: Array<WallPostWallCommentsInner>;
    /**
     * 
     * @type {Date}
     * @memberof WallPost
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WallPost
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof WallPost
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the WallPost interface.
 */
export function instanceOfWallPost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function WallPostFromJSON(json: any): WallPost {
    return WallPostFromJSONTyped(json, false);
}

export function WallPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): WallPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'user': !exists(json, 'user') ? undefined : User1FromJSON(json['user']),
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'project': !exists(json, 'project') ? undefined : Project1FromJSON(json['project']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(BaseFileFromJSON)),
        'wallComments': !exists(json, 'wall_comments') ? undefined : ((json['wall_comments'] as Array<any>).map(WallPostWallCommentsInnerFromJSON)),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function WallPostToJSON(value?: WallPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'user': User1ToJSON(value.user),
        'project_id': value.projectId,
        'project': Project1ToJSON(value.project),
        'message': value.message,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(BaseFileToJSON)),
        'wall_comments': value.wallComments === undefined ? undefined : ((value.wallComments as Array<any>).map(WallPostWallCommentsInnerToJSON)),
    };
}

