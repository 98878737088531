import { ReactiveFormsModule } from "@angular/forms";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { NgxsModule } from "@ngxs/store";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppState } from "src/store/app/app.state";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterStateModule } from "src/store/router/router.state";
import { ProjectsState } from "src/store/projects/projects.state";
import { FormTemplatesState } from "src/store/form-templates/form-templates.state";
import { ProjectWallState } from "src/store/project-wall/project-wall.state";
import { ContactsState } from "src/store/contacts/contacts.state";
import { ProductsState } from "src/store/products/products.state";
import { MaterialsState } from "src/store/materials/materials.state";
import { ConfigState } from "src/store/config/config.state";
import { DrivingState } from "src/store/driving/driving.state";
import { environment } from "src/environments/environment";

import { UsersState } from "src/store/users/users.state";
import { ActivityState } from "src/store/activities/activities.state";
import { ProjectStatusesState } from "src/store/project-statuses/projects-statuses.state";
import { SecondMenuState } from "src/store/second-menu/second-menu.state";
import { TimeEntryTypesState } from "src/store/time-entries-types/time-entry-types.state";
import * as Sentry from "@sentry/angular-ivy";
import { AppRoutingModule } from "src/app/app-routing.module";
import { AppComponent } from "src/app/app.component";
import { SharedModule } from "src/app/shared/shared.module";
import { NavigationComponent as MobileNavigationComponent } from "src/app/employee/menu/navigation/navigation.component";
import { TokenInterceptor } from "src/app/auth/token.interceptor";
import { AddDefaultHeadersInterceptor } from "src/app/auth/headers.interceptor";

import { MenuComponent } from "src/app/control-panel/layout/menu/menu.component";
import { FeaturesState } from "src/store/features/features.state";
import { DateTime } from "src/app/employee/forms/form-field-types/date-fields/DateTime";
import { FormsState } from "src/store/forms/forms.state";
import { ErrorPageComponent } from "src/app/error-page/error-page.component";
import { STATE_INITIALIZER } from "src/app/services/state-initialization/state-initialization.service";
import { CompanyStateInitializationService } from "src/app/services/state-initialization/company-state-initialization.service";
import { EmployeeStateInitializationService } from "src/app/services/state-initialization/employee-state-initialization.service";
import { LoginModule } from "src/app/login/login.module";

import "@angular/common/locales/global/da";
import "@angular/common/locales/global/nb";
import "@angular/common/locales/global/de";
import "@angular/common/locales/global/ru";
import "@angular/common/locales/global/ro";
import "@angular/common/locales/global/pl";
import { RetryInterceptor } from "src/app/auth/retry.interceptor";
import { CompaniesState } from "src/store/companies/companies.state";

@NgModule({
    declarations: [AppComponent, MobileNavigationComponent, MenuComponent, ErrorPageComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        SharedModule,
        LoginModule,
        TranslateModule.forRoot({
            defaultLanguage: "dk",
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        IonicModule.forRoot(),
        AppRoutingModule,
        ReactiveFormsModule,
        NgxsModule.forRoot(
            [
                AppState,
                CompaniesState,
                RouterStateModule,
                ProjectsState,
                FeaturesState,
                FormsState,
                ContactsState,
                ProjectWallState,
                FormTemplatesState,
                ProductsState,
                MaterialsState,
                ConfigState,
                DrivingState,
                UsersState,
                ActivityState,
                ProjectStatusesState,
                SecondMenuState,
                TimeEntryTypesState,
            ],
            { developmentMode: !environment.production }
        ),
    ],
    providers: [
        DateTime,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddDefaultHeadersInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryInterceptor,
            multi: true,
        },
        {
            provide: STATE_INITIALIZER,
            useClass: CompanyStateInitializationService,
            multi: true,
        },
        {
            provide: STATE_INITIALIZER,
            useClass: EmployeeStateInitializationService,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                logErrors: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    exports: [MobileNavigationComponent, MenuComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
