/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CardUpdateDTO
 */
export interface CardUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof CardUpdateDTO
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardUpdateDTO
     */
    taskId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardUpdateDTO
     */
    type?: CardUpdateDTOTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CardUpdateDTO
     */
    listIndex?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof CardUpdateDTO
     */
    date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CardUpdateDTO
     */
    startTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof CardUpdateDTO
     */
    estimate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CardUpdateDTO
     */
    status?: CardUpdateDTOStatusEnum;
}


/**
 * @export
 */
export const CardUpdateDTOTypeEnum = {
    Task: 'task'
} as const;
export type CardUpdateDTOTypeEnum = typeof CardUpdateDTOTypeEnum[keyof typeof CardUpdateDTOTypeEnum];

/**
 * @export
 */
export const CardUpdateDTOStatusEnum = {
    ToDo: 'to_do',
    Doing: 'doing',
    Done: 'done'
} as const;
export type CardUpdateDTOStatusEnum = typeof CardUpdateDTOStatusEnum[keyof typeof CardUpdateDTOStatusEnum];


/**
 * Check if a given object implements the CardUpdateDTO interface.
 */
export function instanceOfCardUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CardUpdateDTOFromJSON(json: any): CardUpdateDTO {
    return CardUpdateDTOFromJSONTyped(json, false);
}

export function CardUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'taskId': !exists(json, 'task_id') ? undefined : json['task_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'listIndex': !exists(json, 'list_index') ? undefined : json['list_index'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'startTime': !exists(json, 'start_time') ? undefined : (json['start_time'] === null ? null : new Date(json['start_time'])),
        'estimate': !exists(json, 'estimate') ? undefined : json['estimate'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function CardUpdateDTOToJSON(value?: CardUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'task_id': value.taskId,
        'type': value.type,
        'list_index': value.listIndex,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'start_time': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
        'estimate': value.estimate,
        'status': value.status,
    };
}

