/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteProductVariantsRequest
 */
export interface DeleteProductVariantsRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteProductVariantsRequest
     */
    productId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteProductVariantsRequest
     */
    vendorProducts?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteProductVariantsRequest
     */
    expenseLines?: Array<string>;
}

/**
 * Check if a given object implements the DeleteProductVariantsRequest interface.
 */
export function instanceOfDeleteProductVariantsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;

    return isInstance;
}

export function DeleteProductVariantsRequestFromJSON(json: any): DeleteProductVariantsRequest {
    return DeleteProductVariantsRequestFromJSONTyped(json, false);
}

export function DeleteProductVariantsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteProductVariantsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
        'vendorProducts': !exists(json, 'vendor_products') ? undefined : json['vendor_products'],
        'expenseLines': !exists(json, 'expense_lines') ? undefined : json['expense_lines'],
    };
}

export function DeleteProductVariantsRequestToJSON(value?: DeleteProductVariantsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'vendor_products': value.vendorProducts,
        'expense_lines': value.expenseLines,
    };
}

