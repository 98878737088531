/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontpageTotalConsumptionDTO } from './FrontpageTotalConsumptionDTO';
import {
    FrontpageTotalConsumptionDTOFromJSON,
    FrontpageTotalConsumptionDTOFromJSONTyped,
    FrontpageTotalConsumptionDTOToJSON,
} from './FrontpageTotalConsumptionDTO';
import type { IGetConsumption200ResponseDataWeeklyConsumption } from './IGetConsumption200ResponseDataWeeklyConsumption';
import {
    IGetConsumption200ResponseDataWeeklyConsumptionFromJSON,
    IGetConsumption200ResponseDataWeeklyConsumptionFromJSONTyped,
    IGetConsumption200ResponseDataWeeklyConsumptionToJSON,
} from './IGetConsumption200ResponseDataWeeklyConsumption';

/**
 * 
 * @export
 * @interface IGetConsumption200ResponseData
 */
export interface IGetConsumption200ResponseData {
    /**
     * 
     * @type {IGetConsumption200ResponseDataWeeklyConsumption}
     * @memberof IGetConsumption200ResponseData
     */
    weeklyConsumption: IGetConsumption200ResponseDataWeeklyConsumption;
    /**
     * 
     * @type {FrontpageTotalConsumptionDTO}
     * @memberof IGetConsumption200ResponseData
     */
    totalConsumption: FrontpageTotalConsumptionDTO;
}

/**
 * Check if a given object implements the IGetConsumption200ResponseData interface.
 */
export function instanceOfIGetConsumption200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "weeklyConsumption" in value;
    isInstance = isInstance && "totalConsumption" in value;

    return isInstance;
}

export function IGetConsumption200ResponseDataFromJSON(json: any): IGetConsumption200ResponseData {
    return IGetConsumption200ResponseDataFromJSONTyped(json, false);
}

export function IGetConsumption200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetConsumption200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weeklyConsumption': IGetConsumption200ResponseDataWeeklyConsumptionFromJSON(json['weekly_consumption']),
        'totalConsumption': FrontpageTotalConsumptionDTOFromJSON(json['total_consumption']),
    };
}

export function IGetConsumption200ResponseDataToJSON(value?: IGetConsumption200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weekly_consumption': IGetConsumption200ResponseDataWeeklyConsumptionToJSON(value.weeklyConsumption),
        'total_consumption': FrontpageTotalConsumptionDTOToJSON(value.totalConsumption),
    };
}

