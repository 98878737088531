import { FormTemplateData } from "src/app/services/response-models/form-templates.response.model";
import { Pagination } from "src/app/services/response-models/projects.response.model";

export class LoadFormTemplates {
    public static readonly type = "[Form Templates] Save all form templates";

    constructor(public payload: FormTemplateData[]) {}
}

export class SavePagination {
    public static readonly type = "[Form Templates] Save pagination";

    constructor(public payload: Pagination) {}
}

export class ClearFormTemplates {
    public static readonly type = "[Form Templates] Clear form templates";

    constructor() {}
}

export class ClearCardForm {
    public static readonly type = "[Form Templates] Clear card form";

    constructor() {}
}
