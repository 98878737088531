/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Invoice } from './Invoice';
import {
    InvoiceFromJSON,
    InvoiceFromJSONTyped,
    InvoiceToJSON,
} from './Invoice';

/**
 * 
 * @export
 * @interface IGetInvoice200Response
 */
export interface IGetInvoice200Response {
    /**
     * 
     * @type {boolean}
     * @memberof IGetInvoice200Response
     */
    success: boolean;
    /**
     * 
     * @type {Invoice}
     * @memberof IGetInvoice200Response
     */
    data: Invoice;
}

/**
 * Check if a given object implements the IGetInvoice200Response interface.
 */
export function instanceOfIGetInvoice200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function IGetInvoice200ResponseFromJSON(json: any): IGetInvoice200Response {
    return IGetInvoice200ResponseFromJSONTyped(json, false);
}

export function IGetInvoice200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetInvoice200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': InvoiceFromJSON(json['data']),
    };
}

export function IGetInvoice200ResponseToJSON(value?: IGetInvoice200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': InvoiceToJSON(value.data),
    };
}

