/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner } from './UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner';
import {
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerFromJSON,
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerFromJSONTyped,
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerToJSON,
} from './UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner';

/**
 * 
 * @export
 * @interface UsersGetWeekly200ResponseWeeklyViewInner
 */
export interface UsersGetWeekly200ResponseWeeklyViewInner {
    /**
     * 
     * @type {User}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInner
     */
    user: User;
    /**
     * 
     * @type {Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner>}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInner
     */
    weeks: Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner>;
}

/**
 * Check if a given object implements the UsersGetWeekly200ResponseWeeklyViewInner interface.
 */
export function instanceOfUsersGetWeekly200ResponseWeeklyViewInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "weeks" in value;

    return isInstance;
}

export function UsersGetWeekly200ResponseWeeklyViewInnerFromJSON(json: any): UsersGetWeekly200ResponseWeeklyViewInner {
    return UsersGetWeekly200ResponseWeeklyViewInnerFromJSONTyped(json, false);
}

export function UsersGetWeekly200ResponseWeeklyViewInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetWeekly200ResponseWeeklyViewInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserFromJSON(json['user']),
        'weeks': ((json['weeks'] as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerFromJSON)),
    };
}

export function UsersGetWeekly200ResponseWeeklyViewInnerToJSON(value?: UsersGetWeekly200ResponseWeeklyViewInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'weeks': ((value.weeks as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerToJSON)),
    };
}

