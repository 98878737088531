/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KpiCardEconomyPrice } from './KpiCardEconomyPrice';
import {
    KpiCardEconomyPriceFromJSON,
    KpiCardEconomyPriceFromJSONTyped,
    KpiCardEconomyPriceToJSON,
} from './KpiCardEconomyPrice';
import type { ProjectGetKpiCardEconomySubPage200ResponseDataInterval } from './ProjectGetKpiCardEconomySubPage200ResponseDataInterval';
import {
    ProjectGetKpiCardEconomySubPage200ResponseDataIntervalFromJSON,
    ProjectGetKpiCardEconomySubPage200ResponseDataIntervalFromJSONTyped,
    ProjectGetKpiCardEconomySubPage200ResponseDataIntervalToJSON,
} from './ProjectGetKpiCardEconomySubPage200ResponseDataInterval';

/**
 * 
 * @export
 * @interface ProjectGetKpiCardEconomySubPage200ResponseData
 */
export interface ProjectGetKpiCardEconomySubPage200ResponseData {
    /**
     * 
     * @type {ProjectGetKpiCardEconomySubPage200ResponseDataInterval}
     * @memberof ProjectGetKpiCardEconomySubPage200ResponseData
     */
    interval: ProjectGetKpiCardEconomySubPage200ResponseDataInterval;
    /**
     * 
     * @type {KpiCardEconomyPrice}
     * @memberof ProjectGetKpiCardEconomySubPage200ResponseData
     */
    products: KpiCardEconomyPrice;
    /**
     * 
     * @type {KpiCardEconomyPrice}
     * @memberof ProjectGetKpiCardEconomySubPage200ResponseData
     */
    productRules?: KpiCardEconomyPrice;
    /**
     * 
     * @type {KpiCardEconomyPrice}
     * @memberof ProjectGetKpiCardEconomySubPage200ResponseData
     */
    hours: KpiCardEconomyPrice;
    /**
     * 
     * @type {KpiCardEconomyPrice}
     * @memberof ProjectGetKpiCardEconomySubPage200ResponseData
     */
    total?: KpiCardEconomyPrice;
}

/**
 * Check if a given object implements the ProjectGetKpiCardEconomySubPage200ResponseData interface.
 */
export function instanceOfProjectGetKpiCardEconomySubPage200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "interval" in value;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "hours" in value;

    return isInstance;
}

export function ProjectGetKpiCardEconomySubPage200ResponseDataFromJSON(json: any): ProjectGetKpiCardEconomySubPage200ResponseData {
    return ProjectGetKpiCardEconomySubPage200ResponseDataFromJSONTyped(json, false);
}

export function ProjectGetKpiCardEconomySubPage200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectGetKpiCardEconomySubPage200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interval': ProjectGetKpiCardEconomySubPage200ResponseDataIntervalFromJSON(json['interval']),
        'products': KpiCardEconomyPriceFromJSON(json['products']),
        'productRules': !exists(json, 'product_rules') ? undefined : KpiCardEconomyPriceFromJSON(json['product_rules']),
        'hours': KpiCardEconomyPriceFromJSON(json['hours']),
        'total': !exists(json, 'total') ? undefined : KpiCardEconomyPriceFromJSON(json['total']),
    };
}

export function ProjectGetKpiCardEconomySubPage200ResponseDataToJSON(value?: ProjectGetKpiCardEconomySubPage200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interval': ProjectGetKpiCardEconomySubPage200ResponseDataIntervalToJSON(value.interval),
        'products': KpiCardEconomyPriceToJSON(value.products),
        'product_rules': KpiCardEconomyPriceToJSON(value.productRules),
        'hours': KpiCardEconomyPriceToJSON(value.hours),
        'total': KpiCardEconomyPriceToJSON(value.total),
    };
}

