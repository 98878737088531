import { DeviceInfo } from "@capacitor/device";

export class SetAppConfig {
    static readonly type = "[Configuration] Save app config";

    constructor(public payload: DeviceInfo) {}
}

export class SetPlatform {
    static readonly type = "[Configuration] Save app platform";

    constructor(public payload: string) {}
}
