import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Feature, Pagination } from "src/app/services/response-models/features.response.model";
import {
    ClearFeatures,
    LoadFeatures,
    LoadMenuFeatures,
    LoadPagination,
} from "./actions/set-features.action";
import { Injectable } from "@angular/core";
import { MenuFeature } from "src/app/shared/models/menu-feature";

export interface FeaturesStateModel {
    features: Feature[];
    pagination: Pagination;
    menuFeatures: Array<MenuFeature>;
}

@State<FeaturesStateModel>({
    name: "features",
    defaults: {
        features: [],
        pagination: null,
        menuFeatures: [],
    },
})
@Injectable()
export class FeaturesState {
    @Selector()
    public static getFeatures(state: FeaturesStateModel) {
        return state.features;
    }

    @Selector()
    public static getMenuFeatures(state: FeaturesStateModel) {
        return state.menuFeatures;
    }

    @Action(LoadFeatures)
    loadContacts(ctx: StateContext<FeaturesStateModel>, { payload }: LoadFeatures) {
        const state = ctx.getState();

        ctx.patchState({
            features: [...state.features, ...payload],
        });
    }

    @Action(LoadPagination)
    loadPagination(ctx: StateContext<FeaturesStateModel>, { payload }: LoadPagination) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }

    @Action(ClearFeatures)
    clear(ctx: StateContext<FeaturesStateModel>) {
        ctx.setState({ ...ctx.getState(), features: [] });
    }

    @Action(LoadMenuFeatures)
    loadMenuFeatures(ctx: StateContext<FeaturesStateModel>, { payload }: LoadMenuFeatures) {
        ctx.setState({ ...ctx.getState(), menuFeatures: payload });
    }
}
