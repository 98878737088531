/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Card } from './Card';
import {
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';
import type { TaskReadDTO } from './TaskReadDTO';
import {
    TaskReadDTOFromJSON,
    TaskReadDTOFromJSONTyped,
    TaskReadDTOToJSON,
} from './TaskReadDTO';

/**
 * 
 * @export
 * @interface CreateCardAndTask201ResponseData
 */
export interface CreateCardAndTask201ResponseData {
    /**
     * 
     * @type {Array<Card>}
     * @memberof CreateCardAndTask201ResponseData
     */
    cards?: Array<Card>;
    /**
     * 
     * @type {Array<TaskReadDTO>}
     * @memberof CreateCardAndTask201ResponseData
     */
    tasks?: Array<TaskReadDTO>;
}

/**
 * Check if a given object implements the CreateCardAndTask201ResponseData interface.
 */
export function instanceOfCreateCardAndTask201ResponseData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateCardAndTask201ResponseDataFromJSON(json: any): CreateCardAndTask201ResponseData {
    return CreateCardAndTask201ResponseDataFromJSONTyped(json, false);
}

export function CreateCardAndTask201ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCardAndTask201ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cards': !exists(json, 'cards') ? undefined : ((json['cards'] as Array<any>).map(CardFromJSON)),
        'tasks': !exists(json, 'tasks') ? undefined : ((json['tasks'] as Array<any>).map(TaskReadDTOFromJSON)),
    };
}

export function CreateCardAndTask201ResponseDataToJSON(value?: CreateCardAndTask201ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cards': value.cards === undefined ? undefined : ((value.cards as Array<any>).map(CardToJSON)),
        'tasks': value.tasks === undefined ? undefined : ((value.tasks as Array<any>).map(TaskReadDTOToJSON)),
    };
}

