/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductBundleFile } from './ProductBundleFile';
import {
    ProductBundleFileFromJSON,
    ProductBundleFileFromJSONTyped,
    ProductBundleFileToJSON,
} from './ProductBundleFile';

/**
 * 
 * @export
 * @interface StandardOfferFile
 */
export interface StandardOfferFile {
    /**
     * 
     * @type {string}
     * @memberof StandardOfferFile
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof StandardOfferFile
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof StandardOfferFile
     */
    fileId: string;
    /**
     * 
     * @type {ProductBundleFile}
     * @memberof StandardOfferFile
     */
    file: ProductBundleFile;
    /**
     * 
     * @type {string}
     * @memberof StandardOfferFile
     */
    type: StandardOfferFileTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof StandardOfferFile
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof StandardOfferFile
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof StandardOfferFile
     */
    readonly deleted: Date | null;
}


/**
 * @export
 */
export const StandardOfferFileTypeEnum = {
    Quote: 'quote',
    Estimate: 'estimate'
} as const;
export type StandardOfferFileTypeEnum = typeof StandardOfferFileTypeEnum[keyof typeof StandardOfferFileTypeEnum];


/**
 * Check if a given object implements the StandardOfferFile interface.
 */
export function instanceOfStandardOfferFile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "fileId" in value;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function StandardOfferFileFromJSON(json: any): StandardOfferFile {
    return StandardOfferFileFromJSONTyped(json, false);
}

export function StandardOfferFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandardOfferFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['company_id'],
        'fileId': json['file_id'],
        'file': ProductBundleFileFromJSON(json['file']),
        'type': json['type'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function StandardOfferFileToJSON(value?: StandardOfferFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'file_id': value.fileId,
        'file': ProductBundleFileToJSON(value.file),
        'type': value.type,
    };
}

