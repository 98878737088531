/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LabelEntity } from './LabelEntity';
import {
    LabelEntityFromJSON,
    LabelEntityFromJSONTyped,
    LabelEntityToJSON,
} from './LabelEntity';
import type { Language } from './Language';
import {
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './Language';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import type { UserMobileAppPreferences } from './UserMobileAppPreferences';
import {
    UserMobileAppPreferencesFromJSON,
    UserMobileAppPreferencesFromJSONTyped,
    UserMobileAppPreferencesToJSON,
} from './UserMobileAppPreferences';
import type { UsersPriceGroup } from './UsersPriceGroup';
import {
    UsersPriceGroupFromJSON,
    UsersPriceGroupFromJSONTyped,
    UsersPriceGroupToJSON,
} from './UsersPriceGroup';

/**
 * User entity - including Role and Language
 * @export
 * @interface GetMe200ResponseUser
 */
export interface GetMe200ResponseUser {
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    cityId: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    languageId: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    erpId?: string;
    /**
     * Time entry rule group
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    timeEntryRuleGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    createdById: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    usersPriceGroupId?: string;
    /**
     * 
     * @type {UsersPriceGroup}
     * @memberof GetMe200ResponseUser
     */
    usersPriceGroup?: UsersPriceGroup;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    lastName: string;
    /**
     * READ-ONLY
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    initials: string;
    /**
     * Original file name
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    image?: string;
    /**
     * Signed AWS S3 link to user's profile image
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    streetName: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    website: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    phoneCountrycode: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    mobile: string;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    mobileCountrycode: string;
    /**
     * if true - cost and extra price manually changed.
     * @type {boolean}
     * @memberof GetMe200ResponseUser
     */
    manualPrice: boolean;
    /**
     * Cost of salaries
     * @type {number}
     * @memberof GetMe200ResponseUser
     */
    costPrice: number;
    /**
     * The price this employee costs per hour when working
     * @type {number}
     * @memberof GetMe200ResponseUser
     */
    readonly salePrice: number;
    /**
     * Additional cost on this employee (pension, vacation etc.)
     * @type {number}
     * @memberof GetMe200ResponseUser
     */
    extraPrice: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseUser
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    apiKey: string;
    /**
     * Employee will receipt of every form submitted on the provided email
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    receiveFormMails: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseUser
     */
    hideAddress: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseUser
     */
    hidePhone: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseUser
     */
    expectedBillableHours: number;
    /**
     * 
     * @type {UserMobileAppPreferences}
     * @memberof GetMe200ResponseUser
     */
    mobileAppPreferences: UserMobileAppPreferences;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseUser
     */
    isProjectLeader: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseUser
     */
    adminAccess: GetMe200ResponseUserAdminAccessEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMe200ResponseUser
     */
    adminAccessOptions?: Array<string>;
    /**
     * 
     * @type {Array<Project>}
     * @memberof GetMe200ResponseUser
     */
    projects?: Array<Project>;
    /**
     * 
     * @type {Array<LabelEntity>}
     * @memberof GetMe200ResponseUser
     */
    labels?: Array<LabelEntity>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof GetMe200ResponseUser
     */
    roles: Array<Role>;
    /**
     * 
     * @type {Language}
     * @memberof GetMe200ResponseUser
     */
    language: Language;
}


/**
 * @export
 */
export const GetMe200ResponseUserAdminAccessEnum = {
    NoAccessToAdmin: 'no_access_to_admin',
    AccessToAdmin: 'access_to_admin',
    LimitedAccessToAdmin: 'limited_access_to_admin'
} as const;
export type GetMe200ResponseUserAdminAccessEnum = typeof GetMe200ResponseUserAdminAccessEnum[keyof typeof GetMe200ResponseUserAdminAccessEnum];


/**
 * Check if a given object implements the GetMe200ResponseUser interface.
 */
export function instanceOfGetMe200ResponseUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "cityId" in value;
    isInstance = isInstance && "languageId" in value;
    isInstance = isInstance && "createdById" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "initials" in value;
    isInstance = isInstance && "streetName" in value;
    isInstance = isInstance && "website" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "phoneCountrycode" in value;
    isInstance = isInstance && "mobile" in value;
    isInstance = isInstance && "mobileCountrycode" in value;
    isInstance = isInstance && "manualPrice" in value;
    isInstance = isInstance && "costPrice" in value;
    isInstance = isInstance && "salePrice" in value;
    isInstance = isInstance && "extraPrice" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "apiKey" in value;
    isInstance = isInstance && "receiveFormMails" in value;
    isInstance = isInstance && "hideAddress" in value;
    isInstance = isInstance && "hidePhone" in value;
    isInstance = isInstance && "expectedBillableHours" in value;
    isInstance = isInstance && "mobileAppPreferences" in value;
    isInstance = isInstance && "isProjectLeader" in value;
    isInstance = isInstance && "adminAccess" in value;
    isInstance = isInstance && "roles" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function GetMe200ResponseUserFromJSON(json: any): GetMe200ResponseUser {
    return GetMe200ResponseUserFromJSONTyped(json, false);
}

export function GetMe200ResponseUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMe200ResponseUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['company_id'],
        'cityId': json['city_id'],
        'countryId': !exists(json, 'country_id') ? undefined : json['country_id'],
        'languageId': json['language_id'],
        'erpId': !exists(json, 'erp_id') ? undefined : json['erp_id'],
        'timeEntryRuleGroupId': !exists(json, 'time_entry_rule_group_id') ? undefined : json['time_entry_rule_group_id'],
        'createdById': json['created_by_id'],
        'usersPriceGroupId': !exists(json, 'users_price_group_id') ? undefined : json['users_price_group_id'],
        'usersPriceGroup': !exists(json, 'users_price_group') ? undefined : UsersPriceGroupFromJSON(json['users_price_group']),
        'email': json['email'],
        'password': json['password'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'fullName': json['full_name'],
        'cityName': !exists(json, 'city_name') ? undefined : json['city_name'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'initials': json['initials'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'streetName': json['street_name'],
        'website': json['website'],
        'phone': json['phone'],
        'phoneCountrycode': json['phone_countrycode'],
        'mobile': json['mobile'],
        'mobileCountrycode': json['mobile_countrycode'],
        'manualPrice': json['manual_price'],
        'costPrice': json['cost_price'],
        'salePrice': json['sale_price'],
        'extraPrice': json['extra_price'],
        'isActive': json['is_active'],
        'apiKey': json['api_key'],
        'receiveFormMails': json['receive_form_mails'],
        'hideAddress': json['hide_address'],
        'hidePhone': json['hide_phone'],
        'expectedBillableHours': json['expected_billable_hours'],
        'mobileAppPreferences': UserMobileAppPreferencesFromJSON(json['mobile_app_preferences']),
        'isProjectLeader': json['is_project_leader'],
        'adminAccess': json['admin_access'],
        'adminAccessOptions': !exists(json, 'admin_access_options') ? undefined : json['admin_access_options'],
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(ProjectFromJSON)),
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(LabelEntityFromJSON)),
        'roles': ((json['roles'] as Array<any>).map(RoleFromJSON)),
        'language': LanguageFromJSON(json['language']),
    };
}

export function GetMe200ResponseUserToJSON(value?: GetMe200ResponseUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'company_id': value.companyId,
        'city_id': value.cityId,
        'country_id': value.countryId,
        'language_id': value.languageId,
        'erp_id': value.erpId,
        'time_entry_rule_group_id': value.timeEntryRuleGroupId,
        'created_by_id': value.createdById,
        'users_price_group_id': value.usersPriceGroupId,
        'users_price_group': UsersPriceGroupToJSON(value.usersPriceGroup),
        'email': value.email,
        'password': value.password,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'full_name': value.fullName,
        'city_name': value.cityName,
        'zip_code': value.zipCode,
        'initials': value.initials,
        'image': value.image,
        'image_url': value.imageUrl,
        'street_name': value.streetName,
        'website': value.website,
        'phone': value.phone,
        'phone_countrycode': value.phoneCountrycode,
        'mobile': value.mobile,
        'mobile_countrycode': value.mobileCountrycode,
        'manual_price': value.manualPrice,
        'cost_price': value.costPrice,
        'extra_price': value.extraPrice,
        'is_active': value.isActive,
        'api_key': value.apiKey,
        'receive_form_mails': value.receiveFormMails,
        'hide_address': value.hideAddress,
        'hide_phone': value.hidePhone,
        'expected_billable_hours': value.expectedBillableHours,
        'mobile_app_preferences': UserMobileAppPreferencesToJSON(value.mobileAppPreferences),
        'is_project_leader': value.isProjectLeader,
        'admin_access': value.adminAccess,
        'admin_access_options': value.adminAccessOptions,
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(ProjectToJSON)),
        'labels': value.labels === undefined ? undefined : ((value.labels as Array<any>).map(LabelEntityToJSON)),
        'roles': ((value.roles as Array<any>).map(RoleToJSON)),
        'language': LanguageToJSON(value.language),
    };
}

