import { Injectable } from "@angular/core";
import { Company } from "src/app/services/response-models/companies.response.model";
import { User } from "src/app/services/response-models/user.response.model";
import { environment } from "src/environments/environment";
import { WindowWrapper } from "src/app/services/window-wrapper.service";

@Injectable({
    providedIn: "root",
})
export class SegmentService {
    constructor(private _w: WindowWrapper) {}

    public load(): void {
        this._w.getWindow().analytics.load(environment.segmentWriteKey);
    }

    public groupCompany(company: Company): void {
        this._w.getWindow().analytics.group(company.id, {
            address: company.street_name,
            cvr: company.cvr,
            createdAt: company.created,
            email: company.contact_email,
            id: company.id,
            name: company.name,
            phone: company.phone,
            website: company.website,
        });
    }

    public trackPage(url?: string): void {
        if (url) {
            this._w.getWindow().analytics.page(url);
        }
        this._w.getWindow().analytics.page();
    }
    public identifyUser(user: User): void {
        this._w.getWindow().analytics.identify(user.id, {
            name: user.full_name,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            phone: user.phone,
            language: user.language?.name,
            locale: user.language?.short_code,
            createdAt: user.created,
        });
    }
}
