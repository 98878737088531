/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';

/**
 * 
 * @export
 * @interface EditUserRequest
 */
export interface EditUserRequest {
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    cityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    languageId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    countryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    streetName?: string | null;
    /**
     * To use this automagic field, city_id *must* be set to null - city_id takes precedence over this field
     * @type {string}
     * @memberof EditUserRequest
     */
    cityName?: string;
    /**
     * To use this automagic field, city_id *must* be set to null - city_id takes precedence over this field
     * @type {string}
     * @memberof EditUserRequest
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    mobileCountrycode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserRequest
     */
    manualPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    usersPriceGroupId?: string | null;
    /**
     * Cost of salaries
     * @type {number}
     * @memberof EditUserRequest
     */
    costPrice?: number;
    /**
     * The price this employee costs per hour when working
     * @type {number}
     * @memberof EditUserRequest
     */
    readonly salePrice?: number;
    /**
     * Additional cost on this employee (pension, vacation etc.)
     * @type {number}
     * @memberof EditUserRequest
     */
    extraPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserRequest
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    apiKey?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    erpId?: string;
    /**
     * Employee will receipt of every form submitted on the provided email
     * @type {string}
     * @memberof EditUserRequest
     */
    receiveFormMails?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserRequest
     */
    hideAddress?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserRequest
     */
    hidePhone?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EditUserRequest
     */
    expectedBillableHours?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserRequest
     */
    isProjectLeader?: boolean;
    /**
     * Time entry rule group
     * @type {string}
     * @memberof EditUserRequest
     */
    timeEntryRuleGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    adminAccess?: EditUserRequestAdminAccessEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditUserRequest
     */
    adminAccessOptions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditUserRequest
     */
    projectIds?: Array<string>;
    /**
     * 
     * @type {Array<Project>}
     * @memberof EditUserRequest
     */
    projects?: Array<Project>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditUserRequest
     */
    labelIds?: Array<string>;
}


/**
 * @export
 */
export const EditUserRequestAdminAccessEnum = {
    NoAccessToAdmin: 'no_access_to_admin',
    AccessToAdmin: 'access_to_admin',
    LimitedAccessToAdmin: 'limited_access_to_admin'
} as const;
export type EditUserRequestAdminAccessEnum = typeof EditUserRequestAdminAccessEnum[keyof typeof EditUserRequestAdminAccessEnum];


/**
 * Check if a given object implements the EditUserRequest interface.
 */
export function instanceOfEditUserRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EditUserRequestFromJSON(json: any): EditUserRequest {
    return EditUserRequestFromJSONTyped(json, false);
}

export function EditUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cityId': !exists(json, 'city_id') ? undefined : json['city_id'],
        'languageId': !exists(json, 'language_id') ? undefined : json['language_id'],
        'countryId': !exists(json, 'country_id') ? undefined : json['country_id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'streetName': !exists(json, 'street_name') ? undefined : json['street_name'],
        'cityName': !exists(json, 'city_name') ? undefined : json['city_name'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'mobileCountrycode': !exists(json, 'mobile_countrycode') ? undefined : json['mobile_countrycode'],
        'manualPrice': !exists(json, 'manual_price') ? undefined : json['manual_price'],
        'usersPriceGroupId': !exists(json, 'users_price_group_id') ? undefined : json['users_price_group_id'],
        'costPrice': !exists(json, 'cost_price') ? undefined : json['cost_price'],
        'salePrice': !exists(json, 'sale_price') ? undefined : json['sale_price'],
        'extraPrice': !exists(json, 'extra_price') ? undefined : json['extra_price'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'apiKey': !exists(json, 'api_key') ? undefined : json['api_key'],
        'erpId': !exists(json, 'erp_id') ? undefined : json['erp_id'],
        'receiveFormMails': !exists(json, 'receive_form_mails') ? undefined : json['receive_form_mails'],
        'hideAddress': !exists(json, 'hide_address') ? undefined : json['hide_address'],
        'hidePhone': !exists(json, 'hide_phone') ? undefined : json['hide_phone'],
        'expectedBillableHours': !exists(json, 'expected_billable_hours') ? undefined : json['expected_billable_hours'],
        'isProjectLeader': !exists(json, 'is_project_leader') ? undefined : json['is_project_leader'],
        'timeEntryRuleGroupId': !exists(json, 'time_entry_rule_group_id') ? undefined : json['time_entry_rule_group_id'],
        'adminAccess': !exists(json, 'admin_access') ? undefined : json['admin_access'],
        'adminAccessOptions': !exists(json, 'admin_access_options') ? undefined : json['admin_access_options'],
        'projectIds': !exists(json, 'project_ids') ? undefined : json['project_ids'],
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(ProjectFromJSON)),
        'labelIds': !exists(json, 'label_ids') ? undefined : json['label_ids'],
    };
}

export function EditUserRequestToJSON(value?: EditUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city_id': value.cityId,
        'language_id': value.languageId,
        'country_id': value.countryId,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'street_name': value.streetName,
        'city_name': value.cityName,
        'zip_code': value.zipCode,
        'mobile': value.mobile,
        'mobile_countrycode': value.mobileCountrycode,
        'manual_price': value.manualPrice,
        'users_price_group_id': value.usersPriceGroupId,
        'cost_price': value.costPrice,
        'extra_price': value.extraPrice,
        'is_active': value.isActive,
        'api_key': value.apiKey,
        'erp_id': value.erpId,
        'receive_form_mails': value.receiveFormMails,
        'hide_address': value.hideAddress,
        'hide_phone': value.hidePhone,
        'expected_billable_hours': value.expectedBillableHours,
        'is_project_leader': value.isProjectLeader,
        'time_entry_rule_group_id': value.timeEntryRuleGroupId,
        'admin_access': value.adminAccess,
        'admin_access_options': value.adminAccessOptions,
        'project_ids': value.projectIds,
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(ProjectToJSON)),
        'label_ids': value.labelIds,
    };
}

