/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Vendor
 */
export interface Vendor {
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    cvr?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Vendor
     */
    supportAutomaticPriceFiles?: boolean;
}

/**
 * Check if a given object implements the Vendor interface.
 */
export function instanceOfVendor(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function VendorFromJSON(json: any): Vendor {
    return VendorFromJSONTyped(json, false);
}

export function VendorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vendor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'cvr': !exists(json, 'cvr') ? undefined : json['cvr'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'supportAutomaticPriceFiles': !exists(json, 'support_automatic_price_files') ? undefined : json['support_automatic_price_files'],
    };
}

export function VendorToJSON(value?: Vendor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'cvr': value.cvr,
        'email': value.email,
        'identifier': value.identifier,
        'image_url': value.imageUrl,
        'support_automatic_price_files': value.supportAutomaticPriceFiles,
    };
}

