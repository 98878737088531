/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    cityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    languageId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    mobileCountrycode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    manualPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    usersPriceGroupId?: string | null;
    /**
     * Cost of salaries
     * @type {number}
     * @memberof CreateUserRequest
     */
    costPrice?: number;
    /**
     * The price this employee costs per hour when working
     * @type {number}
     * @memberof CreateUserRequest
     */
    readonly salePrice?: number;
    /**
     * Additional cost on this employee (pension, vacation etc.)
     * @type {number}
     * @memberof CreateUserRequest
     */
    extraPrice?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest
     */
    labelIds?: Array<string>;
}

/**
 * Check if a given object implements the CreateUserRequest interface.
 */
export function instanceOfCreateUserRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;

    return isInstance;
}

export function CreateUserRequestFromJSON(json: any): CreateUserRequest {
    return CreateUserRequestFromJSONTyped(json, false);
}

export function CreateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cityId': !exists(json, 'city_id') ? undefined : json['city_id'],
        'languageId': !exists(json, 'language_id') ? undefined : json['language_id'],
        'firstName': json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'mobileCountrycode': !exists(json, 'mobile_countrycode') ? undefined : json['mobile_countrycode'],
        'manualPrice': !exists(json, 'manual_price') ? undefined : json['manual_price'],
        'usersPriceGroupId': !exists(json, 'users_price_group_id') ? undefined : json['users_price_group_id'],
        'costPrice': !exists(json, 'cost_price') ? undefined : json['cost_price'],
        'salePrice': !exists(json, 'sale_price') ? undefined : json['sale_price'],
        'extraPrice': !exists(json, 'extra_price') ? undefined : json['extra_price'],
        'labelIds': !exists(json, 'label_ids') ? undefined : json['label_ids'],
    };
}

export function CreateUserRequestToJSON(value?: CreateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city_id': value.cityId,
        'language_id': value.languageId,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'mobile': value.mobile,
        'mobile_countrycode': value.mobileCountrycode,
        'manual_price': value.manualPrice,
        'users_price_group_id': value.usersPriceGroupId,
        'cost_price': value.costPrice,
        'extra_price': value.extraPrice,
        'label_ids': value.labelIds,
    };
}

