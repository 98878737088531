import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { NavController } from "@ionic/angular";

@Injectable({
    providedIn: "root",
})
export class RedirectService {
    constructor(
        private readonly navCtrl: NavController,
        @Inject(DOCUMENT) private readonly document: Document
    ) {}

    get window(): Window {
        return this.document.defaultView;
    }

    public navigate(url: string, extras?: { target: string }): Promise<boolean> {
        return this.isExternal(url)
            ? this.redirectToExternal(url, extras && extras.target)
            : this.navCtrl.navigateRoot(url);
    }

    private redirectToExternal(url: string, target = "_blank"): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                resolve(!!this.window.open(url, target));
            } catch (e) {
                reject(e);
            }
        });
    }

    private isExternal(url: string): boolean {
        return /^http(?:s)?:\/{2}/.test(url);
    }
}
