/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductBundleLine
 */
export interface ProductBundleLine {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleLine
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleLine
     */
    readonly createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleLine
     */
    readonly modifiedById?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleLine
     */
    productBundleId: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleLine
     */
    vendorProductId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleLine
     */
    usersPriceGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleLine
     */
    expenseLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleLine
     */
    productId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleLine
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleLine
     */
    buyingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleLine
     */
    sellingPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleLine
     */
    placement?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleLine
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductBundleLine
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductBundleLine
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof ProductBundleLine
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the ProductBundleLine interface.
 */
export function instanceOfProductBundleLine(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "productBundleId" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "sellingPrice" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function ProductBundleLineFromJSON(json: any): ProductBundleLine {
    return ProductBundleLineFromJSONTyped(json, false);
}

export function ProductBundleLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundleLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'productBundleId': json['product_bundle_id'],
        'vendorProductId': !exists(json, 'vendor_product_id') ? undefined : json['vendor_product_id'],
        'usersPriceGroupId': !exists(json, 'users_price_group_id') ? undefined : json['users_price_group_id'],
        'expenseLineId': !exists(json, 'expense_line_id') ? undefined : json['expense_line_id'],
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'quantity': json['quantity'],
        'buyingPrice': !exists(json, 'buying_price') ? undefined : json['buying_price'],
        'sellingPrice': json['selling_price'],
        'placement': !exists(json, 'placement') ? undefined : json['placement'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function ProductBundleLineToJSON(value?: ProductBundleLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_bundle_id': value.productBundleId,
        'vendor_product_id': value.vendorProductId,
        'users_price_group_id': value.usersPriceGroupId,
        'expense_line_id': value.expenseLineId,
        'product_id': value.productId,
        'quantity': value.quantity,
        'buying_price': value.buyingPrice,
        'selling_price': value.sellingPrice,
        'placement': value.placement,
        'name': value.name,
    };
}

