import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Store } from "@ngxs/store";
import { LoadFeatures, LoadMenuFeatures } from "src/store/features/actions/set-features.action";
import { Feature, Features } from "src/app/services/response-models/features.response.model";
import { MenuFeature } from "src/app/shared/models/menu-feature";
import { allFeatures } from "src/app/shared/constants/features.constants";

@Injectable({
    providedIn: "root",
})
export class FeaturesService {
    private readonly featuresUrl: string = `${environment.apiBaseUrl}features`;

    constructor(
        private http: HttpClient,
        private store: Store
    ) {}

    public async getFeatures(): Promise<Features> {
        const { data } = await this.http.get<Features>(this.featuresUrl).toPromise();
        this.mapMenuItemsToFeatures(data);
        this.store.dispatch(new LoadFeatures(data));

        return { data } as Features;
    }

    private mapMenuItemsToFeatures(features: Feature[]): void {
        const featureIdentifiers: string[] = features.map((x) => x.identifier);
        let currentFeature: MenuFeature = null;
        const companyFeatures = [];
        const sortedFeature = allFeatures
            .slice()
            .sort((a: any, b: any) => a.placement - b.placement);

        for (const feature of sortedFeature) {
            const isFeatureActive = !!featureIdentifiers.find(
                (identifier) => identifier === feature.feature
            );
            currentFeature = { ...feature, activated: isFeatureActive };

            if (currentFeature && currentFeature.is_parent) {
                currentFeature.children = currentFeature.children.filter((y) =>
                    featureIdentifiers.includes(y.feature)
                );
            }

            if (currentFeature && Object.keys(currentFeature).length !== 0) {
                companyFeatures.push(currentFeature);
            }
        }

        this.store.dispatch(new LoadMenuFeatures(companyFeatures));
    }
}
