/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductBundleLine } from './ProductBundleLine';
import {
    ProductBundleLineFromJSON,
    ProductBundleLineFromJSONTyped,
    ProductBundleLineToJSON,
} from './ProductBundleLine';

/**
 * 
 * @export
 * @interface ProductBundleLineView200Response
 */
export interface ProductBundleLineView200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleLineView200Response
     */
    success?: boolean;
    /**
     * 
     * @type {ProductBundleLine}
     * @memberof ProductBundleLineView200Response
     */
    data: ProductBundleLine;
}

/**
 * Check if a given object implements the ProductBundleLineView200Response interface.
 */
export function instanceOfProductBundleLineView200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ProductBundleLineView200ResponseFromJSON(json: any): ProductBundleLineView200Response {
    return ProductBundleLineView200ResponseFromJSONTyped(json, false);
}

export function ProductBundleLineView200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundleLineView200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': ProductBundleLineFromJSON(json['data']),
    };
}

export function ProductBundleLineView200ResponseToJSON(value?: ProductBundleLineView200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': ProductBundleLineToJSON(value.data),
    };
}

