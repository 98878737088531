import { PaymentTerm } from "src/app/services/response-models/contacts.response.model";
import { IntegrationFeatureSetting } from "src/app/services/response-models/companies.response.model";

export class SetDefaultBankAccount {
    static readonly type = "[Contacts] Set default back account";

    constructor(public payload: string) {}
}

export class SetDefaultCompantIntegrationFeatureSetting {
    static readonly type = "[Contacts] Set default payment terms setting";

    constructor(public payload: IntegrationFeatureSetting[]) {}
}

export class SetDefaultPaymentTerm {
    static readonly type = "[Contacts] Set default payment term";

    constructor(public payload: PaymentTerm) {}
}
