/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Vendor } from './Vendor';
import {
    VendorFromJSON,
    VendorFromJSONTyped,
    VendorToJSON,
} from './Vendor';

/**
 * 
 * @export
 * @interface ProductVariant
 */
export interface ProductVariant {
    /**
     * 
     * @type {string}
     * @memberof ProductVariant
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariant
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariant
     */
    readonly type: ProductVariantTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariant
     */
    readonly isPrimaryBuyingOption: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductVariant
     */
    readonly latestPrice: number;
    /**
     * 
     * @type {Vendor}
     * @memberof ProductVariant
     */
    readonly vendor?: Vendor;
    /**
     * 
     * @type {Date}
     * @memberof ProductVariant
     */
    readonly latestUpdated: Date;
}


/**
 * @export
 */
export const ProductVariantTypeEnum = {
    ExpenseLine: 'expense_line',
    VendorProduct: 'vendor_product'
} as const;
export type ProductVariantTypeEnum = typeof ProductVariantTypeEnum[keyof typeof ProductVariantTypeEnum];


/**
 * Check if a given object implements the ProductVariant interface.
 */
export function instanceOfProductVariant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "isPrimaryBuyingOption" in value;
    isInstance = isInstance && "latestPrice" in value;
    isInstance = isInstance && "latestUpdated" in value;

    return isInstance;
}

export function ProductVariantFromJSON(json: any): ProductVariant {
    return ProductVariantFromJSONTyped(json, false);
}

export function ProductVariantFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductVariant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'isPrimaryBuyingOption': json['is_primary_buying_option'],
        'latestPrice': json['latest_price'],
        'vendor': !exists(json, 'vendor') ? undefined : VendorFromJSON(json['vendor']),
        'latestUpdated': (new Date(json['latest_updated'])),
    };
}

export function ProductVariantToJSON(value?: ProductVariant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

