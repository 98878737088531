/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectGetKpiCardEconomySubPage200ResponseData } from './ProjectGetKpiCardEconomySubPage200ResponseData';
import {
    ProjectGetKpiCardEconomySubPage200ResponseDataFromJSON,
    ProjectGetKpiCardEconomySubPage200ResponseDataFromJSONTyped,
    ProjectGetKpiCardEconomySubPage200ResponseDataToJSON,
} from './ProjectGetKpiCardEconomySubPage200ResponseData';

/**
 * 
 * @export
 * @interface ProjectGetKpiCardEconomySubPage200Response
 */
export interface ProjectGetKpiCardEconomySubPage200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectGetKpiCardEconomySubPage200Response
     */
    success: boolean;
    /**
     * 
     * @type {ProjectGetKpiCardEconomySubPage200ResponseData}
     * @memberof ProjectGetKpiCardEconomySubPage200Response
     */
    data: ProjectGetKpiCardEconomySubPage200ResponseData;
}

/**
 * Check if a given object implements the ProjectGetKpiCardEconomySubPage200Response interface.
 */
export function instanceOfProjectGetKpiCardEconomySubPage200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ProjectGetKpiCardEconomySubPage200ResponseFromJSON(json: any): ProjectGetKpiCardEconomySubPage200Response {
    return ProjectGetKpiCardEconomySubPage200ResponseFromJSONTyped(json, false);
}

export function ProjectGetKpiCardEconomySubPage200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectGetKpiCardEconomySubPage200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': ProjectGetKpiCardEconomySubPage200ResponseDataFromJSON(json['data']),
    };
}

export function ProjectGetKpiCardEconomySubPage200ResponseToJSON(value?: ProjectGetKpiCardEconomySubPage200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': ProjectGetKpiCardEconomySubPage200ResponseDataToJSON(value.data),
    };
}

