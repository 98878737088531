export class SetCheckInIntervals {
    public static readonly type = "[Users] Load intervals";

    constructor(public payload: number[]) {}
}

export class ClearUserIntervals {
    public static readonly type = "[Users] Clear intervals";

    constructor() {}
}
