/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersPriceGroup
 */
export interface UsersPriceGroup {
    /**
     * 
     * @type {string}
     * @memberof UsersPriceGroup
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPriceGroup
     */
    createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPriceGroup
     */
    modifiedById?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPriceGroup
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPriceGroup
     */
    name: string;
    /**
     * Cost of salaries
     * @type {number}
     * @memberof UsersPriceGroup
     */
    costPrice: number;
    /**
     * The price this employee costs per hour when working
     * @type {number}
     * @memberof UsersPriceGroup
     */
    salePrice: number;
    /**
     * Additional cost on this employee (pension, vacation etc.)
     * @type {number}
     * @memberof UsersPriceGroup
     */
    extraPrice?: number;
    /**
     * 
     * @type {Date}
     * @memberof UsersPriceGroup
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UsersPriceGroup
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof UsersPriceGroup
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the UsersPriceGroup interface.
 */
export function instanceOfUsersPriceGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "costPrice" in value;
    isInstance = isInstance && "salePrice" in value;

    return isInstance;
}

export function UsersPriceGroupFromJSON(json: any): UsersPriceGroup {
    return UsersPriceGroupFromJSONTyped(json, false);
}

export function UsersPriceGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersPriceGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'companyId': json['company_id'],
        'name': json['name'],
        'costPrice': json['cost_price'],
        'salePrice': json['sale_price'],
        'extraPrice': !exists(json, 'extra_price') ? undefined : json['extra_price'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function UsersPriceGroupToJSON(value?: UsersPriceGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by_id': value.createdById,
        'modified_by_id': value.modifiedById,
        'company_id': value.companyId,
        'name': value.name,
        'cost_price': value.costPrice,
        'sale_price': value.salePrice,
        'extra_price': value.extraPrice,
    };
}

