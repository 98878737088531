import "@angular/compiler";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
    enableProdMode();
}

if (environment.enableSentry) {
    Sentry.init({
        dsn: "https://24637249273a4ee28303483cee02eef9@o84452.ingest.sentry.io/1412712",
        transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.sentryTracesSampleRate,
        environment: environment.environmentName,
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
