import { Action, StateContext, State, Selector, Store } from "@ngxs/store";
import { Navigate, NavigationExtras } from "./router.actions";
import { NgZone, Injectable } from "@angular/core";
import { AppState } from "../app/app.state";
import { SetMenuVisibility } from "../app/actions/set-menu-visibility.action";
import { RedirectService } from "src/app/services/redirect.service";

export interface RouterStateModel {
    path: string[];
    target: string;
}

@State<RouterStateModel>({
    name: "router",
    defaults: {
        path: [],
        target: "_blank",
    },
})
@Injectable()
export class RouterStateModule {
    constructor(
        private store: Store,
        private redirectService: RedirectService,
        private zone: NgZone
    ) {}

    @Selector()
    static getRouteExtras(state: RouterStateModel) {
        return state.target;
    }

    @Selector()
    static getRoute(state: RouterStateModel) {
        return state.path;
    }

    @Action(NavigationExtras)
    setNavigationExtras(ctx: StateContext<RouterStateModel>, { payload }: NavigationExtras) {
        ctx.setState({ ...ctx.getState(), target: payload });
    }

    @Action(Navigate)
    async changeRoute(context: StateContext<RouterStateModel>, { payload }: Navigate) {
        const path = payload.join("/");

        await this.zone.run(() => {
            this.redirectService.navigate(path, { target: context.getState().target }).then(() => {
                // // Triggered once to activate menu bar, usecase login redirect
                const isMenuVisible = this.store.selectSnapshot(AppState.getMenuVisibility);
                if (!isMenuVisible && !path.includes("login")) {
                    this.store.dispatch(new SetMenuVisibility(true));
                }
            });
        });
        context.setState({ path: path.split("/"), target: "_blank" });
    }
}
