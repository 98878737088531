import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Store } from "@ngxs/store";
import {
    ClearProjects,
    LoadProjects,
    SavePagination,
} from "src/store/projects/actions/set-projects.action";
import { SaveCurrentProject } from "src/store/projects/actions/set-current-project.action";
import { SaveCreatedProjectId } from "src/store/projects/actions/set-project-id.action";
import {
    SetProjectEmployees,
    SetProjectEmployeesPagination,
} from "src/store/projects/actions/set-project-employees.action";
import { ToastService } from "src/app/services/toast.service";
import {
    GeneralSuccessResp,
    SuccessAdd,
    SuccessResponseStatus,
} from "src/app/services/response-models/companies.response.model";
import {
    CreateProject,
    CurrentProject,
    CustomProjectStatuses,
    FinancialStatistics,
    FinancialStatisticsResponseModel,
    MarkAsInvoicedResponseModel,
    MergeProjectsResponseModel,
    OfferOverview,
    Pagination,
    ProjectData,
    ProjectEmployeesResponseModel,
    ProjectFile,
    ProjectOverview,
    Projects,
} from "src/app/services/response-models/projects.response.model";
import {
    LastUsedProductsResponseModel,
    ProductConsumptionResponse,
} from "src/app/services/response-models/product.response.model";
import {
    DeleteUserFromProjectResponse,
    UploadedFileWithTypePagination,
} from "src/app/services/models/misc.model";
import { ProjectStatus } from "src/app/services/response-models/project-statuses.reponse.model";
import { sentryCaptureException } from "src/app/utility/errors";

@Injectable({
    providedIn: "root",
})
export class ProjectsService {
    private readonly projectsUrl = `${environment.apiBaseUrl}projects`;
    private readonly projectStatusesUrl = `${environment.apiBaseUrl}project_statuses`;
    private readonly hasProjectsWithCustomStatusesUrl = `${environment.apiBaseUrl}projects/has_projects_with_custom_statuses`;

    constructor(
        private http: HttpClient,
        private store: Store,
        private toastService: ToastService
    ) {}

    public async getProjects(
        page = 1,
        query = "",
        includes = "",
        sortBy = "",
        direction = "",
        groupBy = "",
        project_status_ids: Array<string> = [],
        limit = "20",
        contactId = "",
        activities: Array<string> = [],
        withActivities = "false",
        is_offer = "",
        employeeIds = "",
        withChildProjects = true,
        useStore = true
    ): Promise<Projects> {
        const headers = {
            params: {
                page: page.toString(),
                q: query,
                include: includes,
                sort: sortBy,
                direction,
                limit,
                group_by: groupBy,
                contact_id: contactId,
                withActivities,
                show_all: "true",
                is_offer,
                employee_ids: employeeIds,
                withChildProjects,
            },
        };

        if (!withChildProjects) {
            delete headers.params.withChildProjects;
        }

        if (!employeeIds) {
            delete headers.params.employee_ids;
        }

        if (is_offer?.length === 0) {
            delete headers.params.is_offer;
        }

        // Sort by filter first if any
        if (project_status_ids.length) {
            for (let i = 0; i < project_status_ids.length; i++) {
                headers.params[`project_status_ids[${i}]`] = project_status_ids[i];
            }
        }

        if (activities.length) {
            for (let i = 0; i < activities.length; i++) {
                headers.params[`activity_ids[${i}]`] = activities[i];
            }
        }

        if (limit !== "" && limit !== null) {
            headers.params.limit = limit;
        }

        const { data, pagination } = await this.http
            .get<Projects>(this.projectsUrl, headers)
            .toPromise()
            .catch((err) => {
                sentryCaptureException(err);
                this.toastService.showToastMessage("TOAST.BAD_CONNECTION");
                return { data: [], pagination: null };
            });

        if (page === 1 && useStore) {
            this.store.dispatch(new ClearProjects());
        }

        if (groupBy !== "" && useStore) {
            this.store.dispatch(new SavePagination(pagination));
            this.store.dispatch(new LoadProjects(Object.entries(data)));
            return;
        }

        if (useStore) {
            this.store.dispatch(new SavePagination(pagination as Pagination));
            this.store.dispatch(new LoadProjects(data));
        }

        return { data, pagination } as Projects;
    }

    public async getLastUsedProjects(
        page = 1,
        query = "",
        sortBy = "",
        direction = "",
        groupBy = "",
        filters: Array<string> = [],
        employeeIds = ""
    ): Promise<Projects> {
        const url = `${this.projectsUrl}/recently-used`;

        const headers = {
            params: {
                page: page.toString(),
                q: query,
                include: "contacts,contact_persons,project_statuses",
                sort: sortBy,
                direction,
                group_by: groupBy,
                employee_ids: employeeIds,
                withChildProjects: true,
                show_all: "true",
            },
        };

        if (!employeeIds) {
            delete headers.params.employee_ids;
        }

        if (filters.length) {
            for (let i = 0; i < filters.length; i++) {
                headers.params[`project_status_ids[${i}]`] = filters[i];
            }
        }

        const { data, pagination } = await this.http
            .get<Projects>(url, headers)
            .toPromise()
            .catch(() => {
                this.toastService.showToastMessage("TOAST.BAD_CONNECTION");
                return { data: [], pagination: null };
            });

        if (page === 1) {
            this.store.dispatch(new ClearProjects());
        }

        this.store.dispatch(new SavePagination(pagination));
        this.store.dispatch(new LoadProjects(data));

        return { data, pagination } as Projects;
    }

    public async getProjectById(
        id: string,
        include = "contacts,events.event_tasks,cities,contact_persons",
        skipSavingProject = false
    ): Promise<ProjectData> {
        const headers = {
            params: {
                include,
            },
        };
        const { data } = await this.http
            .get<CurrentProject>(`${this.projectsUrl}/${id}`, headers)
            .toPromise();

        if (!skipSavingProject) {
            this.store.dispatch(new SaveCurrentProject(data));
        }

        return data;
    }

    public async getProjectForms(
        filters?: Array<string>,
        includes?: string
    ): Promise<ProjectData[]> {
        const headers = {
            params: {
                include: includes || "time_entries,forms.time_entries,forms.users",
            },
        };

        if (filters?.length) {
            for (let i = 0; i < filters.length; i++) {
                headers.params[`project_status_ids[${i}]`] = filters[i];
            }
        }

        const { data } = await this.http.get<Projects>(this.projectsUrl, headers).toPromise();

        return data;
    }

    public async createProject(body): Promise<ProjectData> {
        const { data } = await this.http.post<CreateProject>(this.projectsUrl, body).toPromise();
        await this.getProjectById(data.id);
        this.store.dispatch(new SaveCreatedProjectId(data.id));
        return data;
    }

    public async editProject(projectId: string, body): Promise<GeneralSuccessResp> {
        return await this.http
            .put<GeneralSuccessResp>(`${this.projectsUrl}/${projectId}`, body)
            .toPromise();
    }

    public getCustomProjectStatuses = async (statuses: string): Promise<CustomProjectStatuses> => {
        const headers = {
            params: {
                limit: "100",
                project_status_type_identifier: statuses,
            },
        };

        return await this.http
            .get<CustomProjectStatuses>(this.projectStatusesUrl, headers)
            .toPromise();
    };

    /**
     * Check if user is able to submit a form for this project.
     * Open and Prepared for invoicing statues are accepted as truty
     * @param projectId
     * @returns bool
     */
    public isProjectOpen = async (projectId: string): Promise<boolean> => {
        const url = `${this.projectsUrl}/${projectId}`;
        const headers = {
            params: {
                include: "project_statuses.project_status_types",
            },
        };

        const { data } = await this.http.get<CurrentProject>(url, headers).toPromise();

        return data.project_status.project_status_type.identifier !== "closed";
    };

    public async changeProjectStatus(
        statusId: string,
        projectId: string
    ): Promise<GeneralSuccessResp> {
        const url = `${this.projectsUrl}/${projectId}`;

        const body = {
            project_status_id: statusId,
        };

        return await this.http.put<GeneralSuccessResp>(url, body).toPromise();
    }

    public async openProjectPdf(
        id: string,
        apiKey,
        date_from?: string,
        date_to?: string
    ): Promise<any> {
        let url = `${environment.apiBaseUrl}projects/view/${id}?api_key=${apiKey}`;
        if (date_from && date_to) {
            url += `&date_from=${date_from}&date_to=${date_to}`;
        }

        window.open(url, "_blank");
    }

    public getProjectOfferOverview(offerId: string): Promise<OfferOverview> {
        const url = `${environment.apiBaseUrl}financial_statistics/offer_overview/${offerId}`;

        const headers = {
            params: {},
        };

        return this.http.get<OfferOverview>(url, headers).toPromise();
    }

    public getProjectOverview(
        id: string,
        date_from?: string,
        date_to?: string,
        activities?: string,
        includeChildData = false
    ): Promise<ProjectOverview> {
        const url = `${environment.apiBaseUrl}financial_statistics/overview`;

        const headers = {
            params: {
                project_id: id,
                include_child_data: includeChildData,
            } as any,
        };

        if (date_from && date_to) {
            headers.params.date_from = date_from;
            headers.params.date_to = date_to;
        }

        if (activities) {
            headers.params.activities = activities;
        }

        return this.http.get<ProjectOverview>(url, headers).toPromise();
    }

    public async getFormsProducts(
        id,
        page = 1,
        date_from?: string,
        date_to?: string
    ): Promise<LastUsedProductsResponseModel> {
        const url = `${environment.apiBaseUrl}forms_products`;
        const headers = {
            params: {
                project_id: id,
                page: page.toString(),
                include: "products,forms",
            } as any,
        };
        if (date_from && date_to) {
            headers.params.gte_form_from_date = date_from;
            headers.params.lte_form_to_date = date_to;
        }
        const data = await this.http.get<LastUsedProductsResponseModel>(url, headers).toPromise();

        if (data.data.length) {
            data.data.sort((a, b) => a.product.name.localeCompare(b.product.name));
        }

        return data;
    }

    public getProductConsumption(
        projectId,
        page = 1,
        dateFrom: string,
        dateTo: string,
        includeChildData = false,
        limit = 10
    ): Promise<ProductConsumptionResponse> {
        const url = `${this.projectsUrl}/${projectId}/product_consumption`;
        const headers = {
            params: {
                project_id: projectId,
                page: page.toString(),
                date_from: dateFrom,
                date_to: dateTo,
                include_child_data: includeChildData,
                limit,
            },
        };

        return this.http.get<ProductConsumptionResponse>(url, headers).toPromise();
    }

    public getForms(id) {
        const url = `${environment.apiBaseUrl}forms`;
        const headers = {
            params: {
                project_id: id,
                include: "time_entries,users,form_templates",
            },
        };
        return this.http.get<ProjectOverview>(url, headers).toPromise();
    }

    public getInvoices(id): Promise<ProjectOverview> {
        const url = `${environment.apiBaseUrl}invoices`;
        const headers = {
            params: {
                project_id: id,
                include: "contacts",
            },
        };
        return this.http.get<ProjectOverview>(url, headers).toPromise();
    }

    public getExpenses(id): Promise<ProjectOverview> {
        const url = `${environment.apiBaseUrl}expenses`;
        const headers = {
            params: {
                project_id: id,
                include: "expense_lines,vendors",
            },
        };
        return this.http.get<ProjectOverview>(url, headers).toPromise();
    }

    public async getFinancialStatistics(
        id?: string,
        date_from?: string,
        date_to?: string,
        activities = ""
    ): Promise<FinancialStatistics> {
        const url = `${environment.apiBaseUrl}financial_statistics`;

        const headers = {
            params: {} as any,
        };

        if (id) {
            headers.params.project_id = id;
        }
        if (date_from && date_to) {
            headers.params.date_from = date_from;
            headers.params.date_to = date_to;
        }

        if (activities) {
            headers.params.activities = activities;
        }

        const { data } = await this.http
            .get<FinancialStatisticsResponseModel>(url, headers)
            .toPromise();
        return data;
    }

    public deleteProject(id: string): Promise<GeneralSuccessResp> {
        return this.http
            .delete<GeneralSuccessResp>(`${environment.apiBaseUrl}projects/${id}`)
            .toPromise();
    }

    public deleteSingleProjectFile(projectId: string, fileId: string): Promise<GeneralSuccessResp> {
        return this.http
            .delete<GeneralSuccessResp>(`${this.projectsUrl}/${projectId}/project_files/${fileId}`)
            .toPromise();
    }

    public async mergeProjects(original_project_id: string, merging_project_id: string) {
        const url = `${this.projectsUrl}/merge`;

        const body = {
            original_project_id,
            merging_project_id,
        };

        const { success } = await this.http
            .post<MergeProjectsResponseModel>(url, body)
            .toPromise()
            .then(() => ({ success: true, data: [] }))
            .catch(() => ({ success: false, data: [] }));

        return success;
    }

    public async getAllFilesForProject(
        projectId: string,
        includeChildData = false
    ): Promise<UploadedFileWithTypePagination> {
        const headers = {
            params: {
                include_child_data: includeChildData,
            },
        };

        return this.http
            .get<UploadedFileWithTypePagination>(
                `${this.projectsUrl}/${projectId}/all_files`,
                headers
            )
            .toPromise();
    }

    public async getAllImagesForProject(
        projectId: string,
        includeChildData = false,
        page = 1
    ): Promise<UploadedFileWithTypePagination> {
        const headers = {
            params: {
                include_child_data: includeChildData,
                page: page,
            },
        };

        return this.http
            .get<UploadedFileWithTypePagination>(
                `${this.projectsUrl}/${projectId}/all_files/get_images`,
                headers
            )
            .toPromise();
    }

    public async addFileToProject(projectId: string, file: File): Promise<ProjectFile> {
        const body = new FormData();

        body.append("file", file, file.name);

        return await this.http
            .post<ProjectFile>(`${this.projectsUrl}/${projectId}/project_files`, body)
            .toPromise();
    }

    public async updateProjects(project: Partial<ProjectData>) {
        const url = `${this.projectsUrl}/${project.id}`;

        await this.http.put(url, project).toPromise();
    }

    public sendProjectFilesToMail(projectId: string, body): Promise<SuccessResponseStatus> {
        return this.http
            .post<SuccessResponseStatus>(`${this.projectsUrl}/${projectId}/send_files`, body)
            .toPromise();
    }

    public async getProjectEmployees(
        projectId: string,
        page = 1,
        query = "",
        sortBy = "",
        direction = "",
        is_active = "true"
    ): Promise<ProjectEmployeesResponseModel> {
        const headers = {
            params: {
                page: page.toString(),
                q: query,
                sort: sortBy,
                include: "roles",
                direction,
                is_active,
            },
        };

        const { data, pagination } = await this.http
            .get<ProjectEmployeesResponseModel>(`${this.projectsUrl}/${projectId}/users`, headers)
            .toPromise();

        this.store.dispatch(new SetProjectEmployees(data));
        this.store.dispatch(new SetProjectEmployeesPagination(pagination));

        return { data, pagination } as ProjectEmployeesResponseModel;
    }

    public async addEmployeeToProject(projectId, updateUsers): Promise<any> {
        const url = `${this.projectsUrl}/${projectId}`;

        const body = {
            users: updateUsers,
        };

        return await this.http.put(url, body).toPromise();
    }

    public async copyProject(projectId): Promise<SuccessAdd> {
        const data = await this.http
            .post<any>(`${this.projectsUrl}/${projectId}/copy`, {})
            .toPromise();
        return data as SuccessAdd;
    }

    public sendMailGeneratePdf(project_id: string, form_id: string[]): Promise<GeneralSuccessResp> {
        const url = `${this.projectsUrl}/${project_id}/send_bulk_pdf`;
        const body = {
            form_id,
        };

        return this.http.post<GeneralSuccessResp>(url, body).toPromise();
    }

    public deleteUserFromProject(
        project_id: string,
        user_id: string
    ): Promise<DeleteUserFromProjectResponse> {
        return this.http
            .delete<DeleteUserFromProjectResponse>(
                `${environment.apiBaseUrl}projects/${project_id}/users/${user_id}`
            )
            .toPromise();
    }

    public async markAsInvoiced(projectId: string): Promise<MarkAsInvoicedResponseModel> {
        const url = `${this.projectsUrl}/${projectId}/invoice_all`;

        return await this.http.post<MarkAsInvoicedResponseModel>(url, {}).toPromise();
    }

    public extractOpenProjectStatusIds(projectStatuses: ProjectStatus[]): string[] {
        if (!projectStatuses || projectStatuses?.length === 0) {
            return [];
        }

        return projectStatuses
            .filter((status: ProjectStatus) => status.project_status_type.identifier === "open")
            .map((status: ProjectStatus) => status.id);
    }
}
