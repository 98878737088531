import {
    CustomProjectStatus,
    Pagination,
    ProjectCustomFieldsAttribute,
    ProjectData,
    ProjectEmployees,
} from "src/app/services/response-models/projects.response.model";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ClearProjects, LoadProjects, SavePagination } from "./actions/set-projects.action";
import { SaveCurrentProject } from "src/store/projects/actions/set-current-project.action";
import { SearchProjects } from "src/store/projects/actions/search-project.action";
import { SaveCreatedProjectId } from "src/store/projects/actions/set-project-id.action";
import {
    ClearSelectedEvent,
    SetSelectedEvent,
} from "src/store/projects/actions/set-selected-event.action";
import {
    ClearSelectedCard,
    SetSelectedCard,
} from "src/store/projects/actions/set-selected-card.action";
import { ProjectEvent } from "src/app/services/response-models/events.response.model";
import { SetSelectedFilters } from "./actions/set-selected-filters.action";
import { DisplayStatus } from "src/app/employee/projects/project-filters/models/filters.models";
import {
    SetProjectGroupBy,
    SetProjectSortBy,
    SetProjectView,
} from "./actions/set-project-view.action";
import { SetProjectsWithEvents } from "./actions/set-projects-events.action";
import { Injectable } from "@angular/core";
import { SetProjectStatuses } from "./actions/set-project-statuses.action";
import {
    ClearProjectEmployees,
    SetProjectEmployees,
    SetProjectEmployeesPagination,
} from "./actions/set-project-employees.action";
import { SetProjectCustomFieldAttributes } from "./actions/set-project_custom_field_attributes.action";
import { UploadedFileWithType } from "src/app/services/models/misc.model";
import { SetProjectImageFiles } from "./actions/set-project-files.action";
import { SetActiveAppListProjectsTabAction } from "./actions/set-active-app-list-projects-tab.action";
import { SetActiveStatusIdsAction } from "./actions/set-active-status-ids.action";
import { SetActiveProjectsTypeFilter } from "./actions/set-active-project-type.action";
import { SetKeepFilters } from "src/store/projects/actions/set-keep-filters.action";
import { SetProjectsListScrollTop } from "src/store/projects/actions/set-projects-list-scroll-top.action";
import { SetLastPageProjectView } from "src/store/projects/actions/set-last-page-project-view.action";
import { ProjectCreateData } from "src/app/employee/projects/projects-create/models/project-create-data.model";
import { SetCreateProjectData } from "./actions/set-create-project.action";
import { SetSearchBarStatus } from "./actions/set-search-bar.action";
import { MobileGetCards200ResponseDataInner } from "@apacta/sdk";
import { SetNotebookProducts } from "./actions/set-notebook-products.action";
import { SetNotebookPhotos } from "./actions/set-notebook-photos.action";

export interface ProjectsStateModel {
    projects: ProjectData[] | any;
    selectedProject: ProjectData;
    searchProjects: ProjectData[];
    searchQuery: any;
    pagination: Pagination;
    createdProjectId: string;
    selectedEvent: ProjectEvent;
    selectedCard: MobileGetCards200ResponseDataInner;
    selectedFilters: Array<DisplayStatus>;
    view: string;
    sortDir: string;
    groupBy: string;
    projectsWithEvents: Object;
    projectStatuses: Array<CustomProjectStatus>;
    projectEmployees: ProjectEmployees[];
    projectEmployeesPagination: Pagination;
    projectCustomFieldsAttributes: Array<ProjectCustomFieldsAttribute>;
    imageFiles: UploadedFileWithType[];
    appProjectListActiveTab: number;
    activeStatusIds: string[];
    keepFilters: boolean;
    activeProjectsTypeFilter: string;
    projectsListScrollTop: number;
    lastPageProjectView: boolean;
    createProjectData: ProjectCreateData;
    isSearchBarHidden: boolean;
    notebookPhotos: number;
    notebookProducts: number;
}

@State<ProjectsStateModel>({
    name: "projects",
    defaults: {
        projects: [],
        selectedProject: null,
        searchProjects: [],
        searchQuery: "",
        pagination: null,
        createdProjectId: "",
        selectedEvent: null,
        selectedCard: null,
        selectedFilters: [],
        view: null,
        sortDir: null,
        groupBy: null,
        projectsWithEvents: null,
        projectStatuses: [],
        projectEmployees: [],
        projectEmployeesPagination: null,
        projectCustomFieldsAttributes: [],
        imageFiles: [],
        appProjectListActiveTab: 1,
        activeStatusIds: [],
        keepFilters: true,
        activeProjectsTypeFilter: "LIST_PROJECTS.ALL_PROJECT_TYPES",
        projectsListScrollTop: 0,
        lastPageProjectView: false,
        createProjectData: null,
        isSearchBarHidden: true,
        notebookPhotos: 0,
        notebookProducts: 0,
    },
})
@Injectable()
export class ProjectsState {
    @Selector()
    static getProjects(state: ProjectsStateModel) {
        return state.projects;
    }

    @Selector()
    static getSelectedProject(state: ProjectsStateModel) {
        return state.selectedProject;
    }

    @Selector()
    static getPagination(state: ProjectsStateModel) {
        return state.pagination;
    }

    @Selector()
    static getCreatedProjectId(state: ProjectsStateModel) {
        return state.createdProjectId;
    }

    @Selector()
    static getSearchQuery(state: ProjectsStateModel) {
        return state.searchQuery;
    }

    @Selector()
    static getSelectedEvent(state: ProjectsStateModel) {
        return state.selectedEvent;
    }

    @Selector()
    static getSelectedCard(state: ProjectsStateModel) {
        return state.selectedCard;
    }

    @Selector()
    static getSelectedFilters(state: ProjectsStateModel) {
        return state.selectedFilters;
    }

    @Selector()
    static getView(state: ProjectsStateModel) {
        return state.view;
    }

    @Selector()
    static getSort(state: ProjectsStateModel) {
        return state.sortDir;
    }

    @Selector()
    static getGroupBy(state: ProjectsStateModel) {
        return state.groupBy;
    }

    @Selector()
    static getProjectsWithEvents(state: ProjectsStateModel) {
        return state.projectsWithEvents;
    }

    @Selector()
    static getProjectStatuses(state: ProjectsStateModel) {
        return state.projectStatuses;
    }

    @Selector()
    static getProjectEmployees(state: ProjectsStateModel) {
        return state.projectEmployees;
    }

    @Selector()
    static getProjectEmployeesPagination(state: ProjectsStateModel) {
        return state.projectEmployeesPagination;
    }

    @Selector()
    static getProjectImageFiles(state: ProjectsStateModel) {
        return state.imageFiles;
    }

    @Selector()
    static getAppListProjectsActiveTab(state: ProjectsStateModel) {
        return state.appProjectListActiveTab;
    }

    @Selector()
    static getActiveStatusIds(state: ProjectsStateModel) {
        return state.activeStatusIds;
    }

    @Selector()
    static getKeepFilters(state: ProjectsStateModel) {
        return state.keepFilters;
    }

    @Selector()
    static getActiveProjectsTypeFilter(state: ProjectsStateModel) {
        return state.activeProjectsTypeFilter;
    }

    @Selector()
    static getProjectsListScrollTop(state: ProjectsStateModel) {
        return state.projectsListScrollTop;
    }

    @Selector()
    static getLastPageProjectView(state: ProjectsStateModel) {
        return state.lastPageProjectView;
    }

    @Selector()
    static getCreateProjectData(state: ProjectsStateModel) {
        return state.createProjectData;
    }

    @Selector()
    static getIsSearchBarOpen(state: ProjectsStateModel) {
        return state.isSearchBarHidden;
    }

    @Selector()
    static getNotebookPhotos(state: ProjectsStateModel) {
        return state.notebookPhotos;
    }

    @Selector()
    static getNotebookProducts(state: ProjectsStateModel) {
        return state.notebookProducts;
    }

    @Action(LoadProjects)
    load(ctx: StateContext<ProjectsStateModel>, { payload }: LoadProjects) {
        const state = ctx.getState();

        ctx.patchState({
            projects: [...state.projects, ...payload],
        });
    }

    @Action(SaveCurrentProject)
    save(ctx: StateContext<ProjectsStateModel>, { payload }: SaveCurrentProject) {
        ctx.setState({ ...ctx.getState(), selectedProject: payload });
    }

    @Action(SearchProjects)
    search(ctx: StateContext<ProjectsStateModel>, { payloadSearchQuery }: SearchProjects) {
        ctx.setState({ ...ctx.getState(), searchQuery: payloadSearchQuery });
    }

    @Action(SavePagination)
    pagination(ctx: StateContext<ProjectsStateModel>, { payload }: SavePagination) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }

    @Action(ClearProjects)
    clear(ctx: StateContext<ProjectsStateModel>) {
        ctx.setState({ ...ctx.getState(), projects: [] });
    }

    @Action(SaveCreatedProjectId)
    saveCreatedProjectId(ctx: StateContext<ProjectsStateModel>, { payload }: SaveCreatedProjectId) {
        ctx.setState({ ...ctx.getState(), createdProjectId: payload });
    }

    @Action(SetSelectedEvent)
    setEventId(ctx: StateContext<ProjectsStateModel>, { payload }: SetSelectedEvent) {
        ctx.setState({ ...ctx.getState(), selectedEvent: payload });
    }

    @Action(ClearSelectedEvent)
    ClearSelectedEvent(ctx: StateContext<ProjectsStateModel>) {
        ctx.setState({ ...ctx.getState(), selectedEvent: null });
    }

    @Action(SetSelectedCard)
    setCardId(ctx: StateContext<ProjectsStateModel>, { payload }: SetSelectedCard) {
        ctx.setState({ ...ctx.getState(), selectedCard: payload });
    }

    @Action(ClearSelectedCard)
    ClearSelectedCard(ctx: StateContext<ProjectsStateModel>) {
        ctx.setState({ ...ctx.getState(), selectedCard: null });
    }

    @Action(SetSelectedFilters)
    setSelectedFilter(ctx: StateContext<ProjectsStateModel>, { payload }: SetSelectedFilters) {
        ctx.setState({ ...ctx.getState(), selectedFilters: payload });
    }

    @Action(SetProjectView)
    setView(ctx: StateContext<ProjectsStateModel>, { payload }: SetProjectView) {
        ctx.setState({ ...ctx.getState(), view: payload });
    }

    @Action(SetProjectSortBy)
    setSort(ctx: StateContext<ProjectsStateModel>, { payload }: SetProjectSortBy) {
        ctx.setState({ ...ctx.getState(), sortDir: payload });
    }

    @Action(SetProjectGroupBy)
    setGroupBy(ctx: StateContext<ProjectsStateModel>, { payload }: SetProjectGroupBy) {
        ctx.setState({ ...ctx.getState(), groupBy: payload });
    }

    @Action(SetProjectsWithEvents)
    setProjectsEvents(ctx: StateContext<ProjectsStateModel>, { payload }: SetProjectsWithEvents) {
        ctx.setState({ ...ctx.getState(), projectsWithEvents: payload });
    }

    @Action(SetActiveAppListProjectsTabAction)
    setAppListProjectsActiveTab(
        ctx: StateContext<ProjectsStateModel>,
        { payload }: SetActiveAppListProjectsTabAction
    ) {
        ctx.setState({ ...ctx.getState(), appProjectListActiveTab: payload });
    }

    @Action(SetActiveStatusIdsAction)
    setActiveStatusIds(
        ctx: StateContext<ProjectsStateModel>,
        { payload }: SetActiveStatusIdsAction
    ) {
        ctx.setState({ ...ctx.getState(), activeStatusIds: payload });
    }

    @Action(SetProjectStatuses)
    setProjectStatuses(ctx: StateContext<ProjectsStateModel>, { payload }: SetProjectStatuses) {
        ctx.setState({ ...ctx.getState(), projectStatuses: payload });
    }

    @Action(SetProjectEmployees)
    setProjectEmployees(ctx: StateContext<ProjectsStateModel>, { payload }: SetProjectEmployees) {
        ctx.setState({ ...ctx.getState(), projectEmployees: payload });
    }

    @Action(SetProjectEmployeesPagination)
    SetProjectEmployeesPagination(
        ctx: StateContext<ProjectsStateModel>,
        { payload }: SetProjectEmployeesPagination
    ) {
        ctx.setState({ ...ctx.getState(), projectEmployeesPagination: payload });
    }

    @Action(ClearProjectEmployees)
    ClearProjectEmployees(ctx: StateContext<ProjectsStateModel>) {
        ctx.setState({ ...ctx.getState(), projectEmployees: [] });
    }

    @Action(SetProjectCustomFieldAttributes)
    setProjectCustomFieldsAttributs(
        ctx: StateContext<ProjectsStateModel>,
        { payload }: SetProjectCustomFieldAttributes
    ) {
        ctx.setState({ ...ctx.getState(), projectCustomFieldsAttributes: payload });
    }

    @Action(SetProjectImageFiles)
    setProjectImageFiles(ctx: StateContext<ProjectsStateModel>, { payload }: SetProjectImageFiles) {
        ctx.setState({ ...ctx.getState(), imageFiles: payload });
    }

    @Action(SetKeepFilters)
    SetKeepFilters(ctx: StateContext<ProjectsStateModel>, { payload }: SetKeepFilters) {
        ctx.setState({ ...ctx.getState(), keepFilters: payload });
    }

    @Action(SetActiveProjectsTypeFilter)
    SetActiveProjectsTypeFilter(
        ctx: StateContext<ProjectsStateModel>,
        { payload }: SetActiveProjectsTypeFilter
    ) {
        ctx.setState({ ...ctx.getState(), activeProjectsTypeFilter: payload });
    }

    @Action(SetProjectsListScrollTop)
    SetProjectsListScrollTop(
        ctx: StateContext<ProjectsStateModel>,
        { payload }: SetProjectsListScrollTop
    ) {
        ctx.setState({ ...ctx.getState(), projectsListScrollTop: payload });
    }

    @Action(SetLastPageProjectView)
    SetLastPageProjectView(
        ctx: StateContext<ProjectsStateModel>,
        { payload }: SetLastPageProjectView
    ) {
        ctx.setState({ ...ctx.getState(), lastPageProjectView: payload });
    }

    @Action(SetCreateProjectData)
    setCreateProjectData(ctx: StateContext<ProjectsStateModel>, { payload }: SetCreateProjectData) {
        ctx.setState({ ...ctx.getState(), createProjectData: payload });
    }

    @Action(SetSearchBarStatus)
    setSearchBar(ctx: StateContext<ProjectsStateModel>, { payload }: SetSearchBarStatus) {
        ctx.setState({ ...ctx.getState(), isSearchBarHidden: payload });
    }

    @Action(SetNotebookPhotos)
    setNotebookPhotos(ctx: StateContext<ProjectsStateModel>, { payload }: SetNotebookPhotos) {
        ctx.setState({ ...ctx.getState(), notebookPhotos: payload });
    }

    @Action(SetNotebookProducts)
    setNotebookProducts(ctx: StateContext<ProjectsStateModel>, { payload }: SetNotebookProducts) {
        ctx.setState({ ...ctx.getState(), notebookProducts: payload });
    }
}
