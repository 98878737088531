import { ProjectEvent } from "src/app/services/response-models/events.response.model";

export class SetSelectedEvent {
    public static readonly type = "[Projects] Set selected event";

    constructor(public payload: ProjectEvent) {}
}

export class ClearSelectedEvent {
    public static readonly type = "[Projects] Clear selected event";

    constructor() {}
}
