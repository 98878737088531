/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IGetWorkRelated200ResponseAllOfData } from './IGetWorkRelated200ResponseAllOfData';
import {
    IGetWorkRelated200ResponseAllOfDataFromJSON,
    IGetWorkRelated200ResponseAllOfDataFromJSONTyped,
    IGetWorkRelated200ResponseAllOfDataToJSON,
} from './IGetWorkRelated200ResponseAllOfData';
import type { PaginationDetails } from './PaginationDetails';
import {
    PaginationDetailsFromJSON,
    PaginationDetailsFromJSONTyped,
    PaginationDetailsToJSON,
} from './PaginationDetails';

/**
 * 
 * @export
 * @interface IGetWorkRelated200Response
 */
export interface IGetWorkRelated200Response {
    /**
     * 
     * @type {boolean}
     * @memberof IGetWorkRelated200Response
     */
    success: boolean;
    /**
     * 
     * @type {PaginationDetails}
     * @memberof IGetWorkRelated200Response
     */
    pagination: PaginationDetails;
    /**
     * 
     * @type {IGetWorkRelated200ResponseAllOfData}
     * @memberof IGetWorkRelated200Response
     */
    data: IGetWorkRelated200ResponseAllOfData;
}

/**
 * Check if a given object implements the IGetWorkRelated200Response interface.
 */
export function instanceOfIGetWorkRelated200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "pagination" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function IGetWorkRelated200ResponseFromJSON(json: any): IGetWorkRelated200Response {
    return IGetWorkRelated200ResponseFromJSONTyped(json, false);
}

export function IGetWorkRelated200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetWorkRelated200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'pagination': PaginationDetailsFromJSON(json['pagination']),
        'data': IGetWorkRelated200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function IGetWorkRelated200ResponseToJSON(value?: IGetWorkRelated200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'pagination': PaginationDetailsToJSON(value.pagination),
        'data': IGetWorkRelated200ResponseAllOfDataToJSON(value.data),
    };
}

