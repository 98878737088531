export enum IncludeType {
    Contacts = "contacts",
    ContactPersons = "contact_persons",
    ContactCustomFieldAttributes = "contact_custom_field_attributes",
    ContactCustomFieldValues = "contact_custom_field_values",
    Countries = "countries",
    Currencies = "currencies",
    Cities = "cities",
    Drivings = "drivings",
    DrivingTypes = "driving_types",
    Expenses = "expenses",
    ExpenseLines = "expense_lines",
    ExpenseFiles = "expense_files",
    EanProducts = "ean_products",
    Events = "events",
    EventTasks = "event_tasks",
    FeatureGroups = "feature_groups",
    Forms = "forms",
    FormTemplates = "form_templates",
    FormTemplateFields = "form_template_fields",
    FormFields = "form_fields",
    FormFieldTypes = "form_field_types",
    Files = "files",
    Integrations = "integrations",
    IntegrationSettings = "integration_settings",
    IntegrationTypes = "integration_types",
    Languages = "languages",
    MaterialRentals = "material_rentals",
    Materials = "materials",
    Offers = "offers",
    Projects = "projects",
    ProjectCustomFieldAttributes = "project_custom_field_attributes",
    ProjectCustomFieldValues = "project_custom_field_values",
    ProjectStatuses = "project_statuses",
    ProjectStatusTypes = "project_status_types",
    Products = "products",
    PaymentTerms = "payment_terms",
    PaymentTermTypes = "payment_term_types",
    Roles = "roles",
    Senders = "senders",
    Subscriptions = "subscriptions",
    TimeEntries = "time_entries",
    TimeEntryValueTypes = "time_entry_value_types",
    TimeEntryRuleGroups = "time_entry_rule_groups",
    TimeEntryTypes = "time_entry_types",
    Users = "users",
    Vendors = "vendors",
    VendorDepartments = "vendor_departments",
    VendorProducts = "vendor_products",
    WallComments = "wall_comments",
    UserCustomFieldValues = "user_custom_field_values",
    CreatedBy = "created_by",
    FirebaseCloudMessagingRegistrationTokens = "firebase_cloud_messaging_registration_tokens",
}
