/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { City } from './City';
import {
    CityFromJSON,
    CityFromJSONTyped,
    CityToJSON,
} from './City';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { ContactPerson } from './ContactPerson';
import {
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
} from './ContactPerson';
import type { ControlPanelApiOfferStatusResponse } from './ControlPanelApiOfferStatusResponse';
import {
    ControlPanelApiOfferStatusResponseFromJSON,
    ControlPanelApiOfferStatusResponseFromJSONTyped,
    ControlPanelApiOfferStatusResponseToJSON,
} from './ControlPanelApiOfferStatusResponse';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';

/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    readonly createdById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    readonly modifiedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    offerStatusId: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    cityId?: string | null;
    /**
     * 
     * @type {City}
     * @memberof Offer
     */
    city?: City;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    contactId?: string | null;
    /**
     * 
     * @type {Contact}
     * @memberof Offer
     */
    readonly contact?: Contact | null;
    /**
     * 
     * @type {ContactPerson}
     * @memberof Offer
     */
    readonly contactPerson?: ContactPerson | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    contactPersonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    companyId: string;
    /**
     * 
     * @type {Company}
     * @memberof Offer
     */
    readonly company?: Company | null;
    /**
     * 
     * @type {ControlPanelApiOfferStatusResponse}
     * @memberof Offer
     */
    readonly offerStatus?: ControlPanelApiOfferStatusResponse | null;
    /**
     * 
     * @type {Project}
     * @memberof Offer
     */
    readonly project?: Project | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly offerNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    address?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Offer
     */
    issuedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Offer
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    acceptedBy?: OfferAcceptedByEnum;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly vatPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    discountPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    paymentTermId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    readonly erpPaymentTermId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    offerLinesRule?: OfferOfferLinesRuleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    allProductsOneLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    allLinesOneLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    allWorkingHoursOneLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    showOfferLines?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    distributionHoursOverProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    totalPriceLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    isQuote?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    isV3?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    readonly sentToCustomer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    readonly pdfUrl?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Offer
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Offer
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Offer
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    readonly publicClientUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    readonly pdfLink?: string;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly grossPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly netPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    readonly token?: string;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly subtotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly totalDiscountPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly totalDiscountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly workingHoursTotalSellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly workingHoursTotalCostPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly productsTotalSellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    readonly productsTotalCostPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    readonly workingHoursAmount?: string | null;
}


/**
 * @export
 */
export const OfferAcceptedByEnum = {
    Company: 'company',
    Contact: 'contact'
} as const;
export type OfferAcceptedByEnum = typeof OfferAcceptedByEnum[keyof typeof OfferAcceptedByEnum];

/**
 * @export
 */
export const OfferOfferLinesRuleEnum = {
    GatherOfferLines: 'gather_offer_lines',
    DistributionHoursOverProducts: 'distribution_hours_over_products',
    ShowOnlyProductBundles: 'show_only_product_bundles',
    GroupByProductAndHours: 'group_by_product_and_hours',
    TotalPriceLine: 'total_price_line'
} as const;
export type OfferOfferLinesRuleEnum = typeof OfferOfferLinesRuleEnum[keyof typeof OfferOfferLinesRuleEnum];


/**
 * Check if a given object implements the Offer interface.
 */
export function instanceOfOffer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "offerStatusId" in value;
    isInstance = isInstance && "companyId" in value;

    return isInstance;
}

export function OfferFromJSON(json: any): Offer {
    return OfferFromJSONTyped(json, false);
}

export function OfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): Offer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'offerStatusId': json['offer_status_id'],
        'cityId': !exists(json, 'city_id') ? undefined : json['city_id'],
        'city': !exists(json, 'city') ? undefined : CityFromJSON(json['city']),
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'contact': !exists(json, 'contact') ? undefined : ContactFromJSON(json['contact']),
        'contactPerson': !exists(json, 'contact_person') ? undefined : ContactPersonFromJSON(json['contact_person']),
        'contactPersonId': !exists(json, 'contact_person_id') ? undefined : json['contact_person_id'],
        'companyId': json['company_id'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'offerStatus': !exists(json, 'offer_status') ? undefined : ControlPanelApiOfferStatusResponseFromJSON(json['offer_status']),
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'offerNumber': !exists(json, 'offer_number') ? undefined : json['offer_number'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'issuedDate': !exists(json, 'issued_date') ? undefined : (json['issued_date'] === null ? null : new Date(json['issued_date'])),
        'expirationDate': !exists(json, 'expiration_date') ? undefined : (json['expiration_date'] === null ? null : new Date(json['expiration_date'])),
        'acceptedBy': !exists(json, 'accepted_by') ? undefined : json['accepted_by'],
        'vatPercent': !exists(json, 'vat_percent') ? undefined : json['vat_percent'],
        'discountPercent': !exists(json, 'discount_percent') ? undefined : json['discount_percent'],
        'paymentTermId': !exists(json, 'payment_term_id') ? undefined : json['payment_term_id'],
        'erpPaymentTermId': !exists(json, 'erp_payment_term_id') ? undefined : json['erp_payment_term_id'],
        'offerLinesRule': !exists(json, 'offer_lines_rule') ? undefined : json['offer_lines_rule'],
        'allProductsOneLine': !exists(json, 'all_products_one_line') ? undefined : json['all_products_one_line'],
        'allLinesOneLine': !exists(json, 'all_lines_one_line') ? undefined : json['all_lines_one_line'],
        'allWorkingHoursOneLine': !exists(json, 'all_working_hours_one_line') ? undefined : json['all_working_hours_one_line'],
        'showOfferLines': !exists(json, 'show_offer_lines') ? undefined : json['show_offer_lines'],
        'distributionHoursOverProducts': !exists(json, 'distribution_hours_over_products') ? undefined : json['distribution_hours_over_products'],
        'totalPriceLine': !exists(json, 'total_price_line') ? undefined : json['total_price_line'],
        'isQuote': !exists(json, 'is_quote') ? undefined : json['is_quote'],
        'isV3': !exists(json, 'is_v3') ? undefined : json['is_v3'],
        'sentToCustomer': !exists(json, 'sent_to_customer') ? undefined : json['sent_to_customer'],
        'pdfUrl': !exists(json, 'pdf_url') ? undefined : json['pdf_url'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'publicClientUrl': !exists(json, 'public_client_url') ? undefined : json['public_client_url'],
        'pdfLink': !exists(json, 'pdf_link') ? undefined : json['pdf_link'],
        'grossPayment': !exists(json, 'gross_payment') ? undefined : json['gross_payment'],
        'netPayment': !exists(json, 'net_payment') ? undefined : json['net_payment'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'subtotal': !exists(json, 'subtotal') ? undefined : json['subtotal'],
        'totalDiscountPercent': !exists(json, 'total_discount_percent') ? undefined : json['total_discount_percent'],
        'totalDiscountAmount': !exists(json, 'total_discount_amount') ? undefined : json['total_discount_amount'],
        'workingHoursTotalSellingPrice': !exists(json, 'working_hours_total_selling_price') ? undefined : json['working_hours_total_selling_price'],
        'workingHoursTotalCostPrice': !exists(json, 'working_hours_total_cost_price') ? undefined : json['working_hours_total_cost_price'],
        'productsTotalSellingPrice': !exists(json, 'products_total_selling_price') ? undefined : json['products_total_selling_price'],
        'productsTotalCostPrice': !exists(json, 'products_total_cost_price') ? undefined : json['products_total_cost_price'],
        'workingHoursAmount': !exists(json, 'working_hours_amount') ? undefined : json['working_hours_amount'],
    };
}

export function OfferToJSON(value?: Offer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offer_status_id': value.offerStatusId,
        'city_id': value.cityId,
        'city': CityToJSON(value.city),
        'contact_id': value.contactId,
        'contact_person_id': value.contactPersonId,
        'company_id': value.companyId,
        'title': value.title,
        'description': value.description,
        'address': value.address,
        'issued_date': value.issuedDate === undefined ? undefined : (value.issuedDate === null ? null : value.issuedDate.toISOString().substr(0,10)),
        'expiration_date': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString()),
        'accepted_by': value.acceptedBy,
        'discount_percent': value.discountPercent,
        'payment_term_id': value.paymentTermId,
        'offer_lines_rule': value.offerLinesRule,
        'all_products_one_line': value.allProductsOneLine,
        'all_lines_one_line': value.allLinesOneLine,
        'all_working_hours_one_line': value.allWorkingHoursOneLine,
        'show_offer_lines': value.showOfferLines,
        'distribution_hours_over_products': value.distributionHoursOverProducts,
        'total_price_line': value.totalPriceLine,
        'is_quote': value.isQuote,
        'is_v3': value.isV3,
    };
}

