/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { ControlPanelApiProjectViewOrIndexResponse } from './ControlPanelApiProjectViewOrIndexResponse';
import {
    ControlPanelApiProjectViewOrIndexResponseFromJSON,
    ControlPanelApiProjectViewOrIndexResponseFromJSONTyped,
    ControlPanelApiProjectViewOrIndexResponseToJSON,
} from './ControlPanelApiProjectViewOrIndexResponse';
import type { InvoiceIntegration } from './InvoiceIntegration';
import {
    InvoiceIntegrationFromJSON,
    InvoiceIntegrationFromJSONTyped,
    InvoiceIntegrationToJSON,
} from './InvoiceIntegration';
import type { InvoiceLine } from './InvoiceLine';
import {
    InvoiceLineFromJSON,
    InvoiceLineFromJSONTyped,
    InvoiceLineToJSON,
} from './InvoiceLine';
import type { PaymentTerm } from './PaymentTerm';
import {
    PaymentTermFromJSON,
    PaymentTermFromJSONTyped,
    PaymentTermToJSON,
} from './PaymentTerm';

/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    senderId?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    contactId?: string;
    /**
     * 
     * @type {Contact}
     * @memberof Invoice
     */
    contact?: Contact;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    currencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    projectId?: string;
    /**
     * 
     * @type {ControlPanelApiProjectViewOrIndexResponse}
     * @memberof Invoice
     */
    project: ControlPanelApiProjectViewOrIndexResponse;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    paymentTermId?: string;
    /**
     * 
     * @type {PaymentTerm}
     * @memberof Invoice
     */
    paymentTerm?: PaymentTerm;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    integrationId?: string | null;
    /**
     * 
     * @type {InvoiceIntegration}
     * @memberof Invoice
     */
    integration?: InvoiceIntegration;
    /**
     * 
     * @type {Array<InvoiceLine>}
     * @memberof Invoice
     */
    invoiceLines?: Array<InvoiceLine>;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    erpId?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    erpPaymentTermId?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    offerNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    invoiceNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    reference?: string;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    issuedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    paymentDueDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    paidDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    discountPercent?: number;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    dateTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isDraft?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isOffer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isLocked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isPaid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isFinalInvoice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isManuallyInvoiced?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    includingVat?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    projectOverviewAttached?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    euCustomer?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    downloaded?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    includeInvoicedForms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    combineProductLines?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    combineWorkingTimeLines?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    showPaymentTerm?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    showPrices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    showProductImages?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    showProductsProductBundle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    showEmployeeName?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    vatPercent?: number;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Invoice
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    readonly pdfUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    readonly hasProjectPdfAttached?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    readonly grossPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    readonly netPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    readonly totalDiscountPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    readonly totalCostPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    readonly integrationImageUrl?: string | null;
}

/**
 * Check if a given object implements the Invoice interface.
 */
export function instanceOfInvoice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "created" in value;

    return isInstance;
}

export function InvoiceFromJSON(json: any): Invoice {
    return InvoiceFromJSONTyped(json, false);
}

export function InvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['company_id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'senderId': !exists(json, 'sender_id') ? undefined : json['sender_id'],
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'contact': !exists(json, 'contact') ? undefined : ContactFromJSON(json['contact']),
        'currencyId': !exists(json, 'currency_id') ? undefined : json['currency_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'project': ControlPanelApiProjectViewOrIndexResponseFromJSON(json['project']),
        'paymentTermId': !exists(json, 'payment_term_id') ? undefined : json['payment_term_id'],
        'paymentTerm': !exists(json, 'payment_term') ? undefined : PaymentTermFromJSON(json['payment_term']),
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'integration': !exists(json, 'integration') ? undefined : InvoiceIntegrationFromJSON(json['integration']),
        'invoiceLines': !exists(json, 'invoice_lines') ? undefined : ((json['invoice_lines'] as Array<any>).map(InvoiceLineFromJSON)),
        'erpId': !exists(json, 'erp_id') ? undefined : json['erp_id'],
        'erpPaymentTermId': !exists(json, 'erp_payment_term_id') ? undefined : json['erp_payment_term_id'],
        'offerNumber': !exists(json, 'offer_number') ? undefined : json['offer_number'],
        'invoiceNumber': !exists(json, 'invoice_number') ? undefined : json['invoice_number'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'issuedDate': !exists(json, 'issued_date') ? undefined : (new Date(json['issued_date'])),
        'paymentDueDate': !exists(json, 'payment_due_date') ? undefined : (new Date(json['payment_due_date'])),
        'paidDate': !exists(json, 'paid_date') ? undefined : (new Date(json['paid_date'])),
        'discountPercent': !exists(json, 'discount_percent') ? undefined : json['discount_percent'],
        'dateFrom': !exists(json, 'date_from') ? undefined : (new Date(json['date_from'])),
        'dateTo': !exists(json, 'date_to') ? undefined : (new Date(json['date_to'])),
        'isDraft': !exists(json, 'is_draft') ? undefined : json['is_draft'],
        'isOffer': !exists(json, 'is_offer') ? undefined : json['is_offer'],
        'isLocked': !exists(json, 'is_locked') ? undefined : json['is_locked'],
        'isPaid': !exists(json, 'is_paid') ? undefined : json['is_paid'],
        'isFinalInvoice': !exists(json, 'is_final_invoice') ? undefined : json['is_final_invoice'],
        'isManuallyInvoiced': !exists(json, 'is_manually_invoiced') ? undefined : json['is_manually_invoiced'],
        'includingVat': !exists(json, 'including_vat') ? undefined : json['including_vat'],
        'projectOverviewAttached': !exists(json, 'project_overview_attached') ? undefined : json['project_overview_attached'],
        'euCustomer': !exists(json, 'eu_customer') ? undefined : json['eu_customer'],
        'downloaded': !exists(json, 'downloaded') ? undefined : (new Date(json['downloaded'])),
        'includeInvoicedForms': !exists(json, 'include_invoiced_forms') ? undefined : json['include_invoiced_forms'],
        'combineProductLines': !exists(json, 'combine_product_lines') ? undefined : json['combine_product_lines'],
        'combineWorkingTimeLines': !exists(json, 'combine_working_time_lines') ? undefined : json['combine_working_time_lines'],
        'showPaymentTerm': !exists(json, 'show_payment_term') ? undefined : json['show_payment_term'],
        'showPrices': !exists(json, 'show_prices') ? undefined : json['show_prices'],
        'showProductImages': !exists(json, 'show_product_images') ? undefined : json['show_product_images'],
        'showProductsProductBundle': !exists(json, 'show_products_product_bundle') ? undefined : json['show_products_product_bundle'],
        'showEmployeeName': !exists(json, 'show_employee_name') ? undefined : json['show_employee_name'],
        'vatPercent': !exists(json, 'vat_percent') ? undefined : json['vat_percent'],
        'created': (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'pdfUrl': !exists(json, 'pdf_url') ? undefined : json['pdf_url'],
        'hasProjectPdfAttached': !exists(json, 'has_project_pdf_attached') ? undefined : json['has_project_pdf_attached'],
        'grossPayment': !exists(json, 'gross_payment') ? undefined : json['gross_payment'],
        'netPayment': !exists(json, 'net_payment') ? undefined : json['net_payment'],
        'totalDiscountPercent': !exists(json, 'total_discount_percent') ? undefined : json['total_discount_percent'],
        'totalCostPrice': !exists(json, 'total_cost_price') ? undefined : json['total_cost_price'],
        'integrationImageUrl': !exists(json, 'integration_image_url') ? undefined : json['integration_image_url'],
    };
}

export function InvoiceToJSON(value?: Invoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'company_id': value.companyId,
        'created_by_id': value.createdById,
        'sender_id': value.senderId,
        'contact_id': value.contactId,
        'contact': ContactToJSON(value.contact),
        'currency_id': value.currencyId,
        'project_id': value.projectId,
        'project': ControlPanelApiProjectViewOrIndexResponseToJSON(value.project),
        'payment_term_id': value.paymentTermId,
        'payment_term': PaymentTermToJSON(value.paymentTerm),
        'integration_id': value.integrationId,
        'integration': InvoiceIntegrationToJSON(value.integration),
        'invoice_lines': value.invoiceLines === undefined ? undefined : ((value.invoiceLines as Array<any>).map(InvoiceLineToJSON)),
        'erp_id': value.erpId,
        'erp_payment_term_id': value.erpPaymentTermId,
        'offer_number': value.offerNumber,
        'invoice_number': value.invoiceNumber,
        'title': value.title,
        'message': value.message,
        'reference': value.reference,
        'issued_date': value.issuedDate === undefined ? undefined : (value.issuedDate.toISOString().substr(0,10)),
        'payment_due_date': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate.toISOString().substr(0,10)),
        'paid_date': value.paidDate === undefined ? undefined : (value.paidDate.toISOString()),
        'discount_percent': value.discountPercent,
        'date_from': value.dateFrom === undefined ? undefined : (value.dateFrom.toISOString()),
        'date_to': value.dateTo === undefined ? undefined : (value.dateTo.toISOString()),
        'is_draft': value.isDraft,
        'is_offer': value.isOffer,
        'is_locked': value.isLocked,
        'is_paid': value.isPaid,
        'is_final_invoice': value.isFinalInvoice,
        'is_manually_invoiced': value.isManuallyInvoiced,
        'including_vat': value.includingVat,
        'project_overview_attached': value.projectOverviewAttached,
        'eu_customer': value.euCustomer,
        'downloaded': value.downloaded === undefined ? undefined : (value.downloaded.toISOString()),
        'include_invoiced_forms': value.includeInvoicedForms,
        'combine_product_lines': value.combineProductLines,
        'combine_working_time_lines': value.combineWorkingTimeLines,
        'show_payment_term': value.showPaymentTerm,
        'show_prices': value.showPrices,
        'show_product_images': value.showProductImages,
        'show_products_product_bundle': value.showProductsProductBundle,
        'show_employee_name': value.showEmployeeName,
        'vat_percent': value.vatPercent,
    };
}

