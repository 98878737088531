import { ClockingRecord } from "src/app/services/response-models/user.response.model";

export class SetUserCheckIn {
    static readonly type = "[APP] Set user check in status";

    constructor(public payload: boolean) {}
}

export class SetUserClockingRecords {
    static readonly type = "[APP] Set user clocking records";

    constructor(public payload: Array<ClockingRecord>) {}
}
