import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { LoadProjectWallPostsPagination } from "./projects-wall.actions";
import { Pagination } from "src/app/services/response-models/pagination.response.model";

export interface ProjectWallStateModel {
    projectWallPagination: Pagination;
}

@State<ProjectWallStateModel>({
    name: "projectWall",
    defaults: {
        projectWallPagination: null,
    },
})
@Injectable()
export class ProjectWallState {
    @Selector()
    static getWallPagination(state: ProjectWallStateModel) {
        return state.projectWallPagination;
    }

    @Action(LoadProjectWallPostsPagination)
    loadPagination(
        ctx: StateContext<ProjectWallStateModel>,
        { payload }: LoadProjectWallPostsPagination
    ) {
        ctx.setState({ ...ctx.getState(), projectWallPagination: payload });
    }
}
