/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KpiChardHoursIndividualEntry } from './KpiChardHoursIndividualEntry';
import {
    KpiChardHoursIndividualEntryFromJSON,
    KpiChardHoursIndividualEntryFromJSONTyped,
    KpiChardHoursIndividualEntryToJSON,
} from './KpiChardHoursIndividualEntry';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
 */
export interface ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    readonly projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    readonly userId: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    readonly formDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    readonly approved?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    readonly invoiced?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    readonly workDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    readonly totalHours?: number;
    /**
     * 
     * @type {User}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    user?: User;
    /**
     * 
     * @type {Array<KpiChardHoursIndividualEntry>}
     * @memberof ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner
     */
    readonly entries?: Array<KpiChardHoursIndividualEntry>;
}

/**
 * Check if a given object implements the ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner interface.
 */
export function instanceOfProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "formDate" in value;

    return isInstance;
}

export function ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInnerFromJSON(json: any): ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner {
    return ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInnerFromJSONTyped(json, false);
}

export function ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectId': json['project_id'],
        'userId': json['user_id'],
        'formDate': (new Date(json['form_date'])),
        'approved': !exists(json, 'approved') ? undefined : (json['approved'] === null ? null : new Date(json['approved'])),
        'invoiced': !exists(json, 'invoiced') ? undefined : (json['invoiced'] === null ? null : new Date(json['invoiced'])),
        'workDescription': !exists(json, 'work_description') ? undefined : json['work_description'],
        'totalHours': !exists(json, 'total_hours') ? undefined : json['total_hours'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'entries': !exists(json, 'entries') ? undefined : ((json['entries'] as Array<any>).map(KpiChardHoursIndividualEntryFromJSON)),
    };
}

export function ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInnerToJSON(value?: ProjectGetKpiCardHoursAllFormsSubPage200ResponseDataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
    };
}

