import { OrderedProduct } from "../models/ordered-product.model";

export class LoadAllProductsOrdered {
    public static readonly type = "[Products] Load all products ordered";

    constructor(public payload: OrderedProduct) {}
}

export class ClearAllProductsOrdered {
    public static readonly type = "[Products] Clear all products ordered";
}
