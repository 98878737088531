import { InjectionToken } from "@angular/core";
import { Store } from "@ngxs/store";
import { CompaniesService } from "src/app/services/companies.service";
import { CompanySettingsService } from "src/app/services/company-settings.service";
import { ProjectStatusesService } from "src/app/services/project-statuses.service";
import { User } from "src/app/services/response-models/user.response.model";
import { UsersService } from "src/app/services/users.service";
import { TimeEntryService } from "src/app/services/time-entry.service";
import { CurrenciesService } from "src/app/services/currencies.service";
import { CountriesService } from "src/app/services/countries.service";
import { sentryCaptureException } from "src/app/utility/errors";
import { FeaturesService } from "src/app/services/features.service";

export interface StateInitializationInterface {
    init(): Promise<void>;

    canUse(user: User, platform: string): boolean;
}

export const STATE_INITIALIZER = new InjectionToken<StateInitializationInterface>(
    "State_Initializer"
);

export abstract class StateInitializationService {
    constructor(
        protected companiesService: CompaniesService,
        protected companySettingsService: CompanySettingsService,
        protected projectStatusesService: ProjectStatusesService,
        protected userService: UsersService,
        protected store: Store,
        protected timeEntryTypesServices: TimeEntryService,
        protected currenciesService: CurrenciesService,
        protected countriesService: CountriesService,
        protected featuresService: FeaturesService
    ) {}

    public async init(): Promise<void> {
        await Promise.all([
            this.getCompanySettings(),
            this.getProjectStatuses(),
            this.getTimeEntryTypes(),
            this.getCountries(),
            this.getCurrencies(),
            this.getFeatures(),
        ]).catch((reason) => {
            sentryCaptureException(reason);
            throw new Error("Could not initialize app");
        });
    }

    private async getProjectStatuses(): Promise<void> {
        await this.projectStatusesService.getProjectStatusesByFilter();
    }

    private async getCompanySettings(): Promise<void> {
        await this.companiesService.getCompanySettings();
    }

    private async getTimeEntryTypes(): Promise<void> {
        await this.timeEntryTypesServices.getTimeEntryTypes(1, "", "name", "asc", "100");
    }

    private async getCountries(): Promise<void> {
        await this.countriesService.getCountries();
    }

    private async getCurrencies(): Promise<void> {
        await this.currenciesService.getCurrencies();
    }

    private async getFeatures() {
        await this.featuresService.getFeatures();
    }
}
