import { Feature, Pagination } from "src/app/services/response-models/features.response.model";
import { MenuFeature } from "src/app/shared/models/menu-feature";

export class LoadFeatures {
    public static readonly type = "[Features] Load features";

    constructor(public payload: Feature[]) {}
}

export class LoadPagination {
    public static readonly type = "[Features] Load pagination";

    constructor(public payload: Pagination) {}
}

export class ClearFeatures {
    public static readonly type = "[Features] Clear features";

    constructor() {}
}

export class LoadMenuFeatures {
    static readonly type = "[Features] Load menu features";

    constructor(public payload: MenuFeature[]) {}
}
