/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormTemplateModelWithJsonFormsData } from './FormTemplateModelWithJsonFormsData';
import {
    FormTemplateModelWithJsonFormsDataFromJSON,
    FormTemplateModelWithJsonFormsDataFromJSONTyped,
    FormTemplateModelWithJsonFormsDataToJSON,
} from './FormTemplateModelWithJsonFormsData';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Form
 */
export interface Form {
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    readonly createdById: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    readonly modifiedById: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    formTemplateId: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    approvedById?: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    activityId?: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    clockingRecordId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Form
     */
    formDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Form
     */
    isInvoiced?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Form
     */
    isDraft: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Form
     */
    approved?: Date;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    linkToPdf?: string;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    linkToHtml?: string;
    /**
     * 
     * @type {User}
     * @memberof Form
     */
    modifiedBy?: User;
    /**
     * 
     * @type {Project}
     * @memberof Form
     */
    project?: Project;
    /**
     * 
     * @type {User}
     * @memberof Form
     */
    user?: User;
    /**
     * 
     * @type {FormTemplateModelWithJsonFormsData}
     * @memberof Form
     */
    formTemplate?: FormTemplateModelWithJsonFormsData;
    /**
     * 
     * @type {Date}
     * @memberof Form
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Form
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Form
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the Form interface.
 */
export function instanceOfForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdById" in value;
    isInstance = isInstance && "modifiedById" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "formTemplateId" in value;
    isInstance = isInstance && "formDate" in value;
    isInstance = isInstance && "isDraft" in value;

    return isInstance;
}

export function FormFromJSON(json: any): Form {
    return FormFromJSONTyped(json, false);
}

export function FormFromJSONTyped(json: any, ignoreDiscriminator: boolean): Form {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': json['created_by_id'],
        'modifiedById': json['modified_by_id'],
        'userId': json['user_id'],
        'companyId': json['company_id'],
        'projectId': json['project_id'],
        'formTemplateId': json['form_template_id'],
        'approvedById': !exists(json, 'approved_by_id') ? undefined : json['approved_by_id'],
        'activityId': !exists(json, 'activity_id') ? undefined : json['activity_id'],
        'clockingRecordId': !exists(json, 'clocking_record_id') ? undefined : json['clocking_record_id'],
        'formDate': (new Date(json['form_date'])),
        'isInvoiced': !exists(json, 'is_invoiced') ? undefined : (new Date(json['is_invoiced'])),
        'isDraft': json['is_draft'],
        'approved': !exists(json, 'approved') ? undefined : (new Date(json['approved'])),
        'linkToPdf': !exists(json, 'link_to_pdf') ? undefined : json['link_to_pdf'],
        'linkToHtml': !exists(json, 'link_to_html') ? undefined : json['link_to_html'],
        'modifiedBy': !exists(json, 'modified_by') ? undefined : UserFromJSON(json['modified_by']),
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'formTemplate': !exists(json, 'form_template') ? undefined : FormTemplateModelWithJsonFormsDataFromJSON(json['form_template']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function FormToJSON(value?: Form | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'company_id': value.companyId,
        'project_id': value.projectId,
        'form_template_id': value.formTemplateId,
        'approved_by_id': value.approvedById,
        'activity_id': value.activityId,
        'clocking_record_id': value.clockingRecordId,
        'form_date': (value.formDate.toISOString().substr(0,10)),
        'is_invoiced': value.isInvoiced === undefined ? undefined : (value.isInvoiced.toISOString()),
        'is_draft': value.isDraft,
        'approved': value.approved === undefined ? undefined : (value.approved.toISOString()),
        'link_to_pdf': value.linkToPdf,
        'link_to_html': value.linkToHtml,
        'modified_by': UserToJSON(value.modifiedBy),
        'project': ProjectToJSON(value.project),
        'user': UserToJSON(value.user),
        'form_template': FormTemplateModelWithJsonFormsDataToJSON(value.formTemplate),
    };
}

