/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlPanelProductsToTaskProduct } from './ControlPanelProductsToTaskProduct';
import {
    ControlPanelProductsToTaskProductFromJSON,
    ControlPanelProductsToTaskProductFromJSONTyped,
    ControlPanelProductsToTaskProductToJSON,
} from './ControlPanelProductsToTaskProduct';

/**
 * 
 * @export
 * @interface ControlPanelProductsToTask
 */
export interface ControlPanelProductsToTask {
    /**
     * 
     * @type {string}
     * @memberof ControlPanelProductsToTask
     */
    productId?: string;
    /**
     * 
     * @type {number}
     * @memberof ControlPanelProductsToTask
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ControlPanelProductsToTask
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlPanelProductsToTask
     */
    vendorProductId?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlPanelProductsToTask
     */
    expenseLineId?: string;
    /**
     * 
     * @type {ControlPanelProductsToTaskProduct}
     * @memberof ControlPanelProductsToTask
     */
    product?: ControlPanelProductsToTaskProduct;
}

/**
 * Check if a given object implements the ControlPanelProductsToTask interface.
 */
export function instanceOfControlPanelProductsToTask(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function ControlPanelProductsToTaskFromJSON(json: any): ControlPanelProductsToTask {
    return ControlPanelProductsToTaskFromJSONTyped(json, false);
}

export function ControlPanelProductsToTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPanelProductsToTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'amount': json['amount'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'vendorProductId': !exists(json, 'vendor_product_id') ? undefined : json['vendor_product_id'],
        'expenseLineId': !exists(json, 'expense_line-id') ? undefined : json['expense_line-id'],
        'product': !exists(json, 'product') ? undefined : ControlPanelProductsToTaskProductFromJSON(json['product']),
    };
}

export function ControlPanelProductsToTaskToJSON(value?: ControlPanelProductsToTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'amount': value.amount,
        'name': value.name,
        'vendor_product_id': value.vendorProductId,
        'expense_line-id': value.expenseLineId,
        'product': ControlPanelProductsToTaskProductToJSON(value.product),
    };
}

