/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageUrls
 */
export interface ImageUrls {
    /**
     * 
     * @type {string}
     * @memberof ImageUrls
     */
    large?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageUrls
     */
    medium?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageUrls
     */
    small?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageUrls
     */
    thumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageUrls
     */
    original?: string;
}

/**
 * Check if a given object implements the ImageUrls interface.
 */
export function instanceOfImageUrls(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImageUrlsFromJSON(json: any): ImageUrls {
    return ImageUrlsFromJSONTyped(json, false);
}

export function ImageUrlsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageUrls {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'large': !exists(json, 'large') ? undefined : json['large'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'small': !exists(json, 'small') ? undefined : json['small'],
        'thumbnail': !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'original': !exists(json, 'original') ? undefined : json['original'],
    };
}

export function ImageUrlsToJSON(value?: ImageUrls | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'large': value.large,
        'medium': value.medium,
        'small': value.small,
        'thumbnail': value.thumbnail,
        'original': value.original,
    };
}

