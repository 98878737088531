/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMe200ResponseCompanySettings
 */
export interface GetMe200ResponseCompanySettings {
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    activateTimeEntryTypes: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    addEmployeesToProject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowEmployeesToAddNewContacts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowEmployeesToAddNewProducts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowEmployeesToAddNewProjects: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowEmployeesToViewProjectExpenses: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowEmployeesToViewProjectPhotos: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowEmployeesToViewProjectProducts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowEmployeesToViewProjectRegistrations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowEmployeesToViewProjectWorkingHours: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowEmployeesToViewRentalMachines: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    allowIndividualLoginForEmployees: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    approveForms: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    checkInOutEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    customProjectStatuses: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseCompanySettings
     */
    dagseddelDefaultEndHours: number;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseCompanySettings
     */
    dagseddelDefaultEndMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseCompanySettings
     */
    dagseddelDefaultPauseHours: number;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseCompanySettings
     */
    dagseddelDefaultPauseMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseCompanySettings
     */
    dagseddelDefaultStartHours: number;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseCompanySettings
     */
    dagseddelDefaultStartMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseCompanySettings
     */
    dagseddelDefaultTimeIntervalMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseCompanySettings
     */
    daysOfFormEditingWindow: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    deselectUsersProjects: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    distributeProjectExpenseAutomatically: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    emailDeliveryCheck: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    mobileAppAdvancedCreateProjectEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    multipleLocations: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseCompanySettings
     */
    productPricesSetting: GetMe200ResponseCompanySettingsProductPricesSettingEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    projectNumbers: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseCompanySettings
     */
    projectsRottenAfterDays: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    receiveNotificationsMails: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    receivePdfForms: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseCompanySettings
     */
    salesPriceByCostPrice: GetMe200ResponseCompanySettingsSalesPriceByCostPriceEnum;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseCompanySettings
     */
    selectPriority: GetMe200ResponseCompanySettingsSelectPriorityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    showBenchmarkIcons: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    showEmployeeInitials: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    showFormEditOnCheckOut: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseCompanySettings
     */
    showSellingPriceApp: boolean;
}


/**
 * @export
 */
export const GetMe200ResponseCompanySettingsProductPricesSettingEnum = {
    CostBasedPrices: 'Cost based prices',
    ManualPrices: 'Manual prices'
} as const;
export type GetMe200ResponseCompanySettingsProductPricesSettingEnum = typeof GetMe200ResponseCompanySettingsProductPricesSettingEnum[keyof typeof GetMe200ResponseCompanySettingsProductPricesSettingEnum];

/**
 * @export
 */
export const GetMe200ResponseCompanySettingsSalesPriceByCostPriceEnum = {
    HjesteKostpris: 'Højeste kostpris',
    LavesteKostpris: 'Laveste kostpris',
    GennemsnitligeKostpris: 'Gennemsnitlige kostpris'
} as const;
export type GetMe200ResponseCompanySettingsSalesPriceByCostPriceEnum = typeof GetMe200ResponseCompanySettingsSalesPriceByCostPriceEnum[keyof typeof GetMe200ResponseCompanySettingsSalesPriceByCostPriceEnum];

/**
 * @export
 */
export const GetMe200ResponseCompanySettingsSelectPriorityEnum = {
    VejledendeUdsalgspris: 'Vejledende udsalgspris',
    Kostpris: 'Kostpris'
} as const;
export type GetMe200ResponseCompanySettingsSelectPriorityEnum = typeof GetMe200ResponseCompanySettingsSelectPriorityEnum[keyof typeof GetMe200ResponseCompanySettingsSelectPriorityEnum];


/**
 * Check if a given object implements the GetMe200ResponseCompanySettings interface.
 */
export function instanceOfGetMe200ResponseCompanySettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "activateTimeEntryTypes" in value;
    isInstance = isInstance && "addEmployeesToProject" in value;
    isInstance = isInstance && "allowEmployeesToAddNewContacts" in value;
    isInstance = isInstance && "allowEmployeesToAddNewProducts" in value;
    isInstance = isInstance && "allowEmployeesToAddNewProjects" in value;
    isInstance = isInstance && "allowEmployeesToViewProjectExpenses" in value;
    isInstance = isInstance && "allowEmployeesToViewProjectPhotos" in value;
    isInstance = isInstance && "allowEmployeesToViewProjectProducts" in value;
    isInstance = isInstance && "allowEmployeesToViewProjectRegistrations" in value;
    isInstance = isInstance && "allowEmployeesToViewProjectWorkingHours" in value;
    isInstance = isInstance && "allowEmployeesToViewRentalMachines" in value;
    isInstance = isInstance && "allowIndividualLoginForEmployees" in value;
    isInstance = isInstance && "approveForms" in value;
    isInstance = isInstance && "checkInOutEnabled" in value;
    isInstance = isInstance && "customProjectStatuses" in value;
    isInstance = isInstance && "dagseddelDefaultEndHours" in value;
    isInstance = isInstance && "dagseddelDefaultEndMinutes" in value;
    isInstance = isInstance && "dagseddelDefaultPauseHours" in value;
    isInstance = isInstance && "dagseddelDefaultPauseMinutes" in value;
    isInstance = isInstance && "dagseddelDefaultStartHours" in value;
    isInstance = isInstance && "dagseddelDefaultStartMinutes" in value;
    isInstance = isInstance && "dagseddelDefaultTimeIntervalMinutes" in value;
    isInstance = isInstance && "daysOfFormEditingWindow" in value;
    isInstance = isInstance && "deselectUsersProjects" in value;
    isInstance = isInstance && "distributeProjectExpenseAutomatically" in value;
    isInstance = isInstance && "emailDeliveryCheck" in value;
    isInstance = isInstance && "mobileAppAdvancedCreateProjectEnabled" in value;
    isInstance = isInstance && "multipleLocations" in value;
    isInstance = isInstance && "productPricesSetting" in value;
    isInstance = isInstance && "projectNumbers" in value;
    isInstance = isInstance && "projectsRottenAfterDays" in value;
    isInstance = isInstance && "receiveNotificationsMails" in value;
    isInstance = isInstance && "receivePdfForms" in value;
    isInstance = isInstance && "salesPriceByCostPrice" in value;
    isInstance = isInstance && "selectPriority" in value;
    isInstance = isInstance && "showBenchmarkIcons" in value;
    isInstance = isInstance && "showEmployeeInitials" in value;
    isInstance = isInstance && "showFormEditOnCheckOut" in value;
    isInstance = isInstance && "showSellingPriceApp" in value;

    return isInstance;
}

export function GetMe200ResponseCompanySettingsFromJSON(json: any): GetMe200ResponseCompanySettings {
    return GetMe200ResponseCompanySettingsFromJSONTyped(json, false);
}

export function GetMe200ResponseCompanySettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMe200ResponseCompanySettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activateTimeEntryTypes': json['activate_time_entry_types'],
        'addEmployeesToProject': json['add_employees_to_project'],
        'allowEmployeesToAddNewContacts': json['allow_employees_to_add_new_contacts'],
        'allowEmployeesToAddNewProducts': json['allow_employees_to_add_new_products'],
        'allowEmployeesToAddNewProjects': json['allow_employees_to_add_new_projects'],
        'allowEmployeesToViewProjectExpenses': json['allow_employees_to_view_project_expenses'],
        'allowEmployeesToViewProjectPhotos': json['allow_employees_to_view_project_photos'],
        'allowEmployeesToViewProjectProducts': json['allow_employees_to_view_project_products'],
        'allowEmployeesToViewProjectRegistrations': json['allow_employees_to_view_project_registrations'],
        'allowEmployeesToViewProjectWorkingHours': json['allow_employees_to_view_project_working_hours'],
        'allowEmployeesToViewRentalMachines': json['allow_employees_to_view_rental_machines'],
        'allowIndividualLoginForEmployees': json['allow_individual_login_for_employees'],
        'approveForms': json['approve_forms'],
        'checkInOutEnabled': json['check_in_out_enabled'],
        'customProjectStatuses': json['custom_project_statuses'],
        'dagseddelDefaultEndHours': json['dagseddel_default_end_hours'],
        'dagseddelDefaultEndMinutes': json['dagseddel_default_end_minutes'],
        'dagseddelDefaultPauseHours': json['dagseddel_default_pause_hours'],
        'dagseddelDefaultPauseMinutes': json['dagseddel_default_pause_minutes'],
        'dagseddelDefaultStartHours': json['dagseddel_default_start_hours'],
        'dagseddelDefaultStartMinutes': json['dagseddel_default_start_minutes'],
        'dagseddelDefaultTimeIntervalMinutes': json['dagseddel_default_time_interval_minutes'],
        'daysOfFormEditingWindow': json['days_of_form_editing_window'],
        'deselectUsersProjects': json['deselect_users_projects'],
        'distributeProjectExpenseAutomatically': json['distribute_project_expense_automatically'],
        'emailDeliveryCheck': json['email_delivery_check'],
        'mobileAppAdvancedCreateProjectEnabled': json['mobile_app_advanced_create_project_enabled'],
        'multipleLocations': json['multiple_locations'],
        'productPricesSetting': json['product_prices_setting'],
        'projectNumbers': json['project_numbers'],
        'projectsRottenAfterDays': json['projects_rotten_after_days'],
        'receiveNotificationsMails': json['receive_notifications_mails'],
        'receivePdfForms': json['receive_pdf_forms'],
        'salesPriceByCostPrice': json['sales_price_by_cost_price'],
        'selectPriority': json['select_priority'],
        'showBenchmarkIcons': json['show_benchmark_icons'],
        'showEmployeeInitials': json['show_employee_initials'],
        'showFormEditOnCheckOut': json['show_form_edit_on_check_out'],
        'showSellingPriceApp': json['show_selling_price_app'],
    };
}

export function GetMe200ResponseCompanySettingsToJSON(value?: GetMe200ResponseCompanySettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activate_time_entry_types': value.activateTimeEntryTypes,
        'add_employees_to_project': value.addEmployeesToProject,
        'allow_employees_to_add_new_contacts': value.allowEmployeesToAddNewContacts,
        'allow_employees_to_add_new_products': value.allowEmployeesToAddNewProducts,
        'allow_employees_to_add_new_projects': value.allowEmployeesToAddNewProjects,
        'allow_employees_to_view_project_expenses': value.allowEmployeesToViewProjectExpenses,
        'allow_employees_to_view_project_photos': value.allowEmployeesToViewProjectPhotos,
        'allow_employees_to_view_project_products': value.allowEmployeesToViewProjectProducts,
        'allow_employees_to_view_project_registrations': value.allowEmployeesToViewProjectRegistrations,
        'allow_employees_to_view_project_working_hours': value.allowEmployeesToViewProjectWorkingHours,
        'allow_employees_to_view_rental_machines': value.allowEmployeesToViewRentalMachines,
        'allow_individual_login_for_employees': value.allowIndividualLoginForEmployees,
        'approve_forms': value.approveForms,
        'check_in_out_enabled': value.checkInOutEnabled,
        'custom_project_statuses': value.customProjectStatuses,
        'dagseddel_default_end_hours': value.dagseddelDefaultEndHours,
        'dagseddel_default_end_minutes': value.dagseddelDefaultEndMinutes,
        'dagseddel_default_pause_hours': value.dagseddelDefaultPauseHours,
        'dagseddel_default_pause_minutes': value.dagseddelDefaultPauseMinutes,
        'dagseddel_default_start_hours': value.dagseddelDefaultStartHours,
        'dagseddel_default_start_minutes': value.dagseddelDefaultStartMinutes,
        'dagseddel_default_time_interval_minutes': value.dagseddelDefaultTimeIntervalMinutes,
        'days_of_form_editing_window': value.daysOfFormEditingWindow,
        'deselect_users_projects': value.deselectUsersProjects,
        'distribute_project_expense_automatically': value.distributeProjectExpenseAutomatically,
        'email_delivery_check': value.emailDeliveryCheck,
        'mobile_app_advanced_create_project_enabled': value.mobileAppAdvancedCreateProjectEnabled,
        'multiple_locations': value.multipleLocations,
        'product_prices_setting': value.productPricesSetting,
        'project_numbers': value.projectNumbers,
        'projects_rotten_after_days': value.projectsRottenAfterDays,
        'receive_notifications_mails': value.receiveNotificationsMails,
        'receive_pdf_forms': value.receivePdfForms,
        'sales_price_by_cost_price': value.salesPriceByCostPrice,
        'select_priority': value.selectPriority,
        'show_benchmark_icons': value.showBenchmarkIcons,
        'show_employee_initials': value.showEmployeeInitials,
        'show_form_edit_on_check_out': value.showFormEditOnCheckOut,
        'show_selling_price_app': value.showSellingPriceApp,
    };
}

