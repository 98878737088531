import { Platform } from "@ionic/angular";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Store } from "@ngxs/store";
import { Navigate } from "src/store/router/router.actions";
import { SetMenuVisibility } from "src/store/app/actions/set-menu-visibility.action";
import { ClearSecondMenu } from "src/store/second-menu/actions/set-second-menu.action";
import { AppState } from "src/store/app/app.state";
import { SetApiKey } from "src/store/app/actions/set-api-key.action";
import { User } from "src/app/services/response-models/user.response.model";
import { SetUserCompany } from "src/store/app/actions/set-user-company.action";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ApactaApiSdkService } from "src/app/services/apacta-api-sdk.service";
import { Device } from "@capacitor/device";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private readonly userToken = "USERAPIKEY";
    private readonly authUser = "USER";

    private authState = new BehaviorSubject(false);

    constructor(
        private platform: Platform,
        private store: Store,
        private localStorageService: LocalStorageService,
        private apactaSdk: ApactaApiSdkService
    ) {
        this.platform.ready().then(() => {
            this.checkToken();
        });
    }

    public async login(apiKey: string, rememberMe = true): Promise<void> {
        if (rememberMe) {
            this.localStorageService.write(this.userToken, apiKey);
        } else {
            this.store.dispatch(new SetApiKey(apiKey));
        }

        this.authState.next(true);
    }

    public async logOut(): Promise<void> {
        const user = await this.getAuthUser();
        // if there are any tokens
        // get the device id and delete all of them
        if (user.firebase_cloud_messaging_registration_tokens) {
            const deviceId = await Device.getId();
            const mobileSdk = this.apactaSdk.mobileConfiguration();
            // no await here
            // even if something is wrong with deletion
            // the token will be deleted with creation of new one
            // for this device
            mobileSdk.deleteTokenByDeviceId({
                deleteTokenByDeviceIdRequest: { deviceId: deviceId.identifier },
            });
        }

        this.localStorageService.clear();
        this.store.dispatch(new SetApiKey(null));
        this.store.dispatch(new SetUserCompany(null));
        this.store.dispatch(new ClearSecondMenu());
        this.store.dispatch(new SetMenuVisibility(false));
        this.store.dispatch(new Navigate(["login"]));
    }

    public async getToken(): Promise<string> {
        let token = this.localStorageService.read(this.userToken);

        if (token === "" || token === null) {
            token = await this.store.selectSnapshot(AppState.getUserApiKey);
        }

        return token;
    }

    public async isAuthenticated(): Promise<boolean> {
        await this.checkToken();
        return this.authState.value;
    }

    public async getAuthUser(): Promise<User> {
        let user = this.localStorageService.readObject(this.authUser);

        if (user === "" || user === null) {
            user = await this.store.selectSnapshot(AppState.getUser);
        }

        return user;
    }

    private async checkToken(): Promise<void> {
        const apikey = await this.getToken();

        if (apikey) {
            this.authState.next(true);
            return;
        }

        this.authState.next(false);
    }
}
