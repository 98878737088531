import { FormTemplateData } from "src/app/services/response-models/form-templates.response.model";
import { State, Action, Selector, StateContext } from "@ngxs/store";
import { Pagination } from "src/app/services/response-models/projects.response.model";
import {
    LoadFormTemplates,
    SavePagination,
    ClearFormTemplates,
    ClearCardForm,
} from "./actions/set-form-templates.action";
import { LoadSelectedFormTemplate } from "./actions/set-selected-template.action";
import { LoadIsFormValid } from "./actions/set-form-valid.action";
import { LoadCreatedFormId } from "./actions/set-created-form-id.action";
import { LoadStateFormTemplate } from "./actions/set-state-form.action";
import { ClearStateForm } from "./actions/clear-state-form.action";
import { StateForm, Form } from "src/app/services/response-models/form.response.model";

import { SetFormToEdit } from "./actions/set-edit-form.actions";
import { EnableNextPageButton } from "./actions/set-next-page-btn.action";
import { LoadFormError } from "./actions/set-form-error.action";
import { Injectable } from "@angular/core";
import { IsEditForm } from "./actions/set-is-edit.action";
import { LoadCardForm } from "src/store/form-templates/actions/set-card-form.action";
import { MobilePlanningGetCard200ResponseData } from "@apacta/sdk";

export interface FormTemplatesStateModel {
    formTemplates: FormTemplateData[];
    searchQuery: string;
    pagination: Pagination;
    selectedFormTemplate: FormTemplateData;
    isFormValid: boolean;
    enableNextPageBtn: boolean;
    createdFormId: string;
    stateFormTemplate: StateForm;
    editForm: Form;
    error: string;
    allFromTemplates: FormTemplateData[];
    allFromTemplatesPagination: Pagination;
    isEdit: boolean;
    cardForm: MobilePlanningGetCard200ResponseData;
}

@State<FormTemplatesStateModel>({
    name: "formTemplates",
    defaults: {
        formTemplates: [],
        searchQuery: "",
        pagination: null,
        selectedFormTemplate: null,
        enableNextPageBtn: true,
        isFormValid: true,
        createdFormId: "",
        stateFormTemplate: null,
        editForm: null,
        error: "",
        allFromTemplates: [],
        allFromTemplatesPagination: null,
        isEdit: false,
        cardForm: null,
    },
})
@Injectable()
export class FormTemplatesState {
    @Selector()
    static getFormTemplates(state: FormTemplatesStateModel) {
        return state.formTemplates;
    }

    @Selector()
    static getFormTemplateFields(state: FormTemplatesStateModel) {
        return state.selectedFormTemplate.form_template_fields;
    }

    @Selector()
    static getPagination(state: FormTemplatesStateModel) {
        return state.pagination;
    }

    @Selector()
    static getSelectedFormTemplate(state: FormTemplatesStateModel): FormTemplateData {
        return state.selectedFormTemplate;
    }

    @Selector()
    static IsFormValid(state: FormTemplatesStateModel) {
        return state.isFormValid;
    }

    @Selector()
    static getCreatedFormId(state: FormTemplatesStateModel) {
        return state.createdFormId;
    }

    @Selector()
    static getCardForm(state: FormTemplatesStateModel) {
        return state.cardForm;
    }

    @Selector()
    static getStateForm(state: FormTemplatesStateModel) {
        return state.stateFormTemplate;
    }

    @Selector()
    static getFormToEdit(state: FormTemplatesStateModel) {
        return state.editForm;
    }

    @Selector()
    static getNextPageBtn(state: FormTemplatesStateModel) {
        return state.enableNextPageBtn;
    }

    @Selector()
    static getError(state: FormTemplatesStateModel) {
        return state.error;
    }

    @Selector()
    static getAllFormTemplatesPagination(state: FormTemplatesStateModel) {
        return state.allFromTemplatesPagination;
    }

    @Selector()
    static getIsEdit(state: FormTemplatesStateModel) {
        return state.isEdit;
    }

    @Action(LoadFormTemplates)
    loadFormTemplates(ctx: StateContext<FormTemplatesStateModel>, { payload }: LoadFormTemplates) {
        const state = ctx.getState();

        ctx.patchState({
            formTemplates: [...state.formTemplates, ...payload],
        });
    }

    @Action(SavePagination)
    savePagination(ctx: StateContext<FormTemplatesStateModel>, { payload }: SavePagination) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }

    @Action(ClearFormTemplates)
    clear(ctx: StateContext<FormTemplatesStateModel>) {
        ctx.setState({ ...ctx.getState(), formTemplates: [] });
    }

    @Action(ClearCardForm)
    clearCardForm(ctx: StateContext<FormTemplatesStateModel>) {
        ctx.setState({ ...ctx.getState(), cardForm: null });
    }

    @Action(LoadSelectedFormTemplate)
    loadSelectedFormTemplate(
        ctx: StateContext<FormTemplatesStateModel>,
        { payload }: LoadSelectedFormTemplate
    ) {
        ctx.setState({ ...ctx.getState(), selectedFormTemplate: payload });
    }

    @Action(LoadIsFormValid)
    loadIsFromValid(ctx: StateContext<FormTemplatesStateModel>, { payload }: LoadIsFormValid) {
        ctx.setState({ ...ctx.getState(), isFormValid: payload });
    }

    @Action(LoadCreatedFormId)
    loadCreatedFormId(ctx: StateContext<FormTemplatesStateModel>, { payload }: LoadCreatedFormId) {
        ctx.setState({ ...ctx.getState(), createdFormId: payload });
    }

    @Action(LoadCardForm)
    loadCardForm(ctx: StateContext<FormTemplatesStateModel>, { payload }: LoadCardForm) {
        ctx.setState({ ...ctx.getState(), cardForm: payload });
    }

    @Action(LoadStateFormTemplate)
    loadStateFormTemplate(
        ctx: StateContext<FormTemplatesStateModel>,
        { payload }: LoadStateFormTemplate
    ) {
        ctx.setState({ ...ctx.getState(), stateFormTemplate: payload });
    }

    @Action(ClearStateForm)
    clearStateForm(ctx: StateContext<FormTemplatesStateModel>) {
        ctx.setState({ ...ctx.getState(), stateFormTemplate: null });
    }

    @Action(SetFormToEdit)
    setFormToEdit(ctx: StateContext<FormTemplatesStateModel>, { payload }: SetFormToEdit) {
        ctx.setState({ ...ctx.getState(), editForm: payload });
    }

    @Action(EnableNextPageButton)
    setNextPageBtn(ctx: StateContext<FormTemplatesStateModel>, { payload }: EnableNextPageButton) {
        ctx.setState({ ...ctx.getState(), enableNextPageBtn: payload });
    }

    @Action(LoadFormError)
    loadError(ctx: StateContext<FormTemplatesStateModel>, { payload }: LoadFormError) {
        ctx.setState({ ...ctx.getState(), error: payload });
    }

    @Action(IsEditForm)
    isEdit(ctx: StateContext<FormTemplatesStateModel>, { payload }: IsEditForm) {
        ctx.setState({ ...ctx.getState(), isEdit: payload });
    }
}
