import { Component, ViewChild } from "@angular/core";
import { PlatformService } from "src/app/services/state-initialization/platform.service";
import { LoadingComponent } from "src/app/shared/loading/loading.component";
import { ToastService } from "src/app/services/toast.service";
import { ApiKeyService } from "src/app/services/api-key.service";
import { LoginCredentials } from "src/app/services/response-models/apikey.response.model";
import { AuthService } from "src/app/services/auth.service";
import { sentryCaptureException } from "src/app/utility/errors";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
    @ViewChild("loading") loading: LoadingComponent;

    public showForgotPassword: boolean;
    public loginError: string;

    constructor(
        private apiKeyService: ApiKeyService,
        private authService: AuthService,
        private toastService: ToastService,
        private platformService: PlatformService
    ) {}

    public async authenticateUser(credentials: LoginCredentials): Promise<void> {
        this.loading.startLoading();
        this.loginError = "";
        try {
            const authData = await this.apiKeyService.authorize(credentials);
            await this.authService.login(authData.api_key, credentials.rememberMe);

            await this.platformService.initPlatform();

            this.loading.endLoading();
        } catch (err) {
            sentryCaptureException(err);
            this.showForgotPassword = true;
            this.loginError = "LOGIN.WRONG_CREDENTIALS_ERROR";
            this.toastService.showToastMessage("LOGIN.LOGIN_ERROR");
            this.loading.endLoading();
        }
    }
}
