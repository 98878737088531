/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IDeleteCardRequest
 */
export interface IDeleteCardRequest {
    /**
     * Array of Card IDs in their current order
     * @type {Array<string>}
     * @memberof IDeleteCardRequest
     */
    indexes?: Array<string>;
}

/**
 * Check if a given object implements the IDeleteCardRequest interface.
 */
export function instanceOfIDeleteCardRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IDeleteCardRequestFromJSON(json: any): IDeleteCardRequest {
    return IDeleteCardRequestFromJSONTyped(json, false);
}

export function IDeleteCardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDeleteCardRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'indexes': !exists(json, 'indexes') ? undefined : json['indexes'],
    };
}

export function IDeleteCardRequestToJSON(value?: IDeleteCardRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'indexes': value.indexes,
    };
}

