/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMobileAppPreferencesFormsDailyReminderPreferences
 */
export interface UserMobileAppPreferencesFormsDailyReminderPreferences {
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppPreferencesFormsDailyReminderPreferences
     */
    monday?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppPreferencesFormsDailyReminderPreferences
     */
    tuesday?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppPreferencesFormsDailyReminderPreferences
     */
    wednesday?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppPreferencesFormsDailyReminderPreferences
     */
    thursday?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppPreferencesFormsDailyReminderPreferences
     */
    friday?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppPreferencesFormsDailyReminderPreferences
     */
    saturday?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppPreferencesFormsDailyReminderPreferences
     */
    sunday?: string;
}

/**
 * Check if a given object implements the UserMobileAppPreferencesFormsDailyReminderPreferences interface.
 */
export function instanceOfUserMobileAppPreferencesFormsDailyReminderPreferences(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserMobileAppPreferencesFormsDailyReminderPreferencesFromJSON(json: any): UserMobileAppPreferencesFormsDailyReminderPreferences {
    return UserMobileAppPreferencesFormsDailyReminderPreferencesFromJSONTyped(json, false);
}

export function UserMobileAppPreferencesFormsDailyReminderPreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMobileAppPreferencesFormsDailyReminderPreferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monday': !exists(json, 'monday') ? undefined : json['monday'],
        'tuesday': !exists(json, 'tuesday') ? undefined : json['tuesday'],
        'wednesday': !exists(json, 'wednesday') ? undefined : json['wednesday'],
        'thursday': !exists(json, 'thursday') ? undefined : json['thursday'],
        'friday': !exists(json, 'friday') ? undefined : json['friday'],
        'saturday': !exists(json, 'saturday') ? undefined : json['saturday'],
        'sunday': !exists(json, 'sunday') ? undefined : json['sunday'],
    };
}

export function UserMobileAppPreferencesFormsDailyReminderPreferencesToJSON(value?: UserMobileAppPreferencesFormsDailyReminderPreferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monday': value.monday,
        'tuesday': value.tuesday,
        'wednesday': value.wednesday,
        'thursday': value.thursday,
        'friday': value.friday,
        'saturday': value.saturday,
        'sunday': value.sunday,
    };
}

