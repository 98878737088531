/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Vendor } from './Vendor';
import {
    VendorFromJSON,
    VendorFromJSONTyped,
    VendorToJSON,
} from './Vendor';

/**
 * 
 * @export
 * @interface ExpenseReadDTO
 */
export interface ExpenseReadDTO {
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    createdById: string;
    /**
     * 
     * @type {User}
     * @memberof ExpenseReadDTO
     */
    createdBy?: User;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    vendorId?: string;
    /**
     * 
     * @type {Vendor}
     * @memberof ExpenseReadDTO
     */
    vendor?: Vendor;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    projectId: string;
    /**
     * 
     * @type {Project}
     * @memberof ExpenseReadDTO
     */
    project?: Project;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    currencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    activityId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    rogerId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ExpenseReadDTO
     */
    deliveryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExpenseReadDTO
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    shortText?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    supplierInvoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    fileReference?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    status: ExpenseReadDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ExpenseReadDTO
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExpenseReadDTO
     */
    isImported: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpenseReadDTO
     */
    sentToEmail: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ExpenseReadDTO
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExpenseReadDTO
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof ExpenseReadDTO
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ExpenseReadDTO
     */
    totalBuyingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpenseReadDTO
     */
    totalSellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpenseReadDTO
     */
    profitPercentage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExpenseReadDTO
     */
    isLocked?: boolean;
}


/**
 * @export
 */
export const ExpenseReadDTOStatusEnum = {
    NotHandled: 'not_handled',
    ExpiredSubscription: 'expired_subscription',
    Approved: 'approved'
} as const;
export type ExpenseReadDTOStatusEnum = typeof ExpenseReadDTOStatusEnum[keyof typeof ExpenseReadDTOStatusEnum];


/**
 * Check if a given object implements the ExpenseReadDTO interface.
 */
export function instanceOfExpenseReadDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdById" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "isImported" in value;
    isInstance = isInstance && "sentToEmail" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function ExpenseReadDTOFromJSON(json: any): ExpenseReadDTO {
    return ExpenseReadDTOFromJSONTyped(json, false);
}

export function ExpenseReadDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpenseReadDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'createdById': json['created_by_id'],
        'createdBy': !exists(json, 'created_by') ? undefined : UserFromJSON(json['created_by']),
        'vendorId': !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
        'vendor': !exists(json, 'vendor') ? undefined : VendorFromJSON(json['vendor']),
        'projectId': json['project_id'],
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'currencyId': !exists(json, 'currency_id') ? undefined : json['currency_id'],
        'activityId': !exists(json, 'activity_id') ? undefined : json['activity_id'],
        'rogerId': !exists(json, 'roger_id') ? undefined : json['roger_id'],
        'deliveryDate': !exists(json, 'delivery_date') ? undefined : (new Date(json['delivery_date'])),
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'shortText': !exists(json, 'short_text') ? undefined : json['short_text'],
        'supplierInvoiceNumber': !exists(json, 'supplier_invoice_number') ? undefined : json['supplier_invoice_number'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fileReference': !exists(json, 'file_reference') ? undefined : json['file_reference'],
        'status': json['status'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'isImported': json['is_imported'],
        'sentToEmail': json['sent_to_email'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'totalBuyingPrice': !exists(json, 'total_buying_price') ? undefined : json['total_buying_price'],
        'totalSellingPrice': !exists(json, 'total_selling_price') ? undefined : json['total_selling_price'],
        'profitPercentage': !exists(json, 'profit_percentage') ? undefined : json['profit_percentage'],
        'isLocked': !exists(json, 'is_locked') ? undefined : json['is_locked'],
    };
}

export function ExpenseReadDTOToJSON(value?: ExpenseReadDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'company_id': value.companyId,
        'created_by_id': value.createdById,
        'created_by': UserToJSON(value.createdBy),
        'vendor_id': value.vendorId,
        'vendor': VendorToJSON(value.vendor),
        'project_id': value.projectId,
        'project': ProjectToJSON(value.project),
        'currency_id': value.currencyId,
        'activity_id': value.activityId,
        'roger_id': value.rogerId,
        'delivery_date': value.deliveryDate === undefined ? undefined : (value.deliveryDate.toISOString()),
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'short_text': value.shortText,
        'supplier_invoice_number': value.supplierInvoiceNumber,
        'reference': value.reference,
        'description': value.description,
        'file_reference': value.fileReference,
        'status': value.status,
        'comment': value.comment,
        'is_imported': value.isImported,
        'sent_to_email': value.sentToEmail,
        'total_buying_price': value.totalBuyingPrice,
        'total_selling_price': value.totalSellingPrice,
        'profit_percentage': value.profitPercentage,
        'is_locked': value.isLocked,
    };
}

