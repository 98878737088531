/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormReadDTO } from './FormReadDTO';
import {
    FormReadDTOFromJSON,
    FormReadDTOFromJSONTyped,
    FormReadDTOToJSON,
} from './FormReadDTO';

/**
 * 
 * @export
 * @interface FeedItemForm
 */
export interface FeedItemForm {
    /**
     * 
     * @type {string}
     * @memberof FeedItemForm
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FeedItemForm
     */
    type: FeedItemFormTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof FeedItemForm
     */
    date: Date;
    /**
     * 
     * @type {FormReadDTO}
     * @memberof FeedItemForm
     */
    data: FormReadDTO;
}


/**
 * @export
 */
export const FeedItemFormTypeEnum = {
    Invoice: 'invoice',
    WallPost: 'wall_post',
    Expense: 'expense',
    Form: 'form',
    ProjectFile: 'project_file'
} as const;
export type FeedItemFormTypeEnum = typeof FeedItemFormTypeEnum[keyof typeof FeedItemFormTypeEnum];


/**
 * Check if a given object implements the FeedItemForm interface.
 */
export function instanceOfFeedItemForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function FeedItemFormFromJSON(json: any): FeedItemForm {
    return FeedItemFormFromJSONTyped(json, false);
}

export function FeedItemFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedItemForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'date': (new Date(json['date'])),
        'data': FormReadDTOFromJSON(json['data']),
    };
}

export function FeedItemFormToJSON(value?: FeedItemForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'date': (value.date.toISOString().substr(0,10)),
        'data': FormReadDTOToJSON(value.data),
    };
}

