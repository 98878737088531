/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Card } from './Card';
import {
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';
import type { ControlPanelProductsToTask } from './ControlPanelProductsToTask';
import {
    ControlPanelProductsToTaskFromJSON,
    ControlPanelProductsToTaskFromJSONTyped,
    ControlPanelProductsToTaskToJSON,
} from './ControlPanelProductsToTask';
import type { FormTemplateSimpleEntity } from './FormTemplateSimpleEntity';
import {
    FormTemplateSimpleEntityFromJSON,
    FormTemplateSimpleEntityFromJSONTyped,
    FormTemplateSimpleEntityToJSON,
} from './FormTemplateSimpleEntity';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface IGetTask200ResponseData
 */
export interface IGetTask200ResponseData {
    /**
     * 
     * @type {string}
     * @memberof IGetTask200ResponseData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IGetTask200ResponseData
     */
    createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetTask200ResponseData
     */
    modifiedById?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetTask200ResponseData
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof IGetTask200ResponseData
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof IGetTask200ResponseData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IGetTask200ResponseData
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof IGetTask200ResponseData
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IGetTask200ResponseData
     */
    endTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IGetTask200ResponseData
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof IGetTask200ResponseData
     */
    repeat?: IGetTask200ResponseDataRepeatEnum;
    /**
     * 
     * @type {number}
     * @memberof IGetTask200ResponseData
     */
    interval?: number;
    /**
     * 
     * @type {Date}
     * @memberof IGetTask200ResponseData
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IGetTask200ResponseData
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof IGetTask200ResponseData
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof IGetTask200ResponseData
     */
    employees?: Array<User>;
    /**
     * 
     * @type {Array<Card>}
     * @memberof IGetTask200ResponseData
     */
    cards?: Array<Card>;
    /**
     * 
     * @type {Project}
     * @memberof IGetTask200ResponseData
     */
    project?: Project;
    /**
     * 
     * @type {Array<FormTemplateSimpleEntity>}
     * @memberof IGetTask200ResponseData
     */
    formTemplates?: Array<FormTemplateSimpleEntity>;
    /**
     * 
     * @type {Array<ControlPanelProductsToTask>}
     * @memberof IGetTask200ResponseData
     */
    products?: Array<ControlPanelProductsToTask>;
}


/**
 * @export
 */
export const IGetTask200ResponseDataRepeatEnum = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;
export type IGetTask200ResponseDataRepeatEnum = typeof IGetTask200ResponseDataRepeatEnum[keyof typeof IGetTask200ResponseDataRepeatEnum];


/**
 * Check if a given object implements the IGetTask200ResponseData interface.
 */
export function instanceOfIGetTask200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function IGetTask200ResponseDataFromJSON(json: any): IGetTask200ResponseData {
    return IGetTask200ResponseDataFromJSONTyped(json, false);
}

export function IGetTask200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetTask200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'companyId': json['company_id'],
        'projectId': json['project_id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
        'repeat': !exists(json, 'repeat') ? undefined : json['repeat'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'employees': !exists(json, 'employees') ? undefined : ((json['employees'] as Array<any>).map(UserFromJSON)),
        'cards': !exists(json, 'cards') ? undefined : ((json['cards'] as Array<any>).map(CardFromJSON)),
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'formTemplates': !exists(json, 'form_templates') ? undefined : ((json['form_templates'] as Array<any>).map(FormTemplateSimpleEntityFromJSON)),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ControlPanelProductsToTaskFromJSON)),
    };
}

export function IGetTask200ResponseDataToJSON(value?: IGetTask200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by_id': value.createdById,
        'modified_by_id': value.modifiedById,
        'company_id': value.companyId,
        'project_id': value.projectId,
        'name': value.name,
        'description': value.description,
        'start_time': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'end_date': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'repeat': value.repeat,
        'interval': value.interval,
        'employees': value.employees === undefined ? undefined : ((value.employees as Array<any>).map(UserToJSON)),
        'cards': value.cards === undefined ? undefined : ((value.cards as Array<any>).map(CardToJSON)),
        'project': ProjectToJSON(value.project),
        'form_templates': value.formTemplates === undefined ? undefined : ((value.formTemplates as Array<any>).map(FormTemplateSimpleEntityToJSON)),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ControlPanelProductsToTaskToJSON)),
    };
}

