/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlPanelProductsToTask } from './ControlPanelProductsToTask';
import {
    ControlPanelProductsToTaskFromJSON,
    ControlPanelProductsToTaskFromJSONTyped,
    ControlPanelProductsToTaskToJSON,
} from './ControlPanelProductsToTask';

/**
 * 
 * @export
 * @interface TaskCreateOrUpdateDTO
 */
export interface TaskCreateOrUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof TaskCreateOrUpdateDTO
     */
    projectId: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskCreateOrUpdateDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCreateOrUpdateDTO
     */
    description: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskCreateOrUpdateDTO
     */
    labelIds?: Array<string>;
    /**
     * 
     * @type {Array<ControlPanelProductsToTask>}
     * @memberof TaskCreateOrUpdateDTO
     */
    products?: Array<ControlPanelProductsToTask>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskCreateOrUpdateDTO
     */
    formTemplateIds?: Array<string>;
}

/**
 * Check if a given object implements the TaskCreateOrUpdateDTO interface.
 */
export function instanceOfTaskCreateOrUpdateDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function TaskCreateOrUpdateDTOFromJSON(json: any): TaskCreateOrUpdateDTO {
    return TaskCreateOrUpdateDTOFromJSONTyped(json, false);
}

export function TaskCreateOrUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskCreateOrUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'name': json['name'],
        'description': json['description'],
        'labelIds': !exists(json, 'label_ids') ? undefined : json['label_ids'],
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ControlPanelProductsToTaskFromJSON)),
        'formTemplateIds': !exists(json, 'form_template_ids') ? undefined : json['form_template_ids'],
    };
}

export function TaskCreateOrUpdateDTOToJSON(value?: TaskCreateOrUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'name': value.name,
        'description': value.description,
        'label_ids': value.labelIds,
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ControlPanelProductsToTaskToJSON)),
        'form_template_ids': value.formTemplateIds,
    };
}

