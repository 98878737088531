/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseFile
 */
export interface BaseFile {
    /**
     * 
     * @type {string}
     * @memberof BaseFile
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof BaseFile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BaseFile
     */
    originalFilename: string;
    /**
     * 
     * @type {string}
     * @memberof BaseFile
     */
    fileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof BaseFile
     */
    downloadUrl: string;
    /**
     * Naive guess on mime type based on file extension
     * @type {string}
     * @memberof BaseFile
     */
    mimeType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseFile
     */
    isStandardFile?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseFile
     */
    standardOfferFileId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BaseFile
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseFile
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof BaseFile
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the BaseFile interface.
 */
export function instanceOfBaseFile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "originalFilename" in value;
    isInstance = isInstance && "fileUrl" in value;
    isInstance = isInstance && "downloadUrl" in value;

    return isInstance;
}

export function BaseFileFromJSON(json: any): BaseFile {
    return BaseFileFromJSONTyped(json, false);
}

export function BaseFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'originalFilename': json['original_filename'],
        'fileUrl': json['file_url'],
        'downloadUrl': json['download_url'],
        'mimeType': !exists(json, 'mime_type') ? undefined : json['mime_type'],
        'isStandardFile': !exists(json, 'is_standard_file') ? undefined : json['is_standard_file'],
        'standardOfferFileId': !exists(json, 'standard_offer_file_id') ? undefined : json['standard_offer_file_id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function BaseFileToJSON(value?: BaseFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'original_filename': value.originalFilename,
        'file_url': value.fileUrl,
        'download_url': value.downloadUrl,
        'mime_type': value.mimeType,
        'is_standard_file': value.isStandardFile,
        'standard_offer_file_id': value.standardOfferFileId,
    };
}

