/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontpageTotalConsumptionDTO
 */
export interface FrontpageTotalConsumptionDTO {
    /**
     * 
     * @type {number}
     * @memberof FrontpageTotalConsumptionDTO
     */
    productCost: number;
    /**
     * 
     * @type {number}
     * @memberof FrontpageTotalConsumptionDTO
     */
    hoursCost: number;
    /**
     * 
     * @type {number}
     * @memberof FrontpageTotalConsumptionDTO
     */
    contribution: number;
}

/**
 * Check if a given object implements the FrontpageTotalConsumptionDTO interface.
 */
export function instanceOfFrontpageTotalConsumptionDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productCost" in value;
    isInstance = isInstance && "hoursCost" in value;
    isInstance = isInstance && "contribution" in value;

    return isInstance;
}

export function FrontpageTotalConsumptionDTOFromJSON(json: any): FrontpageTotalConsumptionDTO {
    return FrontpageTotalConsumptionDTOFromJSONTyped(json, false);
}

export function FrontpageTotalConsumptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontpageTotalConsumptionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productCost': json['product_cost'],
        'hoursCost': json['hours_cost'],
        'contribution': json['contribution'],
    };
}

export function FrontpageTotalConsumptionDTOToJSON(value?: FrontpageTotalConsumptionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_cost': value.productCost,
        'hours_cost': value.hoursCost,
        'contribution': value.contribution,
    };
}

