/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateFirebaseTokenRequest,
  DeleteTokenByDeviceIdRequest,
  GetUserNotifications200Response,
  GetWallPosts404Response,
  IEditWallPost200Response,
  ISendProjectForms200Response,
  MobileGetCards200Response,
  MobilePlanningCreateCardAndTask201Response,
  MobilePlanningCreateCardAndTaskRequest,
  MobilePlanningEditCardRequest,
  MobilePlanningGetCard200Response,
  RequestResetPassword422Response,
} from '../models';
import {
    CreateFirebaseTokenRequestFromJSON,
    CreateFirebaseTokenRequestToJSON,
    DeleteTokenByDeviceIdRequestFromJSON,
    DeleteTokenByDeviceIdRequestToJSON,
    GetUserNotifications200ResponseFromJSON,
    GetUserNotifications200ResponseToJSON,
    GetWallPosts404ResponseFromJSON,
    GetWallPosts404ResponseToJSON,
    IEditWallPost200ResponseFromJSON,
    IEditWallPost200ResponseToJSON,
    ISendProjectForms200ResponseFromJSON,
    ISendProjectForms200ResponseToJSON,
    MobileGetCards200ResponseFromJSON,
    MobileGetCards200ResponseToJSON,
    MobilePlanningCreateCardAndTask201ResponseFromJSON,
    MobilePlanningCreateCardAndTask201ResponseToJSON,
    MobilePlanningCreateCardAndTaskRequestFromJSON,
    MobilePlanningCreateCardAndTaskRequestToJSON,
    MobilePlanningEditCardRequestFromJSON,
    MobilePlanningEditCardRequestToJSON,
    MobilePlanningGetCard200ResponseFromJSON,
    MobilePlanningGetCard200ResponseToJSON,
    RequestResetPassword422ResponseFromJSON,
    RequestResetPassword422ResponseToJSON,
} from '../models';

export interface CreateFirebaseTokenOperationRequest {
    createFirebaseTokenRequest?: CreateFirebaseTokenRequest;
}

export interface DeleteTokenByDeviceIdOperationRequest {
    deleteTokenByDeviceIdRequest?: DeleteTokenByDeviceIdRequest;
}

export interface DeleteUserNotificationRequest {
    id: string;
}

export interface GetUserNotificationsRequest {
    page?: number;
    onlyUnread?: boolean;
}

export interface MobileGetCardsRequest {
    dateBefore: Date;
    dateAfter: Date;
    unassignedCardsOnly?: boolean;
    unplannedCardsOnly?: boolean;
}

export interface MobilePlanningCreateCardAndTaskOperationRequest {
    mobilePlanningCreateCardAndTaskRequest: MobilePlanningCreateCardAndTaskRequest;
}

export interface MobilePlanningEditCardOperationRequest {
    cardId: string;
    mobilePlanningEditCardRequest?: MobilePlanningEditCardRequest;
}

export interface MobilePlanningGetCardRequest {
    cardId: string;
}

export interface MobilePlanningSelfAssignCardRequest {
    cardId: string;
}

/**
 * MobileAppApi - interface
 * 
 * @export
 * @interface MobileAppApiInterface
 */
export interface MobileAppApiInterface {
    /**
     * 
     * @summary Create firebase registration token
     * @param {CreateFirebaseTokenRequest} [createFirebaseTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    createFirebaseTokenRaw(requestParameters: CreateFirebaseTokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>>;

    /**
     * Create firebase registration token
     */
    createFirebaseToken(requestParameters: CreateFirebaseTokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response>;

    /**
     * 
     * @summary Delete firebase token by device id
     * @param {DeleteTokenByDeviceIdRequest} [deleteTokenByDeviceIdRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    deleteTokenByDeviceIdRaw(requestParameters: DeleteTokenByDeviceIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete firebase token by device id
     */
    deleteTokenByDeviceId(requestParameters: DeleteTokenByDeviceIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Delete given user notification
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    deleteUserNotificationRaw(requestParameters: DeleteUserNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Delete given user notification
     */
    deleteUserNotification(requestParameters: DeleteUserNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary List all user notifications
     * @param {number} [page] Page number
     * @param {boolean} [onlyUnread] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    getUserNotificationsRaw(requestParameters: GetUserNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserNotifications200Response>>;

    /**
     * List all user notifications
     */
    getUserNotifications(requestParameters: GetUserNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserNotifications200Response>;

    /**
     * 
     * @summary Mark all user\'s notifications as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    markAllAsReadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>>;

    /**
     * Mark all user\'s notifications as read
     */
    markAllAsRead(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response>;

    /**
     * 
     * @summary List cards
     * @param {Date} dateBefore Only show cards with a date before this
     * @param {Date} dateAfter Only show cards with a date after this
     * @param {boolean} [unassignedCardsOnly] 
     * @param {boolean} [unplannedCardsOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    mobileGetCardsRaw(requestParameters: MobileGetCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileGetCards200Response>>;

    /**
     * List cards
     */
    mobileGetCards(requestParameters: MobileGetCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileGetCards200Response>;

    /**
     * 
     * @summary Mobile - Create task and card
     * @param {MobilePlanningCreateCardAndTaskRequest} mobilePlanningCreateCardAndTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    mobilePlanningCreateCardAndTaskRaw(requestParameters: MobilePlanningCreateCardAndTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobilePlanningCreateCardAndTask201Response>>;

    /**
     * Mobile - Create task and card
     */
    mobilePlanningCreateCardAndTask(requestParameters: MobilePlanningCreateCardAndTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobilePlanningCreateCardAndTask201Response>;

    /**
     * 
     * @summary Mobile - edit card
     * @param {string} cardId 
     * @param {MobilePlanningEditCardRequest} [mobilePlanningEditCardRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    mobilePlanningEditCardRaw(requestParameters: MobilePlanningEditCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Mobile - edit card
     */
    mobilePlanningEditCard(requestParameters: MobilePlanningEditCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

    /**
     * 
     * @summary Mobile - get card
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    mobilePlanningGetCardRaw(requestParameters: MobilePlanningGetCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobilePlanningGetCard200Response>>;

    /**
     * Mobile - get card
     */
    mobilePlanningGetCard(requestParameters: MobilePlanningGetCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobilePlanningGetCard200Response>;

    /**
     * 
     * @summary Mobile - self-assign card
     * @param {string} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppApiInterface
     */
    mobilePlanningSelfAssignCardRaw(requestParameters: MobilePlanningSelfAssignCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>>;

    /**
     * Mobile - self-assign card
     */
    mobilePlanningSelfAssignCard(requestParameters: MobilePlanningSelfAssignCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response>;

}

/**
 * 
 */
export class MobileAppApi extends runtime.BaseAPI implements MobileAppApiInterface {

    /**
     * Create firebase registration token
     */
    async createFirebaseTokenRaw(requestParameters: CreateFirebaseTokenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/firebase-cloud-messaging-registration-tokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFirebaseTokenRequestToJSON(requestParameters.createFirebaseTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISendProjectForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Create firebase registration token
     */
    async createFirebaseToken(requestParameters: CreateFirebaseTokenOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response> {
        const response = await this.createFirebaseTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete firebase token by device id
     */
    async deleteTokenByDeviceIdRaw(requestParameters: DeleteTokenByDeviceIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/firebase-cloud-messaging-registration-tokens/delete-by-device-id`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteTokenByDeviceIdRequestToJSON(requestParameters.deleteTokenByDeviceIdRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete firebase token by device id
     */
    async deleteTokenByDeviceId(requestParameters: DeleteTokenByDeviceIdOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.deleteTokenByDeviceIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete given user notification
     */
    async deleteUserNotificationRaw(requestParameters: DeleteUserNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/user-notifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete given user notification
     */
    async deleteUserNotification(requestParameters: DeleteUserNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.deleteUserNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all user notifications
     */
    async getUserNotificationsRaw(requestParameters: GetUserNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserNotifications200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.onlyUnread !== undefined) {
            queryParameters['only_unread'] = requestParameters.onlyUnread;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/user-notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserNotifications200ResponseFromJSON(jsonValue));
    }

    /**
     * List all user notifications
     */
    async getUserNotifications(requestParameters: GetUserNotificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserNotifications200Response> {
        const response = await this.getUserNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark all user\'s notifications as read
     */
    async markAllAsReadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ISendProjectForms200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/user-notifications/mark-all-as-read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ISendProjectForms200ResponseFromJSON(jsonValue));
    }

    /**
     * Mark all user\'s notifications as read
     */
    async markAllAsRead(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ISendProjectForms200Response> {
        const response = await this.markAllAsReadRaw(initOverrides);
        return await response.value();
    }

    /**
     * List cards
     */
    async mobileGetCardsRaw(requestParameters: MobileGetCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileGetCards200Response>> {
        if (requestParameters.dateBefore === null || requestParameters.dateBefore === undefined) {
            throw new runtime.RequiredError('dateBefore','Required parameter requestParameters.dateBefore was null or undefined when calling mobileGetCards.');
        }

        if (requestParameters.dateAfter === null || requestParameters.dateAfter === undefined) {
            throw new runtime.RequiredError('dateAfter','Required parameter requestParameters.dateAfter was null or undefined when calling mobileGetCards.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = (requestParameters.dateBefore as any).toISOString();
        }

        if (requestParameters.dateAfter !== undefined) {
            queryParameters['date_after'] = (requestParameters.dateAfter as any).toISOString();
        }

        if (requestParameters.unassignedCardsOnly !== undefined) {
            queryParameters['unassigned_cards_only'] = requestParameters.unassignedCardsOnly;
        }

        if (requestParameters.unplannedCardsOnly !== undefined) {
            queryParameters['unplanned_cards_only'] = requestParameters.unplannedCardsOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/planning`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileGetCards200ResponseFromJSON(jsonValue));
    }

    /**
     * List cards
     */
    async mobileGetCards(requestParameters: MobileGetCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileGetCards200Response> {
        const response = await this.mobileGetCardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mobile - Create task and card
     */
    async mobilePlanningCreateCardAndTaskRaw(requestParameters: MobilePlanningCreateCardAndTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobilePlanningCreateCardAndTask201Response>> {
        if (requestParameters.mobilePlanningCreateCardAndTaskRequest === null || requestParameters.mobilePlanningCreateCardAndTaskRequest === undefined) {
            throw new runtime.RequiredError('mobilePlanningCreateCardAndTaskRequest','Required parameter requestParameters.mobilePlanningCreateCardAndTaskRequest was null or undefined when calling mobilePlanningCreateCardAndTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/planning/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MobilePlanningCreateCardAndTaskRequestToJSON(requestParameters.mobilePlanningCreateCardAndTaskRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobilePlanningCreateCardAndTask201ResponseFromJSON(jsonValue));
    }

    /**
     * Mobile - Create task and card
     */
    async mobilePlanningCreateCardAndTask(requestParameters: MobilePlanningCreateCardAndTaskOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobilePlanningCreateCardAndTask201Response> {
        const response = await this.mobilePlanningCreateCardAndTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mobile - edit card
     */
    async mobilePlanningEditCardRaw(requestParameters: MobilePlanningEditCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling mobilePlanningEditCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/planning/edit-card/{cardId}`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MobilePlanningEditCardRequestToJSON(requestParameters.mobilePlanningEditCardRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Mobile - edit card
     */
    async mobilePlanningEditCard(requestParameters: MobilePlanningEditCardOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.mobilePlanningEditCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mobile - get card
     */
    async mobilePlanningGetCardRaw(requestParameters: MobilePlanningGetCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobilePlanningGetCard200Response>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling mobilePlanningGetCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/planning/view-card/{cardId}`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobilePlanningGetCard200ResponseFromJSON(jsonValue));
    }

    /**
     * Mobile - get card
     */
    async mobilePlanningGetCard(requestParameters: MobilePlanningGetCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobilePlanningGetCard200Response> {
        const response = await this.mobilePlanningGetCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mobile - self-assign card
     */
    async mobilePlanningSelfAssignCardRaw(requestParameters: MobilePlanningSelfAssignCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IEditWallPost200Response>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling mobilePlanningSelfAssignCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mobile-app-api/v1/planning/self-assign/{cardId}`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IEditWallPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Mobile - self-assign card
     */
    async mobilePlanningSelfAssignCard(requestParameters: MobilePlanningSelfAssignCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IEditWallPost200Response> {
        const response = await this.mobilePlanningSelfAssignCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
