/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormField } from './FormField';
import {
    FormFieldFromJSON,
    FormFieldFromJSONTyped,
    FormFieldToJSON,
} from './FormField';
import type { KpiChardHoursIndividualEntry } from './KpiChardHoursIndividualEntry';
import {
    KpiChardHoursIndividualEntryFromJSON,
    KpiChardHoursIndividualEntryFromJSONTyped,
    KpiChardHoursIndividualEntryToJSON,
} from './KpiChardHoursIndividualEntry';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface UsersGetSpecified200ResponseDataInner
 */
export interface UsersGetSpecified200ResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly projectId: string;
    /**
     * 
     * @type {string}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly projectName?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly userId: string;
    /**
     * 
     * @type {Date}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly formDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly approved: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly invoiced: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly deleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly workDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly preview?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly totalHours?: number;
    /**
     * 
     * @type {User}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    user?: User;
    /**
     * 
     * @type {Array<FormField>}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    fields?: Array<FormField>;
    /**
     * 
     * @type {Array<KpiChardHoursIndividualEntry>}
     * @memberof UsersGetSpecified200ResponseDataInner
     */
    readonly entries?: Array<KpiChardHoursIndividualEntry>;
}

/**
 * Check if a given object implements the UsersGetSpecified200ResponseDataInner interface.
 */
export function instanceOfUsersGetSpecified200ResponseDataInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "formDate" in value;
    isInstance = isInstance && "approved" in value;
    isInstance = isInstance && "invoiced" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function UsersGetSpecified200ResponseDataInnerFromJSON(json: any): UsersGetSpecified200ResponseDataInner {
    return UsersGetSpecified200ResponseDataInnerFromJSONTyped(json, false);
}

export function UsersGetSpecified200ResponseDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetSpecified200ResponseDataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectId': json['project_id'],
        'projectName': !exists(json, 'project_name') ? undefined : json['project_name'],
        'userId': json['user_id'],
        'formDate': (new Date(json['form_date'])),
        'approved': (json['approved'] === null ? null : new Date(json['approved'])),
        'invoiced': (json['invoiced'] === null ? null : new Date(json['invoiced'])),
        'deleted': json['deleted'],
        'workDescription': !exists(json, 'work_description') ? undefined : json['work_description'],
        'preview': !exists(json, 'preview') ? undefined : json['preview'],
        'totalHours': !exists(json, 'total_hours') ? undefined : json['total_hours'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'fields': !exists(json, 'fields') ? undefined : ((json['fields'] as Array<any>).map(FormFieldFromJSON)),
        'entries': !exists(json, 'entries') ? undefined : ((json['entries'] as Array<any>).map(KpiChardHoursIndividualEntryFromJSON)),
    };
}

export function UsersGetSpecified200ResponseDataInnerToJSON(value?: UsersGetSpecified200ResponseDataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'fields': value.fields === undefined ? undefined : ((value.fields as Array<any>).map(FormFieldToJSON)),
    };
}

