<ion-content class="login-wrapper bg-dark-blue">
    <ion-item class="ion-text-center bg-dark-blue" lines="none">
        <ion-label>
            <img src="assets/img/Apacta_logo_white.png" alt="logo" width="200px" />
        </ion-label>
    </ion-item>
    <ion-item lines="none" class="ion-text-center bg-dark-blue">
        <ion-label class="ion-text-wrap">{{ "LOGIN.HEADER" | translate }}</ion-label>
    </ion-item>
    <ion-card>
        <form [formGroup]="loginForm" novalidate>
            <ion-item lines="full" mode="md">
                <ion-input
                    type="email"
                    placeholder="{{ 'LOGIN.EMAIL' | translate }}"
                    formControlName="email"
                >
                </ion-input>
            </ion-item>
            <ion-item lines="full">
                <ion-input
                    type="password"
                    placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
                    formControlName="password"
                >
                    <ion-icon name="eye" showPassword></ion-icon>
                </ion-input>
            </ion-item>

            <!-- Remember me-->
            <ion-item lines="none">
                <ion-label>{{ "LOGIN.REMEMBER_ME" | translate }}</ion-label>
                <ion-checkbox slot="start" formControlName="rememberMe" checked="true">
                </ion-checkbox>
            </ion-item>

            <!-- LOGIN-->
            <ion-item
                (click)="authenticateUser()"
                button
                detail="true"
                class="login-btn ion-text-center"
            >
                <ion-label>{{ "LOGIN.LOGIN" | translate }}</ion-label>
            </ion-item>
        </form>
    </ion-card>
    <ion-label class="forgot-password" (click)="navigateToNewResetPassword()"
        >{{ "LOGIN.FORGOT_PASSWORD" | translate }}?</ion-label
    >
    <div class="new-acc">
        <ion-label>{{ "LOGIN.DONT_HAVE_ACC" | translate }}</ion-label>
        <ion-label class="new-cmp" (click)="navigateToNewCompany()">{{
            "LOGIN.SIGN_UP_HERE" | translate
        }}</ion-label>
    </div>

    <div class="ion-text-center bg-dark-blue call-support">
        <a class="no-text-decoration" href="tel:+4577347834">
            <ion-icon class="icons-style" item-start name="call"></ion-icon>
            {{ "PROFILE.CONTACT_SUPPORT" | translate }}
        </a>
    </div>
</ion-content>
