import { State, Selector, Action, StateContext } from "@ngxs/store";
import {
    DrivingType,
    DrivingLocations,
} from "src/app/services/response-models/driving.response.model";
import {
    LoadDrivingTypes,
    ClearDrivingTypes,
    LoadDrivingTypesPagination,
} from "./actions/load-driving-types.action";
import { LoadDrivingLocations } from "./actions/load-driving-locations.action";
import { Injectable } from "@angular/core";
import { Pagination } from "src/app/services/response-models/projects.response.model";

export interface DrivingStateModel {
    drivingTypes: Array<DrivingType>;
    locations: DrivingLocations;
    pagination: Pagination;
}

@State<DrivingStateModel>({
    name: "driving",
    defaults: {
        locations: null,
        drivingTypes: [],
        pagination: null,
    },
})
@Injectable()
export class DrivingState {
    @Selector()
    public static getDrivingTypes(state: DrivingStateModel) {
        return state.drivingTypes;
    }

    @Selector()
    public static getLocations(state: DrivingStateModel) {
        return state.locations;
    }

    @Selector()
    public static getPagination(state: DrivingStateModel) {
        return state.pagination;
    }

    @Action(LoadDrivingTypes)
    loadTypes(ctx: StateContext<DrivingStateModel>, { payload }: LoadDrivingTypes) {
        ctx.setState({ ...ctx.getState(), drivingTypes: payload });
    }

    @Action(LoadDrivingLocations)
    loadLocations(ctx: StateContext<DrivingStateModel>, { payload }: LoadDrivingLocations) {
        ctx.setState({ ...ctx.getState(), locations: payload });
    }

    @Action(LoadDrivingTypesPagination)
    loadPagination(ctx: StateContext<DrivingStateModel>, { payload }: LoadDrivingTypesPagination) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }

    @Action(ClearDrivingTypes)
    clear(ctx: StateContext<DrivingStateModel>) {
        ctx.setState({ ...ctx.getState(), drivingTypes: [] });
    }
}
