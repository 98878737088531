import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { mergeMap, timeout } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { ConfigState } from "src/store/config/config.state";
import { DeviceInfo } from "@capacitor/device";
import { AuthService } from "src/app/services/auth.service";

@Injectable()
export class AddDefaultHeadersInterceptor implements HttpInterceptor {
    @Select(ConfigState.getConfig) config$: Observable<DeviceInfo>;

    private config: DeviceInfo;

    constructor(private auth: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes("tomtom") || req.url.includes("slack")) {
            return next.handle(req);
        }
        return from(this.auth.getToken()).pipe(
            mergeMap((token) => {
                if (token) {
                    const updatedRequest = this.attachHeaders(req, token);
                    return next.handle(updatedRequest).pipe(timeout(300000));
                }

                return next.handle(req);
            })
        );
    }

    private attachHeaders = (request: HttpRequest<any>, token: string) => {
        this.config$
            .subscribe((data) => {
                this.config = data;
            })
            .unsubscribe();

        if (
            request.url.includes("files/add") ||
            request.url.includes("files.json") ||
            request.url.includes("vendor_product_price_files") ||
            request.url.includes("uploadImage") ||
            request.url.includes("/project_files") ||
            request.url.includes("expense_files")
        ) {
            // DO NOT send any content type so that the body
            // can be send as a multipart/form-data when sending
            const clone = request.clone({
                headers: request.headers
                    .set("Authorization", `Bearer ${token}`)
                    .set("Accept", "application/json"),
            });

            return clone;
        }
        const clone = request.clone({
            headers: request.headers
                .set("Accept", "application/json")
                .set("Content-Type", "application/json")
                .set("Authorization", `Bearer ${token}`)
                .set("X-Apacta-App-Version", this.config ? this.config.osVersion.toString() : "")
                .set("X-Apacta-App-Build", this.config ? this.config.osVersion.toString() : "")
                .set("X-Apacta-App-Details", this.config ? this.config.model : ""),
        });

        return clone;
    };
}
