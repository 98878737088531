/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Expense } from './Expense';
import {
    ExpenseFromJSON,
    ExpenseFromJSONTyped,
    ExpenseToJSON,
} from './Expense';
import type { ExpenseLine } from './ExpenseLine';
import {
    ExpenseLineFromJSON,
    ExpenseLineFromJSONTyped,
    ExpenseLineToJSON,
} from './ExpenseLine';
import type { ImageUrls } from './ImageUrls';
import {
    ImageUrlsFromJSON,
    ImageUrlsFromJSONTyped,
    ImageUrlsToJSON,
} from './ImageUrls';
import type { Vendor } from './Vendor';
import {
    VendorFromJSON,
    VendorFromJSONTyped,
    VendorToJSON,
} from './Vendor';
import type { VendorProduct } from './VendorProduct';
import {
    VendorProductFromJSON,
    VendorProductFromJSONTyped,
    VendorProductToJSON,
} from './VendorProduct';

/**
 * 
 * @export
 * @interface ExpenseLinesList200ResponseAllOfDataInner
 */
export interface ExpenseLinesList200ResponseAllOfDataInner {
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly expenseId: string;
    /**
     * 
     * @type {Expense}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly expense?: Expense;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly createdById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly currencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly productId?: string;
    /**
     * 
     * @type {Vendor}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    vendor?: Vendor;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly vendorProductId?: string;
    /**
     * 
     * @type {VendorProduct}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    vendorProduct?: VendorProduct;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    barcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    productNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    buyingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    sellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    ratio?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    priceType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    isPrimaryBuyingOption?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    isInvoiced?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {ImageUrls}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    imageUrls?: ImageUrls;
    /**
     * 
     * @type {string}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly translatedOrigin?: string;
    /**
     * 
     * @type {Array<ExpenseLine>}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    readonly priceHistory?: Array<ExpenseLine> | null;
    /**
     * 
     * @type {number}
     * @memberof ExpenseLinesList200ResponseAllOfDataInner
     */
    grossPrice?: number;
}

/**
 * Check if a given object implements the ExpenseLinesList200ResponseAllOfDataInner interface.
 */
export function instanceOfExpenseLinesList200ResponseAllOfDataInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "expenseId" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function ExpenseLinesList200ResponseAllOfDataInnerFromJSON(json: any): ExpenseLinesList200ResponseAllOfDataInner {
    return ExpenseLinesList200ResponseAllOfDataInnerFromJSONTyped(json, false);
}

export function ExpenseLinesList200ResponseAllOfDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpenseLinesList200ResponseAllOfDataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'expenseId': json['expense_id'],
        'expense': !exists(json, 'expense') ? undefined : ExpenseFromJSON(json['expense']),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'currencyId': !exists(json, 'currency_id') ? undefined : json['currency_id'],
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'vendor': !exists(json, 'vendor') ? undefined : VendorFromJSON(json['vendor']),
        'vendorProductId': !exists(json, 'vendor_product_id') ? undefined : json['vendor_product_id'],
        'vendorProduct': !exists(json, 'vendor_product') ? undefined : VendorProductFromJSON(json['vendor_product']),
        'text': !exists(json, 'text') ? undefined : json['text'],
        'barcode': !exists(json, 'barcode') ? undefined : json['barcode'],
        'productNumber': !exists(json, 'product_number') ? undefined : json['product_number'],
        'buyingPrice': !exists(json, 'buying_price') ? undefined : json['buying_price'],
        'sellingPrice': !exists(json, 'selling_price') ? undefined : json['selling_price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'ratio': !exists(json, 'ratio') ? undefined : json['ratio'],
        'priceType': !exists(json, 'price_type') ? undefined : json['price_type'],
        'isPrimaryBuyingOption': !exists(json, 'is_primary_buying_option') ? undefined : json['is_primary_buying_option'],
        'isInvoiced': !exists(json, 'is_invoiced') ? undefined : (json['is_invoiced'] === null ? null : new Date(json['is_invoiced'])),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'imageUrls': !exists(json, 'image_urls') ? undefined : ImageUrlsFromJSON(json['image_urls']),
        'translatedOrigin': !exists(json, 'translated_origin') ? undefined : json['translated_origin'],
        'priceHistory': !exists(json, 'price_history') ? undefined : (json['price_history'] === null ? null : (json['price_history'] as Array<any>).map(ExpenseLineFromJSON)),
        'grossPrice': !exists(json, 'gross_price') ? undefined : json['gross_price'],
    };
}

export function ExpenseLinesList200ResponseAllOfDataInnerToJSON(value?: ExpenseLinesList200ResponseAllOfDataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vendor': VendorToJSON(value.vendor),
        'vendor_product': VendorProductToJSON(value.vendorProduct),
        'text': value.text,
        'barcode': value.barcode,
        'product_number': value.productNumber,
        'buying_price': value.buyingPrice,
        'selling_price': value.sellingPrice,
        'quantity': value.quantity,
        'ratio': value.ratio,
        'price_type': value.priceType,
        'is_primary_buying_option': value.isPrimaryBuyingOption,
        'is_invoiced': value.isInvoiced === undefined ? undefined : (value.isInvoiced === null ? null : value.isInvoiced.toISOString()),
        'image_urls': ImageUrlsToJSON(value.imageUrls),
        'gross_price': value.grossPrice,
    };
}

