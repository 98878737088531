export class SetSecondMenu {
    static readonly type = "[Second menu] Set second menu";

    constructor(public payload: SecondMenu) {}
}

export class ClearSecondMenu {
    static readonly type = "[Second menu] Clear second menu";

    constructor() {}
}

export class SetSecondMenuPreselectedOption {
    static readonly type = "[Second menu] Set second menu preselected option";

    constructor(public payload: string) {}
}

export class SetSecondMenuHighlightedOption {
    static readonly type = "[Second menu] Set second menu highlighted option";

    constructor(public payload: string) {}
}

export class SetSecondMenuAutomaticHighlight {
    static readonly type = "[Second menu] Set second menu automatic highlight";

    constructor(public payload: boolean) {}
}

export interface SecondMenu {
    url: string;
    options: Array<SecondMenuOption>;
    topOffset?: number;
}

export interface SecondMenuOption {
    icon?: string;
    label: string;
    url: string;
    sortOrder?: number;
}
