/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateCardAndTask201ResponseData } from './CreateCardAndTask201ResponseData';
import {
    CreateCardAndTask201ResponseDataFromJSON,
    CreateCardAndTask201ResponseDataFromJSONTyped,
    CreateCardAndTask201ResponseDataToJSON,
} from './CreateCardAndTask201ResponseData';

/**
 * 
 * @export
 * @interface CreateCardAndTask201Response
 */
export interface CreateCardAndTask201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateCardAndTask201Response
     */
    success: boolean;
    /**
     * 
     * @type {CreateCardAndTask201ResponseData}
     * @memberof CreateCardAndTask201Response
     */
    data: CreateCardAndTask201ResponseData;
}

/**
 * Check if a given object implements the CreateCardAndTask201Response interface.
 */
export function instanceOfCreateCardAndTask201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CreateCardAndTask201ResponseFromJSON(json: any): CreateCardAndTask201Response {
    return CreateCardAndTask201ResponseFromJSONTyped(json, false);
}

export function CreateCardAndTask201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCardAndTask201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': CreateCardAndTask201ResponseDataFromJSON(json['data']),
    };
}

export function CreateCardAndTask201ResponseToJSON(value?: CreateCardAndTask201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': CreateCardAndTask201ResponseDataToJSON(value.data),
    };
}

