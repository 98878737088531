import { Pagination } from "src/app/services/response-models/form.response.model";
import { ProjectEmployees } from "src/app/services/response-models/projects.response.model";

export class SetProjectEmployees {
    public static readonly type = "[Project] Set project employees";

    constructor(public payload: ProjectEmployees[]) {}
}

export class SetProjectEmployeesPagination {
    public static readonly type = "[Project] Set project employees pagination";

    constructor(public payload: Pagination) {}
}

export class ClearProjectEmployees {
    public static readonly type = "[Projects] Clear project employees";

    constructor() {}
}
