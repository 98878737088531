/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseFile } from './BaseFile';
import {
    BaseFileFromJSON,
    BaseFileFromJSONTyped,
    BaseFileToJSON,
} from './BaseFile';
import type { Form } from './Form';
import {
    FormFromJSON,
    FormFromJSONTyped,
    FormToJSON,
} from './Form';

/**
 * 
 * @export
 * @interface FormField
 */
export interface FormField {
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    readonly createdById: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    formId: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    formFieldTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    formTemplateFieldId: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    contentValue?: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof FormField
     */
    placement: number;
    /**
     * 
     * @type {Form}
     * @memberof FormField
     */
    form?: Form;
    /**
     * 
     * @type {BaseFile}
     * @memberof FormField
     */
    file?: BaseFile;
    /**
     * 
     * @type {Date}
     * @memberof FormField
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof FormField
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof FormField
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the FormField interface.
 */
export function instanceOfFormField(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdById" in value;
    isInstance = isInstance && "formId" in value;
    isInstance = isInstance && "formFieldTypeId" in value;
    isInstance = isInstance && "formTemplateFieldId" in value;
    isInstance = isInstance && "placement" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function FormFieldFromJSON(json: any): FormField {
    return FormFieldFromJSONTyped(json, false);
}

export function FormFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': json['created_by_id'],
        'formId': json['form_id'],
        'formFieldTypeId': json['form_field_type_id'],
        'formTemplateFieldId': json['form_template_field_id'],
        'fileId': !exists(json, 'file_id') ? undefined : json['file_id'],
        'contentValue': !exists(json, 'content_value') ? undefined : json['content_value'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'placement': json['placement'],
        'form': !exists(json, 'form') ? undefined : FormFromJSON(json['form']),
        'file': !exists(json, 'file') ? undefined : BaseFileFromJSON(json['file']),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function FormFieldToJSON(value?: FormField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_id': value.formId,
        'form_field_type_id': value.formFieldTypeId,
        'form_template_field_id': value.formTemplateFieldId,
        'file_id': value.fileId,
        'content_value': value.contentValue,
        'comment': value.comment,
        'placement': value.placement,
        'form': FormToJSON(value.form),
        'file': BaseFileToJSON(value.file),
    };
}

