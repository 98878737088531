/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectStatus1 } from './ProjectStatus1';
import {
    ProjectStatus1FromJSON,
    ProjectStatus1FromJSONTyped,
    ProjectStatus1ToJSON,
} from './ProjectStatus1';

/**
 * 
 * @export
 * @interface Project1
 */
export interface Project1 {
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    name?: string;
    /**
     * Includes both project number and name (if project number is enabled for company)
     * @type {string}
     * @memberof Project1
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    thumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    projectImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    streetName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Project1
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Project1
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Project1
     */
    projectStatusId?: string;
    /**
     * 
     * @type {ProjectStatus1}
     * @memberof Project1
     */
    projectStatus?: ProjectStatus1;
    /**
     * 
     * @type {Date}
     * @memberof Project1
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Project1
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Project1
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the Project1 interface.
 */
export function instanceOfProject1(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function Project1FromJSON(json: any): Project1 {
    return Project1FromJSONTyped(json, false);
}

export function Project1FromJSONTyped(json: any, ignoreDiscriminator: boolean): Project1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'projectNumber': !exists(json, 'project_number') ? undefined : json['project_number'],
        'thumbnail': !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'projectImageUrl': !exists(json, 'project_image_url') ? undefined : json['project_image_url'],
        'streetName': !exists(json, 'street_name') ? undefined : json['street_name'],
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'projectStatusId': !exists(json, 'project_status_id') ? undefined : json['project_status_id'],
        'projectStatus': !exists(json, 'project_status') ? undefined : ProjectStatus1FromJSON(json['project_status']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function Project1ToJSON(value?: Project1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'project_number': value.projectNumber,
        'thumbnail': value.thumbnail,
        'project_image_url': value.projectImageUrl,
        'street_name': value.streetName,
        'start_time': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'project_status_id': value.projectStatusId,
        'project_status': ProjectStatus1ToJSON(value.projectStatus),
    };
}

