// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    environmentName: "qa",
    apiBaseUrl: "https://app-qa.apacta.com/api/v1/",
    apactaImageBaseUrl: "https://app-qa.apacta.com/glide",
    appUrl: "https://app-qa.apacta.com/",
    v3ControlPanelUrl: "https://control-panel-qa.apacta.com",
    cpReleaseDate: "2021-11-27",
    newUiUrl: "https://app-frontend-qa.apacta.com/",
    tomtomApiUrl: "https://api.tomtom.com/search/2/",
    tomtomApiKey: "vyD43m3Kpr7RrJ49JKABdsrlF1OROHvO",
    segmentWriteKey: "eyM3XJcPAjeMg3hfUa0mB51pX0UASUsd",
    enableSentry: true,
    sentryTracesSampleRate: 0,
};
