/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceLineCreateOrUpdateDTO
 */
export interface InvoiceLineCreateOrUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    productId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    expenseLineId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    vendorProductId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    productBundleLineId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    usersPriceGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    productBundleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    sellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineCreateOrUpdateDTO
     */
    placement?: number;
}

/**
 * Check if a given object implements the InvoiceLineCreateOrUpdateDTO interface.
 */
export function instanceOfInvoiceLineCreateOrUpdateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvoiceLineCreateOrUpdateDTOFromJSON(json: any): InvoiceLineCreateOrUpdateDTO {
    return InvoiceLineCreateOrUpdateDTOFromJSONTyped(json, false);
}

export function InvoiceLineCreateOrUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceLineCreateOrUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'expenseLineId': !exists(json, 'expense_line_id') ? undefined : json['expense_line_id'],
        'vendorProductId': !exists(json, 'vendor_product_id') ? undefined : json['vendor_product_id'],
        'productBundleLineId': !exists(json, 'product_bundle_line_id') ? undefined : json['product_bundle_line_id'],
        'usersPriceGroupId': !exists(json, 'users_price_group_id') ? undefined : json['users_price_group_id'],
        'productBundleId': !exists(json, 'product_bundle_id') ? undefined : json['product_bundle_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sellingPrice': !exists(json, 'selling_price') ? undefined : json['selling_price'],
        'discountPercent': !exists(json, 'discount_percent') ? undefined : json['discount_percent'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'placement': !exists(json, 'placement') ? undefined : json['placement'],
    };
}

export function InvoiceLineCreateOrUpdateDTOToJSON(value?: InvoiceLineCreateOrUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product_id': value.productId,
        'expense_line_id': value.expenseLineId,
        'vendor_product_id': value.vendorProductId,
        'product_bundle_line_id': value.productBundleLineId,
        'users_price_group_id': value.usersPriceGroupId,
        'product_bundle_id': value.productBundleId,
        'name': value.name,
        'description': value.description,
        'selling_price': value.sellingPrice,
        'discount_percent': value.discountPercent,
        'quantity': value.quantity,
        'placement': value.placement,
    };
}

