/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductBundlesGetKpiCardsData200ResponseData
 */
export interface ProductBundlesGetKpiCardsData200ResponseData {
    /**
     * 
     * @type {number}
     * @memberof ProductBundlesGetKpiCardsData200ResponseData
     */
    readonly hoursCostPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlesGetKpiCardsData200ResponseData
     */
    productsCostPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlesGetKpiCardsData200ResponseData
     */
    totalCostPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlesGetKpiCardsData200ResponseData
     */
    hoursSalesPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlesGetKpiCardsData200ResponseData
     */
    productsSalesPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlesGetKpiCardsData200ResponseData
     */
    totalSalesPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlesGetKpiCardsData200ResponseData
     */
    contributionMargin?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlesGetKpiCardsData200ResponseData
     */
    workingHoursMargin?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlesGetKpiCardsData200ResponseData
     */
    profitMargin?: number;
}

/**
 * Check if a given object implements the ProductBundlesGetKpiCardsData200ResponseData interface.
 */
export function instanceOfProductBundlesGetKpiCardsData200ResponseData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductBundlesGetKpiCardsData200ResponseDataFromJSON(json: any): ProductBundlesGetKpiCardsData200ResponseData {
    return ProductBundlesGetKpiCardsData200ResponseDataFromJSONTyped(json, false);
}

export function ProductBundlesGetKpiCardsData200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundlesGetKpiCardsData200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hoursCostPrice': !exists(json, 'hours_cost_price') ? undefined : json['hours_cost_price'],
        'productsCostPrice': !exists(json, 'products_cost_price') ? undefined : json['products_cost_price'],
        'totalCostPrice': !exists(json, 'total_cost_price') ? undefined : json['total_cost_price'],
        'hoursSalesPrice': !exists(json, 'hours_sales_price') ? undefined : json['hours_sales_price'],
        'productsSalesPrice': !exists(json, 'products_sales_price') ? undefined : json['products_sales_price'],
        'totalSalesPrice': !exists(json, 'total_sales_price') ? undefined : json['total_sales_price'],
        'contributionMargin': !exists(json, 'contribution_margin') ? undefined : json['contribution_margin'],
        'workingHoursMargin': !exists(json, 'working_hours_margin') ? undefined : json['working_hours_margin'],
        'profitMargin': !exists(json, 'profit_margin') ? undefined : json['profit_margin'],
    };
}

export function ProductBundlesGetKpiCardsData200ResponseDataToJSON(value?: ProductBundlesGetKpiCardsData200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products_cost_price': value.productsCostPrice,
        'total_cost_price': value.totalCostPrice,
        'hours_sales_price': value.hoursSalesPrice,
        'products_sales_price': value.productsSalesPrice,
        'total_sales_price': value.totalSalesPrice,
        'contribution_margin': value.contributionMargin,
        'working_hours_margin': value.workingHoursMargin,
        'profit_margin': value.profitMargin,
    };
}

