import { Injectable } from "@angular/core";
import { ApactaApiSdkService } from "src/app/services/apacta-api-sdk.service";
import { PermissionStatus, PushNotifications } from "@capacitor/push-notifications";
import {
    FirebaseCloudMessagingRegistrationToken,
    MobileAppPreferences,
    User,
} from "src/app/services/response-models/user.response.model";
import { Store } from "@ngxs/store";
import { AppState } from "src/store/app/app.state";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { UsersService } from "src/app/services/users.service";

@Injectable({
    providedIn: "root",
})
export class PushNotificationService {
    private user: User;
    private saveToken: boolean = false;
    private notificationSettings: MobileAppPreferences = {
        enable_notifications_tasks: false,
        enable_notifications_messages: false,
        enable_notifications_forms_daily_reminder: false,
        manually_changed: false,
        forms_daily_reminder_preferences: {
            monday: "16:05",
            tuesday: "16:05",
            wednesday: "16:05",
            thursday: "16:05",
            friday: "16:05",
            saturday: null,
            sunday: null,
        },
    };
    private skipUserUpdate: boolean = false;

    constructor(
        private apactaSdk: ApactaApiSdkService,
        private store: Store,
        private usersService: UsersService
    ) {}

    public setup = async (): Promise<void> => {
        // not supported on web
        if (Capacitor.getPlatform() === "web") {
            return;
        }

        this.user = this.store.selectSnapshot(AppState.getUser);
        const deviceId = await Device.getId();
        const mobileSdk = this.apactaSdk.mobileConfiguration();

        await PushNotifications.removeAllListeners();
        await PushNotifications.addListener("registration", (token) => {
            // for some reason even without .register()
            // there is listener and this is to make sure
            // we are not saving the token twice
            if (this.saveToken) {
                this.saveToken = false;

                if (!this.skipUserUpdate) {
                    this.usersService.updateUser(this.user.id, this.getNotificationSettings());
                }

                mobileSdk
                    .createFirebaseToken({
                        createFirebaseTokenRequest: {
                            token: token.value,
                            userId: this.user.id,
                            deviceId: deviceId.identifier,
                        },
                    })
                    .catch(() => {
                        // if there is something wrong we unregister
                        // and remove listeners
                        // on the next opening of the app we will try again
                        // because user already granted access
                        PushNotifications.unregister();
                        PushNotifications.removeAllListeners();
                    });
            }
        });

        const permissionStatus = await PushNotifications.checkPermissions();

        const token = this.user?.firebase_cloud_messaging_registration_tokens.find(
            (x) => x.device_id === deviceId.identifier
        );

        if (await this.refreshToken(token, permissionStatus)) {
            return;
        }

        // we need to make sure after logout and deleting token
        // when login and there is permission to register the device again
        if (permissionStatus.receive === "prompt") {
            this.requestPermission();
            return;
        }

        // if permission is denied turn off user settings
        // and delete the token if exists
        if (permissionStatus.receive === "denied") {
            this.usersService.updateUser(this.user.id, this.getNotificationSettings(false));
            PushNotifications.unregister();
            if (token) {
                mobileSdk.deleteTokenByDeviceId({
                    deleteTokenByDeviceIdRequest: { deviceId: deviceId.identifier },
                });
            }
            return;
        }

        // if permission is granted and there is no token
        // we register the device and save new token
        // but skip user update because we already have the settings
        if (permissionStatus.receive === "granted" && !token) {
            const manuallyChanged = this.user.mobile_app_preferences
                ? this.user.mobile_app_preferences.manually_changed
                : false;

            if (manuallyChanged === true) {
                this.skipUserUpdate = true;
            }
            PushNotifications.register();
            this.saveToken = true;
        }
    };

    private getNotificationSettings = (enabled: boolean = true): Partial<User> => {
        if (enabled) {
            this.notificationSettings.enable_notifications_tasks = true;
            this.notificationSettings.enable_notifications_messages = true;
            this.notificationSettings.enable_notifications_forms_daily_reminder = true;
        }

        return { mobile_app_preferences: this.notificationSettings };
    };

    private requestPermission(): Promise<boolean> {
        return PushNotifications.requestPermissions().then((newStatus) => {
            if (newStatus.receive === "granted") {
                PushNotifications.register();
                this.saveToken = true;
                return true;
            }

            if (!this.skipUserUpdate) {
                this.usersService.updateUser(this.user.id, this.getNotificationSettings(false));
            }
            return false;
        });
    }

    public async checkAndRequest(skipUserUpdate: boolean = false): Promise<boolean> {
        // not supported on web
        if (Capacitor.getPlatform() === "web") {
            return true;
        }
        this.skipUserUpdate = skipUserUpdate;
        const permissionStatus = await PushNotifications.checkPermissions();
        if (permissionStatus.receive !== "granted") {
            return this.requestPermission();
        }
        return true;
    }

    private getDateDifferenceInDays(targetDate: Date): number {
        const today = new Date();
        const diffInTime = today.getTime() - targetDate.getTime();
        return Math.floor(diffInTime / (1000 * 3600 * 24));
    }

    private async refreshToken(
        token: FirebaseCloudMessagingRegistrationToken,
        permissionStatus: PermissionStatus
    ): Promise<boolean> {
        if (
            permissionStatus.receive === "granted" &&
            token &&
            this.getDateDifferenceInDays(new Date(token.created)) > 30
        ) {
            this.skipUserUpdate = true;
            PushNotifications.register();
            this.saveToken = true;
            return true;
        }
        return false;
    }
}
