import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "src/app/login/login/login.component";
import { MobileLoginComponent } from "src/app/login/mobile-login/mobile-login.component";
import { SharedModule as GlobalSharedModule } from "src/app/shared/shared.module";

@NgModule({
    imports: [
        IonicModule.forRoot(),
        ReactiveFormsModule,
        TranslateModule,
        GlobalSharedModule,
        CommonModule,
    ],
    declarations: [MobileLoginComponent, LoginComponent],
    exports: [LoginComponent],
})
export class LoginModule {}
