/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface InvoiceActivityLog
 */
export interface InvoiceActivityLog {
    /**
     * 
     * @type {string}
     * @memberof InvoiceActivityLog
     */
    invoiceId: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceActivityLog
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceActivityLog
     */
    action: InvoiceActivityLogActionEnum;
    /**
     * 
     * @type {User}
     * @memberof InvoiceActivityLog
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof InvoiceActivityLog
     */
    recipient?: string;
}


/**
 * @export
 */
export const InvoiceActivityLogActionEnum = {
    Created: 'created',
    Sent: 'sent'
} as const;
export type InvoiceActivityLogActionEnum = typeof InvoiceActivityLogActionEnum[keyof typeof InvoiceActivityLogActionEnum];


/**
 * Check if a given object implements the InvoiceActivityLog interface.
 */
export function instanceOfInvoiceActivityLog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "invoiceId" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function InvoiceActivityLogFromJSON(json: any): InvoiceActivityLog {
    return InvoiceActivityLogFromJSONTyped(json, false);
}

export function InvoiceActivityLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceActivityLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceId': json['invoice_id'],
        'date': (new Date(json['date'])),
        'action': json['action'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'recipient': !exists(json, 'recipient') ? undefined : json['recipient'],
    };
}

export function InvoiceActivityLogToJSON(value?: InvoiceActivityLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoice_id': value.invoiceId,
        'date': (value.date.toISOString()),
        'action': value.action,
        'user': UserToJSON(value.user),
        'recipient': value.recipient,
    };
}

