import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

interface ToasterSettings {
    duration?: number;
    cssClass?: string;
    position?: "top" | "bottom" | "middle";
    color?: string;
    showCloseButton?: boolean;
}

@Injectable({
    providedIn: "root",
})
export class ToastService {
    constructor(
        private toast: ToastController,
        private translateService: TranslateService
    ) {}

    public showInformationSavedMessage(): void {
        this.showToastMessage("SHARED.INFORMATION_SAVED", {
            color: "success",
            position: "top",
            showCloseButton: true,
            duration: 2500,
        });
    }

    public showGeneralErrorMessage(): void {
        this.showToastMessage("TOAST.ERROR", {
            color: "danger",
            position: "top",
            showCloseButton: true,
            duration: 10000,
        });
    }

    public async showToastMessage(
        message: string,
        settings = {} as ToasterSettings,
        interpolationParams = {} as Object
    ): Promise<void> {
        this.translateService
            .get(message, interpolationParams)
            .subscribe(async (data) => {
                const toast = await this.toast.create({
                    message: data,
                    duration: settings.duration ? settings.duration : 2500,
                    cssClass: settings.cssClass ? settings.cssClass : "txt-center",
                    position: settings.position ? settings.position : "bottom",
                    color: settings.color ? settings.color : "",
                });

                await toast.present();
            })
            .unsubscribe();
    }
}
