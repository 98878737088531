/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMe200ResponseSubscription
 */
export interface GetMe200ResponseSubscription {
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseSubscription
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseSubscription
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200ResponseSubscription
     */
    missingPaymentMethod: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMe200ResponseSubscription
     */
    paymentLink: string;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseSubscription
     */
    activeUsers: number;
    /**
     * 
     * @type {number}
     * @memberof GetMe200ResponseSubscription
     */
    totalSeats: number;
}

/**
 * Check if a given object implements the GetMe200ResponseSubscription interface.
 */
export function instanceOfGetMe200ResponseSubscription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "missingPaymentMethod" in value;
    isInstance = isInstance && "paymentLink" in value;
    isInstance = isInstance && "activeUsers" in value;
    isInstance = isInstance && "totalSeats" in value;

    return isInstance;
}

export function GetMe200ResponseSubscriptionFromJSON(json: any): GetMe200ResponseSubscription {
    return GetMe200ResponseSubscriptionFromJSONTyped(json, false);
}

export function GetMe200ResponseSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMe200ResponseSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isActive': json['is_active'],
        'missingPaymentMethod': json['missing_payment_method'],
        'paymentLink': json['payment_link'],
        'activeUsers': json['active_users'],
        'totalSeats': json['total_seats'],
    };
}

export function GetMe200ResponseSubscriptionToJSON(value?: GetMe200ResponseSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'is_active': value.isActive,
        'missing_payment_method': value.missingPaymentMethod,
        'payment_link': value.paymentLink,
        'active_users': value.activeUsers,
        'total_seats': value.totalSeats,
    };
}

