/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceHistory
 */
export interface PriceHistory {
    /**
     * 
     * @type {string}
     * @memberof PriceHistory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PriceHistory
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PriceHistory
     */
    price: number;
    /**
     * 
     * @type {Date}
     * @memberof PriceHistory
     */
    readonly latestUpdate: Date;
    /**
     * 
     * @type {string}
     * @memberof PriceHistory
     */
    vendorName: string;
    /**
     * 
     * @type {string}
     * @memberof PriceHistory
     */
    vendorImage: string;
    /**
     * 
     * @type {string}
     * @memberof PriceHistory
     */
    type: PriceHistoryTypeEnum;
    /**
     * It could be Expense for expense line or Vendor product itself
     * @type {string}
     * @memberof PriceHistory
     */
    parentId: string | null;
}


/**
 * @export
 */
export const PriceHistoryTypeEnum = {
    ExpenseLine: 'expense_line',
    VendorProduct: 'vendor_product'
} as const;
export type PriceHistoryTypeEnum = typeof PriceHistoryTypeEnum[keyof typeof PriceHistoryTypeEnum];


/**
 * Check if a given object implements the PriceHistory interface.
 */
export function instanceOfPriceHistory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "latestUpdate" in value;
    isInstance = isInstance && "vendorName" in value;
    isInstance = isInstance && "vendorImage" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "parentId" in value;

    return isInstance;
}

export function PriceHistoryFromJSON(json: any): PriceHistory {
    return PriceHistoryFromJSONTyped(json, false);
}

export function PriceHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'price': json['price'],
        'latestUpdate': (new Date(json['latest_update'])),
        'vendorName': json['vendor_name'],
        'vendorImage': json['vendor_image'],
        'type': json['type'],
        'parentId': json['parent_id'],
    };
}

export function PriceHistoryToJSON(value?: PriceHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'price': value.price,
        'vendor_name': value.vendorName,
        'vendor_image': value.vendorImage,
        'type': value.type,
        'parent_id': value.parentId,
    };
}

