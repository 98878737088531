/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseFile } from './BaseFile';
import {
    BaseFileFromJSON,
    BaseFileFromJSONTyped,
    BaseFileToJSON,
} from './BaseFile';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Vendor } from './Vendor';
import {
    VendorFromJSON,
    VendorFromJSONTyped,
    VendorToJSON,
} from './Vendor';

/**
 * 
 * @export
 * @interface Expense
 */
export interface Expense {
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    readonly companyId: string;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    readonly createdById?: string;
    /**
     * 
     * @type {User}
     * @memberof Expense
     */
    readonly createdBy?: User;
    /**
     * Only changeable if the expense is manually created
     * @type {string}
     * @memberof Expense
     */
    vendorId?: string;
    /**
     * 
     * @type {Vendor}
     * @memberof Expense
     */
    readonly vendor?: Vendor;
    /**
     * 
     * @type {Array<BaseFile>}
     * @memberof Expense
     */
    expenseFiles?: Array<BaseFile>;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    projectId?: string;
    /**
     * 
     * @type {Project}
     * @memberof Expense
     */
    readonly project?: Project;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    currencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    activityId?: string;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    readonly rogerId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Expense
     */
    deliveryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Expense
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    readonly shortText?: string;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    supplierInvoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    readonly erpId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Expense
     */
    readonly orderNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    readonly fileReference?: string;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    status: ExpenseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Expense
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Expense
     */
    readonly isImported: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Expense
     */
    readonly sentToEmail: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Expense
     */
    readonly isInvoiced: Date;
    /**
     * 
     * @type {Date}
     * @memberof Expense
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Expense
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Expense
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Expense
     */
    readonly totalBuyingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Expense
     */
    readonly totalSellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Expense
     */
    readonly profitPercentage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Expense
     */
    readonly isLocked?: boolean;
}


/**
 * @export
 */
export const ExpenseStatusEnum = {
    NotHandled: 'not_handled',
    ExpiredSubscription: 'expired_subscription',
    Approved: 'approved'
} as const;
export type ExpenseStatusEnum = typeof ExpenseStatusEnum[keyof typeof ExpenseStatusEnum];


/**
 * Check if a given object implements the Expense interface.
 */
export function instanceOfExpense(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "isImported" in value;
    isInstance = isInstance && "sentToEmail" in value;
    isInstance = isInstance && "isInvoiced" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function ExpenseFromJSON(json: any): Expense {
    return ExpenseFromJSONTyped(json, false);
}

export function ExpenseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Expense {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['company_id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'createdBy': !exists(json, 'created_by') ? undefined : UserFromJSON(json['created_by']),
        'vendorId': !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
        'vendor': !exists(json, 'vendor') ? undefined : VendorFromJSON(json['vendor']),
        'expenseFiles': !exists(json, 'expense_files') ? undefined : ((json['expense_files'] as Array<any>).map(BaseFileFromJSON)),
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'currencyId': !exists(json, 'currency_id') ? undefined : json['currency_id'],
        'activityId': !exists(json, 'activity_id') ? undefined : json['activity_id'],
        'rogerId': !exists(json, 'roger_id') ? undefined : json['roger_id'],
        'deliveryDate': !exists(json, 'delivery_date') ? undefined : (new Date(json['delivery_date'])),
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'shortText': !exists(json, 'short_text') ? undefined : json['short_text'],
        'supplierInvoiceNumber': !exists(json, 'supplier_invoice_number') ? undefined : json['supplier_invoice_number'],
        'erpId': !exists(json, 'erp_id') ? undefined : json['erp_id'],
        'orderNumber': !exists(json, 'order_number') ? undefined : json['order_number'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fileReference': !exists(json, 'file_reference') ? undefined : json['file_reference'],
        'status': json['status'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'isImported': json['is_imported'],
        'sentToEmail': json['sent_to_email'],
        'isInvoiced': (new Date(json['is_invoiced'])),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'totalBuyingPrice': !exists(json, 'total_buying_price') ? undefined : json['total_buying_price'],
        'totalSellingPrice': !exists(json, 'total_selling_price') ? undefined : json['total_selling_price'],
        'profitPercentage': !exists(json, 'profit_percentage') ? undefined : json['profit_percentage'],
        'isLocked': !exists(json, 'is_locked') ? undefined : json['is_locked'],
    };
}

export function ExpenseToJSON(value?: Expense | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vendor_id': value.vendorId,
        'expense_files': value.expenseFiles === undefined ? undefined : ((value.expenseFiles as Array<any>).map(BaseFileToJSON)),
        'project_id': value.projectId,
        'currency_id': value.currencyId,
        'activity_id': value.activityId,
        'delivery_date': value.deliveryDate === undefined ? undefined : (value.deliveryDate.toISOString()),
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'supplier_invoice_number': value.supplierInvoiceNumber,
        'reference': value.reference,
        'description': value.description,
        'status': value.status,
        'comment': value.comment,
    };
}

