/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentTermType } from './PaymentTermType';
import {
    PaymentTermTypeFromJSON,
    PaymentTermTypeFromJSONTyped,
    PaymentTermTypeToJSON,
} from './PaymentTermType';

/**
 * 
 * @export
 * @interface PaymentTerm
 */
export interface PaymentTerm {
    /**
     * 
     * @type {string}
     * @memberof PaymentTerm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentTerm
     */
    paymentTermTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentTerm
     */
    createdById?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentTerm
     */
    daysOfCredit?: number;
    /**
     * 
     * @type {PaymentTermType}
     * @memberof PaymentTerm
     */
    paymentTermType?: PaymentTermType;
    /**
     * 
     * @type {Date}
     * @memberof PaymentTerm
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentTerm
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof PaymentTerm
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the PaymentTerm interface.
 */
export function instanceOfPaymentTerm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentTermFromJSON(json: any): PaymentTerm {
    return PaymentTermFromJSONTyped(json, false);
}

export function PaymentTermFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTerm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'paymentTermTypeId': !exists(json, 'payment_term_type_id') ? undefined : json['payment_term_type_id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'daysOfCredit': !exists(json, 'days_of_credit') ? undefined : json['days_of_credit'],
        'paymentTermType': !exists(json, 'payment_term_type') ? undefined : PaymentTermTypeFromJSON(json['payment_term_type']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function PaymentTermToJSON(value?: PaymentTerm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'payment_term_type_id': value.paymentTermTypeId,
        'created_by_id': value.createdById,
        'days_of_credit': value.daysOfCredit,
        'payment_term_type': PaymentTermTypeToJSON(value.paymentTermType),
    };
}

