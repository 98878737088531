import { Language } from "src/app/services/response-models/user.response.model";

export class SetAppLanguage {
    static readonly type = "[APP] Set selected lang";

    constructor(public payload: string) {}
}

export class UserLanguageChanged {
    static readonly type = "[APP] User lang changed";

    constructor(public payload: Language) {}
}
