import {
    Company,
    CompanySettings,
    StockLocation,
} from "src/app/services/response-models/companies.response.model";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
    ClockingRecord,
    DailyHours,
    Language,
    User,
} from "src/app/services/response-models/user.response.model";
import { Pagination } from "src/app/services/response-models/projects.response.model";
import { SetUsername } from "./actions/set-username.action";
import { SetCompanySettings } from "./actions/set-settings.action";
import { SetMyFormsHours, SetUserDailyHours, SetUserHours } from "./actions/set-user-hours.action";
import { SetUser, SetUserLocale } from "./actions/set-user.action";
import { SetUserCheckIn, SetUserClockingRecords } from "./actions/set-user-check-in.action";
import { SetAppLanguage } from "./actions/set-lang.actions";
import { SetApiKey } from "./actions/set-api-key.action";
import { Form } from "src/app/services/response-models/form.response.model";
import { Injectable } from "@angular/core";
import { SetCurrency } from "./actions/set-currency.action";
import { SetCurrencies } from "./actions/set-currencies.action";
import { CompanySetting } from "src/app/services/response-models/company-settings-response.model";
import { LoadCompanySettings } from "./actions/set-company-settings.action";
import { SetUserCompany } from "./actions/set-user-company.action";
import { SetMenuVisibility } from "./actions/set-menu-visibility.action";
import {
    ClearStockLocations,
    SetStockLocations,
    SetStockLocationsPagination,
} from "./actions/set-stock-locations.action";
import { Currency } from "src/app/services/response-models/currency.model";
import { CustomProperties } from "src/app/services/models/misc.model";
import { SetLanguages } from "./actions/set-languages.action";
import { Country } from "src/app/services/response-models/countries.response.model";
import { SetCountries } from "./actions/set-countries.action";

export interface AppStateModel {
    username: string;
    user: User;
    userLocale: string;
    isUserCheckedIn: boolean;
    clockingRecords: Array<ClockingRecord>;
    apikey: string;
    hours: string;
    myFormsHours: Array<Form>;
    selectedFormId: string;
    companySettings: CompanySettings;
    language: string;
    currency: Currency;
    currencies: Currency[];
    cpSettings: Array<CompanySetting>;
    company: Company;
    menuVisibility: boolean;
    stock_locations: Array<StockLocation>;
    stock_locations_pagination: Pagination;
    custom_field_attributes: CustomProperties[];
    languages: Language[];
    countries: Country[];
    hasMenu: boolean;
    viewOnlyFeature: boolean;
    dailyHours: Array<DailyHours>;
}

@State<AppStateModel>({
    name: "app",
    defaults: {
        hours: "",
        user: null,
        userLocale: "",
        username: "",
        apikey: "",
        myFormsHours: [],
        selectedFormId: "",
        companySettings: null,
        isUserCheckedIn: false,
        clockingRecords: [],
        language: "",
        currency: null,
        currencies: [],
        cpSettings: [],
        company: null,
        menuVisibility: false,
        stock_locations_pagination: null,
        stock_locations: [],
        custom_field_attributes: [],
        languages: [],
        countries: [],
        hasMenu: false,
        viewOnlyFeature: false,
        dailyHours: [],
    },
})
@Injectable()
export class AppState {
    @Selector()
    static getUserCompany(state: AppStateModel) {
        return state.company;
    }

    @Selector()
    static getCompanySettings(state: AppStateModel) {
        return state.companySettings;
    }

    @Selector()
    static getUserName(state: AppStateModel) {
        return state.username;
    }

    @Selector()
    static getUserApiKey(state: AppStateModel) {
        return state.apikey;
    }

    @Selector()
    static getUserHours(state: AppStateModel) {
        return state.hours;
    }

    @Selector()
    static getUserDailyHours(state: AppStateModel) {
        return state.dailyHours;
    }

    @Selector()
    static getUser(state: AppStateModel) {
        return state.user;
    }

    @Selector()
    static getUserLocale(state: AppStateModel) {
        return state.userLocale;
    }

    @Selector()
    static getIsUserCheckedIn(state: AppStateModel) {
        return state.isUserCheckedIn;
    }

    @Selector()
    static getLang(state: AppStateModel) {
        return state.language;
    }

    @Selector()
    static getCPSettings(state: AppStateModel) {
        return state.cpSettings;
    }

    @Selector()
    static getMenuVisibility(state: AppStateModel) {
        return state.menuVisibility;
    }

    @Selector()
    static getStockLocations(state: AppStateModel) {
        return state.stock_locations;
    }

    @Selector()
    static getStockLocationsPagination(state: AppStateModel) {
        return state.stock_locations_pagination;
    }

    @Selector()
    static getCountries(state: AppStateModel) {
        return state.countries;
    }

    @Selector()
    static getMenuStatus(state: AppStateModel) {
        return state.hasMenu;
    }

    @Selector()
    static getUserClockingRecords(state: AppStateModel) {
        return state.clockingRecords;
    }

    @Action(SetUsername)
    SetUsername(ctx: StateContext<AppStateModel>, { payload }: SetUsername) {
        ctx.setState({ ...ctx.getState(), username: payload });
    }

    @Action(SetCompanySettings)
    setCompanySettings(ctx: StateContext<AppStateModel>, { payload }: SetCompanySettings) {
        ctx.setState({ ...ctx.getState(), companySettings: payload });
    }

    @Action(SetUserHours)
    setUserHours(ctx: StateContext<AppStateModel>, { payload }: SetUserHours) {
        ctx.setState({ ...ctx.getState(), hours: payload });
    }

    @Action(SetUserDailyHours)
    setUserDailyHours(ctx: StateContext<AppStateModel>, { payload }: SetUserDailyHours) {
        ctx.setState({ ...ctx.getState(), dailyHours: payload });
    }

    @Action(SetUser)
    setUser(ctx: StateContext<AppStateModel>, { payload }: SetUser) {
        ctx.setState({ ...ctx.getState(), user: payload });
    }

    @Action(SetUserLocale)
    setUserLocale(ctx: StateContext<AppStateModel>, { payload }: SetUserLocale) {
        ctx.setState({ ...ctx.getState(), userLocale: payload });
    }

    @Action(SetUserCheckIn)
    setCheckIn(ctx: StateContext<AppStateModel>, { payload }: SetUserCheckIn) {
        ctx.setState({ ...ctx.getState(), isUserCheckedIn: payload });
    }

    @Action(SetUserClockingRecords)
    setClockingRecords(ctx: StateContext<AppStateModel>, { payload }: SetUserClockingRecords) {
        ctx.setState({ ...ctx.getState(), clockingRecords: payload });
    }

    @Action(SetMyFormsHours)
    setMyFormHours(ctx: StateContext<AppStateModel>, { payload }: SetMyFormsHours) {
        ctx.setState({ ...ctx.getState(), myFormsHours: payload });
    }

    @Action(SetAppLanguage)
    setlang(ctx: StateContext<AppStateModel>, { payload }: SetAppLanguage) {
        ctx.setState({ ...ctx.getState(), language: payload });
    }

    @Action(SetApiKey)
    setApiKey(ctx: StateContext<AppStateModel>, { payload }: SetApiKey) {
        ctx.setState({ ...ctx.getState(), apikey: payload });
    }

    @Action(SetCurrency)
    setCurrency(ctx: StateContext<AppStateModel>, { payload }: SetCurrency) {
        ctx.setState({ ...ctx.getState(), currency: payload });
    }

    @Action(SetCurrencies)
    setCurrencies(ctx: StateContext<AppStateModel>, { payload }: SetCurrencies) {
        ctx.setState({ ...ctx.getState(), currencies: payload });
    }

    @Action(LoadCompanySettings)
    loadCompanySettings(ctx: StateContext<AppStateModel>, { payload }: LoadCompanySettings) {
        ctx.setState({ ...ctx.getState(), cpSettings: payload });
    }

    @Action(SetUserCompany)
    setUserCompany(ctx: StateContext<AppStateModel>, { payload }: SetUserCompany) {
        ctx.setState({ ...ctx.getState(), company: payload });
    }

    @Action(SetMenuVisibility)
    setMenuVisibility(ctx: StateContext<AppStateModel>, { payload }: SetMenuVisibility) {
        ctx.setState({ ...ctx.getState(), menuVisibility: payload });
    }

    @Action(SetStockLocations)
    setLocaitionStocks(ctx: StateContext<AppStateModel>, { payload }: SetStockLocations) {
        const state = ctx.getState();

        ctx.patchState({
            stock_locations: [...state.stock_locations, ...payload],
        });
    }

    @Action(SetStockLocationsPagination)
    setStockLocationsPagination(
        ctx: StateContext<AppStateModel>,
        { payload }: SetStockLocationsPagination
    ) {
        ctx.setState({ ...ctx.getState(), stock_locations_pagination: payload });
    }

    @Action(ClearStockLocations)
    clear(ctx: StateContext<AppStateModel>) {
        ctx.setState({ ...ctx.getState(), stock_locations: [] });
    }

    @Action(SetLanguages)
    setLangs(ctx: StateContext<AppStateModel>, { payload }: SetLanguages) {
        ctx.setState({ ...ctx.getState(), languages: payload });
    }

    @Action(SetCountries)
    setCountries(ctx: StateContext<AppStateModel>, { payload }: SetCountries) {
        ctx.setState({ ...ctx.getState(), countries: payload });
    }
}
