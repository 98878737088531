import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngxs/store";
import {
    LoadProjectStatuses,
    LoadProjectStatusesPagination,
} from "src/store/project-statuses/actions/set-project-statuses.action";
import {
    ProjectStatus,
    ProjectStatusResponseModel,
} from "src/app/services/response-models/project-statuses.reponse.model";

@Injectable({
    providedIn: "root",
})
export class ProjectStatusesService {
    private readonly projectStatusesUrl = `${environment.apiBaseUrl}project_statuses`;
    constructor(
        private http: HttpClient,
        private store: Store
    ) {}

    public async getProjectStatusesByFilter(
        page = 1,
        q = "",
        includes = "",
        sort = "",
        direction = "",
        limit = "30"
    ): Promise<ProjectStatus[]> {
        const headers = {
            params: {
                page: page.toString(),
                include: includes || "project_status_types",
                q,
                sort,
                direction,
                limit,
            },
        };

        const { data, pagination } = await this.http
            .get<ProjectStatusResponseModel>(this.projectStatusesUrl, headers)
            .toPromise();

        this.store.dispatch(new LoadProjectStatuses(data));
        this.store.dispatch(new LoadProjectStatusesPagination(pagination));

        return data;
    }

    public async getProjectStatuses(): Promise<ProjectStatusResponseModel> {
        const headers = {
            params: {
                include: "project_status_types",
            },
        };
        const response = await this.http
            .get<ProjectStatusResponseModel>(this.projectStatusesUrl, headers)
            .toPromise();

        this.store.dispatch(new LoadProjectStatuses(response.data));

        return response;
    }
}
