import { State, Selector, Action, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { Pagination } from "src/app/services/response-models/projects.response.model";
import { TimeEntryTypes } from "src/app/services/response-models/time-entries.response.model";
import { LoadTimeEntryTypesPagination } from "./actions/pagination.action";
import { SetTimeEntryTypes, ClearTimeEntryTypes } from "./actions/time-entry-types.action";

export interface TimeEntryTypesStateModel {
    timeEntryTypes: TimeEntryTypes[];
    pagination: Pagination;
}
@State<TimeEntryTypesStateModel>({
    name: "timeEntryType",
    defaults: {
        timeEntryTypes: [],
        pagination: null,
    },
})
@Injectable()
export class TimeEntryTypesState {
    @Selector()
    static getTimeEntryTypes(state: TimeEntryTypesStateModel) {
        return state.timeEntryTypes;
    }

    @Selector()
    static getPagination(state: TimeEntryTypesStateModel) {
        return state.pagination;
    }

    @Action(SetTimeEntryTypes)
    setTimeEntryTypes(ctx: StateContext<TimeEntryTypesStateModel>, { payload }: SetTimeEntryTypes) {
        ctx.setState({ ...ctx.getState(), timeEntryTypes: payload });
    }

    @Action(ClearTimeEntryTypes)
    clear(ctx: StateContext<TimeEntryTypesStateModel>) {
        ctx.setState({ ...ctx.getState(), timeEntryTypes: [] });
    }

    @Action(LoadTimeEntryTypesPagination)
    setPagination(
        ctx: StateContext<TimeEntryTypesStateModel>,
        { payload }: LoadTimeEntryTypesPagination
    ) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }
}
