import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { ConfigState } from "src/store/config/config.state";
import { AppState } from "src/store/app/app.state";
import { environment } from "src/environments/environment";
import {
    Configuration,
    DefaultApi,
    DefaultApiInterface,
    MobileAppApi,
    MobileAppApiInterface,
} from "@apacta/sdk";

@Injectable({
    providedIn: "root",
})
export class ApactaApiSdkService {
    private sdkConfiguration: Configuration = null;

    constructor(private store: Store) {}

    public configuration(): DefaultApiInterface {
        if (this.sdkConfiguration === null) {
            this.sdkConfiguration = this.getConfiguration();
        }

        return new DefaultApi(this.sdkConfiguration);
    }

    public mobileConfiguration(): MobileAppApiInterface {
        if (this.sdkConfiguration === null) {
            this.sdkConfiguration = this.getConfiguration();
        }

        return new MobileAppApi(this.sdkConfiguration);
    }

    private getConfiguration(): Configuration {
        const config = this.store.selectSnapshot(ConfigState.getConfig);
        const user = this.store.selectSnapshot(AppState.getUser);

        return new Configuration({
            apiKey: user.api_key,
            basePath: environment.appUrl,
            headers: {
                Accept: "application/json",
                "X-Apacta-App-Version": config ? config.osVersion.toString() : "",
                "X-Apacta-App-Build": config ? config.osVersion.toString() : "",
                "X-Apacta-App-Details": config ? config.model : "",
            },
        });
    }
}
