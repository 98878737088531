/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    readonly createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    readonly modifiedById?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    readonly companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    userId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    taskId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    type?: CardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    status?: CardStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    productsAdded?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Card
     */
    formTemplates?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    listIndex: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Card
     */
    date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Card
     */
    startTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    estimate?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Card
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Card
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Card
     */
    readonly deleted?: Date | null;
}


/**
 * @export
 */
export const CardTypeEnum = {
    Task: 'task'
} as const;
export type CardTypeEnum = typeof CardTypeEnum[keyof typeof CardTypeEnum];

/**
 * @export
 */
export const CardStatusEnum = {
    ToDo: 'to_do',
    Doing: 'doing',
    Done: 'done'
} as const;
export type CardStatusEnum = typeof CardStatusEnum[keyof typeof CardStatusEnum];


/**
 * Check if a given object implements the Card interface.
 */
export function instanceOfCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "taskId" in value;
    isInstance = isInstance && "listIndex" in value;

    return isInstance;
}

export function CardFromJSON(json: any): Card {
    return CardFromJSONTyped(json, false);
}

export function CardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Card {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'userId': json['user_id'],
        'taskId': json['task_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'productsAdded': !exists(json, 'products_added') ? undefined : json['products_added'],
        'formTemplates': !exists(json, 'form_templates') ? undefined : json['form_templates'],
        'listIndex': json['list_index'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'startTime': !exists(json, 'start_time') ? undefined : (json['start_time'] === null ? null : new Date(json['start_time'])),
        'estimate': !exists(json, 'estimate') ? undefined : json['estimate'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function CardToJSON(value?: Card | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'task_id': value.taskId,
        'type': value.type,
        'status': value.status,
        'products_added': value.productsAdded,
        'form_templates': value.formTemplates,
        'list_index': value.listIndex,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'start_time': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
        'estimate': value.estimate,
    };
}

