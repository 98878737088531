/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    hexCode: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Activity
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Activity
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Activity
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the Activity interface.
 */
export function instanceOfActivity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "hexCode" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function ActivityFromJSON(json: any): Activity {
    return ActivityFromJSONTyped(json, false);
}

export function ActivityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Activity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'companyId': json['company_id'],
        'hexCode': json['hex_code'],
        'name': json['name'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function ActivityToJSON(value?: Activity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by_id': value.createdById,
        'company_id': value.companyId,
        'hex_code': value.hexCode,
        'name': value.name,
    };
}

