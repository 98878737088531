/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KpiCardProductsIndividualEntry } from './KpiCardProductsIndividualEntry';
import {
    KpiCardProductsIndividualEntryFromJSON,
    KpiCardProductsIndividualEntryFromJSONTyped,
    KpiCardProductsIndividualEntryToJSON,
} from './KpiCardProductsIndividualEntry';

/**
 * 
 * @export
 * @interface ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
 */
export interface ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly type: ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly title: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly quantityEstimated?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly quantityActual: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly buyingPricePerUnitEstimated?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly buyingPriceTotalEstimated?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly buyingPricePerUnitActual: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly buyingPriceTotalActual: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly sellingPricePerUnitActual: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly sellingPriceTotalActual: number;
    /**
     * 
     * @type {Array<KpiCardProductsIndividualEntry>}
     * @memberof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner
     */
    readonly entries?: Array<KpiCardProductsIndividualEntry>;
}


/**
 * @export
 */
export const ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInnerTypeEnum = {
    Product: 'product',
    ExpenseLine: 'expense_line'
} as const;
export type ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInnerTypeEnum = typeof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInnerTypeEnum[keyof typeof ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInnerTypeEnum];


/**
 * Check if a given object implements the ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner interface.
 */
export function instanceOfProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "quantityActual" in value;
    isInstance = isInstance && "buyingPricePerUnitActual" in value;
    isInstance = isInstance && "buyingPriceTotalActual" in value;
    isInstance = isInstance && "sellingPricePerUnitActual" in value;
    isInstance = isInstance && "sellingPriceTotalActual" in value;

    return isInstance;
}

export function ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInnerFromJSON(json: any): ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner {
    return ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInnerFromJSONTyped(json, false);
}

export function ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'id': json['id'],
        'title': json['title'],
        'quantityEstimated': !exists(json, 'quantity_estimated') ? undefined : json['quantity_estimated'],
        'quantityActual': json['quantity_actual'],
        'buyingPricePerUnitEstimated': !exists(json, 'buying_price_per_unit_estimated') ? undefined : json['buying_price_per_unit_estimated'],
        'buyingPriceTotalEstimated': !exists(json, 'buying_price_total_estimated') ? undefined : json['buying_price_total_estimated'],
        'buyingPricePerUnitActual': json['buying_price_per_unit_actual'],
        'buyingPriceTotalActual': json['buying_price_total_actual'],
        'sellingPricePerUnitActual': json['selling_price_per_unit_actual'],
        'sellingPriceTotalActual': json['selling_price_total_actual'],
        'entries': !exists(json, 'entries') ? undefined : ((json['entries'] as Array<any>).map(KpiCardProductsIndividualEntryFromJSON)),
    };
}

export function ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInnerToJSON(value?: ProjectGetKpiCardProductsSubpageProductsTab200ResponseDataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

