import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Store } from "@ngxs/store";
import { Navigate } from "src/store/router/router.actions";
import { LoadingComponent } from "src/app/shared/loading/loading.component";
import { LoginCredentials } from "src/app/login/models/login-credentials.model";
import { SetActiveAppListProjectsTabAction } from "src/store/projects/actions/set-active-app-list-projects-tab.action";
import { environment } from "src/environments/environment";
import { Capacitor } from "@capacitor/core";

@Component({
    selector: "app-mobile-login",
    templateUrl: "./mobile-login.component.html",
    styleUrls: ["./mobile-login.component.scss"],
})
export class MobileLoginComponent implements OnInit {
    @Output() onSave = new EventEmitter<LoginCredentials>();
    @ViewChild("loading") loading: LoadingComponent;

    public loginForm = this.fb.group({
        email: "",
        password: "",
        rememberMe: true,
    });

    private readonly forgotPassword: string = `${environment.v3ControlPanelUrl}/login/reset`;

    constructor(
        private fb: FormBuilder,
        private store: Store
    ) {}

    ngOnInit(): void {
        this.store.dispatch(new SetActiveAppListProjectsTabAction(1));
    }

    public authenticateUser(): void {
        this.onSave.emit(this.loginForm.value);
    }

    public navigateToNewResetPassword(): void {
        this.store.dispatch(new Navigate([this.forgotPassword]));
    }

    public navigateToNewCompany(): void {
        this.store.dispatch(
            new Navigate([
                `https://apacta.com/opret-test?utm_source=mobile_app&utm_campaign=apacta_mobile_signup&utm_medium=${Capacitor.getPlatform()}`,
            ])
        );
    }
}
