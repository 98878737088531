import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class LocalStorageService {
    public write(key, value) {
        localStorage.setItem(key, value);
    }

    public read(key: string) {
        return localStorage.getItem(key);
    }

    public writeObject(key, value) {
        const string = JSON.stringify(value);
        // if the value is undefined after the stringify
        // then we don't want to save it
        if (typeof string !== "undefined") {
            localStorage.setItem(key, string);
        }
    }

    public readObject(key: string) {
        const object = localStorage.getItem(key);
        // parse only if the value is not null
        return object !== null ? JSON.parse(object) : null;
    }

    public removeItem(key: string) {
        localStorage.removeItem(key);
    }

    public clear() {
        localStorage.clear();
    }
}
