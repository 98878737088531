/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditSubscriptionRequest
 */
export interface EditSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof EditSubscriptionRequest
     */
    seats?: number;
    /**
     * 
     * @type {number}
     * @memberof EditSubscriptionRequest
     */
    viewOnlySeats?: number | null;
}

/**
 * Check if a given object implements the EditSubscriptionRequest interface.
 */
export function instanceOfEditSubscriptionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EditSubscriptionRequestFromJSON(json: any): EditSubscriptionRequest {
    return EditSubscriptionRequestFromJSONTyped(json, false);
}

export function EditSubscriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditSubscriptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seats': !exists(json, 'seats') ? undefined : json['seats'],
        'viewOnlySeats': !exists(json, 'view_only_seats') ? undefined : json['view_only_seats'],
    };
}

export function EditSubscriptionRequestToJSON(value?: EditSubscriptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seats': value.seats,
        'view_only_seats': value.viewOnlySeats,
    };
}

