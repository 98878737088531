/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectGetKpiCardHoursPreCalculationSubPage200ResponseData } from './ProjectGetKpiCardHoursPreCalculationSubPage200ResponseData';
import {
    ProjectGetKpiCardHoursPreCalculationSubPage200ResponseDataFromJSON,
    ProjectGetKpiCardHoursPreCalculationSubPage200ResponseDataFromJSONTyped,
    ProjectGetKpiCardHoursPreCalculationSubPage200ResponseDataToJSON,
} from './ProjectGetKpiCardHoursPreCalculationSubPage200ResponseData';

/**
 * 
 * @export
 * @interface ProjectGetKpiCardHoursPreCalculationSubPage200Response
 */
export interface ProjectGetKpiCardHoursPreCalculationSubPage200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectGetKpiCardHoursPreCalculationSubPage200Response
     */
    success: boolean;
    /**
     * 
     * @type {ProjectGetKpiCardHoursPreCalculationSubPage200ResponseData}
     * @memberof ProjectGetKpiCardHoursPreCalculationSubPage200Response
     */
    data: ProjectGetKpiCardHoursPreCalculationSubPage200ResponseData;
}

/**
 * Check if a given object implements the ProjectGetKpiCardHoursPreCalculationSubPage200Response interface.
 */
export function instanceOfProjectGetKpiCardHoursPreCalculationSubPage200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ProjectGetKpiCardHoursPreCalculationSubPage200ResponseFromJSON(json: any): ProjectGetKpiCardHoursPreCalculationSubPage200Response {
    return ProjectGetKpiCardHoursPreCalculationSubPage200ResponseFromJSONTyped(json, false);
}

export function ProjectGetKpiCardHoursPreCalculationSubPage200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectGetKpiCardHoursPreCalculationSubPage200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': ProjectGetKpiCardHoursPreCalculationSubPage200ResponseDataFromJSON(json['data']),
    };
}

export function ProjectGetKpiCardHoursPreCalculationSubPage200ResponseToJSON(value?: ProjectGetKpiCardHoursPreCalculationSubPage200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': ProjectGetKpiCardHoursPreCalculationSubPage200ResponseDataToJSON(value.data),
    };
}

