/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KpiCardsIndexResponseContributionMargins
 */
export interface KpiCardsIndexResponseContributionMargins {
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponseContributionMargins
     */
    contributionMargin: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponseContributionMargins
     */
    contributionMarginPerHour: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponseContributionMargins
     */
    contributionRatio?: number;
}

/**
 * Check if a given object implements the KpiCardsIndexResponseContributionMargins interface.
 */
export function instanceOfKpiCardsIndexResponseContributionMargins(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contributionMargin" in value;
    isInstance = isInstance && "contributionMarginPerHour" in value;

    return isInstance;
}

export function KpiCardsIndexResponseContributionMarginsFromJSON(json: any): KpiCardsIndexResponseContributionMargins {
    return KpiCardsIndexResponseContributionMarginsFromJSONTyped(json, false);
}

export function KpiCardsIndexResponseContributionMarginsFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiCardsIndexResponseContributionMargins {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contributionMargin': json['contribution_margin'],
        'contributionMarginPerHour': json['contribution_margin_per_hour'],
        'contributionRatio': !exists(json, 'contribution_ratio') ? undefined : json['contribution_ratio'],
    };
}

export function KpiCardsIndexResponseContributionMarginsToJSON(value?: KpiCardsIndexResponseContributionMargins | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contribution_margin': value.contributionMargin,
        'contribution_margin_per_hour': value.contributionMarginPerHour,
        'contribution_ratio': value.contributionRatio,
    };
}

