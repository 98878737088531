/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IWallpostCreate201ResponseData } from './IWallpostCreate201ResponseData';
import {
    IWallpostCreate201ResponseDataFromJSON,
    IWallpostCreate201ResponseDataFromJSONTyped,
    IWallpostCreate201ResponseDataToJSON,
} from './IWallpostCreate201ResponseData';

/**
 * 
 * @export
 * @interface EditCard200Response
 */
export interface EditCard200Response {
    /**
     * 
     * @type {boolean}
     * @memberof EditCard200Response
     */
    success: boolean;
    /**
     * 
     * @type {IWallpostCreate201ResponseData}
     * @memberof EditCard200Response
     */
    data: IWallpostCreate201ResponseData;
}

/**
 * Check if a given object implements the EditCard200Response interface.
 */
export function instanceOfEditCard200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function EditCard200ResponseFromJSON(json: any): EditCard200Response {
    return EditCard200ResponseFromJSONTyped(json, false);
}

export function EditCard200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditCard200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': IWallpostCreate201ResponseDataFromJSON(json['data']),
    };
}

export function EditCard200ResponseToJSON(value?: EditCard200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': IWallpostCreate201ResponseDataToJSON(value.data),
    };
}

