/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IGetWorkRelated200ResponseAllOfData
 */
export interface IGetWorkRelated200ResponseAllOfData {
    /**
     * Number of not approved forms
     * @type {number}
     * @memberof IGetWorkRelated200ResponseAllOfData
     */
    readonly notApprovedForms: number;
    /**
     * Number of Not planned tasks(without dates)
     * @type {number}
     * @memberof IGetWorkRelated200ResponseAllOfData
     */
    readonly notPlannedCards: number;
    /**
     * Number of Not assigned tasks for the current week (without users)
     * @type {number}
     * @memberof IGetWorkRelated200ResponseAllOfData
     */
    readonly notAssignedCards: number;
    /**
     * Number of total hours that are planned on the cards that are not assigned
     * @type {number}
     * @memberof IGetWorkRelated200ResponseAllOfData
     */
    readonly notAssignedTotalHours?: number;
}

/**
 * Check if a given object implements the IGetWorkRelated200ResponseAllOfData interface.
 */
export function instanceOfIGetWorkRelated200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "notApprovedForms" in value;
    isInstance = isInstance && "notPlannedCards" in value;
    isInstance = isInstance && "notAssignedCards" in value;

    return isInstance;
}

export function IGetWorkRelated200ResponseAllOfDataFromJSON(json: any): IGetWorkRelated200ResponseAllOfData {
    return IGetWorkRelated200ResponseAllOfDataFromJSONTyped(json, false);
}

export function IGetWorkRelated200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetWorkRelated200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notApprovedForms': json['not_approved_forms'],
        'notPlannedCards': json['not_planned_cards'],
        'notAssignedCards': json['not_assigned_cards'],
        'notAssignedTotalHours': !exists(json, 'not_assigned_total_hours') ? undefined : json['not_assigned_total_hours'],
    };
}

export function IGetWorkRelated200ResponseAllOfDataToJSON(value?: IGetWorkRelated200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

