import { Injectable } from "@angular/core";
import { IncludeType } from "src/app/shared/enums/include-type.enum";

@Injectable({
    providedIn: "root",
})
export class UtilityService {
    public static getIncludes(includes: Array<IncludeType | IncludeType[]>): string {
        return includes
            .map((include) => (Array.isArray(include) ? include.join(".") : include))
            .join(",");
    }

    /**
     * If you have a base64 encoded file, use this to convert it to Blob
     * @param base64Data string
     * @param contentType string
     * @returns Blob
     */
    public static base64toBlob(base64Data, contentType?): Blob {
        contentType = contentType || "";
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }
}
