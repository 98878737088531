import { State, Selector, StateContext, Action } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { Pagination } from "src/app/services/response-models/pagination.response.model";
import { Activity } from "src/app/services/response-models/activity.response.model";
import { AddTag, RemoveTag, SetActivities, SetTags } from "./actions/set-activities.action";
import { ClearActivities } from "./actions/clear-activities.action";
import { SetActivitiesPagination } from "./actions/set-activities-pagination.action";

export interface ActivityStateModel {
    activities: Activity[];
    tags: Activity[];
    pagination: Pagination;
}

@State<ActivityStateModel>({
    name: "activities",
    defaults: {
        activities: [],
        tags: [],
        pagination: null,
    },
})
@Injectable()
export class ActivityState {
    @Selector()
    static getActivities(state: ActivityStateModel) {
        return state.activities;
    }

    @Selector()
    static getActivitiesPagination(state: ActivityStateModel) {
        return state.pagination;
    }

    @Selector()
    static getTags(state: ActivityStateModel) {
        return state.tags;
    }

    @Action(SetActivities)
    activities(ctx: StateContext<ActivityStateModel>, { payload }: SetActivities) {
        ctx.setState({ ...ctx.getState(), activities: payload });
    }

    @Action(ClearActivities)
    clear(ctx: StateContext<ActivityStateModel>) {
        ctx.setState({ ...ctx.getState(), activities: [] });
    }

    @Action(SetActivitiesPagination)
    pagination(ctx: StateContext<ActivityStateModel>, { payload }: SetActivitiesPagination) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }

    @Action(SetTags)
    setTags(ctx: StateContext<ActivityStateModel>, { payload }: SetTags) {
        ctx.setState({ ...ctx.getState(), tags: payload });
    }

    @Action(RemoveTag)
    removeTag(ctx: StateContext<ActivityStateModel>, { payload }: RemoveTag) {
        const tags = ctx.getState().tags.filter((tag) => tag.id !== payload.id);
        ctx.setState({ ...ctx.getState(), tags });
    }

    @Action(AddTag)
    addTag(ctx: StateContext<ActivityStateModel>, { payload }: AddTag) {
        const tags = ctx.getState().tags.filter((tag) => tag.id !== payload.id);
        tags.push(payload);
        ctx.setState({ ...ctx.getState(), tags });
    }
}
