/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontpageWeeklyConsumptionDTO } from './FrontpageWeeklyConsumptionDTO';
import {
    FrontpageWeeklyConsumptionDTOFromJSON,
    FrontpageWeeklyConsumptionDTOFromJSONTyped,
    FrontpageWeeklyConsumptionDTOToJSON,
} from './FrontpageWeeklyConsumptionDTO';

/**
 * 
 * @export
 * @interface IGetFrontpageInvoiced200ResponseDataWeekly
 */
export interface IGetFrontpageInvoiced200ResponseDataWeekly {
    /**
     * 
     * @type {Array<FrontpageWeeklyConsumptionDTO>}
     * @memberof IGetFrontpageInvoiced200ResponseDataWeekly
     */
    invoiced: Array<FrontpageWeeklyConsumptionDTO>;
    /**
     * 
     * @type {Array<FrontpageWeeklyConsumptionDTO>}
     * @memberof IGetFrontpageInvoiced200ResponseDataWeekly
     */
    notInvoiced: Array<FrontpageWeeklyConsumptionDTO>;
}

/**
 * Check if a given object implements the IGetFrontpageInvoiced200ResponseDataWeekly interface.
 */
export function instanceOfIGetFrontpageInvoiced200ResponseDataWeekly(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "invoiced" in value;
    isInstance = isInstance && "notInvoiced" in value;

    return isInstance;
}

export function IGetFrontpageInvoiced200ResponseDataWeeklyFromJSON(json: any): IGetFrontpageInvoiced200ResponseDataWeekly {
    return IGetFrontpageInvoiced200ResponseDataWeeklyFromJSONTyped(json, false);
}

export function IGetFrontpageInvoiced200ResponseDataWeeklyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetFrontpageInvoiced200ResponseDataWeekly {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiced': ((json['invoiced'] as Array<any>).map(FrontpageWeeklyConsumptionDTOFromJSON)),
        'notInvoiced': ((json['not_invoiced'] as Array<any>).map(FrontpageWeeklyConsumptionDTOFromJSON)),
    };
}

export function IGetFrontpageInvoiced200ResponseDataWeeklyToJSON(value?: IGetFrontpageInvoiced200ResponseDataWeekly | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiced': ((value.invoiced as Array<any>).map(FrontpageWeeklyConsumptionDTOToJSON)),
        'not_invoiced': ((value.notInvoiced as Array<any>).map(FrontpageWeeklyConsumptionDTOToJSON)),
    };
}

