import { PaginationDetails, Product } from "@apacta/sdk";

export class LoadExpenseLinesProducts {
    public static readonly type = "[Products] Load expense lines products ";

    constructor(public payload: Product[]) {}
}

export class LoadExpenseLinesPagination {
    public static readonly type = "[Products] Load expense lines products pagination";

    constructor(public payload: PaginationDetails) {}
}

export class ClearExpenseLinesProducts {
    public static readonly type = "[Products] Clear expense lines products";
}
