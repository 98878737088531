/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Activity } from './Activity';
import {
    ActivityFromJSON,
    ActivityFromJSONTyped,
    ActivityToJSON,
} from './Activity';
import type { ImageUrls } from './ImageUrls';
import {
    ImageUrlsFromJSON,
    ImageUrlsFromJSONTyped,
    ImageUrlsToJSON,
} from './ImageUrls';
import type { ProductBundleFile } from './ProductBundleFile';
import {
    ProductBundleFileFromJSON,
    ProductBundleFileFromJSONTyped,
    ProductBundleFileToJSON,
} from './ProductBundleFile';
import type { ProductBundleLine } from './ProductBundleLine';
import {
    ProductBundleLineFromJSON,
    ProductBundleLineFromJSONTyped,
    ProductBundleLineToJSON,
} from './ProductBundleLine';

/**
 * 
 * @export
 * @interface ProductBundle
 */
export interface ProductBundle {
    /**
     * 
     * @type {string}
     * @memberof ProductBundle
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundle
     */
    readonly companyId: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundle
     */
    activityId?: string;
    /**
     * 
     * @type {Activity}
     * @memberof ProductBundle
     */
    activity?: Activity;
    /**
     * 
     * @type {string}
     * @memberof ProductBundle
     */
    readonly createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundle
     */
    readonly modifiedById?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundle
     */
    fileId?: string;
    /**
     * 
     * @type {ProductBundleFile}
     * @memberof ProductBundle
     */
    file?: ProductBundleFile;
    /**
     * 
     * @type {string}
     * @memberof ProductBundle
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundle
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductBundle
     */
    salesPrice?: number;
    /**
     * 
     * @type {Array<ProductBundleLine>}
     * @memberof ProductBundle
     */
    productBundleLines?: Array<ProductBundleLine>;
    /**
     * 
     * @type {ImageUrls}
     * @memberof ProductBundle
     */
    imageUrls?: ImageUrls;
    /**
     * 
     * @type {Date}
     * @memberof ProductBundle
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductBundle
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof ProductBundle
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the ProductBundle interface.
 */
export function instanceOfProductBundle(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function ProductBundleFromJSON(json: any): ProductBundle {
    return ProductBundleFromJSONTyped(json, false);
}

export function ProductBundleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['company_id'],
        'activityId': !exists(json, 'activity_id') ? undefined : json['activity_id'],
        'activity': !exists(json, 'activity') ? undefined : ActivityFromJSON(json['activity']),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'fileId': !exists(json, 'file_id') ? undefined : json['file_id'],
        'file': !exists(json, 'file') ? undefined : ProductBundleFileFromJSON(json['file']),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'salesPrice': !exists(json, 'sales_price') ? undefined : json['sales_price'],
        'productBundleLines': !exists(json, 'product_bundle_lines') ? undefined : ((json['product_bundle_lines'] as Array<any>).map(ProductBundleLineFromJSON)),
        'imageUrls': !exists(json, 'image_urls') ? undefined : ImageUrlsFromJSON(json['image_urls']),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function ProductBundleToJSON(value?: ProductBundle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity_id': value.activityId,
        'activity': ActivityToJSON(value.activity),
        'file_id': value.fileId,
        'file': ProductBundleFileToJSON(value.file),
        'name': value.name,
        'description': value.description,
        'sales_price': value.salesPrice,
        'product_bundle_lines': value.productBundleLines === undefined ? undefined : ((value.productBundleLines as Array<any>).map(ProductBundleLineToJSON)),
        'image_urls': ImageUrlsToJSON(value.imageUrls),
    };
}

