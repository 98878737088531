/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInner } from './UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInner';
import {
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFromJSON,
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFromJSONTyped,
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerToJSON,
} from './UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInner';
import type { UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner } from './UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner';
import {
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerFromJSON,
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerFromJSONTyped,
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerToJSON,
} from './UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner';

/**
 * 
 * @export
 * @interface UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner
 */
export interface UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner {
    /**
     * 
     * @type {string}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner
     */
    week: number;
    /**
     * 
     * @type {number}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner
     */
    year: number;
    /**
     * 
     * @type {Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner>}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner
     */
    sumHours?: Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner>;
    /**
     * 
     * @type {Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner>}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner
     */
    notApprovedHours?: Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner>;
    /**
     * 
     * @type {Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInner>}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner
     */
    projects: Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInner>;
}

/**
 * Check if a given object implements the UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner interface.
 */
export function instanceOfUsersGetWeekly200ResponseWeeklyViewInnerWeeksInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "week" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "projects" in value;

    return isInstance;
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerFromJSON(json: any): UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner {
    return UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerFromJSONTyped(json, false);
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'week': json['week'],
        'year': json['year'],
        'sumHours': !exists(json, 'sum_hours') ? undefined : ((json['sum_hours'] as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerFromJSON)),
        'notApprovedHours': !exists(json, 'not_approved_hours') ? undefined : ((json['not_approved_hours'] as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerFromJSON)),
        'projects': ((json['projects'] as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFromJSON)),
    };
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerToJSON(value?: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'week': value.week,
        'year': value.year,
        'sum_hours': value.sumHours === undefined ? undefined : ((value.sumHours as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerToJSON)),
        'not_approved_hours': value.notApprovedHours === undefined ? undefined : ((value.notApprovedHours as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerToJSON)),
        'projects': ((value.projects as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerToJSON)),
    };
}

