/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlPanelProductsToTask } from './ControlPanelProductsToTask';
import {
    ControlPanelProductsToTaskFromJSON,
    ControlPanelProductsToTaskFromJSONTyped,
    ControlPanelProductsToTaskToJSON,
} from './ControlPanelProductsToTask';
import type { FormTemplateSimpleEntity } from './FormTemplateSimpleEntity';
import {
    FormTemplateSimpleEntityFromJSON,
    FormTemplateSimpleEntityFromJSONTyped,
    FormTemplateSimpleEntityToJSON,
} from './FormTemplateSimpleEntity';
import type { FormTemplatesTasksModel } from './FormTemplatesTasksModel';
import {
    FormTemplatesTasksModelFromJSON,
    FormTemplatesTasksModelFromJSONTyped,
    FormTemplatesTasksModelToJSON,
} from './FormTemplatesTasksModel';
import type { LabelEntity } from './LabelEntity';
import {
    LabelEntityFromJSON,
    LabelEntityFromJSONTyped,
    LabelEntityToJSON,
} from './LabelEntity';
import type { MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject } from './MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject';
import {
    MobileGetCards200ResponseDataInnerAllOfTaskAllOfProjectFromJSON,
    MobileGetCards200ResponseDataInnerAllOfTaskAllOfProjectFromJSONTyped,
    MobileGetCards200ResponseDataInnerAllOfTaskAllOfProjectToJSON,
} from './MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject';
import type { ProductsTask } from './ProductsTask';
import {
    ProductsTaskFromJSON,
    ProductsTaskFromJSONTyped,
    ProductsTaskToJSON,
} from './ProductsTask';

/**
 * 
 * @export
 * @interface MobileGetCards200ResponseDataInnerAllOfTask
 */
export interface MobileGetCards200ResponseDataInnerAllOfTask {
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    readonly createdById: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    readonly modifiedById: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    readonly companyId: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    recurringRule?: string;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    endTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    estimate?: number;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    repeat?: MobileGetCards200ResponseDataInnerAllOfTaskRepeatEnum;
    /**
     * 
     * @type {number}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    interval?: number;
    /**
     * 
     * @type {Array<LabelEntity>}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    labels?: Array<LabelEntity>;
    /**
     * 
     * @type {Array<FormTemplateSimpleEntity>}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    formTemplates?: Array<FormTemplateSimpleEntity>;
    /**
     * 
     * @type {Array<FormTemplatesTasksModel>}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    formTemplatesTasks?: Array<FormTemplatesTasksModel>;
    /**
     * 
     * @type {Array<ControlPanelProductsToTask>}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    products?: Array<ControlPanelProductsToTask>;
    /**
     * 
     * @type {Array<ProductsTask>}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    productsTasks?: Array<ProductsTask>;
    /**
     * 
     * @type {MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    project: MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTask
     */
    readonly deleted: Date | null;
}


/**
 * @export
 */
export const MobileGetCards200ResponseDataInnerAllOfTaskRepeatEnum = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;
export type MobileGetCards200ResponseDataInnerAllOfTaskRepeatEnum = typeof MobileGetCards200ResponseDataInnerAllOfTaskRepeatEnum[keyof typeof MobileGetCards200ResponseDataInnerAllOfTaskRepeatEnum];


/**
 * Check if a given object implements the MobileGetCards200ResponseDataInnerAllOfTask interface.
 */
export function instanceOfMobileGetCards200ResponseDataInnerAllOfTask(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdById" in value;
    isInstance = isInstance && "modifiedById" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function MobileGetCards200ResponseDataInnerAllOfTaskFromJSON(json: any): MobileGetCards200ResponseDataInnerAllOfTask {
    return MobileGetCards200ResponseDataInnerAllOfTaskFromJSONTyped(json, false);
}

export function MobileGetCards200ResponseDataInnerAllOfTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileGetCards200ResponseDataInnerAllOfTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': json['created_by_id'],
        'modifiedById': json['modified_by_id'],
        'companyId': json['company_id'],
        'projectId': json['project_id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'recurringRule': !exists(json, 'recurring_rule') ? undefined : json['recurring_rule'],
        'startDate': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'estimate': !exists(json, 'estimate') ? undefined : json['estimate'],
        'repeat': !exists(json, 'repeat') ? undefined : json['repeat'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(LabelEntityFromJSON)),
        'formTemplates': !exists(json, 'form_templates') ? undefined : ((json['form_templates'] as Array<any>).map(FormTemplateSimpleEntityFromJSON)),
        'formTemplatesTasks': !exists(json, 'form_templates_tasks') ? undefined : ((json['form_templates_tasks'] as Array<any>).map(FormTemplatesTasksModelFromJSON)),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ControlPanelProductsToTaskFromJSON)),
        'productsTasks': !exists(json, 'products_tasks') ? undefined : ((json['products_tasks'] as Array<any>).map(ProductsTaskFromJSON)),
        'project': MobileGetCards200ResponseDataInnerAllOfTaskAllOfProjectFromJSON(json['project']),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function MobileGetCards200ResponseDataInnerAllOfTaskToJSON(value?: MobileGetCards200ResponseDataInnerAllOfTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'name': value.name,
        'description': value.description,
        'recurring_rule': value.recurringRule,
        'start_date': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'end_date': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'start_time': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'estimate': value.estimate,
        'repeat': value.repeat,
        'interval': value.interval,
        'labels': value.labels === undefined ? undefined : ((value.labels as Array<any>).map(LabelEntityToJSON)),
        'form_templates': value.formTemplates === undefined ? undefined : ((value.formTemplates as Array<any>).map(FormTemplateSimpleEntityToJSON)),
        'form_templates_tasks': value.formTemplatesTasks === undefined ? undefined : ((value.formTemplatesTasks as Array<any>).map(FormTemplatesTasksModelToJSON)),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ControlPanelProductsToTaskToJSON)),
        'products_tasks': value.productsTasks === undefined ? undefined : ((value.productsTasks as Array<any>).map(ProductsTaskToJSON)),
        'project': MobileGetCards200ResponseDataInnerAllOfTaskAllOfProjectToJSON(value.project),
    };
}

