import { FeatureGroups } from "src/app/shared/enums/feature-groups";
import { MenuFeature } from "src/app/shared/models/menu-feature";

export const allFeatures: MenuFeature[] = [
    {
        feature: "overview",
        icon: "home",
        label: "MAIN_MENU.OVERVIEW",
        group: FeatureGroups.Overview,
        hidden: true,
        url: ["companies", "overview"],
        placement: 1,
    },
    {
        feature: "projects",
        icon: "folder",
        label: "MAIN_MENU.PROJECTS",
        group: FeatureGroups.Administration,
        url: ["companies", "projects"],
        notification: false,
        placement: 2,
    },
    {
        feature: "calendar",
        icon: "calendar",
        label: "MAIN_MENU.CALENDAR",
        group: FeatureGroups.Overview,
        url: ["companies", "calendar"],
        notification: false,
        placement: 3,
    },
    {
        feature: "project_expenses",
        icon: "file-tray",
        label: "MAIN_MENU.VENDOR_INVOICES",
        group: FeatureGroups.Economy,
        url: ["companies", "expenses"],
        notification: false,
        placement: 4,
    },
    {
        feature: "offers",
        icon: "pricetag",
        label: "MAIN_MENU.OFFERS",
        group: FeatureGroups.Administration,
        url: ["companies", "offers"],
        notification: false,
        placement: 5,
    },
    {
        feature: "users",
        icon: "people",
        label: "MAIN_MENU.EMPLOYEES",
        group: FeatureGroups.Administration,
        is_parent: true,
        notification: false,
        placement: 6,
        url: [],
        children: [
            {
                feature: "users",
                icon: "people",
                label: "MAIN_MENU.SUB_EMPLOYEES",
                url: ["companies", "employees"],
                notification: false,
            },
            {
                feature: "users_overview",
                icon: "people",
                label: "MAIN_MENU.EMPLOYEES_OVERVIEW",
                url: ["companies", "employees", "employees-overview"],
                notification: false,
            },
        ],
    },
    {
        feature: "invoices",
        icon: "document",
        label: "MAIN_MENU.INVOICES",
        group: FeatureGroups.Economy,
        url: ["companies", "invoices"],
        notification: false,
        placement: 7,
    },
    {
        feature: "contacts",
        icon: "person",
        label: "MAIN_MENU.CONTACTS",
        group: FeatureGroups.Administration,
        url: ["companies", "contacts"],
        notification: false,
        placement: 8,
    },
    {
        feature: "distributor_vendors",
        icon: "business",
        label: "MAIN_MENU.VENDORS",
        group: FeatureGroups.Administration,
        url: ["companies", "vendors"],
        notification: false,
        placement: 9,
    },
    {
        feature: "products",
        icon: "cube",
        label: "MAIN_MENU.PRODUCTS",
        group: FeatureGroups.Administration,
        url: ["companies", "products"],
        notification: false,
        placement: 10,
    },
    {
        feature: "reports",
        icon: "podium",
        label: "MAIN_MENU.REPORTS",
        url: ["companies", "reports"],
        notification: false,
        group: FeatureGroups.Administration,
        placement: 11,
        hidden: false,
    },
    {
        feature: "custom_order_complaints",
        icon: "warning",
        label: "MAIN_MENU.ORDER_COMPLAINTS",
        group: FeatureGroups.Economy,
        url: ["companies", "order-complaints"],
        notification: false,
        placement: 14,
    },
];
