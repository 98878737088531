/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlPanelApiProjectViewOrIndexResponse } from './ControlPanelApiProjectViewOrIndexResponse';
import {
    ControlPanelApiProjectViewOrIndexResponseFromJSON,
    ControlPanelApiProjectViewOrIndexResponseFromJSONTyped,
    ControlPanelApiProjectViewOrIndexResponseToJSON,
} from './ControlPanelApiProjectViewOrIndexResponse';
import type { PaginationDetails } from './PaginationDetails';
import {
    PaginationDetailsFromJSON,
    PaginationDetailsFromJSONTyped,
    PaginationDetailsToJSON,
} from './PaginationDetails';

/**
 * 
 * @export
 * @interface IListProjects200Response
 */
export interface IListProjects200Response {
    /**
     * 
     * @type {boolean}
     * @memberof IListProjects200Response
     */
    success: boolean;
    /**
     * 
     * @type {PaginationDetails}
     * @memberof IListProjects200Response
     */
    pagination: PaginationDetails;
    /**
     * 
     * @type {Array<ControlPanelApiProjectViewOrIndexResponse>}
     * @memberof IListProjects200Response
     */
    data: Array<ControlPanelApiProjectViewOrIndexResponse>;
}

/**
 * Check if a given object implements the IListProjects200Response interface.
 */
export function instanceOfIListProjects200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "pagination" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function IListProjects200ResponseFromJSON(json: any): IListProjects200Response {
    return IListProjects200ResponseFromJSONTyped(json, false);
}

export function IListProjects200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IListProjects200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'pagination': PaginationDetailsFromJSON(json['pagination']),
        'data': ((json['data'] as Array<any>).map(ControlPanelApiProjectViewOrIndexResponseFromJSON)),
    };
}

export function IListProjects200ResponseToJSON(value?: IListProjects200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'pagination': PaginationDetailsToJSON(value.pagination),
        'data': ((value.data as Array<any>).map(ControlPanelApiProjectViewOrIndexResponseToJSON)),
    };
}

