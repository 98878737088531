/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferLine
 */
export interface OfferLine {
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    readonly createdById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    readonly modifiedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    offerId: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    productBundleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    productBundleLineId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    usersPriceGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    expenseLineId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    vendorProductId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    productId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    type?: OfferLineTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferLine
     */
    discountPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferLine
     */
    discountAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferLine
     */
    discountType?: OfferLineDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OfferLine
     */
    costPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferLine
     */
    sellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferLine
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferLine
     */
    placement?: number;
    /**
     * 
     * @type {Array<OfferLine>}
     * @memberof OfferLine
     */
    childOfferLines?: Array<OfferLine>;
    /**
     * 
     * @type {Date}
     * @memberof OfferLine
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OfferLine
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof OfferLine
     */
    readonly deleted?: Date | null;
}


/**
 * @export
 */
export const OfferLineTypeEnum = {
    Normal: 'normal',
    Text: 'text',
    User: 'user'
} as const;
export type OfferLineTypeEnum = typeof OfferLineTypeEnum[keyof typeof OfferLineTypeEnum];

/**
 * @export
 */
export const OfferLineDiscountTypeEnum = {
    Percent: 'percent',
    Fixed: 'fixed'
} as const;
export type OfferLineDiscountTypeEnum = typeof OfferLineDiscountTypeEnum[keyof typeof OfferLineDiscountTypeEnum];


/**
 * Check if a given object implements the OfferLine interface.
 */
export function instanceOfOfferLine(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "offerId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function OfferLineFromJSON(json: any): OfferLine {
    return OfferLineFromJSONTyped(json, false);
}

export function OfferLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'offerId': json['offer_id'],
        'productBundleId': !exists(json, 'product_bundle_id') ? undefined : json['product_bundle_id'],
        'productBundleLineId': !exists(json, 'product_bundle_line_id') ? undefined : json['product_bundle_line_id'],
        'usersPriceGroupId': !exists(json, 'users_price_group_id') ? undefined : json['users_price_group_id'],
        'expenseLineId': !exists(json, 'expense_line_id') ? undefined : json['expense_line_id'],
        'vendorProductId': !exists(json, 'vendor_product_id') ? undefined : json['vendor_product_id'],
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'discountPercent': !exists(json, 'discount_percent') ? undefined : json['discount_percent'],
        'discountAmount': !exists(json, 'discount_amount') ? undefined : json['discount_amount'],
        'discountType': !exists(json, 'discount_type') ? undefined : json['discount_type'],
        'costPrice': !exists(json, 'cost_price') ? undefined : json['cost_price'],
        'sellingPrice': !exists(json, 'selling_price') ? undefined : json['selling_price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'placement': !exists(json, 'placement') ? undefined : json['placement'],
        'childOfferLines': !exists(json, 'child_offer_lines') ? undefined : ((json['child_offer_lines'] as Array<any>).map(OfferLineFromJSON)),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function OfferLineToJSON(value?: OfferLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offer_id': value.offerId,
        'product_bundle_id': value.productBundleId,
        'product_bundle_line_id': value.productBundleLineId,
        'users_price_group_id': value.usersPriceGroupId,
        'expense_line_id': value.expenseLineId,
        'vendor_product_id': value.vendorProductId,
        'product_id': value.productId,
        'user_id': value.userId,
        'type': value.type,
        'name': value.name,
        'description': value.description,
        'discount_percent': value.discountPercent,
        'discount_amount': value.discountAmount,
        'discount_type': value.discountType,
        'cost_price': value.costPrice,
        'selling_price': value.sellingPrice,
        'quantity': value.quantity,
        'placement': value.placement,
        'child_offer_lines': value.childOfferLines === undefined ? undefined : ((value.childOfferLines as Array<any>).map(OfferLineToJSON)),
    };
}

