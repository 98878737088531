/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Card } from './Card';
import {
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';
import type { PaginationDetails } from './PaginationDetails';
import {
    PaginationDetailsFromJSON,
    PaginationDetailsFromJSONTyped,
    PaginationDetailsToJSON,
} from './PaginationDetails';

/**
 * 
 * @export
 * @interface GetCards200Response
 */
export interface GetCards200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetCards200Response
     */
    success: boolean;
    /**
     * 
     * @type {PaginationDetails}
     * @memberof GetCards200Response
     */
    pagination: PaginationDetails;
    /**
     * 
     * @type {Array<Card>}
     * @memberof GetCards200Response
     */
    data: Array<Card>;
}

/**
 * Check if a given object implements the GetCards200Response interface.
 */
export function instanceOfGetCards200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "pagination" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetCards200ResponseFromJSON(json: any): GetCards200Response {
    return GetCards200ResponseFromJSONTyped(json, false);
}

export function GetCards200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCards200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'pagination': PaginationDetailsFromJSON(json['pagination']),
        'data': ((json['data'] as Array<any>).map(CardFromJSON)),
    };
}

export function GetCards200ResponseToJSON(value?: GetCards200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'pagination': PaginationDetailsToJSON(value.pagination),
        'data': ((value.data as Array<any>).map(CardToJSON)),
    };
}

