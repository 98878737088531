/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ProjectFileReadDTO
 */
export interface ProjectFileReadDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    createdById: string;
    /**
     * 
     * @type {User}
     * @memberof ProjectFileReadDTO
     */
    createdBy?: User;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    projectId: string;
    /**
     * 
     * @type {Project}
     * @memberof ProjectFileReadDTO
     */
    project?: Project;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    file: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    type?: ProjectFileReadDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    fileType: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    fileOriginalName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    fileSize: string;
    /**
     * Show `form field` message or `expense file` description depends on the entity
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectFileReadDTO
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectFileReadDTO
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof ProjectFileReadDTO
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    downloadUrl: string;
    /**
     * Id of the entity which the file is uploaded to
     * @type {string}
     * @memberof ProjectFileReadDTO
     */
    parentId: string | null;
}


/**
 * @export
 */
export const ProjectFileReadDTOTypeEnum = {
    Form: 'form',
    WallPost: 'wall_post',
    ExpenseFile: 'expense_file',
    ProjectFile: 'project_file'
} as const;
export type ProjectFileReadDTOTypeEnum = typeof ProjectFileReadDTOTypeEnum[keyof typeof ProjectFileReadDTOTypeEnum];


/**
 * Check if a given object implements the ProjectFileReadDTO interface.
 */
export function instanceOfProjectFileReadDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdById" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "fileType" in value;
    isInstance = isInstance && "fileOriginalName" in value;
    isInstance = isInstance && "fileSize" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "downloadUrl" in value;
    isInstance = isInstance && "parentId" in value;

    return isInstance;
}

export function ProjectFileReadDTOFromJSON(json: any): ProjectFileReadDTO {
    return ProjectFileReadDTOFromJSONTyped(json, false);
}

export function ProjectFileReadDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectFileReadDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': json['created_by_id'],
        'createdBy': !exists(json, 'created_by') ? undefined : UserFromJSON(json['created_by']),
        'projectId': json['project_id'],
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'file': json['file'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'fileType': json['file_type'],
        'fileOriginalName': json['file_original_name'],
        'fileSize': json['file_size'],
        'description': json['description'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'path': json['path'],
        'downloadUrl': json['download_url'],
        'parentId': json['parent_id'],
    };
}

export function ProjectFileReadDTOToJSON(value?: ProjectFileReadDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by_id': value.createdById,
        'created_by': UserToJSON(value.createdBy),
        'project_id': value.projectId,
        'project': ProjectToJSON(value.project),
        'file': value.file,
        'type': value.type,
        'file_type': value.fileType,
        'file_original_name': value.fileOriginalName,
        'file_size': value.fileSize,
        'description': value.description,
        'path': value.path,
        'download_url': value.downloadUrl,
        'parent_id': value.parentId,
    };
}

