/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CardUpdateDTO } from './CardUpdateDTO';
import {
    CardUpdateDTOFromJSON,
    CardUpdateDTOFromJSONTyped,
    CardUpdateDTOToJSON,
} from './CardUpdateDTO';
import type { TaskCreateOrUpdateDTO } from './TaskCreateOrUpdateDTO';
import {
    TaskCreateOrUpdateDTOFromJSON,
    TaskCreateOrUpdateDTOFromJSONTyped,
    TaskCreateOrUpdateDTOToJSON,
} from './TaskCreateOrUpdateDTO';

/**
 * 
 * @export
 * @interface UpdateCardListingRequest
 */
export interface UpdateCardListingRequest {
    /**
     * 
     * @type {CardUpdateDTO}
     * @memberof UpdateCardListingRequest
     */
    card: CardUpdateDTO;
    /**
     * 
     * @type {TaskCreateOrUpdateDTO}
     * @memberof UpdateCardListingRequest
     */
    task?: TaskCreateOrUpdateDTO;
    /**
     * Array of Card IDs in the order they should be saved
     * @type {Array<string>}
     * @memberof UpdateCardListingRequest
     */
    fromIndexes: Array<string>;
    /**
     * Array of Card IDs in the order they should be saved
     * @type {Array<string>}
     * @memberof UpdateCardListingRequest
     */
    toIndexes: Array<string>;
}

/**
 * Check if a given object implements the UpdateCardListingRequest interface.
 */
export function instanceOfUpdateCardListingRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "card" in value;
    isInstance = isInstance && "fromIndexes" in value;
    isInstance = isInstance && "toIndexes" in value;

    return isInstance;
}

export function UpdateCardListingRequestFromJSON(json: any): UpdateCardListingRequest {
    return UpdateCardListingRequestFromJSONTyped(json, false);
}

export function UpdateCardListingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCardListingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'card': CardUpdateDTOFromJSON(json['card']),
        'task': !exists(json, 'task') ? undefined : TaskCreateOrUpdateDTOFromJSON(json['task']),
        'fromIndexes': json['from_indexes'],
        'toIndexes': json['to_indexes'],
    };
}

export function UpdateCardListingRequestToJSON(value?: UpdateCardListingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card': CardUpdateDTOToJSON(value.card),
        'task': TaskCreateOrUpdateDTOToJSON(value.task),
        'from_indexes': value.fromIndexes,
        'to_indexes': value.toIndexes,
    };
}

