/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectGetKpiCardEconomySubPage200ResponseDataInterval
 */
export interface ProjectGetKpiCardEconomySubPage200ResponseDataInterval {
    /**
     * 
     * @type {Date}
     * @memberof ProjectGetKpiCardEconomySubPage200ResponseDataInterval
     */
    dateBefore?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectGetKpiCardEconomySubPage200ResponseDataInterval
     */
    dateAfter?: Date;
}

/**
 * Check if a given object implements the ProjectGetKpiCardEconomySubPage200ResponseDataInterval interface.
 */
export function instanceOfProjectGetKpiCardEconomySubPage200ResponseDataInterval(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectGetKpiCardEconomySubPage200ResponseDataIntervalFromJSON(json: any): ProjectGetKpiCardEconomySubPage200ResponseDataInterval {
    return ProjectGetKpiCardEconomySubPage200ResponseDataIntervalFromJSONTyped(json, false);
}

export function ProjectGetKpiCardEconomySubPage200ResponseDataIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectGetKpiCardEconomySubPage200ResponseDataInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateBefore': !exists(json, 'date_before') ? undefined : (new Date(json['date_before'])),
        'dateAfter': !exists(json, 'date_after') ? undefined : (new Date(json['date_after'])),
    };
}

export function ProjectGetKpiCardEconomySubPage200ResponseDataIntervalToJSON(value?: ProjectGetKpiCardEconomySubPage200ResponseDataInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_before': value.dateBefore === undefined ? undefined : (value.dateBefore.toISOString()),
        'date_after': value.dateAfter === undefined ? undefined : (value.dateAfter.toISOString()),
    };
}

