/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeStatusRequest
 */
export interface ChangeStatusRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeStatusRequest
     */
    forms: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeStatusRequest
     */
    approve: boolean;
}

/**
 * Check if a given object implements the ChangeStatusRequest interface.
 */
export function instanceOfChangeStatusRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "forms" in value;
    isInstance = isInstance && "approve" in value;

    return isInstance;
}

export function ChangeStatusRequestFromJSON(json: any): ChangeStatusRequest {
    return ChangeStatusRequestFromJSONTyped(json, false);
}

export function ChangeStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'forms': json['forms'],
        'approve': json['approve'],
    };
}

export function ChangeStatusRequestToJSON(value?: ChangeStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'forms': value.forms,
        'approve': value.approve,
    };
}

