import { Form, Pagination } from "src/app/services/response-models/form.response.model";

export class LoadNotApprovedForms {
    static readonly type = "[Forms] Load not approved forms";

    constructor(public payload: Array<Form>) {}
}

export class LoadNotApprovedFormsPagination {
    static readonly type = "[Forms] Load not approved forms pagination";

    constructor(public payload: Pagination) {}
}

export class ClearNotApprovedForms {
    static readonly type = "[Forms] Clear not approved forms";

    constructor() {}
}
