/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KpiCardsIndexResponsePreCalculation
 */
export interface KpiCardsIndexResponsePreCalculation {
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponsePreCalculation
     */
    costPrice: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponsePreCalculation
     */
    salesPrice: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponsePreCalculation
     */
    contributionMargin: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponsePreCalculation
     */
    contributionMarginPerHour: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponsePreCalculation
     */
    contributionRatio?: number;
}

/**
 * Check if a given object implements the KpiCardsIndexResponsePreCalculation interface.
 */
export function instanceOfKpiCardsIndexResponsePreCalculation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costPrice" in value;
    isInstance = isInstance && "salesPrice" in value;
    isInstance = isInstance && "contributionMargin" in value;
    isInstance = isInstance && "contributionMarginPerHour" in value;

    return isInstance;
}

export function KpiCardsIndexResponsePreCalculationFromJSON(json: any): KpiCardsIndexResponsePreCalculation {
    return KpiCardsIndexResponsePreCalculationFromJSONTyped(json, false);
}

export function KpiCardsIndexResponsePreCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiCardsIndexResponsePreCalculation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costPrice': json['cost_price'],
        'salesPrice': json['sales_price'],
        'contributionMargin': json['contribution_margin'],
        'contributionMarginPerHour': json['contribution_margin_per_hour'],
        'contributionRatio': !exists(json, 'contribution_ratio') ? undefined : json['contribution_ratio'],
    };
}

export function KpiCardsIndexResponsePreCalculationToJSON(value?: KpiCardsIndexResponsePreCalculation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cost_price': value.costPrice,
        'sales_price': value.salesPrice,
        'contribution_margin': value.contributionMargin,
        'contribution_margin_per_hour': value.contributionMarginPerHour,
        'contribution_ratio': value.contributionRatio,
    };
}

