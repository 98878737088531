import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class WindowWrapper {
    public getWindow(): any {
        return window;
    }
}
