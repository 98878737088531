/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductsTask
 */
export interface ProductsTask {
    /**
     * 
     * @type {string}
     * @memberof ProductsTask
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsTask
     */
    readonly taskId: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsTask
     */
    readonly productId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductsTask
     */
    amount?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductsTask
     */
    product?: Product;
    /**
     * 
     * @type {Date}
     * @memberof ProductsTask
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductsTask
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof ProductsTask
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the ProductsTask interface.
 */
export function instanceOfProductsTask(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "taskId" in value;
    isInstance = isInstance && "productId" in value;

    return isInstance;
}

export function ProductsTaskFromJSON(json: any): ProductsTask {
    return ProductsTaskFromJSONTyped(json, false);
}

export function ProductsTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'taskId': json['task_id'],
        'productId': json['product_id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'product': !exists(json, 'product') ? undefined : ProductFromJSON(json['product']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function ProductsTaskToJSON(value?: ProductsTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'product': ProductToJSON(value.product),
    };
}

