/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Card } from './Card';
import {
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';
import type { TaskReadDTO } from './TaskReadDTO';
import {
    TaskReadDTOFromJSON,
    TaskReadDTOFromJSONTyped,
    TaskReadDTOToJSON,
} from './TaskReadDTO';

/**
 * 
 * @export
 * @interface UpdateCardListing200ResponseData
 */
export interface UpdateCardListing200ResponseData {
    /**
     * 
     * @type {Card}
     * @memberof UpdateCardListing200ResponseData
     */
    card?: Card;
    /**
     * 
     * @type {TaskReadDTO}
     * @memberof UpdateCardListing200ResponseData
     */
    task?: TaskReadDTO;
}

/**
 * Check if a given object implements the UpdateCardListing200ResponseData interface.
 */
export function instanceOfUpdateCardListing200ResponseData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateCardListing200ResponseDataFromJSON(json: any): UpdateCardListing200ResponseData {
    return UpdateCardListing200ResponseDataFromJSONTyped(json, false);
}

export function UpdateCardListing200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCardListing200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'card': !exists(json, 'card') ? undefined : CardFromJSON(json['card']),
        'task': !exists(json, 'task') ? undefined : TaskReadDTOFromJSON(json['task']),
    };
}

export function UpdateCardListing200ResponseDataToJSON(value?: UpdateCardListing200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card': CardToJSON(value.card),
        'task': TaskReadDTOToJSON(value.task),
    };
}

