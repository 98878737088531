/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvoiceReadDTO } from './InvoiceReadDTO';
import {
    InvoiceReadDTOFromJSON,
    InvoiceReadDTOFromJSONTyped,
    InvoiceReadDTOToJSON,
} from './InvoiceReadDTO';

/**
 * 
 * @export
 * @interface FeedItemInvoice
 */
export interface FeedItemInvoice {
    /**
     * 
     * @type {string}
     * @memberof FeedItemInvoice
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FeedItemInvoice
     */
    type: FeedItemInvoiceTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof FeedItemInvoice
     */
    date: Date;
    /**
     * 
     * @type {InvoiceReadDTO}
     * @memberof FeedItemInvoice
     */
    data: InvoiceReadDTO;
}


/**
 * @export
 */
export const FeedItemInvoiceTypeEnum = {
    Invoice: 'invoice',
    WallPost: 'wall_post',
    Expense: 'expense',
    Form: 'form',
    ProjectFile: 'project_file'
} as const;
export type FeedItemInvoiceTypeEnum = typeof FeedItemInvoiceTypeEnum[keyof typeof FeedItemInvoiceTypeEnum];


/**
 * Check if a given object implements the FeedItemInvoice interface.
 */
export function instanceOfFeedItemInvoice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function FeedItemInvoiceFromJSON(json: any): FeedItemInvoice {
    return FeedItemInvoiceFromJSONTyped(json, false);
}

export function FeedItemInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedItemInvoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'date': (new Date(json['date'])),
        'data': InvoiceReadDTOFromJSON(json['data']),
    };
}

export function FeedItemInvoiceToJSON(value?: FeedItemInvoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'date': (value.date.toISOString().substr(0,10)),
        'data': InvoiceReadDTOToJSON(value.data),
    };
}

