import { ProjectData, Pagination } from "src/app/services/response-models/projects.response.model";

export class LoadProjects {
    public static readonly type = "[Projects] Save all projects";

    constructor(public payload: ProjectData[] | any) {}
}

export class SavePagination {
    public static readonly type = "[Projects] Save pagination";

    constructor(public payload: Pagination) {}
}

export class ClearProjects {
    public static readonly type = "[Projects] Clear projects";

    constructor() {}
}
