import { State, Selector, Action, StateContext } from "@ngxs/store";
import {
    Contact,
    ContactType,
    PaymentTerm,
} from "src/app/services/response-models/contacts.response.model";
import { Pagination } from "src/app/services/response-models/projects.response.model";
import { LoadContacts, LoadPagination, ClearContacts } from "./actions/set-contacts.actions";
import { LoadContactTypes } from "./actions/set-types.action";
import { SetSearchString } from "./actions/set-search-string.actions";
import { SetCreatedContact } from "./actions/set-created-contact.actions";
import { Injectable } from "@angular/core";
import { SaveCurrentContact } from "./actions/set-current-contact.action";
import { SetPaymentTerms } from "./actions/set-payment-terms.action";
import {
    SetDefaultBankAccount,
    SetDefaultPaymentTerm,
    SetDefaultCompantIntegrationFeatureSetting,
} from "./actions/set-default-payment-terms.action";
import { IntegrationFeatureSetting } from "src/app/services/response-models/companies.response.model";

export interface ContactsStateModel {
    contacts: Contact[];
    pagination: Pagination;
    contactTypes: ContactType[];
    searchString: string;
    createdContact: Contact;
    selectedContact: Contact;
    paymentTypes: PaymentTerm[];
    defaultBankAccount: string;
    defaultPaymentTerm: PaymentTerm;
    companyIntegrationSettings: IntegrationFeatureSetting[];
}

@State<ContactsStateModel>({
    name: "contacts",
    defaults: {
        contacts: [],
        pagination: null,
        contactTypes: [],
        searchString: "",
        createdContact: null,
        selectedContact: null,
        paymentTypes: [],
        defaultBankAccount: "",
        defaultPaymentTerm: null,
        companyIntegrationSettings: null,
    },
})
@Injectable()
export class ContactsState {
    @Selector()
    public static getContacts(state: ContactsStateModel) {
        return state.contacts;
    }

    @Selector()
    public static getPagination(state: ContactsStateModel) {
        return state.pagination;
    }

    @Selector()
    public static getContactTypes(state: ContactsStateModel) {
        return state.contactTypes;
    }

    @Selector()
    public static getSearchString(state: ContactsStateModel) {
        return state.searchString;
    }

    @Selector()
    public static getCreatedContact(state: ContactsStateModel) {
        return state.createdContact;
    }

    @Selector()
    public static getPaymentTerms(state: ContactsStateModel) {
        return state.paymentTypes;
    }

    @Selector()
    public static getDefaultPaymentTerm(state: ContactsStateModel) {
        return state.defaultPaymentTerm;
    }

    @Selector()
    public static getDefaultBankAccount(state: ContactsStateModel) {
        return state.defaultBankAccount;
    }

    @Selector()
    public static getCompanyIntegtaionSettings(state: ContactsStateModel) {
        return state.companyIntegrationSettings;
    }

    @Action(LoadContacts)
    loadContacts(ctx: StateContext<ContactsStateModel>, { payload }: LoadContacts) {
        const state = ctx.getState();

        ctx.patchState({
            contacts: [...state.contacts, ...payload],
        });
    }

    @Action(LoadPagination)
    loadPagination(ctx: StateContext<ContactsStateModel>, { payload }: LoadPagination) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }

    @Action(LoadContactTypes)
    loadContactTypes(ctx: StateContext<ContactsStateModel>, { payload }: LoadContactTypes) {
        ctx.setState({ ...ctx.getState(), contactTypes: payload });
    }

    @Action(SaveCurrentContact)
    save(ctx: StateContext<ContactsStateModel>, { payload }: SaveCurrentContact) {
        ctx.setState({ ...ctx.getState(), selectedContact: payload });
    }

    @Action(ClearContacts)
    clear(ctx: StateContext<ContactsStateModel>) {
        ctx.setState({ ...ctx.getState(), contacts: [] });
    }

    @Action(SetSearchString)
    setSearchString(ctx: StateContext<ContactsStateModel>, { payload }: SetSearchString) {
        ctx.setState({ ...ctx.getState(), searchString: payload });
    }

    @Action(SetCreatedContact)
    setCreatedContact(ctx: StateContext<ContactsStateModel>, { payload }: SetCreatedContact) {
        ctx.setState({ ...ctx.getState(), createdContact: payload });
    }

    @Action(SetPaymentTerms)
    setPaymentTerms(ctx: StateContext<ContactsStateModel>, { payload }: SetPaymentTerms) {
        ctx.setState({ ...ctx.getState(), paymentTypes: payload });
    }

    @Action(SetDefaultBankAccount)
    backAccount(ctx: StateContext<ContactsStateModel>, { payload }: SetDefaultBankAccount) {
        ctx.setState({ ...ctx.getState(), defaultBankAccount: payload });
    }

    @Action(SetDefaultPaymentTerm)
    setDefaultPaymentTerm(
        ctx: StateContext<ContactsStateModel>,
        { payload }: SetDefaultPaymentTerm
    ) {
        ctx.setState({ ...ctx.getState(), defaultPaymentTerm: payload });
    }

    @Action(SetDefaultCompantIntegrationFeatureSetting)
    setBankAccountFeatureSetting(
        ctx: StateContext<ContactsStateModel>,
        { payload }: SetDefaultCompantIntegrationFeatureSetting
    ) {
        ctx.setState({ ...ctx.getState(), companyIntegrationSettings: payload });
    }
}
