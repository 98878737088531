/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Vendor } from './Vendor';
import {
    VendorFromJSON,
    VendorFromJSONTyped,
    VendorToJSON,
} from './Vendor';

/**
 * 
 * @export
 * @interface KpiCardProductsIndividualEntry
 */
export interface KpiCardProductsIndividualEntry {
    /**
     * 
     * @type {string}
     * @memberof KpiCardProductsIndividualEntry
     */
    readonly type: KpiCardProductsIndividualEntryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof KpiCardProductsIndividualEntry
     */
    readonly originType: KpiCardProductsIndividualEntryOriginTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof KpiCardProductsIndividualEntry
     */
    readonly originId: string;
    /**
     * 
     * @type {Date}
     * @memberof KpiCardProductsIndividualEntry
     */
    date: Date | null;
    /**
     * 
     * @type {number}
     * @memberof KpiCardProductsIndividualEntry
     */
    readonly quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardProductsIndividualEntry
     */
    readonly buyingPricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardProductsIndividualEntry
     */
    readonly buyingPriceTotal: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardProductsIndividualEntry
     */
    readonly sellingPricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardProductsIndividualEntry
     */
    readonly sellingPriceTotal: number;
    /**
     * 
     * @type {Vendor}
     * @memberof KpiCardProductsIndividualEntry
     */
    vendor?: Vendor;
    /**
     * 
     * @type {User}
     * @memberof KpiCardProductsIndividualEntry
     */
    createdBy?: User;
}


/**
 * @export
 */
export const KpiCardProductsIndividualEntryTypeEnum = {
    Product: 'product',
    ExpenseLine: 'expense_line'
} as const;
export type KpiCardProductsIndividualEntryTypeEnum = typeof KpiCardProductsIndividualEntryTypeEnum[keyof typeof KpiCardProductsIndividualEntryTypeEnum];

/**
 * @export
 */
export const KpiCardProductsIndividualEntryOriginTypeEnum = {
    Form: 'form',
    Expense: 'expense'
} as const;
export type KpiCardProductsIndividualEntryOriginTypeEnum = typeof KpiCardProductsIndividualEntryOriginTypeEnum[keyof typeof KpiCardProductsIndividualEntryOriginTypeEnum];


/**
 * Check if a given object implements the KpiCardProductsIndividualEntry interface.
 */
export function instanceOfKpiCardProductsIndividualEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "originType" in value;
    isInstance = isInstance && "originId" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "buyingPricePerUnit" in value;
    isInstance = isInstance && "buyingPriceTotal" in value;
    isInstance = isInstance && "sellingPricePerUnit" in value;
    isInstance = isInstance && "sellingPriceTotal" in value;

    return isInstance;
}

export function KpiCardProductsIndividualEntryFromJSON(json: any): KpiCardProductsIndividualEntry {
    return KpiCardProductsIndividualEntryFromJSONTyped(json, false);
}

export function KpiCardProductsIndividualEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiCardProductsIndividualEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'originType': json['origin_type'],
        'originId': json['origin_id'],
        'date': (json['date'] === null ? null : new Date(json['date'])),
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'buyingPricePerUnit': json['buying_price_per_unit'],
        'buyingPriceTotal': json['buying_price_total'],
        'sellingPricePerUnit': json['selling_price_per_unit'],
        'sellingPriceTotal': json['selling_price_total'],
        'vendor': !exists(json, 'vendor') ? undefined : VendorFromJSON(json['vendor']),
        'createdBy': !exists(json, 'created_by') ? undefined : UserFromJSON(json['created_by']),
    };
}

export function KpiCardProductsIndividualEntryToJSON(value?: KpiCardProductsIndividualEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date === null ? null : value.date.toISOString().substr(0,10)),
        'vendor': VendorToJSON(value.vendor),
        'created_by': UserToJSON(value.createdBy),
    };
}

