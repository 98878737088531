/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ICreateProductBundleRequestProductBundleLinesInner
 */
export interface ICreateProductBundleRequestProductBundleLinesInner {
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    productBundleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    vendorProductId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    usersPriceGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    expenseLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    productId?: string;
    /**
     * 
     * @type {number}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    buyingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    sellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    placement?: number;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequestProductBundleLinesInner
     */
    name?: string;
}

/**
 * Check if a given object implements the ICreateProductBundleRequestProductBundleLinesInner interface.
 */
export function instanceOfICreateProductBundleRequestProductBundleLinesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "quantity" in value;

    return isInstance;
}

export function ICreateProductBundleRequestProductBundleLinesInnerFromJSON(json: any): ICreateProductBundleRequestProductBundleLinesInner {
    return ICreateProductBundleRequestProductBundleLinesInnerFromJSONTyped(json, false);
}

export function ICreateProductBundleRequestProductBundleLinesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICreateProductBundleRequestProductBundleLinesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productBundleId': !exists(json, 'product_bundle_id') ? undefined : json['product_bundle_id'],
        'vendorProductId': !exists(json, 'vendor_product_id') ? undefined : json['vendor_product_id'],
        'usersPriceGroupId': !exists(json, 'users_price_group_id') ? undefined : json['users_price_group_id'],
        'expenseLineId': !exists(json, 'expense_line_id') ? undefined : json['expense_line_id'],
        'productId': !exists(json, 'product_id') ? undefined : json['product_id'],
        'quantity': json['quantity'],
        'buyingPrice': !exists(json, 'buying_price') ? undefined : json['buying_price'],
        'sellingPrice': !exists(json, 'selling_price') ? undefined : json['selling_price'],
        'placement': !exists(json, 'placement') ? undefined : json['placement'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function ICreateProductBundleRequestProductBundleLinesInnerToJSON(value?: ICreateProductBundleRequestProductBundleLinesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_bundle_id': value.productBundleId,
        'vendor_product_id': value.vendorProductId,
        'users_price_group_id': value.usersPriceGroupId,
        'expense_line_id': value.expenseLineId,
        'product_id': value.productId,
        'quantity': value.quantity,
        'buying_price': value.buyingPrice,
        'selling_price': value.sellingPrice,
        'placement': value.placement,
        'name': value.name,
    };
}

