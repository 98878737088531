/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IGetFrontpageInvoiced200ResponseDataTotal } from './IGetFrontpageInvoiced200ResponseDataTotal';
import {
    IGetFrontpageInvoiced200ResponseDataTotalFromJSON,
    IGetFrontpageInvoiced200ResponseDataTotalFromJSONTyped,
    IGetFrontpageInvoiced200ResponseDataTotalToJSON,
} from './IGetFrontpageInvoiced200ResponseDataTotal';
import type { IGetFrontpageInvoiced200ResponseDataWeekly } from './IGetFrontpageInvoiced200ResponseDataWeekly';
import {
    IGetFrontpageInvoiced200ResponseDataWeeklyFromJSON,
    IGetFrontpageInvoiced200ResponseDataWeeklyFromJSONTyped,
    IGetFrontpageInvoiced200ResponseDataWeeklyToJSON,
} from './IGetFrontpageInvoiced200ResponseDataWeekly';

/**
 * 
 * @export
 * @interface IGetFrontpageInvoiced200ResponseData
 */
export interface IGetFrontpageInvoiced200ResponseData {
    /**
     * 
     * @type {IGetFrontpageInvoiced200ResponseDataWeekly}
     * @memberof IGetFrontpageInvoiced200ResponseData
     */
    weekly: IGetFrontpageInvoiced200ResponseDataWeekly;
    /**
     * 
     * @type {IGetFrontpageInvoiced200ResponseDataTotal}
     * @memberof IGetFrontpageInvoiced200ResponseData
     */
    total: IGetFrontpageInvoiced200ResponseDataTotal;
}

/**
 * Check if a given object implements the IGetFrontpageInvoiced200ResponseData interface.
 */
export function instanceOfIGetFrontpageInvoiced200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "weekly" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function IGetFrontpageInvoiced200ResponseDataFromJSON(json: any): IGetFrontpageInvoiced200ResponseData {
    return IGetFrontpageInvoiced200ResponseDataFromJSONTyped(json, false);
}

export function IGetFrontpageInvoiced200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetFrontpageInvoiced200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weekly': IGetFrontpageInvoiced200ResponseDataWeeklyFromJSON(json['weekly']),
        'total': IGetFrontpageInvoiced200ResponseDataTotalFromJSON(json['total']),
    };
}

export function IGetFrontpageInvoiced200ResponseDataToJSON(value?: IGetFrontpageInvoiced200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weekly': IGetFrontpageInvoiced200ResponseDataWeeklyToJSON(value.weekly),
        'total': IGetFrontpageInvoiced200ResponseDataTotalToJSON(value.total),
    };
}

