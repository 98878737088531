/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FeedItemExpense,
    instanceOfFeedItemExpense,
    FeedItemExpenseFromJSON,
    FeedItemExpenseFromJSONTyped,
    FeedItemExpenseToJSON,
} from './FeedItemExpense';
import {
    FeedItemForm,
    instanceOfFeedItemForm,
    FeedItemFormFromJSON,
    FeedItemFormFromJSONTyped,
    FeedItemFormToJSON,
} from './FeedItemForm';
import {
    FeedItemInvoice,
    instanceOfFeedItemInvoice,
    FeedItemInvoiceFromJSON,
    FeedItemInvoiceFromJSONTyped,
    FeedItemInvoiceToJSON,
} from './FeedItemInvoice';
import {
    FeedItemProjectFile,
    instanceOfFeedItemProjectFile,
    FeedItemProjectFileFromJSON,
    FeedItemProjectFileFromJSONTyped,
    FeedItemProjectFileToJSON,
} from './FeedItemProjectFile';
import {
    FeedItemWallPost,
    instanceOfFeedItemWallPost,
    FeedItemWallPostFromJSON,
    FeedItemWallPostFromJSONTyped,
    FeedItemWallPostToJSON,
} from './FeedItemWallPost';

/**
 * @type ControlPanelApiProjectFeedResponseDTODataInner
 * 
 * @export
 */
export type ControlPanelApiProjectFeedResponseDTODataInner = { type: 'expense' } & FeedItemExpense | { type: 'form' } & FeedItemForm | { type: 'invoice' } & FeedItemInvoice | { type: 'project_file' } & FeedItemProjectFile | { type: 'wall_post' } & FeedItemWallPost;

export function ControlPanelApiProjectFeedResponseDTODataInnerFromJSON(json: any): ControlPanelApiProjectFeedResponseDTODataInner {
    return ControlPanelApiProjectFeedResponseDTODataInnerFromJSONTyped(json, false);
}

export function ControlPanelApiProjectFeedResponseDTODataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPanelApiProjectFeedResponseDTODataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'expense':
            return {...FeedItemExpenseFromJSONTyped(json, true), type: 'expense'};
        case 'form':
            return {...FeedItemFormFromJSONTyped(json, true), type: 'form'};
        case 'invoice':
            return {...FeedItemInvoiceFromJSONTyped(json, true), type: 'invoice'};
        case 'project_file':
            return {...FeedItemProjectFileFromJSONTyped(json, true), type: 'project_file'};
        case 'wall_post':
            return {...FeedItemWallPostFromJSONTyped(json, true), type: 'wall_post'};
        default:
            throw new Error(`No variant of ControlPanelApiProjectFeedResponseDTODataInner exists with 'type=${json['type']}'`);
    }
}

export function ControlPanelApiProjectFeedResponseDTODataInnerToJSON(value?: ControlPanelApiProjectFeedResponseDTODataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'expense':
            return FeedItemExpenseToJSON(value);
        case 'form':
            return FeedItemFormToJSON(value);
        case 'invoice':
            return FeedItemInvoiceToJSON(value);
        case 'project_file':
            return FeedItemProjectFileToJSON(value);
        case 'wall_post':
            return FeedItemWallPostToJSON(value);
        default:
            throw new Error(`No variant of ControlPanelApiProjectFeedResponseDTODataInner exists with 'type=${value['type']}'`);
    }

}

