/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { ContactPerson } from './ContactPerson';
import {
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
} from './ContactPerson';
import type { ProjectCustomFieldAttribute } from './ProjectCustomFieldAttribute';
import {
    ProjectCustomFieldAttributeFromJSON,
    ProjectCustomFieldAttributeFromJSONTyped,
    ProjectCustomFieldAttributeToJSON,
} from './ProjectCustomFieldAttribute';

/**
 * 
 * @export
 * @interface IGetProject200ResponseData
 */
export interface IGetProject200ResponseData {
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    readonly companyId: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    readonly createdById: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    contactId?: string;
    /**
     * 
     * @type {Contact}
     * @memberof IGetProject200ResponseData
     */
    contact?: Contact;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    contactPersonId?: string;
    /**
     * 
     * @type {ContactPerson}
     * @memberof IGetProject200ResponseData
     */
    contactPerson?: ContactPerson;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    cityId?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    projectStatusId: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    offerId?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    readonly projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof IGetProject200ResponseData
     */
    invoiceDiscountPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    longitude?: string;
    /**
     * 
     * @type {Date}
     * @memberof IGetProject200ResponseData
     */
    readonly firstActivityDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IGetProject200ResponseData
     */
    isFixedPrice: boolean;
    /**
     * 
     * @type {number}
     * @memberof IGetProject200ResponseData
     */
    workingHoursTotalCostPrice: number;
    /**
     * 
     * @type {number}
     * @memberof IGetProject200ResponseData
     */
    productsTotalCostPrice: number;
    /**
     * 
     * @type {number}
     * @memberof IGetProject200ResponseData
     */
    totalSalesPrice: number;
    /**
     * 
     * @type {number}
     * @memberof IGetProject200ResponseData
     */
    readonly notInvoicedAmount: number;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    readonly projectImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    projectType: IGetProject200ResponseDataProjectTypeEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IGetProject200ResponseData
     */
    customFields?: { [key: string]: string; };
    /**
     * 
     * @type {Array<ProjectCustomFieldAttribute>}
     * @memberof IGetProject200ResponseData
     */
    customFieldAttributes?: Array<ProjectCustomFieldAttribute>;
    /**
     * 
     * @type {Date}
     * @memberof IGetProject200ResponseData
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof IGetProject200ResponseData
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof IGetProject200ResponseData
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    readonly fullName: string;
    /**
     * 
     * @type {boolean}
     * @memberof IGetProject200ResponseData
     */
    isOffer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IGetProject200ResponseData
     */
    readonly isRotten: boolean;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    readonly thumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    readonly hasFinalInvoice: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof IGetProject200ResponseData
     */
    cityName?: string;
    /**
     * Map of user IDs with true/false value to allow/disallow access to project
     * @type {{ [key: string]: boolean; }}
     * @memberof IGetProject200ResponseData
     */
    userAccess: { [key: string]: boolean; };
}


/**
 * @export
 */
export const IGetProject200ResponseDataProjectTypeEnum = {
    Hourly: 'hourly',
    FixedPrice: 'fixed_price',
    Offer: 'offer',
    Estimate: 'estimate'
} as const;
export type IGetProject200ResponseDataProjectTypeEnum = typeof IGetProject200ResponseDataProjectTypeEnum[keyof typeof IGetProject200ResponseDataProjectTypeEnum];


/**
 * Check if a given object implements the IGetProject200ResponseData interface.
 */
export function instanceOfIGetProject200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "createdById" in value;
    isInstance = isInstance && "projectStatusId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isFixedPrice" in value;
    isInstance = isInstance && "workingHoursTotalCostPrice" in value;
    isInstance = isInstance && "productsTotalCostPrice" in value;
    isInstance = isInstance && "totalSalesPrice" in value;
    isInstance = isInstance && "notInvoicedAmount" in value;
    isInstance = isInstance && "projectType" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "isOffer" in value;
    isInstance = isInstance && "isRotten" in value;
    isInstance = isInstance && "hasFinalInvoice" in value;
    isInstance = isInstance && "userAccess" in value;

    return isInstance;
}

export function IGetProject200ResponseDataFromJSON(json: any): IGetProject200ResponseData {
    return IGetProject200ResponseDataFromJSONTyped(json, false);
}

export function IGetProject200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetProject200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['company_id'],
        'createdById': json['created_by_id'],
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'contact': !exists(json, 'contact') ? undefined : ContactFromJSON(json['contact']),
        'contactPersonId': !exists(json, 'contact_person_id') ? undefined : json['contact_person_id'],
        'contactPerson': !exists(json, 'contact_person') ? undefined : ContactPersonFromJSON(json['contact_person']),
        'cityId': !exists(json, 'city_id') ? undefined : json['city_id'],
        'projectStatusId': json['project_status_id'],
        'offerId': !exists(json, 'offer_id') ? undefined : json['offer_id'],
        'projectNumber': !exists(json, 'project_number') ? undefined : json['project_number'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'invoiceDiscountPercent': !exists(json, 'invoice_discount_percent') ? undefined : json['invoice_discount_percent'],
        'streetName': !exists(json, 'street_name') ? undefined : json['street_name'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'firstActivityDate': !exists(json, 'first_activity_date') ? undefined : (new Date(json['first_activity_date'])),
        'isFixedPrice': json['is_fixed_price'],
        'workingHoursTotalCostPrice': json['working_hours_total_cost_price'],
        'productsTotalCostPrice': json['products_total_cost_price'],
        'totalSalesPrice': json['total_sales_price'],
        'notInvoicedAmount': json['not_invoiced_amount'],
        'projectImageUrl': !exists(json, 'project_image_url') ? undefined : json['project_image_url'],
        'projectType': json['project_type'],
        'customFields': !exists(json, 'custom_fields') ? undefined : json['custom_fields'],
        'customFieldAttributes': !exists(json, 'custom_field_attributes') ? undefined : ((json['custom_field_attributes'] as Array<any>).map(ProjectCustomFieldAttributeFromJSON)),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'fullName': json['full_name'],
        'isOffer': json['is_offer'],
        'isRotten': json['is_rotten'],
        'thumbnail': !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'hasFinalInvoice': json['has_final_invoice'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'cityName': !exists(json, 'city_name') ? undefined : json['city_name'],
        'userAccess': json['user_access'],
    };
}

export function IGetProject200ResponseDataToJSON(value?: IGetProject200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact_id': value.contactId,
        'contact': ContactToJSON(value.contact),
        'contact_person_id': value.contactPersonId,
        'contact_person': ContactPersonToJSON(value.contactPerson),
        'city_id': value.cityId,
        'project_status_id': value.projectStatusId,
        'offer_id': value.offerId,
        'name': value.name,
        'description': value.description,
        'invoice_discount_percent': value.invoiceDiscountPercent,
        'street_name': value.streetName,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'is_fixed_price': value.isFixedPrice,
        'working_hours_total_cost_price': value.workingHoursTotalCostPrice,
        'products_total_cost_price': value.productsTotalCostPrice,
        'total_sales_price': value.totalSalesPrice,
        'project_type': value.projectType,
        'custom_fields': value.customFields,
        'custom_field_attributes': value.customFieldAttributes === undefined ? undefined : ((value.customFieldAttributes as Array<any>).map(ProjectCustomFieldAttributeToJSON)),
        'is_offer': value.isOffer,
        'zip_code': value.zipCode,
        'city_name': value.cityName,
        'user_access': value.userAccess,
    };
}

