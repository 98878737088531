import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
    Company,
    IntegrationSetting,
    CompaniesVendors,
} from "src/app/services/response-models/companies.response.model";
import { Pagination } from "src/app/services/response-models/pagination.response.model";
import {
    SetCompanies,
    SaveCompaniesPagination,
    ClearCompanies,
    SetCompanyVendors,
    SetCompanyIntegrationSettings,
    SetCompanyCurrency,
} from "./actions/companies.actions";
import { Currency } from "src/app/services/response-models/currency.model";

export interface CompaniesStateModel {
    companies: Company[];
    pagination: Pagination;
    companyVendors: CompaniesVendors[];
    integrationSettings: IntegrationSetting[];
    currency: Currency;
}

@State<CompaniesStateModel>({
    name: "companies",
    defaults: {
        companies: [],
        pagination: null,
        companyVendors: [],
        integrationSettings: [],
        currency: null,
    },
})
@Injectable()
export class CompaniesState {
    @Selector()
    public static getCompanies(state: CompaniesStateModel) {
        return state.companies;
    }

    @Selector()
    public static getPagination(state: CompaniesStateModel) {
        return state.pagination;
    }

    @Selector()
    public static getCompanyVendors(state: CompaniesStateModel) {
        return state.companyVendors;
    }

    @Selector()
    public static getIntegrationSettings(state: CompaniesStateModel) {
        return state.integrationSettings;
    }

    @Selector()
    public static getCompanyCurrency(state: CompaniesStateModel) {
        return state.currency;
    }

    @Action(SetCompanies)
    load(ctx: StateContext<CompaniesStateModel>, { payload }: SetCompanies) {
        const state = ctx.getState();

        ctx.patchState({
            companies: [...state.companies, ...payload.data],
        });
    }

    @Action(SaveCompaniesPagination)
    pagination(ctx: StateContext<CompaniesStateModel>, { payload }: SaveCompaniesPagination) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }

    @Action(ClearCompanies)
    clear(ctx: StateContext<CompaniesStateModel>) {
        ctx.setState({
            ...ctx.getState(),
            companies: [],
            pagination: null,
            companyVendors: [],
            integrationSettings: [],
        });
    }

    @Action(SetCompanyVendors)
    companyVendors(ctx: StateContext<CompaniesStateModel>, { payload }: SetCompanyVendors) {
        let companyVendorsArray: CompaniesVendors[] = [];

        if (payload.length) {
            payload.map((item) => {
                companyVendorsArray.push(...item.vendors);
            });
        }

        ctx.patchState({
            companyVendors: companyVendorsArray,
        });
    }

    @Action(SetCompanyIntegrationSettings)
    setCompanyIntegrationSettings(
        ctx: StateContext<CompaniesStateModel>,
        { payload }: SetCompanyIntegrationSettings
    ) {
        let integrationSettingsArray: IntegrationSetting[] = [];

        if (payload.length) {
            payload.map((item) => {
                integrationSettingsArray.push(...item.integration_settings);
            });
        }

        ctx.patchState({
            integrationSettings: integrationSettingsArray,
        });
    }

    @Action(SetCompanyCurrency)
    setCompanyCurrency(
        ctx: StateContext<CompaniesStateModel>,
        { payload }: SetCompanyCurrency
    ): void {
        ctx.setState({ ...ctx.getState(), currency: payload });
    }
}
