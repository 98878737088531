import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { sentryCaptureException } from "src/app/utility/errors";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        return event;
                    }
                },
                (err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        this.router.navigate(["login"]);
                    }

                    if (err.status === 0) {
                        // client-side or network error occurred.
                        // log in sentry when connection is back
                        sentryCaptureException(err);
                    }
                }
            )
        );
    }
}
