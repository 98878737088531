<ion-content>
    <ion-grid class="grid">
        <ion-row class="header" offset>
            <ion-col size-sm="12" size-md="6" offset="1">
                <ion-row>
                    <ion-col size-sm="12" size-md="10">
                        <ion-label ion-text-wrap class="primary-error-message">
                            {{ "ERROR_PAGE.PRIMARY_ERROR_MSG" | translate }}
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-margin-top">
                    <ion-col size-sm="12" size-md="8">
                        <ion-label
                            ion-text-wrap
                            class="clr-charcoal-grey font-size-24 txt-bold-500"
                        >
                            {{ "ERROR_PAGE.SECONDAY_ERROR_MSG" | translate }}
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-margin-top">
                    <ion-col size-sm="12" size-md="7">
                        <ion-label ion-text-wrap class="clr-battleship font-size-14">
                            {{ "ERROR_PAGE.ERROR_CONTACT_MSG" | translate }}
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-margin-top">
                    <ion-col size-sm="12" size-md="4">
                        <ion-button
                            size="large"
                            class="home-button txt-bold-500 font-size-14"
                            (click)="navigateToHome()"
                        >
                            {{ "ERROR_PAGE.HOME_BUTTON" | translate }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-col>
            <ion-col size-sm="12" size-md="4">
                <img src="assets/img/Error404.png" alt="error-logo" />
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
