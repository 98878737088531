/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsersPriceGroup } from './UsersPriceGroup';
import {
    UsersPriceGroupFromJSON,
    UsersPriceGroupFromJSONTyped,
    UsersPriceGroupToJSON,
} from './UsersPriceGroup';

/**
 * 
 * @export
 * @interface UsersPriceGroupsView200Response
 */
export interface UsersPriceGroupsView200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UsersPriceGroupsView200Response
     */
    success?: boolean;
    /**
     * 
     * @type {UsersPriceGroup}
     * @memberof UsersPriceGroupsView200Response
     */
    data: UsersPriceGroup;
}

/**
 * Check if a given object implements the UsersPriceGroupsView200Response interface.
 */
export function instanceOfUsersPriceGroupsView200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UsersPriceGroupsView200ResponseFromJSON(json: any): UsersPriceGroupsView200Response {
    return UsersPriceGroupsView200ResponseFromJSONTyped(json, false);
}

export function UsersPriceGroupsView200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersPriceGroupsView200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': UsersPriceGroupFromJSON(json['data']),
    };
}

export function UsersPriceGroupsView200ResponseToJSON(value?: UsersPriceGroupsView200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': UsersPriceGroupToJSON(value.data),
    };
}

