/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressLookup } from './AddressLookup';
import {
    AddressLookupFromJSON,
    AddressLookupFromJSONTyped,
    AddressLookupToJSON,
} from './AddressLookup';

/**
 * 
 * @export
 * @interface AutoCompleteAddress200Response
 */
export interface AutoCompleteAddress200Response {
    /**
     * 
     * @type {boolean}
     * @memberof AutoCompleteAddress200Response
     */
    success: boolean;
    /**
     * 
     * @type {Array<AddressLookup>}
     * @memberof AutoCompleteAddress200Response
     */
    data: Array<AddressLookup>;
}

/**
 * Check if a given object implements the AutoCompleteAddress200Response interface.
 */
export function instanceOfAutoCompleteAddress200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function AutoCompleteAddress200ResponseFromJSON(json: any): AutoCompleteAddress200Response {
    return AutoCompleteAddress200ResponseFromJSONTyped(json, false);
}

export function AutoCompleteAddress200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoCompleteAddress200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': ((json['data'] as Array<any>).map(AddressLookupFromJSON)),
    };
}

export function AutoCompleteAddress200ResponseToJSON(value?: AutoCompleteAddress200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': ((value.data as Array<any>).map(AddressLookupToJSON)),
    };
}

