import { Material } from "src/app/services/response-models/materials.response.model";
import { Pagination } from "src/app/services/response-models/projects.response.model";

export class LoadCheckInMaterials {
    public static readonly type = "[Materials] Load check in materials";

    constructor(public payload: Material[]) {}
}

export class LoadCheckInMaterialsPaginaton {
    public static readonly type = "[Materials] Load check in pagination";

    constructor(public payload: Pagination) {}
}

export class ClearCheckInMaterials {
    public static readonly type = "[Materials] Clear check in materials";
}
