/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId,
    instanceOfRequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId,
    RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdFromJSON,
    RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdFromJSONTyped,
    RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdToJSON,
} from './RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId';
import {
    RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName,
    instanceOfRequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName,
    RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameFromJSON,
    RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameFromJSONTyped,
    RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameToJSON,
} from './RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName';

/**
 * @type CreateCardAndTaskRequestCardsInner
 * 
 * @export
 */
export type CreateCardAndTaskRequestCardsInner = RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId | RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName;

export function CreateCardAndTaskRequestCardsInnerFromJSON(json: any): CreateCardAndTaskRequestCardsInner {
    return CreateCardAndTaskRequestCardsInnerFromJSONTyped(json, false);
}

export function CreateCardAndTaskRequestCardsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCardAndTaskRequestCardsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdFromJSONTyped(json, true), ...RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameFromJSONTyped(json, true) };
}

export function CreateCardAndTaskRequestCardsInnerToJSON(value?: CreateCardAndTaskRequestCardsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfRequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId(value)) {
        return RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdToJSON(value as RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId);
    }
    if (instanceOfRequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName(value)) {
        return RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameToJSON(value as RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName);
    }

    return {};
}

