import { LastUsedProduct } from "src/app/services/response-models/product.response.model";
import { Pagination } from "src/app/services/response-models/projects.response.model";

export class ClearFormsProducts {
    public static readonly type = "[Products] Clear forms products";
}

export class SetFormsProducts {
    public static readonly type = "[Products] Load forms products";

    constructor(public payload: LastUsedProduct[]) {}
}

export class SetFormsProductsPagination {
    public static readonly type = "[Products] Load forms products pagination";

    constructor(public payload: Pagination) {}
}
