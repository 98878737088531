/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KpiCardEconomyPrice
 */
export interface KpiCardEconomyPrice {
    /**
     * 
     * @type {number}
     * @memberof KpiCardEconomyPrice
     */
    costPrice: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardEconomyPrice
     */
    salesPrice?: number | null;
    /**
     * Contribution ratio is calculated as (sales_price - cost_price) / sales_price
     * @type {number}
     * @memberof KpiCardEconomyPrice
     */
    contributionRatio?: number | null;
    /**
     * Contribution margin is calculated as sales_price - cost_price
     * @type {number}
     * @memberof KpiCardEconomyPrice
     */
    contributionMargin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiCardEconomyPrice
     */
    percentage?: number | null;
}

/**
 * Check if a given object implements the KpiCardEconomyPrice interface.
 */
export function instanceOfKpiCardEconomyPrice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costPrice" in value;

    return isInstance;
}

export function KpiCardEconomyPriceFromJSON(json: any): KpiCardEconomyPrice {
    return KpiCardEconomyPriceFromJSONTyped(json, false);
}

export function KpiCardEconomyPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiCardEconomyPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costPrice': json['cost_price'],
        'salesPrice': !exists(json, 'sales_price') ? undefined : json['sales_price'],
        'contributionRatio': !exists(json, 'contribution_ratio') ? undefined : json['contribution_ratio'],
        'contributionMargin': !exists(json, 'contribution_margin') ? undefined : json['contribution_margin'],
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
    };
}

export function KpiCardEconomyPriceToJSON(value?: KpiCardEconomyPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cost_price': value.costPrice,
        'sales_price': value.salesPrice,
        'contribution_ratio': value.contributionRatio,
        'contribution_margin': value.contributionMargin,
        'percentage': value.percentage,
    };
}

