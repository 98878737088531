// Object of app constants for both
// prod and dev environment
// We should migrate to the env file tho.
export const appConstants = {
    sentryDns: "https://24637249273a4ee28303483cee02eef9@sentry.io/1412712",
    googleMapsStaticUrl:
        "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAvNrkfNPL8Fe6TzekCBAr1yhvfJSxUdrs&size=500x500&zoom=16",
    imageTypes: ["jpeg", "jpg", "gif", "png", "tiff", "webp"],
    docTypes: ["pdf", "doc"],
    videoTypes: ["mp4", "m4a", "m4v", "f4v", "f4a", "m4b", "m4r", "f4b", "mov"],
    googleAccountId: "817931321475-8efcoeq808jkilc5uenk1rvdji299u9f.apps.googleusercontent.com",
    facebookAccountId: "153255345428697",
};

export const DEFAULT_LOCALE = "en";
export const DANISH_LOCALE = "da-DK";

export const NOTEBOOK_PHOTOS = "NOTEBOOK_PHOTOS";
export const NOTEBOOK_PRODUCTS = "NOTEBOOK_PRODUCTS";
