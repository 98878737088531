/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageUrls } from './ImageUrls';
import {
    ImageUrlsFromJSON,
    ImageUrlsFromJSONTyped,
    ImageUrlsToJSON,
} from './ImageUrls';
import type { ProductVariant } from './ProductVariant';
import {
    ProductVariantFromJSON,
    ProductVariantFromJSONTyped,
    ProductVariantToJSON,
} from './ProductVariant';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    readonly companyId: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    readonly createdById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    readonly origin?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    priceType?: ProductPriceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    nickname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    barcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productRule?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    buyingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    sellingPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isManualBuyingPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isManualSellingPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    readonly image?: string | null;
    /**
     * 
     * @type {ImageUrls}
     * @memberof Product
     */
    imageUrls?: ImageUrls;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    readonly erpId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    readonly versionId?: number;
    /**
     * 
     * @type {Array<ProductVariant>}
     * @memberof Product
     */
    variants?: Array<ProductVariant>;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Product
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    readonly imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    readonly translatedOrigin?: string;
}


/**
 * @export
 */
export const ProductPriceTypeEnum = {
    Manual: 'manual',
    CostBased: 'cost_based'
} as const;
export type ProductPriceTypeEnum = typeof ProductPriceTypeEnum[keyof typeof ProductPriceTypeEnum];


/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['company_id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'priceType': !exists(json, 'price_type') ? undefined : json['price_type'],
        'name': json['name'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'productNumber': !exists(json, 'product_number') ? undefined : json['product_number'],
        'barcode': !exists(json, 'barcode') ? undefined : json['barcode'],
        'productRule': !exists(json, 'product_rule') ? undefined : json['product_rule'],
        'buyingPrice': !exists(json, 'buying_price') ? undefined : json['buying_price'],
        'sellingPrice': !exists(json, 'selling_price') ? undefined : json['selling_price'],
        'isManualBuyingPrice': !exists(json, 'is_manual_buying_price') ? undefined : json['is_manual_buying_price'],
        'isManualSellingPrice': !exists(json, 'is_manual_selling_price') ? undefined : json['is_manual_selling_price'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'imageUrls': !exists(json, 'image_urls') ? undefined : ImageUrlsFromJSON(json['image_urls']),
        'erpId': !exists(json, 'erp_id') ? undefined : json['erp_id'],
        'versionId': !exists(json, 'version_id') ? undefined : json['version_id'],
        'variants': !exists(json, 'variants') ? undefined : ((json['variants'] as Array<any>).map(ProductVariantFromJSON)),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'translatedOrigin': !exists(json, 'translated_origin') ? undefined : json['translated_origin'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price_type': value.priceType,
        'name': value.name,
        'nickname': value.nickname,
        'description': value.description,
        'product_number': value.productNumber,
        'barcode': value.barcode,
        'product_rule': value.productRule,
        'buying_price': value.buyingPrice,
        'selling_price': value.sellingPrice,
        'is_manual_buying_price': value.isManualBuyingPrice,
        'is_manual_selling_price': value.isManualSellingPrice,
        'image_urls': ImageUrlsToJSON(value.imageUrls),
        'variants': value.variants === undefined ? undefined : ((value.variants as Array<any>).map(ProductVariantToJSON)),
    };
}

