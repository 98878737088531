/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductBundle } from './ProductBundle';
import {
    ProductBundleFromJSON,
    ProductBundleFromJSONTyped,
    ProductBundleToJSON,
} from './ProductBundle';

/**
 * 
 * @export
 * @interface ProductBundlesView200Response
 */
export interface ProductBundlesView200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundlesView200Response
     */
    success?: boolean;
    /**
     * 
     * @type {ProductBundle}
     * @memberof ProductBundlesView200Response
     */
    data: ProductBundle;
}

/**
 * Check if a given object implements the ProductBundlesView200Response interface.
 */
export function instanceOfProductBundlesView200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ProductBundlesView200ResponseFromJSON(json: any): ProductBundlesView200Response {
    return ProductBundlesView200ResponseFromJSONTyped(json, false);
}

export function ProductBundlesView200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBundlesView200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': ProductBundleFromJSON(json['data']),
    };
}

export function ProductBundlesView200ResponseToJSON(value?: ProductBundlesView200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': ProductBundleToJSON(value.data),
    };
}

