import { StockLocation } from "src/app/services/response-models/companies.response.model";

import { Pagination } from "src/app/services/response-models/projects.response.model";

export class SetStockLocations {
    public static readonly type = "[App] Set stock locations";

    constructor(public payload: Array<StockLocation>) {}
}

export class SetStockLocationsPagination {
    public static readonly type = "[App] Set stock locations pagination";

    constructor(public payload: Pagination) {}
}

export class ClearStockLocations {
    public static readonly type = "[App] Clear stock locations";

    constructor() {}
}
