/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OfferLine } from './OfferLine';
import {
    OfferLineFromJSON,
    OfferLineFromJSONTyped,
    OfferLineToJSON,
} from './OfferLine';

/**
 * 
 * @export
 * @interface EditOfferRequest
 */
export interface EditOfferRequest {
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequest
     */
    cityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequest
     */
    contactId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequest
     */
    contactPersonId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequest
     */
    paymentTermId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequest
     */
    offerStatusId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequest
     */
    description?: string | null;
    /**
     * Street address
     * @type {string}
     * @memberof EditOfferRequest
     */
    address?: string;
    /**
     * 
     * @type {Date}
     * @memberof EditOfferRequest
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    isQuote?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EditOfferRequest
     */
    issuedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof EditOfferRequest
     */
    vatPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EditOfferRequest
     */
    discountPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EditOfferRequest
     */
    manualTotalSalesPrice?: number | null;
    /**
     * 
     * @type {Array<OfferLine>}
     * @memberof EditOfferRequest
     */
    offerLines?: Array<OfferLine>;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequest
     */
    offerLinesRule?: EditOfferRequestOfferLinesRuleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    allProductsOneLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    allLinesOneLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    allWorkingHoursOneLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    showOfferLines?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    showPaymentTerm?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    showPrices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    showProductImages?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    showEmployeeName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    distributionHoursOverProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    totalPriceLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequest
     */
    pdfUrl?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequest
     */
    rejectionReason?: string;
}


/**
 * @export
 */
export const EditOfferRequestOfferLinesRuleEnum = {
    GatherOfferLines: 'gather_offer_lines',
    DistributionHoursOverProducts: 'distribution_hours_over_products',
    ShowOnlyProductBundles: 'show_only_product_bundles',
    GroupByProductAndHours: 'group_by_product_and_hours',
    TotalPriceLine: 'total_price_line'
} as const;
export type EditOfferRequestOfferLinesRuleEnum = typeof EditOfferRequestOfferLinesRuleEnum[keyof typeof EditOfferRequestOfferLinesRuleEnum];


/**
 * Check if a given object implements the EditOfferRequest interface.
 */
export function instanceOfEditOfferRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function EditOfferRequestFromJSON(json: any): EditOfferRequest {
    return EditOfferRequestFromJSONTyped(json, false);
}

export function EditOfferRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditOfferRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cityId': !exists(json, 'city_id') ? undefined : json['city_id'],
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'contactPersonId': !exists(json, 'contact_person_id') ? undefined : json['contact_person_id'],
        'paymentTermId': !exists(json, 'payment_term_id') ? undefined : json['payment_term_id'],
        'title': json['title'],
        'offerStatusId': !exists(json, 'offer_status_id') ? undefined : json['offer_status_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'expirationDate': !exists(json, 'expiration_date') ? undefined : (json['expiration_date'] === null ? null : new Date(json['expiration_date'])),
        'isQuote': !exists(json, 'is_quote') ? undefined : json['is_quote'],
        'issuedDate': !exists(json, 'issued_date') ? undefined : (json['issued_date'] === null ? null : new Date(json['issued_date'])),
        'vatPercent': !exists(json, 'vat_percent') ? undefined : json['vat_percent'],
        'discountPercent': !exists(json, 'discount_percent') ? undefined : json['discount_percent'],
        'manualTotalSalesPrice': !exists(json, 'manual_total_sales_price') ? undefined : json['manual_total_sales_price'],
        'offerLines': !exists(json, 'offer_lines') ? undefined : ((json['offer_lines'] as Array<any>).map(OfferLineFromJSON)),
        'offerLinesRule': !exists(json, 'offer_lines_rule') ? undefined : json['offer_lines_rule'],
        'allProductsOneLine': !exists(json, 'all_products_one_line') ? undefined : json['all_products_one_line'],
        'allLinesOneLine': !exists(json, 'all_lines_one_line') ? undefined : json['all_lines_one_line'],
        'allWorkingHoursOneLine': !exists(json, 'all_working_hours_one_line') ? undefined : json['all_working_hours_one_line'],
        'showOfferLines': !exists(json, 'show_offer_lines') ? undefined : json['show_offer_lines'],
        'showPaymentTerm': !exists(json, 'show_payment_term') ? undefined : json['show_payment_term'],
        'showPrices': !exists(json, 'show_prices') ? undefined : json['show_prices'],
        'showProductImages': !exists(json, 'show_product_images') ? undefined : json['show_product_images'],
        'showEmployeeName': !exists(json, 'show_employee_name') ? undefined : json['show_employee_name'],
        'distributionHoursOverProducts': !exists(json, 'distribution_hours_over_products') ? undefined : json['distribution_hours_over_products'],
        'totalPriceLine': !exists(json, 'total_price_line') ? undefined : json['total_price_line'],
        'pdfUrl': !exists(json, 'pdf_url') ? undefined : json['pdf_url'],
        'rejectionReason': !exists(json, 'rejection_reason') ? undefined : json['rejection_reason'],
    };
}

export function EditOfferRequestToJSON(value?: EditOfferRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city_id': value.cityId,
        'contact_id': value.contactId,
        'contact_person_id': value.contactPersonId,
        'payment_term_id': value.paymentTermId,
        'title': value.title,
        'offer_status_id': value.offerStatusId,
        'description': value.description,
        'address': value.address,
        'expiration_date': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString()),
        'is_quote': value.isQuote,
        'issued_date': value.issuedDate === undefined ? undefined : (value.issuedDate === null ? null : value.issuedDate.toISOString()),
        'vat_percent': value.vatPercent,
        'discount_percent': value.discountPercent,
        'manual_total_sales_price': value.manualTotalSalesPrice,
        'offer_lines': value.offerLines === undefined ? undefined : ((value.offerLines as Array<any>).map(OfferLineToJSON)),
        'offer_lines_rule': value.offerLinesRule,
        'all_products_one_line': value.allProductsOneLine,
        'all_lines_one_line': value.allLinesOneLine,
        'all_working_hours_one_line': value.allWorkingHoursOneLine,
        'show_offer_lines': value.showOfferLines,
        'show_payment_term': value.showPaymentTerm,
        'show_prices': value.showPrices,
        'show_product_images': value.showProductImages,
        'show_employee_name': value.showEmployeeName,
        'distribution_hours_over_products': value.distributionHoursOverProducts,
        'total_price_line': value.totalPriceLine,
        'pdf_url': value.pdfUrl,
        'rejection_reason': value.rejectionReason,
    };
}

