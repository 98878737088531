/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateProductRequest
 */
export interface CreateProductRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    vendorProductId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    expenseLineId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    variantType?: CreateProductRequestVariantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    variantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    productNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    barcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    priceType?: CreateProductRequestPriceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    buyingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    sellingPrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    productRule?: CreateProductRequestProductRuleEnum;
}


/**
 * @export
 */
export const CreateProductRequestVariantTypeEnum = {
    Product: 'product',
    ExpenseLine: 'expense_line',
    VendorProduct: 'vendor_product'
} as const;
export type CreateProductRequestVariantTypeEnum = typeof CreateProductRequestVariantTypeEnum[keyof typeof CreateProductRequestVariantTypeEnum];

/**
 * @export
 */
export const CreateProductRequestPriceTypeEnum = {
    CostBased: 'cost_based',
    Manual: 'manual'
} as const;
export type CreateProductRequestPriceTypeEnum = typeof CreateProductRequestPriceTypeEnum[keyof typeof CreateProductRequestPriceTypeEnum];

/**
 * @export
 */
export const CreateProductRequestProductRuleEnum = {
    ReduceWorkingHours: 'REDUCE_WORKING_HOURS',
    ReplaceWorkingHours: 'REPLACE_WORKING_HOURS'
} as const;
export type CreateProductRequestProductRuleEnum = typeof CreateProductRequestProductRuleEnum[keyof typeof CreateProductRequestProductRuleEnum];


/**
 * Check if a given object implements the CreateProductRequest interface.
 */
export function instanceOfCreateProductRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateProductRequestFromJSON(json: any): CreateProductRequest {
    return CreateProductRequestFromJSONTyped(json, false);
}

export function CreateProductRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProductRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'vendorProductId': !exists(json, 'vendor_product_id') ? undefined : json['vendor_product_id'],
        'expenseLineId': !exists(json, 'expense_line_id') ? undefined : json['expense_line_id'],
        'variantType': !exists(json, 'variant_type') ? undefined : json['variant_type'],
        'variantId': !exists(json, 'variant_id') ? undefined : json['variant_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'productNumber': !exists(json, 'product_number') ? undefined : json['product_number'],
        'barcode': !exists(json, 'barcode') ? undefined : json['barcode'],
        'priceType': !exists(json, 'price_type') ? undefined : json['price_type'],
        'buyingPrice': !exists(json, 'buying_price') ? undefined : json['buying_price'],
        'sellingPrice': !exists(json, 'selling_price') ? undefined : json['selling_price'],
        'productRule': !exists(json, 'product_rule') ? undefined : json['product_rule'],
    };
}

export function CreateProductRequestToJSON(value?: CreateProductRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'name': value.name,
        'vendor_product_id': value.vendorProductId,
        'expense_line_id': value.expenseLineId,
        'variant_type': value.variantType,
        'variant_id': value.variantId,
        'description': value.description,
        'product_number': value.productNumber,
        'barcode': value.barcode,
        'price_type': value.priceType,
        'buying_price': value.buyingPrice,
        'selling_price': value.sellingPrice,
        'product_rule': value.productRule,
    };
}

