/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Expense } from './Expense';
import {
    ExpenseFromJSON,
    ExpenseFromJSONTyped,
    ExpenseToJSON,
} from './Expense';
import type { Form } from './Form';
import {
    FormFromJSON,
    FormFromJSONTyped,
    FormToJSON,
} from './Form';
import type { FrontpageFeedDTOFormFields } from './FrontpageFeedDTOFormFields';
import {
    FrontpageFeedDTOFormFieldsFromJSON,
    FrontpageFeedDTOFormFieldsFromJSONTyped,
    FrontpageFeedDTOFormFieldsToJSON,
} from './FrontpageFeedDTOFormFields';
import type { Invoice } from './Invoice';
import {
    InvoiceFromJSON,
    InvoiceFromJSONTyped,
    InvoiceToJSON,
} from './Invoice';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import type { TemplateType } from './TemplateType';
import {
    TemplateTypeFromJSON,
    TemplateTypeFromJSONTyped,
    TemplateTypeToJSON,
} from './TemplateType';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { WallPost } from './WallPost';
import {
    WallPostFromJSON,
    WallPostFromJSONTyped,
    WallPostToJSON,
} from './WallPost';

/**
 * 
 * @export
 * @interface FrontpageFeedDTO
 */
export interface FrontpageFeedDTO {
    /**
     * 
     * @type {string}
     * @memberof FrontpageFeedDTO
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof FrontpageFeedDTO
     */
    date: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FrontpageFeedDTO
     */
    readonly type: FrontpageFeedDTOTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FrontpageFeedDTO
     */
    sum?: number | null;
    /**
     * Will display form template (Only for Forms)
     * @type {string}
     * @memberof FrontpageFeedDTO
     */
    template: string | null;
    /**
     * 
     * @type {TemplateType}
     * @memberof FrontpageFeedDTO
     */
    templateType?: TemplateType;
    /**
     * 
     * @type {string}
     * @memberof FrontpageFeedDTO
     */
    link: string;
    /**
     * Count of form field types contain `standard_ks_question`, `compact_qa_question` with `warning`, `error` value
     * @type {number}
     * @memberof FrontpageFeedDTO
     */
    failedControlPoints?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FrontpageFeedDTO
     */
    invoiced?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontpageFeedDTO
     */
    approved?: boolean;
    /**
     * 
     * @type {User}
     * @memberof FrontpageFeedDTO
     */
    employee: User;
    /**
     * 
     * @type {Project}
     * @memberof FrontpageFeedDTO
     */
    project?: Project;
    /**
     * 
     * @type {Form}
     * @memberof FrontpageFeedDTO
     */
    form?: Form;
    /**
     * 
     * @type {Expense}
     * @memberof FrontpageFeedDTO
     */
    expense?: Expense;
    /**
     * 
     * @type {Invoice}
     * @memberof FrontpageFeedDTO
     */
    invoice?: Invoice;
    /**
     * 
     * @type {WallPost}
     * @memberof FrontpageFeedDTO
     */
    wallPost?: WallPost;
    /**
     * 
     * @type {FrontpageFeedDTOFormFields}
     * @memberof FrontpageFeedDTO
     */
    formFields?: FrontpageFeedDTOFormFields;
}


/**
 * @export
 */
export const FrontpageFeedDTOTypeEnum = {
    Form: 'form',
    Expense: 'expense',
    Invoice: 'invoice',
    WallPost: 'wall_post'
} as const;
export type FrontpageFeedDTOTypeEnum = typeof FrontpageFeedDTOTypeEnum[keyof typeof FrontpageFeedDTOTypeEnum];


/**
 * Check if a given object implements the FrontpageFeedDTO interface.
 */
export function instanceOfFrontpageFeedDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "template" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "employee" in value;

    return isInstance;
}

export function FrontpageFeedDTOFromJSON(json: any): FrontpageFeedDTO {
    return FrontpageFeedDTOFromJSONTyped(json, false);
}

export function FrontpageFeedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontpageFeedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (json['date'] === null ? null : new Date(json['date'])),
        'type': json['type'],
        'sum': !exists(json, 'sum') ? undefined : json['sum'],
        'template': json['template'],
        'templateType': !exists(json, 'template_type') ? undefined : TemplateTypeFromJSON(json['template_type']),
        'link': json['link'],
        'failedControlPoints': !exists(json, 'failed_control_points') ? undefined : json['failed_control_points'],
        'invoiced': !exists(json, 'invoiced') ? undefined : json['invoiced'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'employee': UserFromJSON(json['employee']),
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'form': !exists(json, 'form') ? undefined : FormFromJSON(json['form']),
        'expense': !exists(json, 'expense') ? undefined : ExpenseFromJSON(json['expense']),
        'invoice': !exists(json, 'invoice') ? undefined : InvoiceFromJSON(json['invoice']),
        'wallPost': !exists(json, 'wall_post') ? undefined : WallPostFromJSON(json['wall_post']),
        'formFields': !exists(json, 'form_fields') ? undefined : FrontpageFeedDTOFormFieldsFromJSON(json['form_fields']),
    };
}

export function FrontpageFeedDTOToJSON(value?: FrontpageFeedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date === null ? null : value.date.toISOString()),
        'sum': value.sum,
        'template': value.template,
        'template_type': TemplateTypeToJSON(value.templateType),
        'link': value.link,
        'failed_control_points': value.failedControlPoints,
        'invoiced': value.invoiced,
        'approved': value.approved,
        'employee': UserToJSON(value.employee),
        'project': ProjectToJSON(value.project),
        'form': FormToJSON(value.form),
        'expense': ExpenseToJSON(value.expense),
        'invoice': InvoiceToJSON(value.invoice),
        'wall_post': WallPostToJSON(value.wallPost),
        'form_fields': FrontpageFeedDTOFormFieldsToJSON(value.formFields),
    };
}

