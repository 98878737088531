/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KpiCardsIndexResponseContributionMargins } from './KpiCardsIndexResponseContributionMargins';
import {
    KpiCardsIndexResponseContributionMarginsFromJSON,
    KpiCardsIndexResponseContributionMarginsFromJSONTyped,
    KpiCardsIndexResponseContributionMarginsToJSON,
} from './KpiCardsIndexResponseContributionMargins';
import type { KpiCardsIndexResponsePreCalculation } from './KpiCardsIndexResponsePreCalculation';
import {
    KpiCardsIndexResponsePreCalculationFromJSON,
    KpiCardsIndexResponsePreCalculationFromJSONTyped,
    KpiCardsIndexResponsePreCalculationToJSON,
} from './KpiCardsIndexResponsePreCalculation';

/**
 * 
 * @export
 * @interface KpiCardsIndexResponse
 */
export interface KpiCardsIndexResponse {
    /**
     * This can go above 100% if you actual cost price is > expected cost price
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    completionPercentage?: number;
    /**
     * 
     * @type {Date}
     * @memberof KpiCardsIndexResponse
     */
    dateBefore?: Date;
    /**
     * 
     * @type {Date}
     * @memberof KpiCardsIndexResponse
     */
    dateAfter?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof KpiCardsIndexResponse
     */
    isProjectFinished: boolean;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    costPriceCurrent: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    costPriceEstimated?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    salesPriceCurrent: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    salesPriceEstimated?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    invoicedCurrent: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    notInvoiced: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    productsCostPriceEstimated?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    productsCostPriceDelivered: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    productsSalesPriceDelivered?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    hoursCostPriceEstimated?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    hoursCountEstimated?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    hoursCostPriceDelivered: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    hoursCountDelivered: number;
    /**
     * 
     * @type {number}
     * @memberof KpiCardsIndexResponse
     */
    hoursSalesPriceDelivered?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KpiCardsIndexResponse
     */
    hasZeroPricedProducts: boolean;
    /**
     * 
     * @type {KpiCardsIndexResponsePreCalculation}
     * @memberof KpiCardsIndexResponse
     */
    preCalculation?: KpiCardsIndexResponsePreCalculation;
    /**
     * 
     * @type {KpiCardsIndexResponseContributionMargins}
     * @memberof KpiCardsIndexResponse
     */
    contributionMargins?: KpiCardsIndexResponseContributionMargins;
}

/**
 * Check if a given object implements the KpiCardsIndexResponse interface.
 */
export function instanceOfKpiCardsIndexResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isProjectFinished" in value;
    isInstance = isInstance && "costPriceCurrent" in value;
    isInstance = isInstance && "salesPriceCurrent" in value;
    isInstance = isInstance && "invoicedCurrent" in value;
    isInstance = isInstance && "notInvoiced" in value;
    isInstance = isInstance && "productsCostPriceDelivered" in value;
    isInstance = isInstance && "hoursCostPriceDelivered" in value;
    isInstance = isInstance && "hoursCountDelivered" in value;
    isInstance = isInstance && "hasZeroPricedProducts" in value;

    return isInstance;
}

export function KpiCardsIndexResponseFromJSON(json: any): KpiCardsIndexResponse {
    return KpiCardsIndexResponseFromJSONTyped(json, false);
}

export function KpiCardsIndexResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiCardsIndexResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completionPercentage': !exists(json, 'completion_percentage') ? undefined : json['completion_percentage'],
        'dateBefore': !exists(json, 'date_before') ? undefined : (new Date(json['date_before'])),
        'dateAfter': !exists(json, 'date_after') ? undefined : (new Date(json['date_after'])),
        'isProjectFinished': json['is_project_finished'],
        'costPriceCurrent': json['cost_price_current'],
        'costPriceEstimated': !exists(json, 'cost_price_estimated') ? undefined : json['cost_price_estimated'],
        'salesPriceCurrent': json['sales_price_current'],
        'salesPriceEstimated': !exists(json, 'sales_price_estimated') ? undefined : json['sales_price_estimated'],
        'invoicedCurrent': json['invoiced_current'],
        'notInvoiced': json['not_invoiced'],
        'productsCostPriceEstimated': !exists(json, 'products_cost_price_estimated') ? undefined : json['products_cost_price_estimated'],
        'productsCostPriceDelivered': json['products_cost_price_delivered'],
        'productsSalesPriceDelivered': !exists(json, 'products_sales_price_delivered') ? undefined : json['products_sales_price_delivered'],
        'hoursCostPriceEstimated': !exists(json, 'hours_cost_price_estimated') ? undefined : json['hours_cost_price_estimated'],
        'hoursCountEstimated': !exists(json, 'hours_count_estimated') ? undefined : json['hours_count_estimated'],
        'hoursCostPriceDelivered': json['hours_cost_price_delivered'],
        'hoursCountDelivered': json['hours_count_delivered'],
        'hoursSalesPriceDelivered': !exists(json, 'hours_sales_price_delivered') ? undefined : json['hours_sales_price_delivered'],
        'hasZeroPricedProducts': json['has_zero_priced_products'],
        'preCalculation': !exists(json, 'pre_calculation') ? undefined : KpiCardsIndexResponsePreCalculationFromJSON(json['pre_calculation']),
        'contributionMargins': !exists(json, 'contribution_margins') ? undefined : KpiCardsIndexResponseContributionMarginsFromJSON(json['contribution_margins']),
    };
}

export function KpiCardsIndexResponseToJSON(value?: KpiCardsIndexResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completion_percentage': value.completionPercentage,
        'date_before': value.dateBefore === undefined ? undefined : (value.dateBefore.toISOString().substr(0,10)),
        'date_after': value.dateAfter === undefined ? undefined : (value.dateAfter.toISOString().substr(0,10)),
        'is_project_finished': value.isProjectFinished,
        'cost_price_current': value.costPriceCurrent,
        'cost_price_estimated': value.costPriceEstimated,
        'sales_price_current': value.salesPriceCurrent,
        'sales_price_estimated': value.salesPriceEstimated,
        'invoiced_current': value.invoicedCurrent,
        'not_invoiced': value.notInvoiced,
        'products_cost_price_estimated': value.productsCostPriceEstimated,
        'products_cost_price_delivered': value.productsCostPriceDelivered,
        'products_sales_price_delivered': value.productsSalesPriceDelivered,
        'hours_cost_price_estimated': value.hoursCostPriceEstimated,
        'hours_count_estimated': value.hoursCountEstimated,
        'hours_cost_price_delivered': value.hoursCostPriceDelivered,
        'hours_count_delivered': value.hoursCountDelivered,
        'hours_sales_price_delivered': value.hoursSalesPriceDelivered,
        'has_zero_priced_products': value.hasZeroPricedProducts,
        'pre_calculation': KpiCardsIndexResponsePreCalculationToJSON(value.preCalculation),
        'contribution_margins': KpiCardsIndexResponseContributionMarginsToJSON(value.contributionMargins),
    };
}

