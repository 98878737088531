/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormTemplateModelWithJsonFormsData } from './FormTemplateModelWithJsonFormsData';
import {
    FormTemplateModelWithJsonFormsDataFromJSON,
    FormTemplateModelWithJsonFormsDataFromJSONTyped,
    FormTemplateModelWithJsonFormsDataToJSON,
} from './FormTemplateModelWithJsonFormsData';

/**
 * 
 * @export
 * @interface FormTemplatesTasksModel
 */
export interface FormTemplatesTasksModel {
    /**
     * 
     * @type {string}
     * @memberof FormTemplatesTasksModel
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof FormTemplatesTasksModel
     */
    readonly createdById: string;
    /**
     * 
     * @type {string}
     * @memberof FormTemplatesTasksModel
     */
    taskId: string;
    /**
     * 
     * @type {string}
     * @memberof FormTemplatesTasksModel
     */
    formTemplateId: string;
    /**
     * 
     * @type {Date}
     * @memberof FormTemplatesTasksModel
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof FormTemplatesTasksModel
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof FormTemplatesTasksModel
     */
    readonly deleted: Date | null;
    /**
     * 
     * @type {FormTemplateModelWithJsonFormsData}
     * @memberof FormTemplatesTasksModel
     */
    formTemplate?: FormTemplateModelWithJsonFormsData;
}

/**
 * Check if a given object implements the FormTemplatesTasksModel interface.
 */
export function instanceOfFormTemplatesTasksModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdById" in value;
    isInstance = isInstance && "taskId" in value;
    isInstance = isInstance && "formTemplateId" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function FormTemplatesTasksModelFromJSON(json: any): FormTemplatesTasksModel {
    return FormTemplatesTasksModelFromJSONTyped(json, false);
}

export function FormTemplatesTasksModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormTemplatesTasksModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': json['created_by_id'],
        'taskId': json['task_id'],
        'formTemplateId': json['form_template_id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': (json['deleted'] === null ? null : new Date(json['deleted'])),
        'formTemplate': !exists(json, 'form_template') ? undefined : FormTemplateModelWithJsonFormsDataFromJSON(json['form_template']),
    };
}

export function FormTemplatesTasksModelToJSON(value?: FormTemplatesTasksModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task_id': value.taskId,
        'form_template_id': value.formTemplateId,
        'form_template': FormTemplateModelWithJsonFormsDataToJSON(value.formTemplate),
    };
}

