/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ICreateProductBundleRequestProductBundleLinesInner } from './ICreateProductBundleRequestProductBundleLinesInner';
import {
    ICreateProductBundleRequestProductBundleLinesInnerFromJSON,
    ICreateProductBundleRequestProductBundleLinesInnerFromJSONTyped,
    ICreateProductBundleRequestProductBundleLinesInnerToJSON,
} from './ICreateProductBundleRequestProductBundleLinesInner';

/**
 * 
 * @export
 * @interface ICreateProductBundleRequest
 */
export interface ICreateProductBundleRequest {
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequest
     */
    readonly companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequest
     */
    activityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequest
     */
    fileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ICreateProductBundleRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ICreateProductBundleRequest
     */
    salesPrice?: number;
    /**
     * 
     * @type {Array<ICreateProductBundleRequestProductBundleLinesInner>}
     * @memberof ICreateProductBundleRequest
     */
    productBundleLines?: Array<ICreateProductBundleRequestProductBundleLinesInner>;
}

/**
 * Check if a given object implements the ICreateProductBundleRequest interface.
 */
export function instanceOfICreateProductBundleRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ICreateProductBundleRequestFromJSON(json: any): ICreateProductBundleRequest {
    return ICreateProductBundleRequestFromJSONTyped(json, false);
}

export function ICreateProductBundleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICreateProductBundleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'activityId': !exists(json, 'activity_id') ? undefined : json['activity_id'],
        'fileId': !exists(json, 'file_id') ? undefined : json['file_id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'salesPrice': !exists(json, 'sales_price') ? undefined : json['sales_price'],
        'productBundleLines': !exists(json, 'product_bundle_lines') ? undefined : ((json['product_bundle_lines'] as Array<any>).map(ICreateProductBundleRequestProductBundleLinesInnerFromJSON)),
    };
}

export function ICreateProductBundleRequestToJSON(value?: ICreateProductBundleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity_id': value.activityId,
        'file_id': value.fileId,
        'name': value.name,
        'description': value.description,
        'sales_price': value.salesPrice,
        'product_bundle_lines': value.productBundleLines === undefined ? undefined : ((value.productBundleLines as Array<any>).map(ICreateProductBundleRequestProductBundleLinesInnerToJSON)),
    };
}

