/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAllRegistrations200ResponseDocumentTypesInner
 */
export interface GetAllRegistrations200ResponseDocumentTypesInner {
    /**
     * 
     * @type {string}
     * @memberof GetAllRegistrations200ResponseDocumentTypesInner
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllRegistrations200ResponseDocumentTypesInner
     */
    formTemplateId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAllRegistrations200ResponseDocumentTypesInner
     */
    formTitle?: string | null;
}

/**
 * Check if a given object implements the GetAllRegistrations200ResponseDocumentTypesInner interface.
 */
export function instanceOfGetAllRegistrations200ResponseDocumentTypesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function GetAllRegistrations200ResponseDocumentTypesInnerFromJSON(json: any): GetAllRegistrations200ResponseDocumentTypesInner {
    return GetAllRegistrations200ResponseDocumentTypesInnerFromJSONTyped(json, false);
}

export function GetAllRegistrations200ResponseDocumentTypesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllRegistrations200ResponseDocumentTypesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'formTemplateId': !exists(json, 'form_template_id') ? undefined : json['form_template_id'],
        'formTitle': !exists(json, 'form_title') ? undefined : json['form_title'],
    };
}

export function GetAllRegistrations200ResponseDocumentTypesInnerToJSON(value?: GetAllRegistrations200ResponseDocumentTypesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'form_template_id': value.formTemplateId,
        'form_title': value.formTitle,
    };
}

