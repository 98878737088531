/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MobilePlanningCreateCardAndTask201ResponseData } from './MobilePlanningCreateCardAndTask201ResponseData';
import {
    MobilePlanningCreateCardAndTask201ResponseDataFromJSON,
    MobilePlanningCreateCardAndTask201ResponseDataFromJSONTyped,
    MobilePlanningCreateCardAndTask201ResponseDataToJSON,
} from './MobilePlanningCreateCardAndTask201ResponseData';

/**
 * 
 * @export
 * @interface MobilePlanningCreateCardAndTask201Response
 */
export interface MobilePlanningCreateCardAndTask201Response {
    /**
     * 
     * @type {boolean}
     * @memberof MobilePlanningCreateCardAndTask201Response
     */
    success: boolean;
    /**
     * 
     * @type {MobilePlanningCreateCardAndTask201ResponseData}
     * @memberof MobilePlanningCreateCardAndTask201Response
     */
    data: MobilePlanningCreateCardAndTask201ResponseData;
}

/**
 * Check if a given object implements the MobilePlanningCreateCardAndTask201Response interface.
 */
export function instanceOfMobilePlanningCreateCardAndTask201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function MobilePlanningCreateCardAndTask201ResponseFromJSON(json: any): MobilePlanningCreateCardAndTask201Response {
    return MobilePlanningCreateCardAndTask201ResponseFromJSONTyped(json, false);
}

export function MobilePlanningCreateCardAndTask201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobilePlanningCreateCardAndTask201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': MobilePlanningCreateCardAndTask201ResponseDataFromJSON(json['data']),
    };
}

export function MobilePlanningCreateCardAndTask201ResponseToJSON(value?: MobilePlanningCreateCardAndTask201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': MobilePlanningCreateCardAndTask201ResponseDataToJSON(value.data),
    };
}

