import * as Sentry from "@sentry/angular-ivy";
import { environment } from "src/environments/environment";

export function sentryCaptureException(error: any) {
    // Wraps calls to Sentry for sanity
    if (!environment.enableSentry) {
        return;
    }

    Sentry.captureException(error);
}
