/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateInvoiceRequest
 */
export interface CreateInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInvoiceRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvoiceRequest
     */
    contactId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateInvoiceRequest
     */
    issuedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateInvoiceRequest
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateInvoiceRequest
     */
    dateTo?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateInvoiceRequest
     */
    activityIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvoiceRequest
     */
    includeInvoicedForms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvoiceRequest
     */
    combineProductLines?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvoiceRequest
     */
    combineWorkingTimeLines?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvoiceRequest
     */
    groupByForms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvoiceRequest
     */
    markInvoiced?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvoiceRequest
     */
    hideUsagePrices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvoiceRequest
     */
    isFinalInvoice?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateInvoiceRequest
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateInvoiceRequest
     */
    completionPercentage?: number;
}

/**
 * Check if a given object implements the CreateInvoiceRequest interface.
 */
export function instanceOfCreateInvoiceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateInvoiceRequestFromJSON(json: any): CreateInvoiceRequest {
    return CreateInvoiceRequestFromJSONTyped(json, false);
}

export function CreateInvoiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInvoiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'issuedDate': !exists(json, 'issued_date') ? undefined : (new Date(json['issued_date'])),
        'dateFrom': !exists(json, 'date_from') ? undefined : (new Date(json['date_from'])),
        'dateTo': !exists(json, 'date_to') ? undefined : (new Date(json['date_to'])),
        'activityIds': !exists(json, 'activity_ids') ? undefined : json['activity_ids'],
        'includeInvoicedForms': !exists(json, 'include_invoiced_forms') ? undefined : json['include_invoiced_forms'],
        'combineProductLines': !exists(json, 'combine_product_lines') ? undefined : json['combine_product_lines'],
        'combineWorkingTimeLines': !exists(json, 'combine_working_time_lines') ? undefined : json['combine_working_time_lines'],
        'groupByForms': !exists(json, 'group_by_forms') ? undefined : json['group_by_forms'],
        'markInvoiced': !exists(json, 'mark_invoiced') ? undefined : json['mark_invoiced'],
        'hideUsagePrices': !exists(json, 'hide_usage_prices') ? undefined : json['hide_usage_prices'],
        'isFinalInvoice': !exists(json, 'is_final_invoice') ? undefined : json['is_final_invoice'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'completionPercentage': !exists(json, 'completion_percentage') ? undefined : json['completion_percentage'],
    };
}

export function CreateInvoiceRequestToJSON(value?: CreateInvoiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'contact_id': value.contactId,
        'issued_date': value.issuedDate === undefined ? undefined : (value.issuedDate.toISOString().substr(0,10)),
        'date_from': value.dateFrom === undefined ? undefined : (value.dateFrom.toISOString().substr(0,10)),
        'date_to': value.dateTo === undefined ? undefined : (value.dateTo.toISOString().substr(0,10)),
        'activity_ids': value.activityIds,
        'include_invoiced_forms': value.includeInvoicedForms,
        'combine_product_lines': value.combineProductLines,
        'combine_working_time_lines': value.combineWorkingTimeLines,
        'group_by_forms': value.groupByForms,
        'mark_invoiced': value.markInvoiced,
        'hide_usage_prices': value.hideUsagePrices,
        'is_final_invoice': value.isFinalInvoice,
        'amount': value.amount,
        'completion_percentage': value.completionPercentage,
    };
}

