/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsersGetSpecified200ResponseDataInner } from './UsersGetSpecified200ResponseDataInner';
import {
    UsersGetSpecified200ResponseDataInnerFromJSON,
    UsersGetSpecified200ResponseDataInnerFromJSONTyped,
    UsersGetSpecified200ResponseDataInnerToJSON,
} from './UsersGetSpecified200ResponseDataInner';

/**
 * 
 * @export
 * @interface UsersGetSpecified200Response
 */
export interface UsersGetSpecified200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UsersGetSpecified200Response
     */
    success: boolean;
    /**
     * 
     * @type {Array<UsersGetSpecified200ResponseDataInner>}
     * @memberof UsersGetSpecified200Response
     */
    data: Array<UsersGetSpecified200ResponseDataInner>;
}

/**
 * Check if a given object implements the UsersGetSpecified200Response interface.
 */
export function instanceOfUsersGetSpecified200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UsersGetSpecified200ResponseFromJSON(json: any): UsersGetSpecified200Response {
    return UsersGetSpecified200ResponseFromJSONTyped(json, false);
}

export function UsersGetSpecified200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetSpecified200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': ((json['data'] as Array<any>).map(UsersGetSpecified200ResponseDataInnerFromJSON)),
    };
}

export function UsersGetSpecified200ResponseToJSON(value?: UsersGetSpecified200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': ((value.data as Array<any>).map(UsersGetSpecified200ResponseDataInnerToJSON)),
    };
}

