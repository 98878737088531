/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationDetails } from './PaginationDetails';
import {
    PaginationDetailsFromJSON,
    PaginationDetailsFromJSONTyped,
    PaginationDetailsToJSON,
} from './PaginationDetails';
import type { ProductsList200ResponseAllOfDataInner } from './ProductsList200ResponseAllOfDataInner';
import {
    ProductsList200ResponseAllOfDataInnerFromJSON,
    ProductsList200ResponseAllOfDataInnerFromJSONTyped,
    ProductsList200ResponseAllOfDataInnerToJSON,
} from './ProductsList200ResponseAllOfDataInner';

/**
 * 
 * @export
 * @interface ProductsList200Response
 */
export interface ProductsList200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ProductsList200Response
     */
    success: boolean;
    /**
     * 
     * @type {PaginationDetails}
     * @memberof ProductsList200Response
     */
    pagination: PaginationDetails;
    /**
     * 
     * @type {Array<ProductsList200ResponseAllOfDataInner>}
     * @memberof ProductsList200Response
     */
    data: Array<ProductsList200ResponseAllOfDataInner>;
}

/**
 * Check if a given object implements the ProductsList200Response interface.
 */
export function instanceOfProductsList200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "pagination" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ProductsList200ResponseFromJSON(json: any): ProductsList200Response {
    return ProductsList200ResponseFromJSONTyped(json, false);
}

export function ProductsList200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsList200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'pagination': PaginationDetailsFromJSON(json['pagination']),
        'data': ((json['data'] as Array<any>).map(ProductsList200ResponseAllOfDataInnerFromJSON)),
    };
}

export function ProductsList200ResponseToJSON(value?: ProductsList200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'pagination': PaginationDetailsToJSON(value.pagination),
        'data': ((value.data as Array<any>).map(ProductsList200ResponseAllOfDataInnerToJSON)),
    };
}

