/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Card } from './Card';
import {
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';
import type { PaginationDetails } from './PaginationDetails';
import {
    PaginationDetailsFromJSON,
    PaginationDetailsFromJSONTyped,
    PaginationDetailsToJSON,
} from './PaginationDetails';

/**
 * 
 * @export
 * @interface GetCard200Response
 */
export interface GetCard200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetCard200Response
     */
    success: boolean;
    /**
     * 
     * @type {Card}
     * @memberof GetCard200Response
     */
    data: Card;
    /**
     * 
     * @type {PaginationDetails}
     * @memberof GetCard200Response
     */
    pagination: PaginationDetails;
}

/**
 * Check if a given object implements the GetCard200Response interface.
 */
export function instanceOfGetCard200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function GetCard200ResponseFromJSON(json: any): GetCard200Response {
    return GetCard200ResponseFromJSONTyped(json, false);
}

export function GetCard200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCard200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': CardFromJSON(json['data']),
        'pagination': PaginationDetailsFromJSON(json['pagination']),
    };
}

export function GetCard200ResponseToJSON(value?: GetCard200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': CardToJSON(value.data),
        'pagination': PaginationDetailsToJSON(value.pagination),
    };
}

