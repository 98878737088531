/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OfferLine } from './OfferLine';
import {
    OfferLineFromJSON,
    OfferLineFromJSONTyped,
    OfferLineToJSON,
} from './OfferLine';

/**
 * 
 * @export
 * @interface GatherOfferLines200Response
 */
export interface GatherOfferLines200Response {
    /**
     * 
     * @type {Array<OfferLine>}
     * @memberof GatherOfferLines200Response
     */
    data: Array<OfferLine>;
}

/**
 * Check if a given object implements the GatherOfferLines200Response interface.
 */
export function instanceOfGatherOfferLines200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GatherOfferLines200ResponseFromJSON(json: any): GatherOfferLines200Response {
    return GatherOfferLines200ResponseFromJSONTyped(json, false);
}

export function GatherOfferLines200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatherOfferLines200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(OfferLineFromJSON)),
    };
}

export function GatherOfferLines200ResponseToJSON(value?: GatherOfferLines200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(OfferLineToJSON)),
    };
}

