/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextTemplate
 */
export interface TextTemplate {
    /**
     * 
     * @type {string}
     * @memberof TextTemplate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TextTemplate
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof TextTemplate
     */
    createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof TextTemplate
     */
    modifiedById?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TextTemplate
     */
    expense?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TextTemplate
     */
    offer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TextTemplate
     */
    invoice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TextTemplate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TextTemplate
     */
    content: string;
    /**
     * 
     * @type {Date}
     * @memberof TextTemplate
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TextTemplate
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof TextTemplate
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the TextTemplate interface.
 */
export function instanceOfTextTemplate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function TextTemplateFromJSON(json: any): TextTemplate {
    return TextTemplateFromJSONTyped(json, false);
}

export function TextTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'expense': !exists(json, 'expense') ? undefined : json['expense'],
        'offer': !exists(json, 'offer') ? undefined : json['offer'],
        'invoice': !exists(json, 'invoice') ? undefined : json['invoice'],
        'title': json['title'],
        'content': json['content'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function TextTemplateToJSON(value?: TextTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'company_id': value.companyId,
        'created_by_id': value.createdById,
        'modified_by_id': value.modifiedById,
        'expense': value.expense,
        'offer': value.offer,
        'invoice': value.invoice,
        'title': value.title,
        'content': value.content,
    };
}

