import { MobileGetCards200ResponseDataInner } from "@apacta/sdk";

export class SetSelectedCard {
    public static readonly type = "[Projects] Set selected card";

    constructor(public payload: MobileGetCards200ResponseDataInner) {}
}

export class ClearSelectedCard {
    public static readonly type = "[Projects] Clear selected card";

    constructor() {}
}
