/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailAction
 */
export interface EmailAction {
    /**
     * 
     * @type {string}
     * @memberof EmailAction
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAction
     */
    emailId: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAction
     */
    event: EmailActionEventEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailAction
     */
    eventId: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailAction
     */
    eventTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmailAction
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmailAction
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof EmailAction
     */
    readonly deleted: Date | null;
}


/**
 * @export
 */
export const EmailActionEventEnum = {
    Accepted: 'accepted',
    Opened: 'opened',
    Clicked: 'clicked',
    Unsubscribed: 'unsubscribed',
    Delivered: 'delivered',
    PermanentlyFailed: 'permanently_failed',
    TemporarilyFailed: 'temporarily_failed',
    Complained: 'complained'
} as const;
export type EmailActionEventEnum = typeof EmailActionEventEnum[keyof typeof EmailActionEventEnum];


/**
 * Check if a given object implements the EmailAction interface.
 */
export function instanceOfEmailAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "emailId" in value;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "eventId" in value;
    isInstance = isInstance && "eventTime" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function EmailActionFromJSON(json: any): EmailAction {
    return EmailActionFromJSONTyped(json, false);
}

export function EmailActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'emailId': json['email_id'],
        'event': json['event'],
        'eventId': json['event_id'],
        'eventTime': (new Date(json['event_time'])),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function EmailActionToJSON(value?: EmailAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_id': value.emailId,
        'event': value.event,
        'event_id': value.eventId,
        'event_time': (value.eventTime.toISOString()),
    };
}

