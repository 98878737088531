/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseFile } from './BaseFile';
import {
    BaseFileFromJSON,
    BaseFileFromJSONTyped,
    BaseFileToJSON,
} from './BaseFile';

/**
 * 
 * @export
 * @interface FrontpageFeedDTOFormFields
 */
export interface FrontpageFeedDTOFormFields {
    /**
     * 
     * @type {Array<BaseFile>}
     * @memberof FrontpageFeedDTOFormFields
     */
    images?: Array<BaseFile>;
    /**
     * 
     * @type {string}
     * @memberof FrontpageFeedDTOFormFields
     */
    timeIntervalFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontpageFeedDTOFormFields
     */
    timeIntervalTo?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontpageFeedDTOFormFields
     */
    timepicker?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontpageFeedDTOFormFields
     */
    pause?: string;
}

/**
 * Check if a given object implements the FrontpageFeedDTOFormFields interface.
 */
export function instanceOfFrontpageFeedDTOFormFields(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FrontpageFeedDTOFormFieldsFromJSON(json: any): FrontpageFeedDTOFormFields {
    return FrontpageFeedDTOFormFieldsFromJSONTyped(json, false);
}

export function FrontpageFeedDTOFormFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontpageFeedDTOFormFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(BaseFileFromJSON)),
        'timeIntervalFrom': !exists(json, 'time_interval_from') ? undefined : json['time_interval_from'],
        'timeIntervalTo': !exists(json, 'time_interval_to') ? undefined : json['time_interval_to'],
        'timepicker': !exists(json, 'timepicker') ? undefined : json['timepicker'],
        'pause': !exists(json, 'pause') ? undefined : json['pause'],
    };
}

export function FrontpageFeedDTOFormFieldsToJSON(value?: FrontpageFeedDTOFormFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(BaseFileToJSON)),
        'time_interval_from': value.timeIntervalFrom,
        'time_interval_to': value.timeIntervalTo,
        'timepicker': value.timepicker,
        'pause': value.pause,
    };
}

