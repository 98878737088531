/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Activity } from './Activity';
import {
    ActivityFromJSON,
    ActivityFromJSONTyped,
    ActivityToJSON,
} from './Activity';

/**
 * 
 * @export
 * @interface RegistrationsDTO
 */
export interface RegistrationsDTO {
    /**
     * 
     * @type {string}
     * @memberof RegistrationsDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationsDTO
     */
    activityId: string;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationsDTO
     */
    date: Date | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationsDTO
     */
    type: RegistrationsDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegistrationsDTO
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationsDTO
     */
    preview?: string;
    /**
     * User full name
     * @type {string}
     * @memberof RegistrationsDTO
     */
    employee: string;
    /**
     * User image url
     * @type {string}
     * @memberof RegistrationsDTO
     */
    avatar?: string;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationsDTO
     */
    invoicedDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationsDTO
     */
    invoiced: boolean;
    /**
     * Will display form template (Only for Forms)
     * @type {string}
     * @memberof RegistrationsDTO
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationsDTO
     */
    workDescription: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationsDTO
     */
    vendorName: string;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationsDTO
     */
    approvedDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationsDTO
     */
    approved: boolean;
    /**
     * 
     * @type {Activity}
     * @memberof RegistrationsDTO
     */
    activity?: Activity;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationsDTO
     */
    deletedDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationsDTO
     */
    deleted: boolean;
}


/**
 * @export
 */
export const RegistrationsDTOTypeEnum = {
    Form: 'form',
    Expense: 'expense',
    Invoice: 'invoice'
} as const;
export type RegistrationsDTOTypeEnum = typeof RegistrationsDTOTypeEnum[keyof typeof RegistrationsDTOTypeEnum];


/**
 * Check if a given object implements the RegistrationsDTO interface.
 */
export function instanceOfRegistrationsDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "activityId" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "employee" in value;
    isInstance = isInstance && "invoicedDate" in value;
    isInstance = isInstance && "invoiced" in value;
    isInstance = isInstance && "template" in value;
    isInstance = isInstance && "workDescription" in value;
    isInstance = isInstance && "vendorName" in value;
    isInstance = isInstance && "approvedDate" in value;
    isInstance = isInstance && "approved" in value;
    isInstance = isInstance && "deletedDate" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function RegistrationsDTOFromJSON(json: any): RegistrationsDTO {
    return RegistrationsDTOFromJSONTyped(json, false);
}

export function RegistrationsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'activityId': json['activity_id'],
        'date': (json['date'] === null ? null : new Date(json['date'])),
        'type': json['type'],
        'link': json['link'],
        'preview': !exists(json, 'preview') ? undefined : json['preview'],
        'employee': json['employee'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'invoicedDate': (json['invoiced_date'] === null ? null : new Date(json['invoiced_date'])),
        'invoiced': json['invoiced'],
        'template': json['template'],
        'workDescription': json['work_description'],
        'vendorName': json['vendor_name'],
        'approvedDate': (json['approved_date'] === null ? null : new Date(json['approved_date'])),
        'approved': json['approved'],
        'activity': !exists(json, 'activity') ? undefined : ActivityFromJSON(json['activity']),
        'deletedDate': (json['deleted_date'] === null ? null : new Date(json['deleted_date'])),
        'deleted': json['deleted'],
    };
}

export function RegistrationsDTOToJSON(value?: RegistrationsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'activity_id': value.activityId,
        'date': (value.date === null ? null : value.date.toISOString()),
        'type': value.type,
        'link': value.link,
        'preview': value.preview,
        'employee': value.employee,
        'avatar': value.avatar,
        'invoiced_date': (value.invoicedDate === null ? null : value.invoicedDate.toISOString()),
        'invoiced': value.invoiced,
        'template': value.template,
        'work_description': value.workDescription,
        'vendor_name': value.vendorName,
        'approved_date': (value.approvedDate === null ? null : value.approvedDate.toISOString()),
        'approved': value.approved,
        'activity': ActivityToJSON(value.activity),
        'deleted_date': (value.deletedDate === null ? null : value.deletedDate.toISOString()),
        'deleted': value.deleted,
    };
}

