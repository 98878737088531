/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { PaymentTerm } from './PaymentTerm';
import {
    PaymentTermFromJSON,
    PaymentTermFromJSONTyped,
    PaymentTermToJSON,
} from './PaymentTerm';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';

/**
 * 
 * @export
 * @interface InvoiceReadDTO
 */
export interface InvoiceReadDTO {
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    contactId?: string;
    /**
     * 
     * @type {Contact}
     * @memberof InvoiceReadDTO
     */
    contact?: Contact;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    vendorId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    readonly orderLineGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    currencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    readonly createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    paymentTermId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    readonly companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    readonly integrationId?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceReadDTO
     */
    invoiceNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceReadDTO
     */
    offerNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    reference?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceReadDTO
     */
    issuedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceReadDTO
     */
    paymentDueDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceReadDTO
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceReadDTO
     */
    dateTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof InvoiceReadDTO
     */
    readonly grossPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceReadDTO
     */
    readonly totalCostPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceReadDTO
     */
    readonly netPayment?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    isDraft?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    isOffer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly isLocked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly euCustomer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly vatPercent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    readonly erpId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    readonly erpPaymentTermId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly projectOverviewAttached?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    readonly downloaded?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly includeInvoicedLines?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly allProductsOneLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly allWorkingHoursOneLine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly showPricesProductsAndHours?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly showProductImages?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly showProductsProductBundle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly showEmployeeName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly groupByForms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceReadDTO
     */
    readonly isFinalInvoice?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceReadDTO
     */
    readonly totalDiscountPercent?: number;
    /**
     * 
     * @type {PaymentTerm}
     * @memberof InvoiceReadDTO
     */
    paymentTerm?: PaymentTerm;
    /**
     * 
     * @type {Project}
     * @memberof InvoiceReadDTO
     */
    project?: Project;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceReadDTO
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceReadDTO
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof InvoiceReadDTO
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReadDTO
     */
    readonly pdfUrl?: string | null;
}

/**
 * Check if a given object implements the InvoiceReadDTO interface.
 */
export function instanceOfInvoiceReadDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function InvoiceReadDTOFromJSON(json: any): InvoiceReadDTO {
    return InvoiceReadDTOFromJSONTyped(json, false);
}

export function InvoiceReadDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceReadDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'contact': !exists(json, 'contact') ? undefined : ContactFromJSON(json['contact']),
        'vendorId': !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
        'orderLineGroupId': !exists(json, 'order_line_group_id') ? undefined : json['order_line_group_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'currencyId': !exists(json, 'currency_id') ? undefined : json['currency_id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'paymentTermId': !exists(json, 'payment_term_id') ? undefined : json['payment_term_id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'invoiceNumber': !exists(json, 'invoice_number') ? undefined : json['invoice_number'],
        'offerNumber': !exists(json, 'offer_number') ? undefined : json['offer_number'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'issuedDate': !exists(json, 'issued_date') ? undefined : (new Date(json['issued_date'])),
        'paymentDueDate': !exists(json, 'payment_due_date') ? undefined : (new Date(json['payment_due_date'])),
        'dateFrom': !exists(json, 'date_from') ? undefined : (new Date(json['date_from'])),
        'dateTo': !exists(json, 'date_to') ? undefined : (new Date(json['date_to'])),
        'grossPayment': !exists(json, 'gross_payment') ? undefined : json['gross_payment'],
        'totalCostPrice': !exists(json, 'total_cost_price') ? undefined : json['total_cost_price'],
        'netPayment': !exists(json, 'net_payment') ? undefined : json['net_payment'],
        'isDraft': !exists(json, 'is_draft') ? undefined : json['is_draft'],
        'isOffer': !exists(json, 'is_offer') ? undefined : json['is_offer'],
        'isLocked': !exists(json, 'is_locked') ? undefined : json['is_locked'],
        'euCustomer': !exists(json, 'eu_customer') ? undefined : json['eu_customer'],
        'vatPercent': !exists(json, 'vat_percent') ? undefined : json['vat_percent'],
        'erpId': !exists(json, 'erp_id') ? undefined : json['erp_id'],
        'erpPaymentTermId': !exists(json, 'erp_payment_term_id') ? undefined : json['erp_payment_term_id'],
        'projectOverviewAttached': !exists(json, 'project_overview_attached') ? undefined : json['project_overview_attached'],
        'downloaded': !exists(json, 'downloaded') ? undefined : json['downloaded'],
        'includeInvoicedLines': !exists(json, 'include_invoiced_lines') ? undefined : json['include_invoiced_lines'],
        'allProductsOneLine': !exists(json, 'all_products_one_line') ? undefined : json['all_products_one_line'],
        'allWorkingHoursOneLine': !exists(json, 'all_working_hours_one_line') ? undefined : json['all_working_hours_one_line'],
        'showPricesProductsAndHours': !exists(json, 'show_prices_products_and_hours') ? undefined : json['show_prices_products_and_hours'],
        'showProductImages': !exists(json, 'show_product_images') ? undefined : json['show_product_images'],
        'showProductsProductBundle': !exists(json, 'show_products_product_bundle') ? undefined : json['show_products_product_bundle'],
        'showEmployeeName': !exists(json, 'show_employee_name') ? undefined : json['show_employee_name'],
        'groupByForms': !exists(json, 'group_by_forms') ? undefined : json['group_by_forms'],
        'isFinalInvoice': !exists(json, 'is_final_invoice') ? undefined : json['is_final_invoice'],
        'totalDiscountPercent': !exists(json, 'total_discount_percent') ? undefined : json['total_discount_percent'],
        'paymentTerm': !exists(json, 'payment_term') ? undefined : PaymentTermFromJSON(json['payment_term']),
        'project': !exists(json, 'project') ? undefined : ProjectFromJSON(json['project']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'pdfUrl': !exists(json, 'pdf_url') ? undefined : json['pdf_url'],
    };
}

export function InvoiceReadDTOToJSON(value?: InvoiceReadDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact_id': value.contactId,
        'contact': ContactToJSON(value.contact),
        'vendor_id': value.vendorId,
        'project_id': value.projectId,
        'currency_id': value.currencyId,
        'payment_term_id': value.paymentTermId,
        'invoice_number': value.invoiceNumber,
        'offer_number': value.offerNumber,
        'title': value.title,
        'message': value.message,
        'reference': value.reference,
        'issued_date': value.issuedDate === undefined ? undefined : (value.issuedDate.toISOString().substr(0,10)),
        'payment_due_date': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate.toISOString().substr(0,10)),
        'date_from': value.dateFrom === undefined ? undefined : (value.dateFrom.toISOString().substr(0,10)),
        'date_to': value.dateTo === undefined ? undefined : (value.dateTo.toISOString().substr(0,10)),
        'is_draft': value.isDraft,
        'is_offer': value.isOffer,
        'payment_term': PaymentTermToJSON(value.paymentTerm),
        'project': ProjectToJSON(value.project),
    };
}

