import { Injectable } from "@angular/core";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import {
    LoadProjectStatuses,
    LoadProjectStatusesPagination,
    ClearProjectStatus,
    LoadProjectStatusTypes,
} from "./actions/set-project-statuses.action";
import { Pagination } from "src/app/services/response-models/projects.response.model";
import {
    ProjectStatus,
    ProjectStatusType,
} from "src/app/services/response-models/project-statuses.reponse.model";

export interface ProjectStatusesStateModel {
    projectStatuses: ProjectStatus[];
    projectStatusesPagination: Pagination;
    projectStatusTypes: ProjectStatusType[];
}

@State<ProjectStatusesStateModel>({
    name: "project_statuses",
    defaults: {
        projectStatuses: [],
        projectStatusesPagination: null,
        projectStatusTypes: [],
    },
})
@Injectable()
export class ProjectStatusesState {
    @Selector()
    static getProjectStatuses(state: ProjectStatusesStateModel) {
        return state.projectStatuses;
    }

    @Selector()
    static getPagination(state: ProjectStatusesStateModel) {
        return state.projectStatusesPagination;
    }

    @Selector()
    static getProjectStatusTypes(state: ProjectStatusesStateModel) {
        return state.projectStatusTypes;
    }

    @Action(LoadProjectStatuses)
    load(ctx: StateContext<ProjectStatusesStateModel>, { payload }: LoadProjectStatuses) {
        ctx.setState({ ...ctx.getState(), projectStatuses: payload });
    }

    @Action(LoadProjectStatusesPagination)
    loadPagination(
        ctx: StateContext<ProjectStatusesStateModel>,
        { payload }: LoadProjectStatusesPagination
    ) {
        ctx.setState({ ...ctx.getState(), projectStatusesPagination: payload });
    }

    @Action(ClearProjectStatus)
    clear(ctx: StateContext<ProjectStatusesStateModel>) {
        ctx.setState({ ...ctx.getState(), projectStatuses: [] });
    }

    @Action(LoadProjectStatusTypes)
    loadStatusTypes(
        ctx: StateContext<ProjectStatusesStateModel>,
        { payload }: LoadProjectStatusTypes
    ) {
        ctx.setState({ ...ctx.getState(), projectStatusTypes: payload });
    }
}
