/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlPanelProductsToTask } from './ControlPanelProductsToTask';
import {
    ControlPanelProductsToTaskFromJSON,
    ControlPanelProductsToTaskFromJSONTyped,
    ControlPanelProductsToTaskToJSON,
} from './ControlPanelProductsToTask';
import type { CreateCardAndTaskRequestCardsInner } from './CreateCardAndTaskRequestCardsInner';
import {
    CreateCardAndTaskRequestCardsInnerFromJSON,
    CreateCardAndTaskRequestCardsInnerFromJSONTyped,
    CreateCardAndTaskRequestCardsInnerToJSON,
} from './CreateCardAndTaskRequestCardsInner';

/**
 * 
 * @export
 * @interface CreateCardAndTaskRequest
 */
export interface CreateCardAndTaskRequest {
    /**
     * If true, all existing cards for this task will be deleted - useful for "batch editing" feature
     * @type {boolean}
     * @memberof CreateCardAndTaskRequest
     */
    deleteExistingCards?: boolean;
    /**
     * If true, weekends will be included in the task dates
     * @type {boolean}
     * @memberof CreateCardAndTaskRequest
     */
    includeWeekends?: boolean;
    /**
     * 
     * @type {Array<CreateCardAndTaskRequestCardsInner>}
     * @memberof CreateCardAndTaskRequest
     */
    cards: Array<CreateCardAndTaskRequestCardsInner>;
    /**
     * 
     * @type {Array<ControlPanelProductsToTask>}
     * @memberof CreateCardAndTaskRequest
     */
    products?: Array<ControlPanelProductsToTask>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCardAndTaskRequest
     */
    formTemplateIds?: Array<string>;
}

/**
 * Check if a given object implements the CreateCardAndTaskRequest interface.
 */
export function instanceOfCreateCardAndTaskRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cards" in value;

    return isInstance;
}

export function CreateCardAndTaskRequestFromJSON(json: any): CreateCardAndTaskRequest {
    return CreateCardAndTaskRequestFromJSONTyped(json, false);
}

export function CreateCardAndTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCardAndTaskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteExistingCards': !exists(json, 'delete_existing_cards') ? undefined : json['delete_existing_cards'],
        'includeWeekends': !exists(json, 'include_weekends') ? undefined : json['include_weekends'],
        'cards': ((json['cards'] as Array<any>).map(CreateCardAndTaskRequestCardsInnerFromJSON)),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ControlPanelProductsToTaskFromJSON)),
        'formTemplateIds': !exists(json, 'form_template_ids') ? undefined : json['form_template_ids'],
    };
}

export function CreateCardAndTaskRequestToJSON(value?: CreateCardAndTaskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete_existing_cards': value.deleteExistingCards,
        'include_weekends': value.includeWeekends,
        'cards': ((value.cards as Array<any>).map(CreateCardAndTaskRequestCardsInnerToJSON)),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ControlPanelProductsToTaskToJSON)),
        'form_template_ids': value.formTemplateIds,
    };
}

