/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimeEntryRuleGroup } from './TimeEntryRuleGroup';
import {
    TimeEntryRuleGroupFromJSON,
    TimeEntryRuleGroupFromJSONTyped,
    TimeEntryRuleGroupToJSON,
} from './TimeEntryRuleGroup';

/**
 * 
 * @export
 * @interface GetTimeEntryRuleGroups200Response
 */
export interface GetTimeEntryRuleGroups200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetTimeEntryRuleGroups200Response
     */
    success: boolean;
    /**
     * 
     * @type {Array<TimeEntryRuleGroup>}
     * @memberof GetTimeEntryRuleGroups200Response
     */
    data: Array<TimeEntryRuleGroup>;
}

/**
 * Check if a given object implements the GetTimeEntryRuleGroups200Response interface.
 */
export function instanceOfGetTimeEntryRuleGroups200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetTimeEntryRuleGroups200ResponseFromJSON(json: any): GetTimeEntryRuleGroups200Response {
    return GetTimeEntryRuleGroups200ResponseFromJSONTyped(json, false);
}

export function GetTimeEntryRuleGroups200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTimeEntryRuleGroups200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': ((json['data'] as Array<any>).map(TimeEntryRuleGroupFromJSON)),
    };
}

export function GetTimeEntryRuleGroups200ResponseToJSON(value?: GetTimeEntryRuleGroups200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': ((value.data as Array<any>).map(TimeEntryRuleGroupToJSON)),
    };
}

