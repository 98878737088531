/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageUrls } from './ImageUrls';
import {
    ImageUrlsFromJSON,
    ImageUrlsFromJSONTyped,
    ImageUrlsToJSON,
} from './ImageUrls';
import type { Vendor } from './Vendor';
import {
    VendorFromJSON,
    VendorFromJSONTyped,
    VendorToJSON,
} from './Vendor';
import type { VendorProductPrice } from './VendorProductPrice';
import {
    VendorProductPriceFromJSON,
    VendorProductPriceFromJSONTyped,
    VendorProductPriceToJSON,
} from './VendorProductPrice';

/**
 * 
 * @export
 * @interface VendorProduct
 */
export interface VendorProduct {
    /**
     * 
     * @type {string}
     * @memberof VendorProduct
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProduct
     */
    readonly createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProduct
     */
    vendorId?: string | null;
    /**
     * 
     * @type {Vendor}
     * @memberof VendorProduct
     */
    readonly vendor?: Vendor;
    /**
     * 
     * @type {string}
     * @memberof VendorProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProduct
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProduct
     */
    productNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProduct
     */
    productCategoryNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProduct
     */
    barcode?: string;
    /**
     * 
     * @type {ImageUrls}
     * @memberof VendorProduct
     */
    imageUrls?: ImageUrls;
    /**
     * 
     * @type {number}
     * @memberof VendorProduct
     */
    buyingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof VendorProduct
     */
    sellingPrice?: number;
    /**
     * Gross price
     * @type {number}
     * @memberof VendorProduct
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof VendorProduct
     */
    unitName?: string;
    /**
     * 
     * @type {number}
     * @memberof VendorProduct
     */
    unitFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof VendorProduct
     */
    pricePerUnit?: number;
    /**
     * 
     * @type {Array<VendorProductPrice>}
     * @memberof VendorProduct
     */
    readonly priceHistory?: Array<VendorProductPrice> | null;
    /**
     * 
     * @type {Date}
     * @memberof VendorProduct
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof VendorProduct
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof VendorProduct
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the VendorProduct interface.
 */
export function instanceOfVendorProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;

    return isInstance;
}

export function VendorProductFromJSON(json: any): VendorProduct {
    return VendorProductFromJSONTyped(json, false);
}

export function VendorProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'vendorId': !exists(json, 'vendor_id') ? undefined : json['vendor_id'],
        'vendor': !exists(json, 'vendor') ? undefined : VendorFromJSON(json['vendor']),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'productNumber': !exists(json, 'product_number') ? undefined : json['product_number'],
        'productCategoryNumber': !exists(json, 'product_category_number') ? undefined : json['product_category_number'],
        'barcode': !exists(json, 'barcode') ? undefined : json['barcode'],
        'imageUrls': !exists(json, 'image_urls') ? undefined : ImageUrlsFromJSON(json['image_urls']),
        'buyingPrice': !exists(json, 'buying_price') ? undefined : json['buying_price'],
        'sellingPrice': !exists(json, 'selling_price') ? undefined : json['selling_price'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'unitName': !exists(json, 'unit_name') ? undefined : json['unit_name'],
        'unitFactor': !exists(json, 'unit_factor') ? undefined : json['unit_factor'],
        'pricePerUnit': !exists(json, 'price_per_unit') ? undefined : json['price_per_unit'],
        'priceHistory': !exists(json, 'price_history') ? undefined : (json['price_history'] === null ? null : (json['price_history'] as Array<any>).map(VendorProductPriceFromJSON)),
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function VendorProductToJSON(value?: VendorProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vendor_id': value.vendorId,
        'name': value.name,
        'description': value.description,
        'product_number': value.productNumber,
        'product_category_number': value.productCategoryNumber,
        'barcode': value.barcode,
        'image_urls': ImageUrlsToJSON(value.imageUrls),
        'buying_price': value.buyingPrice,
        'selling_price': value.sellingPrice,
        'price': value.price,
        'unit_name': value.unitName,
        'unit_factor': value.unitFactor,
        'price_per_unit': value.pricePerUnit,
    };
}

