/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ControlPanelApiProjectFeedResponseDTODataInner } from './ControlPanelApiProjectFeedResponseDTODataInner';
import {
    ControlPanelApiProjectFeedResponseDTODataInnerFromJSON,
    ControlPanelApiProjectFeedResponseDTODataInnerFromJSONTyped,
    ControlPanelApiProjectFeedResponseDTODataInnerToJSON,
} from './ControlPanelApiProjectFeedResponseDTODataInner';
import type { ControlPanelApiProjectFeedResponseDTOPagination } from './ControlPanelApiProjectFeedResponseDTOPagination';
import {
    ControlPanelApiProjectFeedResponseDTOPaginationFromJSON,
    ControlPanelApiProjectFeedResponseDTOPaginationFromJSONTyped,
    ControlPanelApiProjectFeedResponseDTOPaginationToJSON,
} from './ControlPanelApiProjectFeedResponseDTOPagination';

/**
 * 
 * @export
 * @interface ControlPanelApiProjectFeedResponseDTO
 */
export interface ControlPanelApiProjectFeedResponseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ControlPanelApiProjectFeedResponseDTO
     */
    success: boolean;
    /**
     * 
     * @type {ControlPanelApiProjectFeedResponseDTOPagination}
     * @memberof ControlPanelApiProjectFeedResponseDTO
     */
    pagination: ControlPanelApiProjectFeedResponseDTOPagination;
    /**
     * 
     * @type {Array<ControlPanelApiProjectFeedResponseDTODataInner>}
     * @memberof ControlPanelApiProjectFeedResponseDTO
     */
    data: Array<ControlPanelApiProjectFeedResponseDTODataInner>;
}

/**
 * Check if a given object implements the ControlPanelApiProjectFeedResponseDTO interface.
 */
export function instanceOfControlPanelApiProjectFeedResponseDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "pagination" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ControlPanelApiProjectFeedResponseDTOFromJSON(json: any): ControlPanelApiProjectFeedResponseDTO {
    return ControlPanelApiProjectFeedResponseDTOFromJSONTyped(json, false);
}

export function ControlPanelApiProjectFeedResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPanelApiProjectFeedResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'pagination': ControlPanelApiProjectFeedResponseDTOPaginationFromJSON(json['pagination']),
        'data': ((json['data'] as Array<any>).map(ControlPanelApiProjectFeedResponseDTODataInnerFromJSON)),
    };
}

export function ControlPanelApiProjectFeedResponseDTOToJSON(value?: ControlPanelApiProjectFeedResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'pagination': ControlPanelApiProjectFeedResponseDTOPaginationToJSON(value.pagination),
        'data': ((value.data as Array<any>).map(ControlPanelApiProjectFeedResponseDTODataInnerToJSON)),
    };
}

