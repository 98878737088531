/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
 */
export interface RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName {
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    taskName: string;
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    projectId: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    userId: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    startTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    endTime?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    indexes?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    listIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    estimate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    taskDescription: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    labelIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    status?: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    repeat?: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameRepeatEnum;
    /**
     * 
     * @type {number}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName
     */
    interval?: number;
}


/**
 * @export
 */
export const RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameStatusEnum = {
    ToDo: 'to_do',
    Doing: 'doing',
    Done: 'done'
} as const;
export type RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameStatusEnum = typeof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameStatusEnum[keyof typeof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameStatusEnum];

/**
 * @export
 */
export const RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameRepeatEnum = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;
export type RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameRepeatEnum = typeof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameRepeatEnum[keyof typeof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameRepeatEnum];


/**
 * Check if a given object implements the RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName interface.
 */
export function instanceOfRequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "taskName" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "taskDescription" in value;

    return isInstance;
}

export function RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameFromJSON(json: any): RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName {
    return RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameFromJSONTyped(json, false);
}

export function RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskName': json['task_name'],
        'projectId': json['project_id'],
        'userId': json['user_id'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'startTime': !exists(json, 'start_time') ? undefined : (json['start_time'] === null ? null : new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (json['end_time'] === null ? null : new Date(json['end_time'])),
        'indexes': !exists(json, 'indexes') ? undefined : json['indexes'],
        'listIndex': !exists(json, 'list_index') ? undefined : json['list_index'],
        'estimate': !exists(json, 'estimate') ? undefined : json['estimate'],
        'taskDescription': json['task_description'],
        'labelIds': !exists(json, 'label_ids') ? undefined : json['label_ids'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'repeat': !exists(json, 'repeat') ? undefined : json['repeat'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
    };
}

export function RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskNameToJSON(value?: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task_name': value.taskName,
        'project_id': value.projectId,
        'user_id': value.userId,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'start_time': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime === null ? null : value.endTime.toISOString()),
        'indexes': value.indexes,
        'list_index': value.listIndex,
        'estimate': value.estimate,
        'task_description': value.taskDescription,
        'label_ids': value.labelIds,
        'status': value.status,
        'repeat': value.repeat,
        'interval': value.interval,
    };
}

