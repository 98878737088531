import { Pipe, PipeTransform } from "@angular/core";
import { FormTimeEntry } from "src/app/services/response-models/form.response.model";
import * as moment from "moment";

@Pipe({
    name: "sumTimeEntries",
})
export class SumTimeEntriesPipe implements PipeTransform {
    transform(value: Array<FormTimeEntry>, calculateAbsence?: string): any {
        // Check if value is not null

        if (!value) {
            return "00:00";
        }

        // If no time entries, return zero
        if (!value.length) {
            return "00:00";
        }

        // Sum time entries, format and return
        let timeEntriesSum = 0;

        for (const entry of value) {
            if (entry.time_entry_type) {
                if (
                    calculateAbsence ||
                    (entry.time_entry_type.is_absence === false &&
                        entry.time_entry_type.is_summable === true &&
                        entry.time_entry_type.time_unit === "hourly")
                ) {
                    timeEntriesSum += entry.sum;
                }
            } else {
                timeEntriesSum += entry.sum;
            }
        }

        const time = moment.duration(Number(timeEntriesSum), "seconds");

        return Math.floor(time.asHours()) + moment.utc(time.asMilliseconds()).format(":mm");
    }
}
