/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferKpiCardDTO
 */
export interface OfferKpiCardDTO {
    /**
     * 
     * @type {string}
     * @memberof OfferKpiCardDTO
     */
    status: OfferKpiCardDTOStatusEnum;
    /**
     * Number of offers from specific status
     * @type {number}
     * @memberof OfferKpiCardDTO
     */
    count: number;
    /**
     * Total offer amount with VAT
     * @type {number}
     * @memberof OfferKpiCardDTO
     */
    amount: number;
    /**
     * -1 without restriction. When period > 0 card is restricted by days
     * @type {number}
     * @memberof OfferKpiCardDTO
     */
    period: number;
}


/**
 * @export
 */
export const OfferKpiCardDTOStatusEnum = {
    Draft: 'draft',
    Sent: 'sent',
    Expired: 'expired',
    Accepted: 'accepted',
    Rejected: 'rejected'
} as const;
export type OfferKpiCardDTOStatusEnum = typeof OfferKpiCardDTOStatusEnum[keyof typeof OfferKpiCardDTOStatusEnum];


/**
 * Check if a given object implements the OfferKpiCardDTO interface.
 */
export function instanceOfOfferKpiCardDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "period" in value;

    return isInstance;
}

export function OfferKpiCardDTOFromJSON(json: any): OfferKpiCardDTO {
    return OfferKpiCardDTOFromJSONTyped(json, false);
}

export function OfferKpiCardDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferKpiCardDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'count': json['count'],
        'amount': json['amount'],
        'period': json['period'],
    };
}

export function OfferKpiCardDTOToJSON(value?: OfferKpiCardDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'count': value.count,
        'amount': value.amount,
        'period': value.period,
    };
}

