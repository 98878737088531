/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormTemplateSimpleEntity
 */
export interface FormTemplateSimpleEntity {
    /**
     * 
     * @type {string}
     * @memberof FormTemplateSimpleEntity
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof FormTemplateSimpleEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FormTemplateSimpleEntity
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof FormTemplateSimpleEntity
     */
    pdfTemplateIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof FormTemplateSimpleEntity
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormTemplateSimpleEntity
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormTemplateSimpleEntity
     */
    isTimeSheet?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FormTemplateSimpleEntity
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof FormTemplateSimpleEntity
     */
    readonly modified: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof FormTemplateSimpleEntity
     */
    readonly deleted: Date | null;
}

/**
 * Check if a given object implements the FormTemplateSimpleEntity interface.
 */
export function instanceOfFormTemplateSimpleEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function FormTemplateSimpleEntityFromJSON(json: any): FormTemplateSimpleEntity {
    return FormTemplateSimpleEntityFromJSONTyped(json, false);
}

export function FormTemplateSimpleEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormTemplateSimpleEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'identifier': json['identifier'],
        'pdfTemplateIdentifier': !exists(json, 'pdf_template_identifier') ? undefined : json['pdf_template_identifier'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'isTimeSheet': !exists(json, 'is_time_sheet') ? undefined : json['is_time_sheet'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'deleted': (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function FormTemplateSimpleEntityToJSON(value?: FormTemplateSimpleEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'identifier': value.identifier,
        'pdf_template_identifier': value.pdfTemplateIdentifier,
        'description': value.description,
        'is_active': value.isActive,
        'is_time_sheet': value.isTimeSheet,
    };
}

