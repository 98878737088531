import { Pagination } from "src/app/services/response-models/invoices.response.model";
import { Form } from "src/app/services/response-models/form.response.model";

export class LoadForms {
    public static readonly type = "[Forms] Save all forms";

    constructor(public payload: Form[] | any) {}
}

export class SavePagination {
    public static readonly type = "[Forms] Save pagination";

    constructor(public payload: Pagination) {}
}

export class ClearForms {
    public static readonly type = "[Forms] Clear forms";

    constructor() {}
}
