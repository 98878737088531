/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersGetKpiCards200ResponseDataInner
 */
export interface UsersGetKpiCards200ResponseDataInner {
    /**
     * Label for the kpi card
     * @type {string}
     * @memberof UsersGetKpiCards200ResponseDataInner
     */
    readonly label: string;
    /**
     * 
     * @type {string}
     * @memberof UsersGetKpiCards200ResponseDataInner
     */
    type?: UsersGetKpiCards200ResponseDataInnerTypeEnum;
    /**
     * Value for the kpi card
     * @type {number}
     * @memberof UsersGetKpiCards200ResponseDataInner
     */
    readonly value: number | null;
    /**
     * Show description on kpi card
     * @type {boolean}
     * @memberof UsersGetKpiCards200ResponseDataInner
     */
    readonly showDescription?: boolean;
}


/**
 * @export
 */
export const UsersGetKpiCards200ResponseDataInnerTypeEnum = {
    Hours: 'hours',
    Currency: 'currency',
    Number: 'number'
} as const;
export type UsersGetKpiCards200ResponseDataInnerTypeEnum = typeof UsersGetKpiCards200ResponseDataInnerTypeEnum[keyof typeof UsersGetKpiCards200ResponseDataInnerTypeEnum];


/**
 * Check if a given object implements the UsersGetKpiCards200ResponseDataInner interface.
 */
export function instanceOfUsersGetKpiCards200ResponseDataInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function UsersGetKpiCards200ResponseDataInnerFromJSON(json: any): UsersGetKpiCards200ResponseDataInner {
    return UsersGetKpiCards200ResponseDataInnerFromJSONTyped(json, false);
}

export function UsersGetKpiCards200ResponseDataInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetKpiCards200ResponseDataInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'value': json['value'],
        'showDescription': !exists(json, 'show_description') ? undefined : json['show_description'],
    };
}

export function UsersGetKpiCards200ResponseDataInnerToJSON(value?: UsersGetKpiCards200ResponseDataInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
    };
}

