/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Card } from './Card';
import {
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';
import type { TaskReadDTO } from './TaskReadDTO';
import {
    TaskReadDTOFromJSON,
    TaskReadDTOFromJSONTyped,
    TaskReadDTOToJSON,
} from './TaskReadDTO';

/**
 * 
 * @export
 * @interface MobilePlanningCreateCardAndTask201ResponseData
 */
export interface MobilePlanningCreateCardAndTask201ResponseData {
    /**
     * 
     * @type {Card}
     * @memberof MobilePlanningCreateCardAndTask201ResponseData
     */
    cards?: Card;
    /**
     * 
     * @type {TaskReadDTO}
     * @memberof MobilePlanningCreateCardAndTask201ResponseData
     */
    tasks?: TaskReadDTO;
}

/**
 * Check if a given object implements the MobilePlanningCreateCardAndTask201ResponseData interface.
 */
export function instanceOfMobilePlanningCreateCardAndTask201ResponseData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MobilePlanningCreateCardAndTask201ResponseDataFromJSON(json: any): MobilePlanningCreateCardAndTask201ResponseData {
    return MobilePlanningCreateCardAndTask201ResponseDataFromJSONTyped(json, false);
}

export function MobilePlanningCreateCardAndTask201ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobilePlanningCreateCardAndTask201ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cards': !exists(json, 'cards') ? undefined : CardFromJSON(json['cards']),
        'tasks': !exists(json, 'tasks') ? undefined : TaskReadDTOFromJSON(json['tasks']),
    };
}

export function MobilePlanningCreateCardAndTask201ResponseDataToJSON(value?: MobilePlanningCreateCardAndTask201ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cards': CardToJSON(value.cards),
        'tasks': TaskReadDTOToJSON(value.tasks),
    };
}

