/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    readonly createdById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    recipients: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    replyTo: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    body: string;
    /**
     * 
     * @type {boolean}
     * @memberof Email
     */
    isSent?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Email
     */
    readonly created?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Email
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    readonly publicClientUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    readonly pdfLink?: string;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    readonly grossPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    readonly netPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    readonly subtotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    readonly totalDiscountPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    readonly totalDiscountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    readonly workingHoursTotalSellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    readonly workingHoursTotalCostPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    readonly productsTotalSellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    readonly productsTotalCostPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    readonly workingHoursAmount?: string | null;
}

/**
 * Check if a given object implements the Email interface.
 */
export function instanceOfEmail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "recipients" in value;
    isInstance = isInstance && "replyTo" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "body" in value;

    return isInstance;
}

export function EmailFromJSON(json: any): Email {
    return EmailFromJSONTyped(json, false);
}

export function EmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): Email {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'companyId': json['company_id'],
        'recipients': json['recipients'],
        'replyTo': json['reply_to'],
        'subject': json['subject'],
        'body': json['body'],
        'isSent': !exists(json, 'is_sent') ? undefined : json['is_sent'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'publicClientUrl': !exists(json, 'public_client_url') ? undefined : json['public_client_url'],
        'pdfLink': !exists(json, 'pdf_link') ? undefined : json['pdf_link'],
        'grossPayment': !exists(json, 'gross_payment') ? undefined : json['gross_payment'],
        'netPayment': !exists(json, 'net_payment') ? undefined : json['net_payment'],
        'subtotal': !exists(json, 'subtotal') ? undefined : json['subtotal'],
        'totalDiscountPercent': !exists(json, 'total_discount_percent') ? undefined : json['total_discount_percent'],
        'totalDiscountAmount': !exists(json, 'total_discount_amount') ? undefined : json['total_discount_amount'],
        'workingHoursTotalSellingPrice': !exists(json, 'working_hours_total_selling_price') ? undefined : json['working_hours_total_selling_price'],
        'workingHoursTotalCostPrice': !exists(json, 'working_hours_total_cost_price') ? undefined : json['working_hours_total_cost_price'],
        'productsTotalSellingPrice': !exists(json, 'products_total_selling_price') ? undefined : json['products_total_selling_price'],
        'productsTotalCostPrice': !exists(json, 'products_total_cost_price') ? undefined : json['products_total_cost_price'],
        'workingHoursAmount': !exists(json, 'working_hours_amount') ? undefined : json['working_hours_amount'],
    };
}

export function EmailToJSON(value?: Email | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'recipients': value.recipients,
        'reply_to': value.replyTo,
        'subject': value.subject,
        'body': value.body,
        'is_sent': value.isSent,
    };
}

