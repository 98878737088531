/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectStatusType } from './ProjectStatusType';
import {
    ProjectStatusTypeFromJSON,
    ProjectStatusTypeFromJSONTyped,
    ProjectStatusTypeToJSON,
} from './ProjectStatusType';

/**
 * 
 * @export
 * @interface ProjectStatus
 */
export interface ProjectStatus {
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    description?: string;
    /**
     * One of 3 values
     * @type {string}
     * @memberof ProjectStatus
     */
    identifier?: ProjectStatusIdentifierEnum;
    /**
     * 
     * @type {ProjectStatusType}
     * @memberof ProjectStatus
     */
    projectStatusType?: ProjectStatusType;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectStatus
     */
    isCustom?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProjectStatus
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectStatus
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof ProjectStatus
     */
    readonly deleted?: Date | null;
}


/**
 * @export
 */
export const ProjectStatusIdentifierEnum = {
    ReadyForBilling: 'ready_for_billing',
    Open: 'open',
    Closed: 'closed'
} as const;
export type ProjectStatusIdentifierEnum = typeof ProjectStatusIdentifierEnum[keyof typeof ProjectStatusIdentifierEnum];


/**
 * Check if a given object implements the ProjectStatus interface.
 */
export function instanceOfProjectStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectStatusFromJSON(json: any): ProjectStatus {
    return ProjectStatusFromJSONTyped(json, false);
}

export function ProjectStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'projectStatusType': !exists(json, 'project_status_type') ? undefined : ProjectStatusTypeFromJSON(json['project_status_type']),
        'isCustom': !exists(json, 'is_custom') ? undefined : json['is_custom'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function ProjectStatusToJSON(value?: ProjectStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by_id': value.createdById,
        'name': value.name,
        'description': value.description,
        'identifier': value.identifier,
        'project_status_type': ProjectStatusTypeToJSON(value.projectStatusType),
        'is_custom': value.isCustom,
    };
}

