/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobilePlanningCreateCardAndTaskRequest
 */
export interface MobilePlanningCreateCardAndTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningCreateCardAndTaskRequest
     */
    taskName: string;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningCreateCardAndTaskRequest
     */
    projectId: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MobilePlanningCreateCardAndTaskRequest
     */
    date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MobilePlanningCreateCardAndTaskRequest
     */
    startTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MobilePlanningCreateCardAndTaskRequest
     */
    estimate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningCreateCardAndTaskRequest
     */
    taskDescription?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MobilePlanningCreateCardAndTaskRequest
     */
    labelIds?: Array<string>;
}

/**
 * Check if a given object implements the MobilePlanningCreateCardAndTaskRequest interface.
 */
export function instanceOfMobilePlanningCreateCardAndTaskRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "taskName" in value;
    isInstance = isInstance && "projectId" in value;

    return isInstance;
}

export function MobilePlanningCreateCardAndTaskRequestFromJSON(json: any): MobilePlanningCreateCardAndTaskRequest {
    return MobilePlanningCreateCardAndTaskRequestFromJSONTyped(json, false);
}

export function MobilePlanningCreateCardAndTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobilePlanningCreateCardAndTaskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskName': json['task_name'],
        'projectId': json['project_id'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'startTime': !exists(json, 'start_time') ? undefined : (json['start_time'] === null ? null : new Date(json['start_time'])),
        'estimate': !exists(json, 'estimate') ? undefined : json['estimate'],
        'taskDescription': !exists(json, 'task_description') ? undefined : json['task_description'],
        'labelIds': !exists(json, 'label_ids') ? undefined : json['label_ids'],
    };
}

export function MobilePlanningCreateCardAndTaskRequestToJSON(value?: MobilePlanningCreateCardAndTaskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task_name': value.taskName,
        'project_id': value.projectId,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'start_time': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
        'estimate': value.estimate,
        'task_description': value.taskDescription,
        'label_ids': value.labelIds,
    };
}

