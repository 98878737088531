/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceMargin
 */
export interface PriceMargin {
    /**
     * 
     * @type {number}
     * @memberof PriceMargin
     */
    amountFrom: number;
    /**
     * 
     * @type {number}
     * @memberof PriceMargin
     */
    amountTo: number;
    /**
     * 
     * @type {number}
     * @memberof PriceMargin
     */
    percentageRatio: number;
}

/**
 * Check if a given object implements the PriceMargin interface.
 */
export function instanceOfPriceMargin(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amountFrom" in value;
    isInstance = isInstance && "amountTo" in value;
    isInstance = isInstance && "percentageRatio" in value;

    return isInstance;
}

export function PriceMarginFromJSON(json: any): PriceMargin {
    return PriceMarginFromJSONTyped(json, false);
}

export function PriceMarginFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceMargin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountFrom': json['amount_from'],
        'amountTo': json['amount_to'],
        'percentageRatio': json['percentage_ratio'],
    };
}

export function PriceMarginToJSON(value?: PriceMargin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_from': value.amountFrom,
        'amount_to': value.amountTo,
        'percentage_ratio': value.percentageRatio,
    };
}

