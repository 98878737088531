import { State, Selector, Action, StateContext } from "@ngxs/store";
import { SetAppConfig, SetPlatform } from "./actions/set-app-config.actions";
import { DeviceInfo } from "@capacitor/device";
import { Injectable } from "@angular/core";

export interface ConfigStateModel {
    config: DeviceInfo;
    platform: string;
}

@State<ConfigStateModel>({
    name: "config",
    defaults: {
        config: null,
        platform: "",
    },
})
@Injectable()
export class ConfigState {
    @Selector()
    public static getConfig(state: ConfigStateModel) {
        return state.config;
    }

    @Selector()
    public static getPlatform(state: ConfigStateModel) {
        return state.platform;
    }

    @Action(SetAppConfig)
    setConfig(ctx: StateContext<ConfigStateModel>, { payload }: SetAppConfig) {
        ctx.setState({ ...ctx.getState(), config: payload });
    }

    @Action(SetPlatform)
    platform(ctx: StateContext<ConfigStateModel>, { payload }: SetPlatform) {
        ctx.setState({ ...ctx.getState(), platform: payload });
    }
}
