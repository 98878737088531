/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner } from './UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner';
import {
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInnerFromJSON,
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInnerFromJSONTyped,
    UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInnerToJSON,
} from './UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner';

/**
 * 
 * @export
 * @interface UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner
 */
export interface UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner {
    /**
     * 
     * @type {string}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner
     */
    approved: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner
     */
    label: string;
    /**
     * 
     * @type {Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner>}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner
     */
    entries: Array<UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInner>;
}

/**
 * Check if a given object implements the UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner interface.
 */
export function instanceOfUsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "approved" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "entries" in value;

    return isInstance;
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerFromJSON(json: any): UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner {
    return UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerFromJSONTyped(json, false);
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'approved': (json['approved'] === null ? null : new Date(json['approved'])),
        'label': json['label'],
        'entries': ((json['entries'] as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInnerFromJSON)),
    };
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerToJSON(value?: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'approved': (value.approved === null ? null : value.approved.toISOString()),
        'label': value.label,
        'entries': ((value.entries as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInnerEntriesInnerToJSON)),
    };
}

