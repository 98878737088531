/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsersGetWeekly200ResponseDateRangeInner } from './UsersGetWeekly200ResponseDateRangeInner';
import {
    UsersGetWeekly200ResponseDateRangeInnerFromJSON,
    UsersGetWeekly200ResponseDateRangeInnerFromJSONTyped,
    UsersGetWeekly200ResponseDateRangeInnerToJSON,
} from './UsersGetWeekly200ResponseDateRangeInner';
import type { UsersGetWeekly200ResponseWeeklyViewInner } from './UsersGetWeekly200ResponseWeeklyViewInner';
import {
    UsersGetWeekly200ResponseWeeklyViewInnerFromJSON,
    UsersGetWeekly200ResponseWeeklyViewInnerFromJSONTyped,
    UsersGetWeekly200ResponseWeeklyViewInnerToJSON,
} from './UsersGetWeekly200ResponseWeeklyViewInner';

/**
 * 
 * @export
 * @interface UsersGetWeekly200Response
 */
export interface UsersGetWeekly200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UsersGetWeekly200Response
     */
    success: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersGetWeekly200Response
     */
    canApproveForms: boolean;
    /**
     * 
     * @type {Array<UsersGetWeekly200ResponseDateRangeInner>}
     * @memberof UsersGetWeekly200Response
     */
    dateRange: Array<UsersGetWeekly200ResponseDateRangeInner>;
    /**
     * 
     * @type {Array<UsersGetWeekly200ResponseWeeklyViewInner>}
     * @memberof UsersGetWeekly200Response
     */
    weeklyView: Array<UsersGetWeekly200ResponseWeeklyViewInner>;
}

/**
 * Check if a given object implements the UsersGetWeekly200Response interface.
 */
export function instanceOfUsersGetWeekly200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "canApproveForms" in value;
    isInstance = isInstance && "dateRange" in value;
    isInstance = isInstance && "weeklyView" in value;

    return isInstance;
}

export function UsersGetWeekly200ResponseFromJSON(json: any): UsersGetWeekly200Response {
    return UsersGetWeekly200ResponseFromJSONTyped(json, false);
}

export function UsersGetWeekly200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetWeekly200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'canApproveForms': json['can_approve_forms'],
        'dateRange': ((json['date_range'] as Array<any>).map(UsersGetWeekly200ResponseDateRangeInnerFromJSON)),
        'weeklyView': ((json['weekly_view'] as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerFromJSON)),
    };
}

export function UsersGetWeekly200ResponseToJSON(value?: UsersGetWeekly200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'can_approve_forms': value.canApproveForms,
        'date_range': ((value.dateRange as Array<any>).map(UsersGetWeekly200ResponseDateRangeInnerToJSON)),
        'weekly_view': ((value.weeklyView as Array<any>).map(UsersGetWeekly200ResponseWeeklyViewInnerToJSON)),
    };
}

