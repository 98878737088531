/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactPerson } from './ContactPerson';
import {
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
} from './ContactPerson';
import type { PaginationDetails } from './PaginationDetails';
import {
    PaginationDetailsFromJSON,
    PaginationDetailsFromJSONTyped,
    PaginationDetailsToJSON,
} from './PaginationDetails';

/**
 * 
 * @export
 * @interface GetContactPersonsList200Response
 */
export interface GetContactPersonsList200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetContactPersonsList200Response
     */
    success: boolean;
    /**
     * 
     * @type {PaginationDetails}
     * @memberof GetContactPersonsList200Response
     */
    pagination: PaginationDetails;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof GetContactPersonsList200Response
     */
    data: Array<ContactPerson>;
}

/**
 * Check if a given object implements the GetContactPersonsList200Response interface.
 */
export function instanceOfGetContactPersonsList200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "pagination" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetContactPersonsList200ResponseFromJSON(json: any): GetContactPersonsList200Response {
    return GetContactPersonsList200ResponseFromJSONTyped(json, false);
}

export function GetContactPersonsList200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactPersonsList200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'pagination': PaginationDetailsFromJSON(json['pagination']),
        'data': ((json['data'] as Array<any>).map(ContactPersonFromJSON)),
    };
}

export function GetContactPersonsList200ResponseToJSON(value?: GetContactPersonsList200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'pagination': PaginationDetailsToJSON(value.pagination),
        'data': ((value.data as Array<any>).map(ContactPersonToJSON)),
    };
}

