/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner
 */
export interface UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner {
    /**
     * 
     * @type {Date}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner
     */
    value: number;
}

/**
 * Check if a given object implements the UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner interface.
 */
export function instanceOfUsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerFromJSON(json: any): UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner {
    return UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerFromJSONTyped(json, false);
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'value': json['value'],
    };
}

export function UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInnerToJSON(value?: UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerSumHoursInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString()),
        'value': value.value,
    };
}

