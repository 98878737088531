import { Pagination } from "src/app/services/response-models/invoices.response.model";
import { Form } from "src/app/services/response-models/form.response.model";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ClearForms, LoadForms, SavePagination } from "./actions/set-forms.action";
import { LoadSelectedForm } from "./actions/load-selected-form.action";
import { Injectable } from "@angular/core";
import {
    ClearNotApprovedForms,
    LoadNotApprovedForms,
    LoadNotApprovedFormsPagination,
} from "./actions/load-not-approved-forms.action";

export interface FormsStateModel {
    forms: Form[] | any;
    searchQuery: string;
    pagination: Pagination;
    view: string;
    selectedForm: Form;
    notApprovedForms: Form[];
    notApprovedFormsPagination: Pagination;
}

@State<FormsStateModel>({
    name: "forms",
    defaults: {
        forms: [],
        searchQuery: "",
        pagination: null,
        view: null,
        selectedForm: null,
        notApprovedForms: [],
        notApprovedFormsPagination: null,
    },
})
@Injectable()
export class FormsState {
    @Selector()
    static getForms(state: FormsStateModel) {
        return state.forms;
    }

    @Selector()
    static getPagination(state: FormsStateModel) {
        return state.pagination;
    }

    @Selector()
    static getSearchQuery(state: FormsStateModel) {
        return state;
    }

    @Selector()
    static getView(state: FormsStateModel) {
        return state.view;
    }

    @Selector()
    static getSelectedForm(state: FormsStateModel) {
        return state.selectedForm;
    }

    @Selector()
    static getNotApprovedForms(state: FormsStateModel) {
        return state.notApprovedForms;
    }

    @Selector()
    static getNotApprovedFormsPagination(state: FormsStateModel) {
        return state.notApprovedFormsPagination;
    }

    @Action(LoadForms)
    load(ctx: StateContext<FormsStateModel>, { payload }: LoadForms) {
        const state = ctx.getState();

        ctx.patchState({
            forms: [...state.forms, ...payload],
        });
    }

    @Action(SavePagination)
    pagination(ctx: StateContext<FormsStateModel>, { payload }: SavePagination) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }

    @Action(ClearForms)
    clear(ctx: StateContext<FormsStateModel>) {
        ctx.setState({ ...ctx.getState(), forms: [] });
    }

    @Action(LoadSelectedForm)
    loadForm(ctx: StateContext<FormsStateModel>, { payload }: LoadSelectedForm) {
        ctx.setState({ ...ctx.getState(), selectedForm: payload });
    }

    @Action(LoadNotApprovedForms)
    loadNotApproved(ctx: StateContext<FormsStateModel>, { payload }: LoadNotApprovedForms) {
        ctx.setState({ ...ctx.getState(), notApprovedForms: payload });
    }

    @Action(LoadNotApprovedFormsPagination)
    notApprovedPagination(
        ctx: StateContext<FormsStateModel>,
        { payload }: LoadNotApprovedFormsPagination
    ) {
        ctx.setState({ ...ctx.getState(), notApprovedFormsPagination: payload });
    }

    @Action(ClearNotApprovedForms)
    clearNotApproved(ctx: StateContext<FormsStateModel>) {
        ctx.setState({ ...ctx.getState(), notApprovedForms: [] });
    }
}
