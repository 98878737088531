import { TimeEntryTypes } from "src/app/services/response-models/time-entries.response.model";

export class SetTimeEntryTypes {
    static readonly type = "[Time Entry Types] Set time entry types";

    constructor(public payload: TimeEntryTypes[]) {}
}

export class ClearTimeEntryTypes {
    static readonly type = "[Time Entry Types] Clear time entry types";

    constructor() {}
}
