import { Component, ViewChild, ElementRef } from "@angular/core";

@Component({
    selector: "app-loading",
    templateUrl: "./loading.component.html",
    styleUrls: ["./loading.component.css"],
})
export class LoadingComponent {
    @ViewChild("image") image: ElementRef;
    @ViewChild("loading") loading: ElementRef;

    private spinner: any; // NodeJS.Timer

    public startLoading = async () => {
        this.showBackdrop();
        this.spinStart();
        this.spinner = setInterval(this.spinStart, 2000);
    };

    public endLoading = async () => {
        this.hideBackdrop();
        clearInterval(this.spinner);
    };

    private spinStart = () => {
        this.image.nativeElement.style.transform = "rotate(360deg)";
        setTimeout(this.spinEnd, 1000);
    };

    private spinEnd = () => {
        this.image.nativeElement.style.transform = "";
    };

    private showBackdrop = () => {
        this.loading.nativeElement.style.opacity = "1";
        this.loading.nativeElement.style["z-index"] = "50";
    };

    private hideBackdrop = () => {
        this.loading.nativeElement.style["z-index"] = "-1";
        this.loading.nativeElement.style.opacity = "0";
    };
}
