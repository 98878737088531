/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { ProjectStatus } from './ProjectStatus';
import {
    ProjectStatusFromJSON,
    ProjectStatusFromJSONTyped,
    ProjectStatusToJSON,
} from './ProjectStatus';

/**
 * 
 * @export
 * @interface MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
 */
export interface MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject {
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    name?: string;
    /**
     * Includes both project number and name (if project number is enabled for company)
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    projectNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    thumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    projectImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    streetName?: string;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    projectStatusId?: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    projectStatus?: ProjectStatus;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {Contact}
     * @memberof MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject
     */
    contact?: Contact;
}

/**
 * Check if a given object implements the MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject interface.
 */
export function instanceOfMobileGetCards200ResponseDataInnerAllOfTaskAllOfProject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function MobileGetCards200ResponseDataInnerAllOfTaskAllOfProjectFromJSON(json: any): MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject {
    return MobileGetCards200ResponseDataInnerAllOfTaskAllOfProjectFromJSONTyped(json, false);
}

export function MobileGetCards200ResponseDataInnerAllOfTaskAllOfProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'projectNumber': !exists(json, 'project_number') ? undefined : json['project_number'],
        'thumbnail': !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'projectImageUrl': !exists(json, 'project_image_url') ? undefined : json['project_image_url'],
        'streetName': !exists(json, 'street_name') ? undefined : json['street_name'],
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'projectStatusId': !exists(json, 'project_status_id') ? undefined : json['project_status_id'],
        'projectStatus': !exists(json, 'project_status') ? undefined : ProjectStatusFromJSON(json['project_status']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'contact': !exists(json, 'contact') ? undefined : ContactFromJSON(json['contact']),
    };
}

export function MobileGetCards200ResponseDataInnerAllOfTaskAllOfProjectToJSON(value?: MobileGetCards200ResponseDataInnerAllOfTaskAllOfProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'project_number': value.projectNumber,
        'thumbnail': value.thumbnail,
        'project_image_url': value.projectImageUrl,
        'street_name': value.streetName,
        'start_time': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'project_status_id': value.projectStatusId,
        'project_status': ProjectStatusToJSON(value.projectStatus),
        'contact': ContactToJSON(value.contact),
    };
}

