/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationDetails
 */
export interface PaginationDetails {
    /**
     * 
     * @type {number}
     * @memberof PaginationDetails
     */
    readonly pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDetails
     */
    readonly currentPage: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginationDetails
     */
    readonly hasNextPage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginationDetails
     */
    readonly hasPrevPage: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginationDetails
     */
    readonly count?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationDetails
     */
    readonly limit?: number | null;
}

/**
 * Check if a given object implements the PaginationDetails interface.
 */
export function instanceOfPaginationDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentPage" in value;
    isInstance = isInstance && "hasNextPage" in value;
    isInstance = isInstance && "hasPrevPage" in value;

    return isInstance;
}

export function PaginationDetailsFromJSON(json: any): PaginationDetails {
    return PaginationDetailsFromJSONTyped(json, false);
}

export function PaginationDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageCount': !exists(json, 'page_count') ? undefined : json['page_count'],
        'currentPage': json['current_page'],
        'hasNextPage': json['has_next_page'],
        'hasPrevPage': json['has_prev_page'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function PaginationDetailsToJSON(value?: PaginationDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

