/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
 */
export interface RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId {
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    taskId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    projectId: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    userId: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    startTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    endTime?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    indexes?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    listIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    estimate?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    labelIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    status?: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    repeat?: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdRepeatEnum;
    /**
     * 
     * @type {number}
     * @memberof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId
     */
    interval?: number;
}


/**
 * @export
 */
export const RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdStatusEnum = {
    ToDo: 'to_do',
    Doing: 'doing',
    Done: 'done'
} as const;
export type RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdStatusEnum = typeof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdStatusEnum[keyof typeof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdStatusEnum];

/**
 * @export
 */
export const RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdRepeatEnum = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;
export type RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdRepeatEnum = typeof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdRepeatEnum[keyof typeof RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdRepeatEnum];


/**
 * Check if a given object implements the RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId interface.
 */
export function instanceOfRequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "taskId" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdFromJSON(json: any): RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId {
    return RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdFromJSONTyped(json, false);
}

export function RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskId': json['task_id'],
        'projectId': json['project_id'],
        'userId': json['user_id'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'startTime': !exists(json, 'start_time') ? undefined : (json['start_time'] === null ? null : new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (json['end_time'] === null ? null : new Date(json['end_time'])),
        'indexes': !exists(json, 'indexes') ? undefined : json['indexes'],
        'listIndex': !exists(json, 'list_index') ? undefined : json['list_index'],
        'estimate': !exists(json, 'estimate') ? undefined : json['estimate'],
        'labelIds': !exists(json, 'label_ids') ? undefined : json['label_ids'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'repeat': !exists(json, 'repeat') ? undefined : json['repeat'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
    };
}

export function RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskIdToJSON(value?: RequestDTOControlPanelApiPlanningCreateTaskAndCardWithTaskId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task_id': value.taskId,
        'project_id': value.projectId,
        'user_id': value.userId,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'start_time': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime === null ? null : value.endTime.toISOString()),
        'indexes': value.indexes,
        'list_index': value.listIndex,
        'estimate': value.estimate,
        'label_ids': value.labelIds,
        'status': value.status,
        'repeat': value.repeat,
        'interval': value.interval,
    };
}

