import { Directive, Injectable, Input } from "@angular/core";
import { CompanySettings } from "src/app/services/response-models/companies.response.model";
import { Select } from "@ngxs/store";
import { AppState } from "src/store/app/app.state";
import { Observable } from "rxjs";
import * as moment from "moment";
import { FormTemplateField } from "src/app/services/response-models/form-templates.response.model";
import { Platform } from "@ionic/angular";
import { FormField } from "src/app/employee/forms/form-field-types/FormField";

/**
 * This class handles a lot of date time logic across the app.
 */
@Directive()
@Injectable()
export class DateTime extends FormField {
    @Input() name: string;
    @Input() labelContent: string;
    @Input() placement: number;
    @Input() identifier: string;
    @Input() field: FormTemplateField;
    @Select(AppState.getCompanySettings) companySettings$: Observable<CompanySettings>;

    public readonly defaultTimeFormat = "HH:mm";
    public companySettings: CompanySettings;
    public defaultDate: string;
    public defaultTime: string;
    public maxDate: string;
    public minDate: string;
    public minutesList: string;
    public weekStartDate: string;
    public weekEndDate: string;
    public isAdmin: boolean;

    public isOldTimeEntry = false;
    public selectedActionSheetValue: string;
    public platform: Platform;

    constructor() {
        super();
    }

    public initDateTime(): void {
        this.getCompanySettings();
        this.countMinutes();
    }

    public getCompanySettings(): void {
        this.companySettings$.subscribe((data) => {
            this.companySettings = data;
        });
    }

    public countMinutes(): void {
        const minutes = [];

        const counterForMinutes = Number(
            this.companySettings.dagseddel_default_time_interval_minutes
                ? this.companySettings.dagseddel_default_time_interval_minutes
                : 1
        );

        for (let index = 0; index <= 59; index += counterForMinutes) {
            if (index.toString().length === 1) {
                minutes.push(`0${index}`);
            } else {
                minutes.push(index.toString());
            }
        }

        this.minutesList = minutes.join(",");
    }

    public setDefaultDate(): void {
        const today = moment();
        const todayMin = moment();
        const todayMax = moment();

        todayMin.subtract(Number(this.companySettings.days_of_form_editing_window), "days");
        todayMax.add(Number(this.companySettings.days_of_form_editing_window), "days");

        if (this.contentValue !== "") {
            this.defaultDate = moment(this.contentValue, "YYYY-MM-DD").toISOString();
        } else {
            this.defaultDate = today.toISOString();
        }

        this.minDate = todayMin.toISOString();
        this.maxDate = todayMax.toISOString();
    }

    public setDefaultTime(): void {
        if (this.field?.identifier.includes("time_entry")) {
            this.isOldTimeEntry = true;

            const time = moment();

            time.set("hours", this.contentValue ? this.contentValue.split(":")[0] : 0);
            time.set("minutes", this.contentValue ? this.contentValue.split(":")[1] : 0);

            this.defaultTime = time.toISOString();

            return;
        }

        if (this.contentValue !== "") {
            const time = moment();
            time.set("hours", this.contentValue.split(":")[0]);
            time.set("minutes", this.contentValue.split(":")[1]);

            this.defaultTime = time.toISOString();
            return;
        }

        if (this.identifier === "time_interval_from") {
            this.getDefaultHoursAndMinutes(
                this.companySettings.dagseddel_default_start_hours,
                this.companySettings.dagseddel_default_start_minutes
            );
        }

        if (this.identifier === "time_interval_to") {
            this.getDefaultHoursAndMinutes(
                this.companySettings.dagseddel_default_end_hours,
                this.companySettings.dagseddel_default_end_minutes
            );
        }

        if (this.identifier === "timepicker") {
            this.getDefaultHoursAndMinutes(
                this.companySettings.dagseddel_default_pause_hours,
                this.companySettings.dagseddel_default_pause_minutes
            );
        }

        if (this.identifier === "timepicker_dagseddel_sum") {
            this.getDefaultHoursAndMinutes("0", "0");
        }
    }

    public getDefaultHoursAndMinutes(defaultHours: string, defaultMinutes: string): void {
        const today = moment();

        today.set("hours", Number(defaultHours));
        today.set("minutes", Number(defaultMinutes));

        this.defaultTime = today.toISOString();
    }

    public getCurrentWeekFirstAndLastDate(): void {
        const today = moment();
        const currentDayOfWeek = today.day();

        switch (currentDayOfWeek) {
            // Sunday
            case 0:
                this.setDefaultStartDate(6);
                this.setDefaultEndDate(0);
                break;
            // Monday
            case 1:
                this.setDefaultStartDate(0);
                this.setDefaultEndDate(6);
                break;
            // Tuesday
            case 2:
                this.setDefaultStartDate(1);
                this.setDefaultEndDate(5);
                break;
            // Wednesday
            case 3:
                this.setDefaultStartDate(2);
                this.setDefaultEndDate(4);
                break;
            // Thursday
            case 4:
                this.setDefaultStartDate(3);
                this.setDefaultEndDate(3);
                break;
            // Beerday
            case 5:
                this.setDefaultStartDate(4);
                this.setDefaultEndDate(2);
                break;
            // Saturday
            case 6:
                this.setDefaultStartDate(5);
                this.setDefaultEndDate(1);
                break;
        }
    }

    private setDefaultStartDate(daysToSubstract: number): void {
        const today = moment();
        today.subtract(daysToSubstract, "days");
        this.weekStartDate = today.toISOString();
    }

    private setDefaultEndDate(daysToAdd: number): void {
        const today = moment();
        today.add(daysToAdd, "days");
        this.weekEndDate = today.toISOString();
    }
}
