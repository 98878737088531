/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LabelEntity
 */
export interface LabelEntity {
    /**
     * 
     * @type {string}
     * @memberof LabelEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity
     */
    readonly companyId: string;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity
     */
    entity?: LabelEntityEntityEnum;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity
     */
    bgColor?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelEntity
     */
    textColor?: string;
    /**
     * 
     * @type {Date}
     * @memberof LabelEntity
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LabelEntity
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof LabelEntity
     */
    readonly deleted?: Date | null;
}


/**
 * @export
 */
export const LabelEntityEntityEnum = {
    Employee: 'employee',
    Task: 'task'
} as const;
export type LabelEntityEntityEnum = typeof LabelEntityEntityEnum[keyof typeof LabelEntityEntityEnum];


/**
 * Check if a given object implements the LabelEntity interface.
 */
export function instanceOfLabelEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "text" in value;

    return isInstance;
}

export function LabelEntityFromJSON(json: any): LabelEntity {
    return LabelEntityFromJSONTyped(json, false);
}

export function LabelEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['company_id'],
        'text': json['text'],
        'entity': !exists(json, 'entity') ? undefined : json['entity'],
        'bgColor': !exists(json, 'bg_color') ? undefined : json['bg_color'],
        'textColor': !exists(json, 'text_color') ? undefined : json['text_color'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function LabelEntityToJSON(value?: LabelEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'entity': value.entity,
        'bg_color': value.bgColor,
        'text_color': value.textColor,
    };
}

