/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { City } from './City';
import {
    CityFromJSON,
    CityFromJSONTyped,
    CityToJSON,
} from './City';
import type { PaymentTerm } from './PaymentTerm';
import {
    PaymentTermFromJSON,
    PaymentTermFromJSONTyped,
    PaymentTermToJSON,
} from './PaymentTerm';

/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    readonly createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    countryId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    cityId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    companyId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    description: string | null;
    /**
     * Street address
     * @type {string}
     * @memberof Contact
     */
    address: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    website: string | null;
    /**
     * Format like eg. `28680133` or `046158971404`
     * @type {string}
     * @memberof Contact
     */
    phone: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phoneCountrycode: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    cvr: string | null;
    /**
     * If company has integration to an ERP system, and the contacts are synchronized, this will be the ERP-systems ID of this contact
     * @type {string}
     * @memberof Contact
     */
    erpId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    tripletexId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    centigaId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    pogoId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    paymentTermId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    cityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    ean?: string | null;
    /**
     * 
     * @type {PaymentTerm}
     * @memberof Contact
     */
    paymentTerm?: PaymentTerm;
    /**
     * 
     * @type {City}
     * @memberof Contact
     */
    city?: City;
    /**
     * 
     * @type {Date}
     * @memberof Contact
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Contact
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Contact
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the Contact interface.
 */
export function instanceOfContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "countryId" in value;
    isInstance = isInstance && "cityId" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "website" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "phoneCountrycode" in value;
    isInstance = isInstance && "cvr" in value;
    isInstance = isInstance && "erpId" in value;
    isInstance = isInstance && "tripletexId" in value;
    isInstance = isInstance && "centigaId" in value;
    isInstance = isInstance && "pogoId" in value;
    isInstance = isInstance && "paymentTermId" in value;

    return isInstance;
}

export function ContactFromJSON(json: any): Contact {
    return ContactFromJSONTyped(json, false);
}

export function ContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'countryId': json['country_id'],
        'cityId': json['city_id'],
        'companyId': json['company_id'],
        'name': json['name'],
        'description': json['description'],
        'address': json['address'],
        'email': json['email'],
        'website': json['website'],
        'phone': json['phone'],
        'phoneCountrycode': json['phone_countrycode'],
        'cvr': json['cvr'],
        'erpId': json['erp_id'],
        'tripletexId': json['tripletex_id'],
        'centigaId': json['centiga_id'],
        'pogoId': json['pogo_id'],
        'paymentTermId': json['payment_term_id'],
        'cityName': !exists(json, 'city_name') ? undefined : json['city_name'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'ean': !exists(json, 'ean') ? undefined : json['ean'],
        'paymentTerm': !exists(json, 'payment_term') ? undefined : PaymentTermFromJSON(json['payment_term']),
        'city': !exists(json, 'city') ? undefined : CityFromJSON(json['city']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function ContactToJSON(value?: Contact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'country_id': value.countryId,
        'city_id': value.cityId,
        'company_id': value.companyId,
        'name': value.name,
        'description': value.description,
        'address': value.address,
        'email': value.email,
        'website': value.website,
        'phone': value.phone,
        'phone_countrycode': value.phoneCountrycode,
        'cvr': value.cvr,
        'erp_id': value.erpId,
        'tripletex_id': value.tripletexId,
        'centiga_id': value.centigaId,
        'pogo_id': value.pogoId,
        'payment_term_id': value.paymentTermId,
        'city_name': value.cityName,
        'zip_code': value.zipCode,
        'ean': value.ean,
        'payment_term': PaymentTermToJSON(value.paymentTerm),
        'city': CityToJSON(value.city),
    };
}

