/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User1 } from './User1';
import {
    User1FromJSON,
    User1FromJSONTyped,
    User1ToJSON,
} from './User1';

/**
 * 
 * @export
 * @interface IListWallComments200ResponseInner
 */
export interface IListWallComments200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof IListWallComments200ResponseInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IListWallComments200ResponseInner
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof IListWallComments200ResponseInner
     */
    userId?: string;
    /**
     * 
     * @type {User1}
     * @memberof IListWallComments200ResponseInner
     */
    user?: User1;
    /**
     * 
     * @type {string}
     * @memberof IListWallComments200ResponseInner
     */
    wallPostId?: string;
    /**
     * 
     * @type {Date}
     * @memberof IListWallComments200ResponseInner
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof IListWallComments200ResponseInner
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof IListWallComments200ResponseInner
     */
    readonly deleted?: Date | null;
}

/**
 * Check if a given object implements the IListWallComments200ResponseInner interface.
 */
export function instanceOfIListWallComments200ResponseInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "created" in value;

    return isInstance;
}

export function IListWallComments200ResponseInnerFromJSON(json: any): IListWallComments200ResponseInner {
    return IListWallComments200ResponseInnerFromJSONTyped(json, false);
}

export function IListWallComments200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): IListWallComments200ResponseInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'message': json['message'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'user': !exists(json, 'user') ? undefined : User1FromJSON(json['user']),
        'wallPostId': !exists(json, 'wall_post_id') ? undefined : json['wall_post_id'],
        'created': (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
    };
}

export function IListWallComments200ResponseInnerToJSON(value?: IListWallComments200ResponseInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'message': value.message,
        'user_id': value.userId,
        'user': User1ToJSON(value.user),
        'wall_post_id': value.wallPostId,
    };
}

