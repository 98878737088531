import { DrivingType } from "src/app/services/response-models/driving.response.model";
import { Pagination } from "src/app/services/response-models/projects.response.model";

export class LoadDrivingTypes {
    static readonly type = "[Driving] Load driving types";

    constructor(public payload: Array<DrivingType>) {}
}

export class LoadDrivingTypesPagination {
    static readonly type = "[Driving] Load driving types paginaiton";

    constructor(public payload: Pagination) {}
}

export class ClearDrivingTypes {
    static readonly type = "[Driving] Clear driving types";

    constructor() {}
}
