import { Activity } from "src/app/services/response-models/activity.response.model";

export class SetActivities {
    public static readonly type = "[Activities] Set activities";

    constructor(public payload: Activity[]) {}
}

export class SetTags {
    public static readonly type = "[Activities] Set tags";

    constructor(public payload: Activity[]) {}
}

export class RemoveTag {
    public static readonly type = "[Activities] Remove tag from list";

    constructor(public payload: Activity) {}
}

export class AddTag {
    public static readonly type = "[Activities] Add tag to list";

    constructor(public payload: Activity) {}
}
