import { PaginationDetails, Product } from "@apacta/sdk";

export class LoadBasicProducts {
    public static readonly type = "[Products] Load basic products";

    constructor(public payload: Product[]) {}
}

export class LoadBasicPagination {
    public static readonly type = "[Products] Load products pagination";

    constructor(public payload: PaginationDetails) {}
}

export class ClearBasicProducts {
    public static readonly type = "[Products] Clear basic products";
}
