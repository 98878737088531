/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendReportRequest
 */
export interface SendReportRequest {
    /**
     * 
     * @type {string}
     * @memberof SendReportRequest
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof SendReportRequest
     */
    dateTo?: string;
    /**
     * 
     * @type {string}
     * @memberof SendReportRequest
     */
    email?: string;
}

/**
 * Check if a given object implements the SendReportRequest interface.
 */
export function instanceOfSendReportRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SendReportRequestFromJSON(json: any): SendReportRequest {
    return SendReportRequestFromJSONTyped(json, false);
}

export function SendReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateFrom': !exists(json, 'date_from') ? undefined : json['date_from'],
        'dateTo': !exists(json, 'date_to') ? undefined : json['date_to'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function SendReportRequestToJSON(value?: SendReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_from': value.dateFrom,
        'date_to': value.dateTo,
        'email': value.email,
    };
}

