import { Pagination } from "src/app/services/response-models/projects.response.model";
import {
    ProjectStatus,
    ProjectStatusType,
} from "src/app/services/response-models/project-statuses.reponse.model";

export class LoadProjectStatuses {
    public static readonly type = "[Project Statuses] Load project statuses";

    constructor(public payload: ProjectStatus[]) {}
}

export class LoadProjectStatusesPagination {
    public static readonly type = "[Project Statuses] Load project status pagination";

    constructor(public payload: Pagination) {}
}

export class ClearProjectStatus {
    public static readonly type = "[Project Statuses] Clear project statuses";

    constructor() {}
}

export class LoadProjectStatusTypes {
    public static readonly type = "[Project Statuses] Load project status type";

    constructor(public payload: ProjectStatusType[]) {}
}
