/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Card } from './Card';
import {
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';
import type { GetCardsWithTaskAndUsers200ResponseDataTasksValue } from './GetCardsWithTaskAndUsers200ResponseDataTasksValue';
import {
    GetCardsWithTaskAndUsers200ResponseDataTasksValueFromJSON,
    GetCardsWithTaskAndUsers200ResponseDataTasksValueFromJSONTyped,
    GetCardsWithTaskAndUsers200ResponseDataTasksValueToJSON,
} from './GetCardsWithTaskAndUsers200ResponseDataTasksValue';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * The 'cards', 'users' and 'tasks' are dictionaries with the primary ID being the key and the actual entity being the value for simpler (and faster) lookups
 * @export
 * @interface GetCardsWithTaskAndUsers200ResponseData
 */
export interface GetCardsWithTaskAndUsers200ResponseData {
    /**
     * 
     * @type {{ [key: string]: Card; }}
     * @memberof GetCardsWithTaskAndUsers200ResponseData
     */
    cards?: { [key: string]: Card; };
    /**
     * 
     * @type {{ [key: string]: User; }}
     * @memberof GetCardsWithTaskAndUsers200ResponseData
     */
    users?: { [key: string]: User; };
    /**
     * 
     * @type {{ [key: string]: GetCardsWithTaskAndUsers200ResponseDataTasksValue; }}
     * @memberof GetCardsWithTaskAndUsers200ResponseData
     */
    tasks?: { [key: string]: GetCardsWithTaskAndUsers200ResponseDataTasksValue; };
}

/**
 * Check if a given object implements the GetCardsWithTaskAndUsers200ResponseData interface.
 */
export function instanceOfGetCardsWithTaskAndUsers200ResponseData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetCardsWithTaskAndUsers200ResponseDataFromJSON(json: any): GetCardsWithTaskAndUsers200ResponseData {
    return GetCardsWithTaskAndUsers200ResponseDataFromJSONTyped(json, false);
}

export function GetCardsWithTaskAndUsers200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCardsWithTaskAndUsers200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cards': !exists(json, 'cards') ? undefined : (mapValues(json['cards'], CardFromJSON)),
        'users': !exists(json, 'users') ? undefined : (mapValues(json['users'], UserFromJSON)),
        'tasks': !exists(json, 'tasks') ? undefined : (mapValues(json['tasks'], GetCardsWithTaskAndUsers200ResponseDataTasksValueFromJSON)),
    };
}

export function GetCardsWithTaskAndUsers200ResponseDataToJSON(value?: GetCardsWithTaskAndUsers200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cards': value.cards === undefined ? undefined : (mapValues(value.cards, CardToJSON)),
        'users': value.users === undefined ? undefined : (mapValues(value.users, UserToJSON)),
        'tasks': value.tasks === undefined ? undefined : (mapValues(value.tasks, GetCardsWithTaskAndUsers200ResponseDataTasksValueToJSON)),
    };
}

