/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlPanelApiProjectFeedResponseDTOPagination
 */
export interface ControlPanelApiProjectFeedResponseDTOPagination {
    /**
     * 
     * @type {string}
     * @memberof ControlPanelApiProjectFeedResponseDTOPagination
     */
    currentPage: string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlPanelApiProjectFeedResponseDTOPagination
     */
    hasNextPage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ControlPanelApiProjectFeedResponseDTOPagination
     */
    hasPrevPage: boolean;
    /**
     * 
     * @type {number}
     * @memberof ControlPanelApiProjectFeedResponseDTOPagination
     */
    limit: number;
}

/**
 * Check if a given object implements the ControlPanelApiProjectFeedResponseDTOPagination interface.
 */
export function instanceOfControlPanelApiProjectFeedResponseDTOPagination(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentPage" in value;
    isInstance = isInstance && "hasNextPage" in value;
    isInstance = isInstance && "hasPrevPage" in value;
    isInstance = isInstance && "limit" in value;

    return isInstance;
}

export function ControlPanelApiProjectFeedResponseDTOPaginationFromJSON(json: any): ControlPanelApiProjectFeedResponseDTOPagination {
    return ControlPanelApiProjectFeedResponseDTOPaginationFromJSONTyped(json, false);
}

export function ControlPanelApiProjectFeedResponseDTOPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlPanelApiProjectFeedResponseDTOPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPage': json['current_page'],
        'hasNextPage': json['has_next_page'],
        'hasPrevPage': json['has_prev_page'],
        'limit': json['limit'],
    };
}

export function ControlPanelApiProjectFeedResponseDTOPaginationToJSON(value?: ControlPanelApiProjectFeedResponseDTOPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_page': value.currentPage,
        'has_next_page': value.hasNextPage,
        'has_prev_page': value.hasPrevPage,
        'limit': value.limit,
    };
}

