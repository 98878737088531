import { User } from "src/app/services/response-models/user.response.model";

export class LoadSelectedUser {
    static readonly type = "[User] Load selected user";

    constructor(public payload: User) {}
}

export class LoadUserActiveSubscriptions {
    static readonly type = "[User] Load user active subscription";

    constructor(public payload: boolean) {}
}
