import { User } from "src/app/services/response-models/user.response.model";

export class SetUser {
    static readonly type = "[APP] Set user";

    constructor(public payload: User) {}
}

export class SetUserLocale {
    static readonly type = "[APP] Set user locale";

    constructor(public payload: string) {}
}
