/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateProjectCustomFieldAttributeRequest
 */
export interface CreateProjectCustomFieldAttributeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectCustomFieldAttributeRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectCustomFieldAttributeRequest
     */
    accessType: CreateProjectCustomFieldAttributeRequestAccessTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectCustomFieldAttributeRequest
     */
    placement?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProjectCustomFieldAttributeRequest
     */
    isActive: boolean;
}


/**
 * @export
 */
export const CreateProjectCustomFieldAttributeRequestAccessTypeEnum = {
    Panel: 'control_panel',
    PanelMobileInvoices: 'control_panel_mobile_invoices',
    PanelInvoices: 'control_panel_invoices',
    PanelMobile: 'control_panel_mobile'
} as const;
export type CreateProjectCustomFieldAttributeRequestAccessTypeEnum = typeof CreateProjectCustomFieldAttributeRequestAccessTypeEnum[keyof typeof CreateProjectCustomFieldAttributeRequestAccessTypeEnum];


/**
 * Check if a given object implements the CreateProjectCustomFieldAttributeRequest interface.
 */
export function instanceOfCreateProjectCustomFieldAttributeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "accessType" in value;
    isInstance = isInstance && "isActive" in value;

    return isInstance;
}

export function CreateProjectCustomFieldAttributeRequestFromJSON(json: any): CreateProjectCustomFieldAttributeRequest {
    return CreateProjectCustomFieldAttributeRequestFromJSONTyped(json, false);
}

export function CreateProjectCustomFieldAttributeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProjectCustomFieldAttributeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'accessType': json['access_type'],
        'placement': !exists(json, 'placement') ? undefined : json['placement'],
        'isActive': json['is_active'],
    };
}

export function CreateProjectCustomFieldAttributeRequestToJSON(value?: CreateProjectCustomFieldAttributeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'access_type': value.accessType,
        'placement': value.placement,
        'is_active': value.isActive,
    };
}

