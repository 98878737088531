/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateFormRequest
 */
export interface CreateFormRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateFormRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormRequest
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormRequest
     */
    formTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormRequest
     */
    approvedById?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormRequest
     */
    activityId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFormRequest
     */
    clockingRecordId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateFormRequest
     */
    isInvoiced?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFormRequest
     */
    isDraft?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateFormRequest
     */
    approved?: Date;
    /**
     * 
     * @type {object}
     * @memberof CreateFormRequest
     */
    fields?: object;
}

/**
 * Check if a given object implements the CreateFormRequest interface.
 */
export function instanceOfCreateFormRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateFormRequestFromJSON(json: any): CreateFormRequest {
    return CreateFormRequestFromJSONTyped(json, false);
}

export function CreateFormRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFormRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'formTemplateId': !exists(json, 'form_template_id') ? undefined : json['form_template_id'],
        'approvedById': !exists(json, 'approved_by_id') ? undefined : json['approved_by_id'],
        'activityId': !exists(json, 'activity_id') ? undefined : json['activity_id'],
        'clockingRecordId': !exists(json, 'clocking_record_id') ? undefined : json['clocking_record_id'],
        'isInvoiced': !exists(json, 'is_invoiced') ? undefined : (new Date(json['is_invoiced'])),
        'isDraft': !exists(json, 'is_draft') ? undefined : json['is_draft'],
        'approved': !exists(json, 'approved') ? undefined : (new Date(json['approved'])),
        'fields': !exists(json, 'fields') ? undefined : json['fields'],
    };
}

export function CreateFormRequestToJSON(value?: CreateFormRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'company_id': value.companyId,
        'project_id': value.projectId,
        'form_template_id': value.formTemplateId,
        'approved_by_id': value.approvedById,
        'activity_id': value.activityId,
        'clocking_record_id': value.clockingRecordId,
        'is_invoiced': value.isInvoiced === undefined ? undefined : (value.isInvoiced.toISOString()),
        'is_draft': value.isDraft,
        'approved': value.approved === undefined ? undefined : (value.approved.toISOString()),
        'fields': value.fields,
    };
}

