import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { TranslateService } from "@ngx-translate/core";
import { SetAppLanguage } from "src/store/app/actions/set-lang.actions";
import { SetCurrency } from "src/store/app/actions/set-currency.action";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import { Device } from "@capacitor/device";
import { SetAppConfig, SetPlatform } from "src/store/config/actions/set-app-config.actions";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ToastService } from "src/app/services/toast.service";
import { UsersState } from "src/store/users/users.state";
import { User } from "src/app/services/response-models/user.response.model";
import {
    STATE_INITIALIZER,
    StateInitializationInterface,
} from "src/app/services/state-initialization/state-initialization.service";
import { CurrenciesService } from "src/app/services/currencies.service";
import { AuthService } from "src/app/services/auth.service";
import { UsersService } from "src/app/services/users.service";
import { ConfigState } from "src/store/config/config.state";
import { LoadSelectedUser } from "src/store/users/actions/load-selected-user.action";
import { SegmentService } from "src/app/services/segment/segment.service";
import * as Sentry from "@sentry/angular-ivy";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { WindowWrapper } from "src/app/services/window-wrapper.service";
import { PushNotificationService } from "src/app/services/push-notification.service";

@Injectable({
    providedIn: "root",
})
export class PlatformService {
    private readonly userToken = "USERAPIKEY";
    private readonly authUser = "USER";

    constructor(
        private store: Store,
        private userService: UsersService,
        private authService: AuthService,
        private translateService: TranslateService,
        private currenciesService: CurrenciesService,
        private toastService: ToastService,
        private router: Router,
        private segmentService: SegmentService,
        private localStorageService: LocalStorageService,
        @Inject(STATE_INITIALIZER) private stateInitServices: StateInitializationInterface[],
        private _w: WindowWrapper,
        private pushNotificationService: PushNotificationService
    ) {}

    /**
     * Init mobile app or control panel based on roles and device that run the code
     * @param platform
     * @returns void
     */
    public async initPlatform(platform?: string) {
        if (!platform) {
            platform = await this.getAppConfig();
        }

        const tokenData = await this.authService.getToken();

        if (tokenData) {
            await this.initState(platform);
            return;
        }

        this.localStorageService.removeItem(this.authUser);
    }

    /**
     * Init the platform with api key from the params
     * @param apiKey
     */
    public async initPlatformWithApiKey(apiKey: string) {
        await this.authService.login(apiKey);
        await this.initState("web");
    }

    /**
     * NOTE: on desktop we cannot get the version
     */
    public async getAppConfig(): Promise<string> {
        const config = await Device.getInfo();

        this.store.dispatch(new SetAppConfig(config));
        this.store.dispatch(new SetPlatform(config.platform));

        return config.platform;
    }

    private async initState(platform: string): Promise<void> {
        const user: User = await this.userService.getUserByApiKey();

        const activeSubscription = this.checkForUserActiveSubscription();

        if (!activeSubscription) {
            return;
        }

        const window = this._w.getWindow();
        const searchParams = new URL(window.location).searchParams;
        const forceMobileApp = searchParams.has("force_mobile_app") === true;

        if (forceMobileApp) {
            this.localStorageService.writeObject("force_mobile_app", true);
            searchParams.delete("force_mobile_app");
        }

        // Check if we should initialize control panel or mobile app.
        const stateInitService = this.stateInitServices.find(
            (service: StateInitializationInterface) => service.canUse(user, platform)
        );

        if (!stateInitService) {
            this.localStorageService.removeItem(this.userToken);
            throw Error("Please provide init service");
        }

        // initialize push notifications
        this.pushNotificationService.setup();

        this.setSentryUserInformation(user, platform);
        this.setStatusBarColor();
        await stateInitService.init();
        await this.setUser(user);
    }

    private setSentryUserInformation(user: User, platform: string) {
        Sentry.setUser({ email: user.email, id: user.id });
        Sentry.setTag("companyId", user.company_id);
        Sentry.setTag("appPlatform", platform === "web" ? "web" : "app");
    }

    private setStatusBarColor() {
        if (Capacitor.getPlatform() === "android" && Capacitor.isPluginAvailable("StatusBar")) {
            StatusBar.setBackgroundColor({ color: "#1C3449" });
        }
    }

    private async setUser(user: User): Promise<void> {
        this.setUserLang(user);
        this.setUserDetailsTab(user);
        await this.setCurrency(user);
        this.localStorageService.writeObject(this.authUser, user);
        this.segmentService.identifyUser(user);
    }

    private setUserDetailsTab(user: User): void {
        const platform = this.store.selectSnapshot(ConfigState.getPlatform);

        // Don't load for web, only for mobile/tablet
        if (platform === "web") {
            return;
        }

        this.store.dispatch(new LoadSelectedUser(user));
    }

    private setUserLang(user: User) {
        moment.locale(user.language.short_code);
        const shortLang = this.getShortLang(user.language.short_code);
        this.translateService.use(shortLang);
        this.translateService.setDefaultLang(shortLang);
        this.store.dispatch(new SetAppLanguage(shortLang));
    }

    private async setCurrency({ language }: User): Promise<void> {
        const currency = await this.currenciesService.getCurrencyById(language.currency_id);
        this.store.dispatch(new SetCurrency(currency));
    }

    private checkForUserActiveSubscription(): boolean {
        const userActiveSubscriptions: boolean = this.store.selectSnapshot(
            UsersState.getUserActiveSubscription
        );

        if (!userActiveSubscriptions) {
            this.toastService.showToastMessage("LOGIN.NO_ACTIVE_SUBSCRIPTIONS", {
                color: "danger",
                duration: 2500,
                position: "top",
            });

            if (!window.location.href.endsWith("login")) {
                this.router.navigate(["login"]);
            }
        }

        return userActiveSubscriptions;
    }

    /**
     * Pass Apacta shortCode (en_US) and receive short lang (gb)
     *
     * @param shortCode
     * @private
     */
    public getShortLang(shortCode) {
        switch (shortCode) {
            case "en_US":
            case "en-US":
                return "gb";
            case "da_DK":
            case "da-DK":
                return "dk";
            case "de_DE":
                return "de";
            case "nb_NO":
                return "no";
            case "ro_RO":
                return "ro";
            case "pl_PL":
                return "pl";
            case "ru_RU":
                return "ru";
            default:
                return "";
        }
    }
}
