import { State, Action, Selector, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import {
    SecondMenu,
    SetSecondMenu,
    ClearSecondMenu,
    SetSecondMenuPreselectedOption,
    SetSecondMenuHighlightedOption,
    SetSecondMenuAutomaticHighlight,
} from "./actions/set-second-menu.action";

export interface SecondMenuStateModel {
    menu: SecondMenu;
    preselectedMenuOption: string;
    highlightedMenuOption: string;
    automaticHighlight: boolean;
}
@State<SecondMenuStateModel>({
    name: "secondMenu",
    defaults: {
        menu: null,
        preselectedMenuOption: "",
        highlightedMenuOption: "",
        automaticHighlight: false,
    },
})
@Injectable()
export class SecondMenuState {
    @Selector()
    static getMenu(state: SecondMenuStateModel) {
        return state.menu;
    }

    @Selector()
    static getPreselectedMenuOption(state: SecondMenuStateModel) {
        return state.preselectedMenuOption;
    }

    @Selector()
    static getHighlightedMenuOption(state: SecondMenuStateModel) {
        return state.highlightedMenuOption;
    }

    @Selector()
    static getAutomaticHighlight(state: SecondMenuStateModel) {
        return state.automaticHighlight;
    }

    @Action(SetSecondMenu)
    setMenu(ctx: StateContext<SecondMenuStateModel>, { payload }: SetSecondMenu) {
        ctx.setState({ ...ctx.getState(), menu: payload });
    }

    @Action(ClearSecondMenu)
    clear(ctx: StateContext<SecondMenuStateModel>) {
        ctx.setState({ ...ctx.getState(), menu: null, preselectedMenuOption: null });
    }

    @Action(SetSecondMenuPreselectedOption)
    setPreselectedMenuOption(
        ctx: StateContext<SecondMenuStateModel>,
        { payload }: SetSecondMenuPreselectedOption
    ) {
        ctx.setState({ ...ctx.getState(), preselectedMenuOption: payload });
    }

    @Action(SetSecondMenuHighlightedOption)
    setHighlightedMenuOption(
        ctx: StateContext<SecondMenuStateModel>,
        { payload }: SetSecondMenuHighlightedOption
    ) {
        ctx.setState({ ...ctx.getState(), highlightedMenuOption: payload });
    }

    @Action(SetSecondMenuAutomaticHighlight)
    setAutomaticHighlight(
        ctx: StateContext<SecondMenuStateModel>,
        { payload }: SetSecondMenuAutomaticHighlight
    ) {
        ctx.setState({ ...ctx.getState(), automaticHighlight: payload });
    }
}
