/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserMobileAppPreferencesFormsDailyReminderPreferences } from './UserMobileAppPreferencesFormsDailyReminderPreferences';
import {
    UserMobileAppPreferencesFormsDailyReminderPreferencesFromJSON,
    UserMobileAppPreferencesFormsDailyReminderPreferencesFromJSONTyped,
    UserMobileAppPreferencesFormsDailyReminderPreferencesToJSON,
} from './UserMobileAppPreferencesFormsDailyReminderPreferences';

/**
 * 
 * @export
 * @interface UserMobileAppPreferences
 */
export interface UserMobileAppPreferences {
    /**
     * 
     * @type {boolean}
     * @memberof UserMobileAppPreferences
     */
    enableNotificationsTasks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMobileAppPreferences
     */
    enableNotificationsMessages?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMobileAppPreferences
     */
    enableNotificationsFormsDailyReminder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMobileAppPreferences
     */
    manuallyChanged?: boolean;
    /**
     * 
     * @type {UserMobileAppPreferencesFormsDailyReminderPreferences}
     * @memberof UserMobileAppPreferences
     */
    formsDailyReminderPreferences?: UserMobileAppPreferencesFormsDailyReminderPreferences;
}

/**
 * Check if a given object implements the UserMobileAppPreferences interface.
 */
export function instanceOfUserMobileAppPreferences(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserMobileAppPreferencesFromJSON(json: any): UserMobileAppPreferences {
    return UserMobileAppPreferencesFromJSONTyped(json, false);
}

export function UserMobileAppPreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMobileAppPreferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enableNotificationsTasks': !exists(json, 'enable_notifications_tasks') ? undefined : json['enable_notifications_tasks'],
        'enableNotificationsMessages': !exists(json, 'enable_notifications_messages') ? undefined : json['enable_notifications_messages'],
        'enableNotificationsFormsDailyReminder': !exists(json, 'enable_notifications_forms_daily_reminder') ? undefined : json['enable_notifications_forms_daily_reminder'],
        'manuallyChanged': !exists(json, 'manually_changed') ? undefined : json['manually_changed'],
        'formsDailyReminderPreferences': !exists(json, 'forms_daily_reminder_preferences') ? undefined : UserMobileAppPreferencesFormsDailyReminderPreferencesFromJSON(json['forms_daily_reminder_preferences']),
    };
}

export function UserMobileAppPreferencesToJSON(value?: UserMobileAppPreferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enable_notifications_tasks': value.enableNotificationsTasks,
        'enable_notifications_messages': value.enableNotificationsMessages,
        'enable_notifications_forms_daily_reminder': value.enableNotificationsFormsDailyReminder,
        'manually_changed': value.manuallyChanged,
        'forms_daily_reminder_preferences': UserMobileAppPreferencesFormsDailyReminderPreferencesToJSON(value.formsDailyReminderPreferences),
    };
}

