/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseFile } from './BaseFile';
import {
    BaseFileFromJSON,
    BaseFileFromJSONTyped,
    BaseFileToJSON,
} from './BaseFile';
import type { City } from './City';
import {
    CityFromJSON,
    CityFromJSONTyped,
    CityToJSON,
} from './City';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    id?: string;
    /**
     * Read-only
     * @type {string}
     * @memberof Company
     */
    createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    contactPersonId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    countryId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    cityId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    languageId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    cvr?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    invoiceEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    receiveFormMails?: string | null;
    /**
     * Format like eg. `45` or `49`
     * @type {string}
     * @memberof Company
     */
    phoneCountrycode?: string;
    /**
     * Format like eg. `28680133` or `046158971404`
     * @type {string}
     * @memberof Company
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    website?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    vatPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    nextInvoiceNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    nextProjectNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    nextOfferNumber?: number;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    expired?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof Company
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {City}
     * @memberof Company
     */
    city?: City;
    /**
     * 
     * @type {BaseFile}
     * @memberof Company
     */
    file?: BaseFile;
}

/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'contactPersonId': !exists(json, 'contact_person_id') ? undefined : json['contact_person_id'],
        'countryId': !exists(json, 'country_id') ? undefined : json['country_id'],
        'cityId': !exists(json, 'city_id') ? undefined : json['city_id'],
        'fileId': !exists(json, 'file_id') ? undefined : json['file_id'],
        'languageId': !exists(json, 'language_id') ? undefined : json['language_id'],
        'cvr': !exists(json, 'cvr') ? undefined : json['cvr'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'streetName': !exists(json, 'street_name') ? undefined : json['street_name'],
        'invoiceEmail': !exists(json, 'invoice_email') ? undefined : json['invoice_email'],
        'contactEmail': !exists(json, 'contact_email') ? undefined : json['contact_email'],
        'receiveFormMails': !exists(json, 'receive_form_mails') ? undefined : json['receive_form_mails'],
        'phoneCountrycode': !exists(json, 'phone_countrycode') ? undefined : json['phone_countrycode'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'vatPercent': !exists(json, 'vat_percent') ? undefined : json['vat_percent'],
        'nextInvoiceNumber': !exists(json, 'next_invoice_number') ? undefined : json['next_invoice_number'],
        'nextProjectNumber': !exists(json, 'next_project_number') ? undefined : json['next_project_number'],
        'nextOfferNumber': !exists(json, 'next_offer_number') ? undefined : json['next_offer_number'],
        'expired': !exists(json, 'expired') ? undefined : (new Date(json['expired'])),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'city': !exists(json, 'city') ? undefined : CityFromJSON(json['city']),
        'file': !exists(json, 'file') ? undefined : BaseFileFromJSON(json['file']),
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_by_id': value.createdById,
        'contact_person_id': value.contactPersonId,
        'country_id': value.countryId,
        'city_id': value.cityId,
        'file_id': value.fileId,
        'language_id': value.languageId,
        'cvr': value.cvr,
        'name': value.name,
        'street_name': value.streetName,
        'invoice_email': value.invoiceEmail,
        'contact_email': value.contactEmail,
        'receive_form_mails': value.receiveFormMails,
        'phone_countrycode': value.phoneCountrycode,
        'phone': value.phone,
        'website': value.website,
        'vat_percent': value.vatPercent,
        'next_invoice_number': value.nextInvoiceNumber,
        'next_project_number': value.nextProjectNumber,
        'next_offer_number': value.nextOfferNumber,
        'expired': value.expired === undefined ? undefined : (value.expired.toISOString()),
        'city': CityToJSON(value.city),
        'file': BaseFileToJSON(value.file),
    };
}

