/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLabelRequest
 */
export interface CreateLabelRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLabelRequest
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLabelRequest
     */
    bgColor?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLabelRequest
     */
    textColor?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLabelRequest
     */
    entity?: CreateLabelRequestEntityEnum;
}


/**
 * @export
 */
export const CreateLabelRequestEntityEnum = {
    Employee: 'employee',
    Task: 'task'
} as const;
export type CreateLabelRequestEntityEnum = typeof CreateLabelRequestEntityEnum[keyof typeof CreateLabelRequestEntityEnum];


/**
 * Check if a given object implements the CreateLabelRequest interface.
 */
export function instanceOfCreateLabelRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;

    return isInstance;
}

export function CreateLabelRequestFromJSON(json: any): CreateLabelRequest {
    return CreateLabelRequestFromJSONTyped(json, false);
}

export function CreateLabelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLabelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'bgColor': !exists(json, 'bg_color') ? undefined : json['bg_color'],
        'textColor': !exists(json, 'text_color') ? undefined : json['text_color'],
        'entity': !exists(json, 'entity') ? undefined : json['entity'],
    };
}

export function CreateLabelRequestToJSON(value?: CreateLabelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'bg_color': value.bgColor,
        'text_color': value.textColor,
        'entity': value.entity,
    };
}

