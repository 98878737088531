/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExpenseLine } from './ExpenseLine';
import {
    ExpenseLineFromJSON,
    ExpenseLineFromJSONTyped,
    ExpenseLineToJSON,
} from './ExpenseLine';

/**
 * 
 * @export
 * @interface ExpenseLinesView200Response
 */
export interface ExpenseLinesView200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ExpenseLinesView200Response
     */
    success: boolean;
    /**
     * 
     * @type {ExpenseLine}
     * @memberof ExpenseLinesView200Response
     */
    data: ExpenseLine;
}

/**
 * Check if a given object implements the ExpenseLinesView200Response interface.
 */
export function instanceOfExpenseLinesView200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ExpenseLinesView200ResponseFromJSON(json: any): ExpenseLinesView200Response {
    return ExpenseLinesView200ResponseFromJSONTyped(json, false);
}

export function ExpenseLinesView200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpenseLinesView200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': ExpenseLineFromJSON(json['data']),
    };
}

export function ExpenseLinesView200ResponseToJSON(value?: ExpenseLinesView200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': ExpenseLineToJSON(value.data),
    };
}

