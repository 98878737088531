/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTextTemplateRequest
 */
export interface CreateTextTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTextTemplateRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTextTemplateRequest
     */
    content: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTextTemplateRequest
     */
    expense?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTextTemplateRequest
     */
    offer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTextTemplateRequest
     */
    invoice?: boolean;
}

/**
 * Check if a given object implements the CreateTextTemplateRequest interface.
 */
export function instanceOfCreateTextTemplateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function CreateTextTemplateRequestFromJSON(json: any): CreateTextTemplateRequest {
    return CreateTextTemplateRequestFromJSONTyped(json, false);
}

export function CreateTextTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTextTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'content': json['content'],
        'expense': !exists(json, 'expense') ? undefined : json['expense'],
        'offer': !exists(json, 'offer') ? undefined : json['offer'],
        'invoice': !exists(json, 'invoice') ? undefined : json['invoice'],
    };
}

export function CreateTextTemplateRequestToJSON(value?: CreateTextTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'content': value.content,
        'expense': value.expense,
        'offer': value.offer,
        'invoice': value.invoice,
    };
}

