import { Form } from "src/app/services/response-models/form.response.model";
import { DailyHours } from "src/app/services/response-models/user.response.model";

export class SetUserHours {
    static readonly type = "[APP] Set user hours";

    constructor(public payload: string) {}
}

export class SetMyFormsHours {
    static readonly type = "[APP] Set user form hours";

    constructor(public payload: Array<Form>) {}
}

export class SetUserDailyHours {
    static readonly type = "[APP] Set user daily hours";

    constructor(public payload: Array<DailyHours>) {}
}
