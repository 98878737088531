import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { of } from "rxjs/internal/observable/of";
import { delay, mergeMap, retryWhen, scan } from "rxjs/operators";

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            retryWhen((errors) =>
                errors.pipe(
                    scan((retryCount, error) => {
                        if (error instanceof HttpErrorResponse) {
                            if (
                                error.status === 404 ||
                                error.status === 422 ||
                                error.status === 401
                            ) {
                                throw error;
                            }
                            if (retryCount >= 4) {
                                throw error;
                            }
                        }
                        return retryCount + 1;
                    }, 0),
                    mergeMap((retryCount) => {
                        const delayTime = Math.pow(retryCount, 2) * 1000; // Exponential backoff
                        return of(null).pipe(delay(delayTime));
                    })
                )
            )
        );
    }
}
