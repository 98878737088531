/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontpageWeeklyConsumptionDTO } from './FrontpageWeeklyConsumptionDTO';
import {
    FrontpageWeeklyConsumptionDTOFromJSON,
    FrontpageWeeklyConsumptionDTOFromJSONTyped,
    FrontpageWeeklyConsumptionDTOToJSON,
} from './FrontpageWeeklyConsumptionDTO';

/**
 * 
 * @export
 * @interface IGetConsumption200ResponseDataWeeklyConsumption
 */
export interface IGetConsumption200ResponseDataWeeklyConsumption {
    /**
     * 
     * @type {Array<FrontpageWeeklyConsumptionDTO>}
     * @memberof IGetConsumption200ResponseDataWeeklyConsumption
     */
    productCost: Array<FrontpageWeeklyConsumptionDTO>;
    /**
     * 
     * @type {Array<FrontpageWeeklyConsumptionDTO>}
     * @memberof IGetConsumption200ResponseDataWeeklyConsumption
     */
    hoursCost: Array<FrontpageWeeklyConsumptionDTO>;
    /**
     * 
     * @type {Array<FrontpageWeeklyConsumptionDTO>}
     * @memberof IGetConsumption200ResponseDataWeeklyConsumption
     */
    contribution: Array<FrontpageWeeklyConsumptionDTO>;
}

/**
 * Check if a given object implements the IGetConsumption200ResponseDataWeeklyConsumption interface.
 */
export function instanceOfIGetConsumption200ResponseDataWeeklyConsumption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productCost" in value;
    isInstance = isInstance && "hoursCost" in value;
    isInstance = isInstance && "contribution" in value;

    return isInstance;
}

export function IGetConsumption200ResponseDataWeeklyConsumptionFromJSON(json: any): IGetConsumption200ResponseDataWeeklyConsumption {
    return IGetConsumption200ResponseDataWeeklyConsumptionFromJSONTyped(json, false);
}

export function IGetConsumption200ResponseDataWeeklyConsumptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IGetConsumption200ResponseDataWeeklyConsumption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productCost': ((json['product_cost'] as Array<any>).map(FrontpageWeeklyConsumptionDTOFromJSON)),
        'hoursCost': ((json['hours_cost'] as Array<any>).map(FrontpageWeeklyConsumptionDTOFromJSON)),
        'contribution': ((json['contribution'] as Array<any>).map(FrontpageWeeklyConsumptionDTOFromJSON)),
    };
}

export function IGetConsumption200ResponseDataWeeklyConsumptionToJSON(value?: IGetConsumption200ResponseDataWeeklyConsumption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_cost': ((value.productCost as Array<any>).map(FrontpageWeeklyConsumptionDTOToJSON)),
        'hours_cost': ((value.hoursCost as Array<any>).map(FrontpageWeeklyConsumptionDTOToJSON)),
        'contribution': ((value.contribution as Array<any>).map(FrontpageWeeklyConsumptionDTOToJSON)),
    };
}

