import { User } from "src/app/services/response-models/user.response.model";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { LoadUsers } from "./actions/load-users.action";
import { Pagination } from "src/app/services/response-models/projects.response.model";
import { LoadUsersPagination } from "./actions/load-users-pagination";
import { LoadSelectedUser, LoadUserActiveSubscriptions } from "./actions/load-selected-user.action";
import { ClearUsers } from "./actions/clear-users.action";
import { Injectable } from "@angular/core";
import { ClearUserIntervals, SetCheckInIntervals } from "./actions/set-checkin-intervals.action";
import { LoadNotificationsCount } from "src/store/users/actions/load-notifications-count.actions";

export interface UsersStateModel {
    users: Array<User>;
    pagination: Pagination;
    selectedUser: User;
    selectedUserHasActiveSubscriptions: boolean;
    notActiveUsers: number;
    notificationsCount: number;
    intervals: number[];
}

@State<UsersStateModel>({
    name: "users",
    defaults: {
        users: [],
        pagination: null,
        selectedUser: null,
        selectedUserHasActiveSubscriptions: false,
        notActiveUsers: 0,
        notificationsCount: 0,
        intervals: [],
    },
})
@Injectable()
export class UsersState {
    @Selector()
    static getUsers(state: UsersStateModel) {
        return state.users;
    }

    @Selector()
    static getUsersPagination(state: UsersStateModel) {
        return state.pagination;
    }

    @Selector()
    static getSelectedUser(state: UsersStateModel) {
        return state.selectedUser;
    }

    @Selector()
    static getUserActiveSubscription(state: UsersStateModel) {
        return state.selectedUserHasActiveSubscriptions;
    }

    @Selector()
    static getNotificationsCount(state: UsersStateModel) {
        return state.notificationsCount;
    }

    @Selector()
    static getIntervals(state: UsersStateModel) {
        return state.intervals;
    }

    @Action(LoadUsers)
    loadUsers(ctx: StateContext<UsersStateModel>, { payload }: LoadUsers) {
        const state = ctx.getState();
        ctx.patchState({
            users: [...state.users, ...payload],
        });
    }

    @Action(LoadUsersPagination)
    pagination(ctx: StateContext<UsersStateModel>, { payload }: LoadUsersPagination) {
        ctx.setState({ ...ctx.getState(), pagination: payload });
    }

    @Action(LoadSelectedUser)
    selectedUser(ctx: StateContext<UsersStateModel>, { payload }: LoadSelectedUser) {
        ctx.setState({ ...ctx.getState(), selectedUser: payload });
    }

    @Action(LoadUserActiveSubscriptions)
    selectedUserActiveSubscriptions(
        ctx: StateContext<UsersStateModel>,
        { payload }: LoadUserActiveSubscriptions
    ) {
        ctx.setState({ ...ctx.getState(), selectedUserHasActiveSubscriptions: payload });
    }

    @Action(ClearUsers)
    clear(ctx: StateContext<UsersStateModel>) {
        ctx.setState({ ...ctx.getState(), users: [] });
    }

    @Action(LoadNotificationsCount)
    loadNotificationsCount(
        ctx: StateContext<UsersStateModel>,
        { payload }: LoadNotificationsCount
    ) {
        ctx.setState({ ...ctx.getState(), notificationsCount: payload });
    }

    @Action(SetCheckInIntervals)
    setIntervals(ctx: StateContext<UsersStateModel>, { payload }: SetCheckInIntervals) {
        const state = ctx.getState();
        ctx.patchState({
            intervals: [...state.intervals, ...payload],
        });
    }

    @Action(ClearUserIntervals)
    clearIntervals(ctx: StateContext<UsersStateModel>) {
        ctx.setState({ ...ctx.getState(), intervals: [] });
    }
}
