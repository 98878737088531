/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { GetMe200ResponseCompanySettings } from './GetMe200ResponseCompanySettings';
import {
    GetMe200ResponseCompanySettingsFromJSON,
    GetMe200ResponseCompanySettingsFromJSONTyped,
    GetMe200ResponseCompanySettingsToJSON,
} from './GetMe200ResponseCompanySettings';
import type { GetMe200ResponseLogos } from './GetMe200ResponseLogos';
import {
    GetMe200ResponseLogosFromJSON,
    GetMe200ResponseLogosFromJSONTyped,
    GetMe200ResponseLogosToJSON,
} from './GetMe200ResponseLogos';
import type { GetMe200ResponseSubscription } from './GetMe200ResponseSubscription';
import {
    GetMe200ResponseSubscriptionFromJSON,
    GetMe200ResponseSubscriptionFromJSONTyped,
    GetMe200ResponseSubscriptionToJSON,
} from './GetMe200ResponseSubscription';
import type { GetMe200ResponseUser } from './GetMe200ResponseUser';
import {
    GetMe200ResponseUserFromJSON,
    GetMe200ResponseUserFromJSONTyped,
    GetMe200ResponseUserToJSON,
} from './GetMe200ResponseUser';
import type { PriceMargin } from './PriceMargin';
import {
    PriceMarginFromJSON,
    PriceMarginFromJSONTyped,
    PriceMarginToJSON,
} from './PriceMargin';

/**
 * 
 * @export
 * @interface GetMe200Response
 */
export interface GetMe200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200Response
     */
    success: boolean;
    /**
     * 
     * @type {GetMe200ResponseUser}
     * @memberof GetMe200Response
     */
    user: GetMe200ResponseUser;
    /**
     * 
     * @type {Company}
     * @memberof GetMe200Response
     */
    company: Company;
    /**
     * Hash to use for Intercom integration
     * @type {string}
     * @memberof GetMe200Response
     */
    intercomHash: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMe200Response
     */
    features: Array<GetMe200ResponseFeaturesEnum>;
    /**
     * 
     * @type {GetMe200ResponseCompanySettings}
     * @memberof GetMe200Response
     */
    companySettings: GetMe200ResponseCompanySettings;
    /**
     * 
     * @type {GetMe200ResponseSubscription}
     * @memberof GetMe200Response
     */
    subscription: GetMe200ResponseSubscription;
    /**
     * 
     * @type {Array<PriceMargin>}
     * @memberof GetMe200Response
     */
    priceMargins: Array<PriceMargin>;
    /**
     * 
     * @type {string}
     * @memberof GetMe200Response
     */
    dineroAuthUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMe200Response
     */
    dineroTokenExpired?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetMe200Response
     */
    readonly dineroTokenExpireDate?: Date | null;
    /**
     * 
     * @type {GetMe200ResponseLogos}
     * @memberof GetMe200Response
     */
    logos: GetMe200ResponseLogos;
}


/**
 * @export
 */
export const GetMe200ResponseFeaturesEnum = {
    AccessToErpLedgerIntegration: 'access_to_erp_ledger_integration',
    Activities: 'activities',
    AllowIndividualLoginForEmployees: 'allow_individual_login_for_employees',
    AllowNoSubscription: 'allow_no_subscription',
    ArkaSoftwareIntegration: 'arka_software_integration',
    ArkaTimeEntryTypesDescription: 'arka_time_entry_types_description',
    Calendar: 'calendar',
    CheckInOut: 'check_in_out',
    Contacts: 'contacts',
    Cp3CreateNavbutton: 'cp3.create_navbutton',
    Cp3Customers: 'cp3.customers',
    Cp3Planner: 'cp3.planner',
    Cp3Projects: 'cp3.projects',
    Cp3Reports: 'cp3.reports',
    Cp3HackRedirectFrontpageToOffers: 'cp3.hack.redirect_frontpage_to_offers',
    Cp3Forms: 'cp3.forms',
    CreateOffers: 'create_offers',
    CustomFields: 'custom_fields',
    CustomSubprojects: 'custom_subprojects',
    DataExport: 'data_export',
    DistributorVendors: 'distributor_vendors',
    DoNotUseV2Frontend: 'do_not_use_v2_frontend',
    DoNotUseV3Frontend: 'do_not_use_v3_frontend',
    DrivingKilometers: 'driving_kilometers',
    ErpIntegration: 'erp_integration',
    ErpStockLocationManagement: 'erp_stock_location_management',
    ExportProjectsCsv: 'export_projects_csv',
    ImportProductsContactsErp: 'import_products_contacts_erp',
    Invoices: 'invoices',
    Timereg: 'timereg',
    LimitedAccess: 'limited_access',
    LinkingExpenseLinesEanProducts: 'linking_expense_lines_ean_products',
    LockedInvoices: 'locked_invoices',
    MachineryRental: 'machinery_rental',
    MassInvoices: 'mass_invoices',
    MobileAppAdvancedCreateProject: 'mobile_app_advanced_create_project',
    MobileAppProjectExpenses: 'mobile_app_project_expenses',
    MobileAppProjectOverview: 'mobile_app_project_overview',
    Offers: 'offers',
    OrderComplaints: 'order_complaints',
    PayrollFileIntegration: 'payroll_file_integration',
    PriceFiles: 'price_files',
    ProductLabels: 'product_labels',
    ProductRules: 'product_rules',
    Products: 'products',
    ProjectExpenses: 'project_expenses',
    ProjectLeader: 'project_leader',
    ProjectNumbers: 'project_numbers',
    ProjectOverview: 'project_overview',
    ProjectStatus: 'project_status',
    Projects: 'projects',
    PublicProjects: 'public_projects',
    QualityAssurance: 'quality_assurance',
    Reports: 'reports',
    RogerIntegration: 'roger_integration',
    SchedulingV2: 'scheduling_v2',
    SchedulingV2InV3: 'scheduling_v2_in_v3',
    SettingsIntegrationsTab: 'settings_integrations_tab',
    ShowErpIds: 'show_erp_ids',
    StockLocations: 'stock_locations',
    SubscriptionPaywall: 'subscription_paywall',
    TimeEntryTypes: 'time_entry_types',
    TimeRegistration: 'time_registration',
    Users: 'users',
    UsersOverview: 'users_overview',
    ViewOnlyUsers: 'view_only_users',
    Wall: 'wall'
} as const;
export type GetMe200ResponseFeaturesEnum = typeof GetMe200ResponseFeaturesEnum[keyof typeof GetMe200ResponseFeaturesEnum];


/**
 * Check if a given object implements the GetMe200Response interface.
 */
export function instanceOfGetMe200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "intercomHash" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "companySettings" in value;
    isInstance = isInstance && "subscription" in value;
    isInstance = isInstance && "priceMargins" in value;
    isInstance = isInstance && "logos" in value;

    return isInstance;
}

export function GetMe200ResponseFromJSON(json: any): GetMe200Response {
    return GetMe200ResponseFromJSONTyped(json, false);
}

export function GetMe200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMe200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'user': GetMe200ResponseUserFromJSON(json['user']),
        'company': CompanyFromJSON(json['company']),
        'intercomHash': json['intercomHash'],
        'features': json['features'],
        'companySettings': GetMe200ResponseCompanySettingsFromJSON(json['company_settings']),
        'subscription': GetMe200ResponseSubscriptionFromJSON(json['subscription']),
        'priceMargins': ((json['price_margins'] as Array<any>).map(PriceMarginFromJSON)),
        'dineroAuthUrl': !exists(json, 'dinero_auth_url') ? undefined : json['dinero_auth_url'],
        'dineroTokenExpired': !exists(json, 'dinero_token_expired') ? undefined : json['dinero_token_expired'],
        'dineroTokenExpireDate': !exists(json, 'dinero_token_expire_date') ? undefined : (json['dinero_token_expire_date'] === null ? null : new Date(json['dinero_token_expire_date'])),
        'logos': GetMe200ResponseLogosFromJSON(json['logos']),
    };
}

export function GetMe200ResponseToJSON(value?: GetMe200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'user': GetMe200ResponseUserToJSON(value.user),
        'company': CompanyToJSON(value.company),
        'intercomHash': value.intercomHash,
        'features': value.features,
        'company_settings': GetMe200ResponseCompanySettingsToJSON(value.companySettings),
        'subscription': GetMe200ResponseSubscriptionToJSON(value.subscription),
        'price_margins': ((value.priceMargins as Array<any>).map(PriceMarginToJSON)),
        'dinero_auth_url': value.dineroAuthUrl,
        'dinero_token_expired': value.dineroTokenExpired,
        'logos': GetMe200ResponseLogosToJSON(value.logos),
    };
}

