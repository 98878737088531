import { State, Selector, Action, StateContext } from "@ngxs/store";
import {
    Material,
    MaterialRental,
} from "src/app/services/response-models/materials.response.model";
import { Pagination } from "src/app/services/response-models/projects.response.model";
import {
    LoadCheckInMaterials,
    LoadCheckInMaterialsPaginaton,
    ClearCheckInMaterials,
} from "./actions/checkin-materials.action";
import {
    LoadCheckOutMaterials,
    LoadCheckOutMaterialsPaginaton,
    ClearCheckOutMaterials,
} from "./actions/checkout-materials.action";
import { Injectable } from "@angular/core";

export interface MaterialsStateModel {
    checkInMaterials: Array<Material>;
    checkInPagination: Pagination;
    checkOutMaterials: Array<MaterialRental>;
    checkOutPagination: Pagination;
}

@State<MaterialsStateModel>({
    name: "materials",
    defaults: {
        checkInMaterials: [],
        checkInPagination: null,
        checkOutMaterials: [],
        checkOutPagination: null,
    },
})
@Injectable()
export class MaterialsState {
    @Selector()
    public static getCheckInMaterials(state: MaterialsStateModel) {
        return state.checkInMaterials;
    }

    @Selector()
    public static getCheckInPagination(state: MaterialsStateModel) {
        return state.checkInPagination;
    }

    @Selector()
    public static getCheckOutMaterials(state: MaterialsStateModel) {
        return state.checkOutMaterials;
    }

    @Selector()
    public static getCheckOutPagination(state: MaterialsStateModel) {
        return state.checkOutPagination;
    }

    @Action(LoadCheckInMaterials)
    loadCheckInMaterials(
        ctx: StateContext<MaterialsStateModel>,
        { payload }: LoadCheckInMaterials
    ) {
        const state = ctx.getState();
        ctx.patchState({
            checkInMaterials: [...state.checkInMaterials, ...payload],
        });
    }

    @Action(LoadCheckInMaterialsPaginaton)
    loadCheckInPagination(
        ctx: StateContext<MaterialsStateModel>,
        { payload }: LoadCheckInMaterialsPaginaton
    ) {
        ctx.setState({ ...ctx.getState(), checkInPagination: payload });
    }

    @Action(ClearCheckInMaterials)
    clearCheckIn(ctx: StateContext<MaterialsStateModel>) {
        ctx.setState({ ...ctx.getState(), checkInMaterials: [] });
    }

    @Action(LoadCheckOutMaterials)
    loadCheckOutMaterials(
        ctx: StateContext<MaterialsStateModel>,
        { payload }: LoadCheckOutMaterials
    ) {
        const state = ctx.getState();
        ctx.patchState({
            checkOutMaterials: [...state.checkOutMaterials, ...payload],
        });
    }

    @Action(LoadCheckOutMaterialsPaginaton)
    loadCheckOutPagination(
        ctx: StateContext<MaterialsStateModel>,
        { payload }: LoadCheckOutMaterialsPaginaton
    ) {
        ctx.setState({ ...ctx.getState(), checkOutPagination: payload });
    }

    @Action(ClearCheckOutMaterials)
    clearCheckOut(ctx: StateContext<MaterialsStateModel>) {
        ctx.setState({ ...ctx.getState(), checkOutMaterials: [] });
    }
}
