/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MergeProjectsRequest
 */
export interface MergeProjectsRequest {
    /**
     * 
     * @type {string}
     * @memberof MergeProjectsRequest
     */
    originalProjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof MergeProjectsRequest
     */
    mergingProjectId?: string;
}

/**
 * Check if a given object implements the MergeProjectsRequest interface.
 */
export function instanceOfMergeProjectsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MergeProjectsRequestFromJSON(json: any): MergeProjectsRequest {
    return MergeProjectsRequestFromJSONTyped(json, false);
}

export function MergeProjectsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeProjectsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'originalProjectId': !exists(json, 'original_project_id') ? undefined : json['original_project_id'],
        'mergingProjectId': !exists(json, 'merging_project_id') ? undefined : json['merging_project_id'],
    };
}

export function MergeProjectsRequestToJSON(value?: MergeProjectsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'original_project_id': value.originalProjectId,
        'merging_project_id': value.mergingProjectId,
    };
}

