import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Select, Store } from "@ngxs/store";
import {
    CreateFormPhotoResponseModel,
    CreateFormTemplateResponseModel,
    FormTemplateData,
    FormTemplates,
} from "src/app/services/response-models/form-templates.response.model";
import { FormTemplatesState } from "src/store/form-templates/form-templates.state";
import { Observable } from "rxjs";
import {
    ClearFormTemplates,
    ClearCardForm,
    LoadFormTemplates,
    SavePagination,
} from "src/store/form-templates/actions/set-form-templates.action";
import { LoadSelectedFormTemplate } from "src/store/form-templates/actions/set-selected-template.action";
import { LoadCreatedFormId } from "src/store/form-templates/actions/set-created-form-id.action";
import { LoadStateFormTemplate } from "src/store/form-templates/actions/set-state-form.action";
import { SetFormToEdit } from "src/store/form-templates/actions/set-edit-form.actions";
import * as moment from "moment";
import { Navigate } from "src/store/router/router.actions";
import { LoadIsFormValid } from "src/store/form-templates/actions/set-form-valid.action";
import { LoadFormError } from "src/store/form-templates/actions/set-form-error.action";
import { AppState } from "src/store/app/app.state";
import { ModalController } from "@ionic/angular";
import { IncludeType } from "src/app/shared/enums/include-type.enum";
import { UtilityService } from "src/app/services/utility.service";
import {
    ClearForms,
    LoadForms,
    SavePagination as SaveFormsPagination,
} from "src/store/forms/actions/set-forms.action";
import { TimeEntryService } from "src/app/services/time-entry.service";
import { ToastService } from "src/app/services/toast.service";
import { ProjectsService } from "src/app/services/projects.service";
import { ProductService } from "src/app/services/product.service";
import { UsersService } from "src/app/services/users.service";
import {
    Form,
    FormsResponseModel,
    GetFormByIdResponseModel,
    StateForm,
    StateFormField,
    UpdateFormResposeModel,
} from "src/app/services/response-models/form.response.model";
import { MaterialService } from "src/app/services/material.service";
import { sentryCaptureException } from "src/app/utility/errors";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SetNotebookPhotos } from "src/store/projects/actions/set-notebook-photos.action";
import { SetNotebookProducts } from "src/store/projects/actions/set-notebook-products.action";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { NOTEBOOK_PHOTOS, NOTEBOOK_PRODUCTS } from "src/environments/constants";
import { ApactaApiSdkService } from "src/app/services/apacta-api-sdk.service";

@Injectable({
    providedIn: "root",
})
export class FormsService {
    @Select(FormTemplatesState.getStateForm) stateForm$: Observable<StateForm>;

    private readonly formTemplatesUrl: string = `${environment.apiBaseUrl}form_templates`;
    private readonly formUrl: string = `${environment.apiBaseUrl}forms`;
    private readonly formFieldsUrl: string = `${environment.apiBaseUrl}form_fields`;
    private readonly createFormUrl: string = `${environment.apiBaseUrl}forms`;
    private readonly removePhotoUrl: string = `${environment.apiBaseUrl}files`;

    constructor(
        private http: HttpClient,
        private store: Store,
        private materialService: MaterialService,
        private productService: ProductService,
        private userService: UsersService,
        private projectService: ProjectsService,
        private toastService: ToastService,
        private timeEntryService: TimeEntryService,
        private modalCtrl: ModalController,
        private localStorageService: LocalStorageService,
        private apactaSdk: ApactaApiSdkService
    ) {}

    public async getFormTemplates(
        page = 1,
        searchQuery = "",
        sortBy = "",
        direction = "",
        useStore = true
    ): Promise<FormTemplateData[]> {
        // IF search or filter form templates funcs are returned
        // Delete the following line
        // TODO (aav)
        // Removing recently used now - it will break the order stuff in the mobile
        const url = this.formTemplatesUrl;

        const { data, pagination } = await this.http
            .get<FormTemplates>(url, {
                params: {
                    page: page.toString(),
                    q: searchQuery,
                    include: "form_template_fields,form_template_fields.form_field_types",
                    sort: sortBy,
                    direction,
                },
            })
            .toPromise()
            .catch((err) => {
                sentryCaptureException(err);
                this.toastService.showToastMessage("TOAST.BAD_CONNECTION");
                return { data: [], pagination: null };
            });

        if (page === 1 && useStore) {
            this.store.dispatch(new ClearFormTemplates());
        }

        if (useStore) {
            this.store.dispatch(new SavePagination(pagination));
            this.store.dispatch(new LoadFormTemplates(data));
        }

        return data;
    }

    public async getLastUsedFormTemplates() {
        const url = `${this.formTemplatesUrl}/recentlyUsed`;

        const { data, pagination } = await this.http
            .get<FormTemplates>(url)
            .toPromise()
            .catch((err) => {
                sentryCaptureException(err);
                this.toastService.showToastMessage("TOAST.BAD_CONNECTION");
                return { data: [], pagination: null };
            });

        this.store.dispatch(new ClearFormTemplates());
        this.store.dispatch(new SavePagination(pagination));
        this.store.dispatch(new LoadFormTemplates(data));
    }

    public getFormTemplateById = async (id: string) => {
        const url = `${this.formTemplatesUrl}/${id}`;

        const headers = {
            params: {
                include: "form_template_fields,form_template_fields.form_field_types",
            },
        };

        const { data } = await this.http.get<any>(url, headers).toPromise();

        this.store.dispatch(new LoadSelectedFormTemplate(data));
    };

    public getAllForms = async (projectId?: string, page = 1, startDate = "", endDate = "") => {
        const defaultIncludes = UtilityService.getIncludes([
            IncludeType.Users,
            IncludeType.FormTemplates,
            [IncludeType.Drivings, IncludeType.DrivingTypes],
            [IncludeType.TimeEntries, IncludeType.TimeEntryTypes],
            [IncludeType.FormFields, IncludeType.FormFieldTypes],
            [IncludeType.Projects, IncludeType.ProjectStatuses, IncludeType.ProjectStatusTypes],
        ]);

        const headers: { [key: string]: any } = {
            params: {
                include: defaultIncludes,
                page: page.toString(),
                sort: "form_date",
                direction: "desc",
                limit: 20,
            },
        };
        if (projectId) {
            headers.params["project_id[0]"] = projectId;
        }

        if (startDate && endDate) {
            headers.params.date_from = startDate;
            headers.params.date_to = endDate;
        }

        let currentTotalHours: any | number = 0;
        const { data, pagination } = await this.http
            .get<FormsResponseModel>(this.formUrl, headers)
            .toPromise();

        for (const form of data) {
            currentTotalHours = 0;

            if (form.time_entries.length !== 0) {
                form.time_entries.forEach((x) => {
                    if (x.time_entry_type) {
                        if (
                            x.time_entry_type.is_absence === false &&
                            x.time_entry_type.is_summable === true &&
                            x.time_entry_type.time_unit === "hourly"
                        ) {
                            currentTotalHours += x.sum;
                        }
                    } else {
                        currentTotalHours += x.sum;
                    }
                });

                currentTotalHours = moment.duration(Number(currentTotalHours), "seconds");
                form.hours =
                    Math.floor(currentTotalHours.asHours()) +
                    moment.utc(currentTotalHours.asMilliseconds()).format(":mm");
            }
        }

        if (page === 1) {
            this.store.dispatch(new ClearForms());
        }
        this.store.dispatch(new SaveFormsPagination(pagination));
        this.store.dispatch(new LoadForms(data));

        return { data, pagination } as FormsResponseModel;
    };

    public getAllFormsWithTimeEntries = async (projectId: string, page = 1) => {
        const headers = {
            params: {
                include: "users,time_entries.time_entry_types",
                project_id: projectId,
                page: page.toString(),
                sort: "form_date",
                direction: "desc",
                limit: "500",
            },
        };

        let currentTotalHours: any | number = 0;
        const res = await this.http.get<FormsResponseModel>(this.formUrl, headers).toPromise();
        const pagination = res.pagination;
        let data = res.data;
        const forms = [];

        for (const form of data) {
            currentTotalHours = 0;

            if (form.time_entries.length !== 0) {
                form.time_entries.forEach((x) => {
                    if (x.time_entry_type) {
                        if (
                            x.time_entry_type.is_absence === false &&
                            x.time_entry_type.is_summable === true &&
                            x.time_entry_type.time_unit === "hourly"
                        ) {
                            currentTotalHours += x.sum;
                        }
                    } else {
                        currentTotalHours += x.sum;
                    }
                });

                currentTotalHours = moment.duration(Number(currentTotalHours), "seconds");
                form.hours =
                    Math.floor(currentTotalHours.asHours()) +
                    moment.utc(currentTotalHours.asMilliseconds()).format(":mm");
                forms.push(form);
            }
        }
        data = forms;

        return { data, pagination } as FormsResponseModel;
    };

    public async getFormById(formId: string, include?: string): Promise<Form> {
        const url = `${this.formUrl}/${formId}`;
        const defaultInclude =
            "form_fields.form_field_types,form_fields.files,form_templates.form_template_fields.form_field_types,projects,products,material_rentals.materials,time_entries,users";
        const headers = {
            params: {
                include: include || defaultInclude,
            },
        };
        const { data } = await this.http.get<GetFormByIdResponseModel>(url, headers).toPromise();
        this.store.dispatch(new SetFormToEdit(data));
        return data;
    }

    public createFromTemplate = async (
        projectId: string,
        formTemplateId: string
    ): Promise<string> => {
        const body = {
            form_template_id: formTemplateId,
            project_id: projectId,
        };

        const { data } = await this.http
            .post<CreateFormTemplateResponseModel>(this.createFormUrl, body)
            .toPromise();
        this.store.dispatch(new LoadCreatedFormId(data.id));

        return data.id;
    };

    public sendFormPhotoToServer = async (
        photo: File | any,
        formTemplateFieldId: string,
        formId: string,
        fileName: string,
        exif?: any
    ): Promise<any> => {
        // E.g.: https://app.apacta.com/api/v1/forms/731e218b-abac-4cb3-8ec6-57010478d775/files
        const photosUrl = `${this.createFormUrl}/${formId}/files.json`;

        const headers = {
            params: {
                form_template_field_id: formTemplateFieldId,
            },
        };

        const body: FormData = new FormData();
        body.append("file", photo, fileName);
        if (exif) {
            body.append("exif_data", JSON.stringify(exif));
        }

        return await this.http
            .post<CreateFormPhotoResponseModel>(photosUrl, body, headers)
            .toPromise();
    };

    public removeFormPhotoFromServer = async (photoId: string) => {
        const url = `${this.removePhotoUrl}/${photoId}`;

        return await this.http.delete(url).toPromise();
    };

    public updateFormPhoto(formFieldId: string, comment: string) {
        const url = `${this.formFieldsUrl}/${formFieldId}`;

        const body = {
            comment,
        };

        return this.http.put(url, body).toPromise();
    }

    public updateStateForm(placement: number, value: any, comment?): void {
        let form: StateForm;

        this.stateForm$
            .subscribe((stateForm) => {
                form = stateForm;
            })
            .unsubscribe();

        form = { ...form };
        form.form_fields = form.form_fields.map((x) => ({ ...x }));
        const field = form.form_fields.find((f) => f.placement === Number(placement));

        if (field.identifier === "date") {
            form.form_date = value;
        }

        if (field.identifier === "materials") {
            field.content_value = {
                checkInMaterials: value || field.content_value.checkInMaterials,
                checkOutMaterials: comment || field.content_value.checkOutMaterials,
            };
            this.store.dispatch(new LoadStateFormTemplate(form));
            return;
        }

        field.content_value = value;
        field.comment = comment;

        this.store.dispatch(new LoadStateFormTemplate(form));
    }

    /**
     * Use this method to send the state form model to the BE and mark this form as draft false.
     * FormPostProcess Queue will be fired by the BE
     * @returns void
     */
    public async editForm(
        clearProductsFromNotebook: boolean = false,
        clearPhotosFromNotebook: boolean = false,
        selectedCardIds: string[] = []
    ): Promise<void> {
        this.stateForm$
            .subscribe(async (form) => {
                // Check time entries
                // If the sum of the time entries are more than the working time - show error
                if (!this.checkTimeEntriesTime(form) || !(await this.checkOldTimeEntries(form))) {
                    this.store.dispatch(new LoadIsFormValid(true));
                    this.toastService.showToastMessage("TOAST.TIME_ENTRIES_MORE_THEN_TIME");
                    return;
                }

                this.store.dispatch(new LoadIsFormValid(false));
                this.store.dispatch(new LoadFormError("TOAST.FORM_STILL_SENDING"));

                form = { ...form };
                if (selectedCardIds.length > 0) {
                    form.cards_with_selected_products = selectedCardIds;
                }

                const cardForm = this.store.selectSnapshot(FormTemplatesState.getCardForm);
                if (cardForm) {
                    form.card_id = cardForm.id;
                }

                // added try catch because of the possibility
                // of lost connection
                try {
                    // Check project status
                    // If project is closed form should not be submitted - terminate form
                    if (!(await this.projectService.isProjectOpen(form.project_id))) {
                        const user = this.store.selectSnapshot(AppState.getUser);
                        await this.projectService.getLastUsedProjects(
                            1,
                            "",
                            "",
                            "",
                            "",
                            [],
                            user.id
                        );
                        this.toastService.showToastMessage("TOAST.PROJECT_CLOSED");
                        this.store.dispatch(new Navigate(["employee", "projects"]));
                        return;
                    }
                } catch (err) {
                    if (err.status === 0) {
                        this.toastService.showToastMessage("TOAST.LOST_CONNECTION_OR_DEVICE_ERROR");
                    } else {
                        this.toastService.showGeneralErrorMessage();
                    }
                    sentryCaptureException(err);
                    this.store.dispatch(new LoadIsFormValid(true));
                    return;
                }

                // This we need because each photo should have its own form field that is "phto"
                let photos: Array<StateFormField> = [];

                for (let field of form.form_fields) {
                    field = { ...field };

                    // Handle products form field data...
                    if (field.identifier === "products") {
                        if (typeof field.content_value === "string") {
                            continue;
                        }

                        form.forms_products = field?.content_value?.map((x) => x._joinData);
                        delete form.products;
                    }

                    // Handle materials form field data...
                    // @mbc please remove this feature
                    if (field.identifier === "materials") {
                        if (field.content_value) {
                            if (field.content_value.checkInMaterials) {
                                for (const material of field.content_value.checkInMaterials) {
                                    await this.materialService.createCheckInMaterial(
                                        material,
                                        form.id
                                    );
                                }
                            }

                            if (field.content_value.checkOutMaterials) {
                                for (const material of field.content_value.checkOutMaterials) {
                                    await this.materialService.checkOutMaterialRental(
                                        material,
                                        form.id
                                    );
                                }
                            }

                            field.content_value = "";
                        }
                    }

                    // Handle extra time_entries
                    // Time entries should be handled in their table
                    // If we edit the form - edit the values of the time entries
                    // If we mark as draft = false - create them instead
                    if (field.identifier === "time_entry") {
                        if (!field.content_value) {
                            continue;
                        }

                        // Send time entries
                        for (const entry of field.content_value) {
                            const newEntry = { ...entry };

                            if (entry.id) {
                                newEntry.date = moment(form.form_date).format("YYYY-MM-DD");
                                await this.timeEntryService.editTimeEntry(newEntry);
                                continue;
                            }

                            delete newEntry.time_entry_type;
                            delete newEntry.label;
                            newEntry.user_id = form.user_id;
                            newEntry.form_id = form.id;
                            newEntry.project_id = form.project_id;
                            newEntry.date = moment(form.form_date).format("YYYY-MM-DD");

                            await this.timeEntryService.createTimeEntry(newEntry);
                        }

                        field.content_value = null;
                        continue;
                    }

                    // Handle photos
                    if (
                        field.identifier === "photo_with_gps" ||
                        field.identifier === "photo_without_gps"
                    ) {
                        if (field.content_value) {
                            for (const photo of field.content_value) {
                                if (photo.is_edit) {
                                    const photoField = form.form_fields.filter(
                                        (x) => x.file_id === photo.id
                                    )[0];
                                    if (photoField) {
                                        await this.updateFormPhoto(photoField.id, photo.comment);
                                        form.form_fields = form.form_fields.filter(
                                            (x) => x.file_id !== photo.id
                                        );
                                    }
                                    continue;
                                }

                                if (!photo.is_edit) {
                                    photos = photos.filter((x) => x.file_id !== photo.photo.id);
                                    photos.push({
                                        form_template_field_id: field.form_template_field_id,
                                        placement: field.placement,
                                        form_id: field.form_id,
                                        form_template_id: field.form_template_id,
                                        created_by_id: field.created_by_id,
                                        form_field_type_name: field.form_field_type_name,
                                        form_field_type_id: field.form_field_type_id,
                                        content_value: "",
                                        comment: photo.comment,
                                        file_id: photo.photo.id,
                                        identifier: field.identifier,
                                    } as StateFormField);
                                }
                            }
                        }

                        field.content_value = null;
                    }
                }

                form.form_fields = form.form_fields.concat(photos);

                const url = `${this.formUrl}/edit/${form.id}`;

                await this.http
                    .put<any>(url, form)
                    .toPromise()
                    .then(() => {
                        // after the form is sent, delete product and/or photos
                        // from the notebook
                        if (clearProductsFromNotebook === true) {
                            this.store.dispatch(new SetNotebookProducts(0));
                            this.localStorageService.removeItem(NOTEBOOK_PRODUCTS);
                        }
                        if (clearPhotosFromNotebook === true) {
                            this.deleteNotebookPhotos();
                        }

                        this.store.dispatch(new LoadIsFormValid(true));
                        this.toastService.showToastMessage("TOAST.FORM_SENT");
                        if (cardForm) {
                            this.store.dispatch(new ClearCardForm());
                            this.store.dispatch(
                                new Navigate(["employee", "calendar", "card", cardForm.id])
                            );
                        } else {
                            this.store.dispatch(
                                new Navigate(["employee", "projects", "details", form.project_id])
                            );
                        }
                    })
                    .catch((err) => {
                        if (err.status === 0) {
                            this.toastService.showToastMessage(
                                "TOAST.LOST_CONNECTION_OR_DEVICE_ERROR"
                            );
                        } else {
                            this.toastService.showGeneralErrorMessage();
                        }
                        sentryCaptureException(err);
                        this.store.dispatch(new LoadIsFormValid(true));
                    });
            })
            .unsubscribe();
    }

    public deleteFormById(formId: string): Promise<UpdateFormResposeModel> {
        const url = `${this.formUrl}/${formId}`;
        return this.http.delete<UpdateFormResposeModel>(url).toPromise();
    }

    /**
     * Checks if time entries exceed working hours
     * @param form StateForm
     * @returns boolean. False if time entries exceed working hours
     */
    private checkTimeEntriesTime(form: StateForm): boolean {
        // Check if form has time entry
        const field = form.form_fields.find((x) => x.identifier === "time_entry");

        // Is there time entry field in the form
        if (!field) {
            return true;
        }
        // Make sure time entry sum is not more than form time
        const duration = this.sumFormWorkingTime(form);
        let timeEntriesSum = 0;

        // Sum time entries
        for (const entry of field.content_value) {
            if (entry.is_summable && entry.time_entry_type.time_unit === "hourly") {
                timeEntriesSum += entry.sum;
            }
        }

        return duration - timeEntriesSum >= 0;
    }

    /**
     * Check for old time entries
     * Some companies still used them and we should take them in consideration
     * This does the same as FormsService.checkTimeEntriesTime but with old time entries
     * @param form StateForm
     * @returns bool
     */
    private async checkOldTimeEntries(form: StateForm): Promise<boolean> {
        // Search for the old time entries
        let timeEntriesSum = 0;
        const pattern = /time_entry_[\d\w-]+/i;
        const oldTimeEntries = form.form_fields.filter(
            (x) => pattern.test(x.form_template_field_identifier) === true
        );

        // If no time entries, move forward
        if (!oldTimeEntries.length) {
            return true;
        }

        // Get the working time
        const workingTime = this.sumFormWorkingTime(form);
        // Assuming that the form has time entries but no time fields, continue with form
        // Very unlikely but a possible scenario
        if (workingTime === null) {
            return true;
        }

        // Sum the time entries
        for (const entry of oldTimeEntries) {
            // Get time entry type and skip if not summable
            const entryTypeId =
                entry.form_template_field_identifier.split("_")[
                    entry.form_template_field_identifier.split("_").length - 1
                ];

            const timeEntryType = await this.timeEntryService.getTimeEntryById(entryTypeId);

            if (timeEntryType.is_summable === false || timeEntryType.time_unit === "daily") {
                continue;
            }

            timeEntriesSum += moment.duration(entry.content_value, "hours").asSeconds();
        }

        // Check if working time is less that the time entries
        return workingTime - timeEntriesSum >= 0;
    }

    /**
     * Returns the total working time of the form
     * If no time form fields are found return null
     * @param form
     * @returns number | null
     */
    private sumFormWorkingTime(form: StateForm): number | null {
        // Find fields
        const startTimeField = form.form_fields.find((x) => x.identifier === "time_interval_from");
        const endTimeField = form.form_fields.find((x) => x.identifier === "time_interval_to");
        const pauseTimeFiled = form.form_fields.find(
            (x) => x.identifier === "timepicker" && !x.identifier.includes("time_entry")
        );

        // If fields exist, take the durations as hours.
        const start = moment.duration(startTimeField ? startTimeField.content_value : 0, "hours");
        const end = moment.duration(endTimeField ? endTimeField.content_value : 0, "hours");
        const pause = moment.duration(pauseTimeFiled ? pauseTimeFiled.content_value : 0, "hours");

        let duration = 0;

        // If no time_interval_x fields check for dagseddel_sum
        if (!startTimeField && !endTimeField) {
            const sum = form.form_fields.find((x) => x.identifier.includes("dagseddel_sum"));

            // End if no dagseddel_sum and normal time interval fields
            if (!sum) {
                return null;
            }

            // Else return dagseddel_sum as seconds
            duration = moment.duration(sum.content_value, "hours").asSeconds();
        } else {
            // Check if form is crossing midnight
            if (end.asSeconds() < start.asSeconds()) {
                duration = 86400 - (start.asSeconds() - end.asSeconds()) - pause.asSeconds();
            } else {
                // Else just sum the duration
                duration = Math.abs(start.asSeconds() - end.asSeconds()) - pause.asSeconds();
            }
        }

        // Returning total working time as seconds
        return duration;
    }

    /**
     * Delete all notebook photos
     * physically and from the local storage
     */
    private deleteNotebookPhotos() {
        const photos = this.localStorageService.readObject(NOTEBOOK_PHOTOS);

        for (const photo of photos) {
            Filesystem.deleteFile({ path: photo.path, directory: Directory.Data }).catch((err) => {
                sentryCaptureException(err);
            });
        }

        this.store.dispatch(new SetNotebookPhotos(0));
        this.localStorageService.removeItem(NOTEBOOK_PHOTOS);
    }
}
