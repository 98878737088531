/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductsTask } from './ProductsTask';
import {
    ProductsTaskFromJSON,
    ProductsTaskFromJSONTyped,
    ProductsTaskToJSON,
} from './ProductsTask';

/**
 * 
 * @export
 * @interface GetTaskProducts200Response
 */
export interface GetTaskProducts200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetTaskProducts200Response
     */
    success: boolean;
    /**
     * 
     * @type {Array<ProductsTask>}
     * @memberof GetTaskProducts200Response
     */
    data: Array<ProductsTask>;
}

/**
 * Check if a given object implements the GetTaskProducts200Response interface.
 */
export function instanceOfGetTaskProducts200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetTaskProducts200ResponseFromJSON(json: any): GetTaskProducts200Response {
    return GetTaskProducts200ResponseFromJSONTyped(json, false);
}

export function GetTaskProducts200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTaskProducts200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'data': ((json['data'] as Array<any>).map(ProductsTaskFromJSON)),
    };
}

export function GetTaskProducts200ResponseToJSON(value?: GetTaskProducts200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'data': ((value.data as Array<any>).map(ProductsTaskToJSON)),
    };
}

