/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateContactRequestContactTypes } from './CreateContactRequestContactTypes';
import {
    CreateContactRequestContactTypesFromJSON,
    CreateContactRequestContactTypesFromJSONTyped,
    CreateContactRequestContactTypesToJSON,
} from './CreateContactRequestContactTypes';

/**
 * 
 * @export
 * @interface CreateContactRequest
 */
export interface CreateContactRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    cityId?: string | null;
    /**
     * 
     * @type {CreateContactRequestContactTypes}
     * @memberof CreateContactRequest
     */
    contactTypes: CreateContactRequestContactTypes;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    description?: string | null;
    /**
     * Street address
     * @type {string}
     * @memberof CreateContactRequest
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    website?: string | null;
    /**
     * Format like eg. `28680133` or `046158971404`
     * @type {string}
     * @memberof CreateContactRequest
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    cvr?: string | null;
    /**
     * If company has integration to an ERP system, and the contacts are synchronized, this will be the ERP-systems ID of this contact
     * @type {string}
     * @memberof CreateContactRequest
     */
    readonly erpId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    countryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    ean?: string | null;
    /**
     * To use this automagic field, city_id *must* be set to null - city_id takes precedence over this field
     * @type {string}
     * @memberof CreateContactRequest
     */
    cityName?: string;
    /**
     * To use this automagic field, city_id *must* be set to null - city_id takes precedence over this field
     * @type {string}
     * @memberof CreateContactRequest
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    paymentTermId?: string | null;
}

/**
 * Check if a given object implements the CreateContactRequest interface.
 */
export function instanceOfCreateContactRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactTypes" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateContactRequestFromJSON(json: any): CreateContactRequest {
    return CreateContactRequestFromJSONTyped(json, false);
}

export function CreateContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateContactRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cityId': !exists(json, 'city_id') ? undefined : json['city_id'],
        'contactTypes': CreateContactRequestContactTypesFromJSON(json['contact_types']),
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'cvr': !exists(json, 'cvr') ? undefined : json['cvr'],
        'erpId': !exists(json, 'erp_id') ? undefined : json['erp_id'],
        'countryId': !exists(json, 'country_id') ? undefined : json['country_id'],
        'ean': !exists(json, 'ean') ? undefined : json['ean'],
        'cityName': !exists(json, 'city_name') ? undefined : json['city_name'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'paymentTermId': !exists(json, 'payment_term_id') ? undefined : json['payment_term_id'],
    };
}

export function CreateContactRequestToJSON(value?: CreateContactRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city_id': value.cityId,
        'contact_types': CreateContactRequestContactTypesToJSON(value.contactTypes),
        'name': value.name,
        'description': value.description,
        'address': value.address,
        'email': value.email,
        'website': value.website,
        'phone': value.phone,
        'cvr': value.cvr,
        'country_id': value.countryId,
        'ean': value.ean,
        'city_name': value.cityName,
        'zip_code': value.zipCode,
        'payment_term_id': value.paymentTermId,
    };
}

