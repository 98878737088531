/* tslint:disable */
/* eslint-disable */
/**
 * Apacta
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Form } from './Form';
import {
    FormFromJSON,
    FormFromJSONTyped,
    FormToJSON,
} from './Form';
import type { MobileGetCards200ResponseDataInnerAllOfTask } from './MobileGetCards200ResponseDataInnerAllOfTask';
import {
    MobileGetCards200ResponseDataInnerAllOfTaskFromJSON,
    MobileGetCards200ResponseDataInnerAllOfTaskFromJSONTyped,
    MobileGetCards200ResponseDataInnerAllOfTaskToJSON,
} from './MobileGetCards200ResponseDataInnerAllOfTask';

/**
 * 
 * @export
 * @interface MobilePlanningGetCard200ResponseData
 */
export interface MobilePlanningGetCard200ResponseData {
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    readonly createdById?: string;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    readonly modifiedById?: string;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    readonly companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    userId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    taskId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    type?: MobilePlanningGetCard200ResponseDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    status?: MobilePlanningGetCard200ResponseDataStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    productsAdded?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    formTemplates?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    listIndex: number | null;
    /**
     * 
     * @type {Date}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    startTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    estimate?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    readonly modified?: Date;
    /**
     * Only present if it's a deleted object
     * @type {Date}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    readonly deleted?: Date | null;
    /**
     * 
     * @type {Array<Form>}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    forms?: Array<Form>;
    /**
     * 
     * @type {MobileGetCards200ResponseDataInnerAllOfTask}
     * @memberof MobilePlanningGetCard200ResponseData
     */
    task: MobileGetCards200ResponseDataInnerAllOfTask;
}


/**
 * @export
 */
export const MobilePlanningGetCard200ResponseDataTypeEnum = {
    Task: 'task'
} as const;
export type MobilePlanningGetCard200ResponseDataTypeEnum = typeof MobilePlanningGetCard200ResponseDataTypeEnum[keyof typeof MobilePlanningGetCard200ResponseDataTypeEnum];

/**
 * @export
 */
export const MobilePlanningGetCard200ResponseDataStatusEnum = {
    ToDo: 'to_do',
    Doing: 'doing',
    Done: 'done'
} as const;
export type MobilePlanningGetCard200ResponseDataStatusEnum = typeof MobilePlanningGetCard200ResponseDataStatusEnum[keyof typeof MobilePlanningGetCard200ResponseDataStatusEnum];


/**
 * Check if a given object implements the MobilePlanningGetCard200ResponseData interface.
 */
export function instanceOfMobilePlanningGetCard200ResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "taskId" in value;
    isInstance = isInstance && "listIndex" in value;
    isInstance = isInstance && "task" in value;

    return isInstance;
}

export function MobilePlanningGetCard200ResponseDataFromJSON(json: any): MobilePlanningGetCard200ResponseData {
    return MobilePlanningGetCard200ResponseDataFromJSONTyped(json, false);
}

export function MobilePlanningGetCard200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobilePlanningGetCard200ResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'modifiedById': !exists(json, 'modified_by_id') ? undefined : json['modified_by_id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'userId': json['user_id'],
        'taskId': json['task_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'productsAdded': !exists(json, 'products_added') ? undefined : json['products_added'],
        'formTemplates': !exists(json, 'form_templates') ? undefined : json['form_templates'],
        'listIndex': json['list_index'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'startTime': !exists(json, 'start_time') ? undefined : (json['start_time'] === null ? null : new Date(json['start_time'])),
        'estimate': !exists(json, 'estimate') ? undefined : json['estimate'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : new Date(json['deleted'])),
        'forms': !exists(json, 'forms') ? undefined : ((json['forms'] as Array<any>).map(FormFromJSON)),
        'task': MobileGetCards200ResponseDataInnerAllOfTaskFromJSON(json['task']),
    };
}

export function MobilePlanningGetCard200ResponseDataToJSON(value?: MobilePlanningGetCard200ResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'task_id': value.taskId,
        'type': value.type,
        'status': value.status,
        'products_added': value.productsAdded,
        'form_templates': value.formTemplates,
        'list_index': value.listIndex,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'start_time': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
        'estimate': value.estimate,
        'forms': value.forms === undefined ? undefined : ((value.forms as Array<any>).map(FormToJSON)),
        'task': MobileGetCards200ResponseDataInnerAllOfTaskToJSON(value.task),
    };
}

