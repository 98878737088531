import { Pagination } from "src/app/services/response-models/projects.response.model";
import { MaterialRental } from "src/app/services/response-models/materials.response.model";

export class LoadCheckOutMaterials {
    public static readonly type = "[Materials] Load check out materials";

    constructor(public payload: MaterialRental[]) {}
}

export class LoadCheckOutMaterialsPaginaton {
    public static readonly type = "[Materials] Load check out pagination";

    constructor(public payload: Pagination) {}
}

export class ClearCheckOutMaterials {
    public static readonly type = "[Materials] Clear check out materials";
}
