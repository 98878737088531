import { Component } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { SetAppLanguage } from "src/store/app/actions/set-lang.actions";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { PlatformService } from "src/app/services/state-initialization/platform.service";
import { AppState } from "src/store/app/app.state";
import { SegmentService } from "src/app/services/segment/segment.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { sentryCaptureException } from "src/app/utility/errors";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
})
export class AppComponent {
    @Select(AppState.getUserApiKey) apiKey$: Observable<string>;

    constructor(
        private store: Store,
        private translateService: TranslateService,
        private router: Router,
        private platformService: PlatformService,
        private segmentService: SegmentService,
        private localStorageService: LocalStorageService
    ) {
        try {
            this.initializeApp();
            this.segmentService.load();
            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    this.segmentService.trackPage(event.url);
                });
        } catch (error) {
            sentryCaptureException(error);
        }
    }

    public async initializeApp(): Promise<void> {
        const URL_API_KEY = this.router.parseUrl(window.location.search).queryParams.api_key;
        const URL_LANG = this.router.parseUrl(window.location.search).queryParams.lang;

        this.setDefaultLanguage(URL_LANG);
        const platform = await this.platformService.getAppConfig();

        if (URL_API_KEY && platform === "web") {
            // clear any cached stuff before loggin in with the key
            this.localStorageService.clear();
            sessionStorage.clear();

            this.platformService.initPlatformWithApiKey(URL_API_KEY);
            return;
        }

        this.platformService.initPlatform(platform);
    }

    /**
     * @description We use Great Britain lang :D
     * Gb - English
     * Dk - Danish
     * De - German
     * No - Norway
     * Ro - Romanian
     * Pl - Polish
     * Check lang in CS and use it
     * when other languages become available
     */
    private setDefaultLanguage(providedLang?: string): void {
        const languageOptions = ["gb", "dk", "de", "no", "ro", "pl", "ru"];
        this.translateService.addLangs(languageOptions);

        const shortLang = this.platformService.getShortLang(providedLang);
        if (shortLang) {
            providedLang = shortLang;
        }

        if (!!providedLang && languageOptions.indexOf(providedLang.toLocaleLowerCase()) >= 0) {
            this.translateService.setDefaultLang(providedLang.toLocaleLowerCase());
            this.store.dispatch(new SetAppLanguage(providedLang.toLocaleLowerCase()));

            return;
        }

        const defaultLanguage = "dk";
        this.translateService.setDefaultLang(defaultLanguage);
        this.store.dispatch(new SetAppLanguage(defaultLanguage));
    }
}
