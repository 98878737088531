import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Navigate } from "src/store/router/router.actions";
import { PlatformsEnum } from "src/app/shared/enums/platforms.enum";
import { FeaturesState } from "src/store/features/features.state";
import { Observable } from "rxjs";
import { MenuFeature } from "src/app/shared/models/menu-feature";
import { distinctUntilChanged, filter, switchMap, take } from "rxjs/operators";
import { RouterStateModule } from "src/store/router/router.state";
import { NavigationEnd, Router } from "@angular/router";
import { AppState } from "src/store/app/app.state";
import {
    StateInitializationInterface,
    StateInitializationService,
} from "src/app/services/state-initialization/state-initialization.service";
import { User } from "src/app/services/response-models/user.response.model";
import { ProjectStatusesService } from "src/app/services/project-statuses.service";
import { CompanySettingsService } from "src/app/services/company-settings.service";
import { CompaniesService } from "src/app/services/companies.service";
import { WindowWrapper } from "src/app/services/window-wrapper.service";
import { CurrenciesService } from "src/app/services/currencies.service";
import { UsersService } from "src/app/services/users.service";
import { TimeEntryService } from "src/app/services/time-entry.service";
import { CountriesService } from "src/app/services/countries.service";
import { SegmentService } from "src/app/services/segment/segment.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { FeaturesService } from "src/app/services/features.service";

@Injectable()
export class CompanyStateInitializationService
    extends StateInitializationService
    implements StateInitializationInterface
{
    @Select(AppState.getMenuVisibility) menuVisibility$: Observable<boolean>;
    @Select(FeaturesState.getMenuFeatures) menuFeatures$: Observable<MenuFeature[]>;

    constructor(
        protected companiesService: CompaniesService,
        protected companySettingsService: CompanySettingsService,
        protected projectStatusesService: ProjectStatusesService,
        protected store: Store,
        protected userService: UsersService,
        protected timeEntryTypesServices: TimeEntryService,
        protected currenciesService: CurrenciesService,
        protected countriesService: CountriesService,
        protected featuresService: FeaturesService,
        private router: Router,
        private _w: WindowWrapper,
        private segmentService: SegmentService,
        private localStorageService: LocalStorageService
    ) {
        super(
            companiesService,
            companySettingsService,
            projectStatusesService,
            userService,
            store,
            timeEntryTypesServices,
            currenciesService,
            countriesService,
            featuresService
        );
    }

    public canUse(user: User, platform: string): boolean {
        if (this.localStorageService.readObject("force_mobile_app") === true) {
            return false;
        }
        const window = this._w.getWindow();

        // Delete after new scheduler
        const searchParams = new URL(window.location).searchParams;
        if (searchParams.has("hide_sidebar") === true) {
            return true;
        }
        // Delete after new scheduler

        return (
            !!user.roles.find((role) => role.identifier === "company") &&
            platform === PlatformsEnum.Desktop &&
            window.location === window.parent.location
        );
    }

    public async init(): Promise<void> {
        await super.init();

        let userCompany = this.store.selectSnapshot(AppState.getUserCompany);

        if (!userCompany) {
            userCompany = await this.companiesService.setUserCompany();
        }

        await this.companySettingsService.getCompanySettings();
        this.segmentService.groupCompany(userCompany);
        this.navigate();

        this.displayControlPanel();
        await this.currenciesService.getCurrencies();
        await this.companiesService.getLangs();
    }

    protected navigate(): void {
        const window = this._w.getWindow();
        if (window.location.pathname === "/login" || window.location.pathname === "/sign-up") {
            this.store.dispatch(new Navigate(["companies", "overview"]));
        }
    }

    /**
     * Display control panel menu once features are loaded and navigation is finished.
     */
    private displayControlPanel(): void {
        this.menuFeatures$
            .pipe(
                filter((features) => features && features.length > 0),
                switchMap(() => this.menuVisibility$),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this.handleReloadPageMenuDisplay();
            });
    }

    /**
     * When we reload page with authenticated user we want to display
     * menu once navigation is done, otherwise we will see menu
     * before the actual page and there is flickering effect.
     */
    private handleReloadPageMenuDisplay(): void {
        const currentRoute = this.store.selectSnapshot(RouterStateModule.getRoute);
        if (currentRoute && currentRoute.length === 0) {
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    filter((event: NavigationEnd) => !event.url.includes("login")),
                    take(1)
                )
                .subscribe();
        }
    }
}
